import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import GenericPromises from "../../../api/GenericPromises";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SupplierBankAccount } from "../../../interfaces/Purchases/Catalogs/supplierBankAccounts";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, Switch, TextField, Typography } from "@mui/material";
import { PrimaryButton } from "../../../theme/buttons";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { Currency } from "../../../interfaces/Commons/currencies";

export const UpdateSupplierBankAccounts = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { supplierBankAccountPayload, permissions } = props;
  const isEdit = !permissions.update;
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([])
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
  } = useForm<SupplierBankAccount>({
    defaultValues: {
      bank_account_id: undefined
    }
  });
  const onSubmit: SubmitHandler<SupplierBankAccount> = (data) => onPut(data);

  const onPut = (data: SupplierBankAccount) => {
    if (!props.add) {
      let myData = {
        account_alias: data.account_alias ?? supplierBankAccountPayload.account_alias,
        bank_name: data.bank_name ?? supplierBankAccountPayload.bank_name,
        card_number: data.card_number ?? supplierBankAccountPayload.card_number,
        account_number: data.account_number ?? supplierBankAccountPayload.account_number,
        interbank_key: data.interbank_key ?? supplierBankAccountPayload.interbank_key,
        swift_code: data.swift_code ?? supplierBankAccountPayload.swift_code,
        phone_number: data.phone_number ?? supplierBankAccountPayload.phone_number,
        currency_id: data.currency_id ?? supplierBankAccountPayload.currency_id,
        bank_references: data.bank_references ?? supplierBankAccountPayload.bank_references,
        balance: data.balance ?? supplierBankAccountPayload.bank_references ?? 0,
        is_active: data.is_active ?? supplierBankAccountPayload.is_active
      }
      setLoadingPost(true);
      GenericPutResource(`/bankaccounts/${supplierBankAccountPayload.bank_account_id}`, myData)
        .then((response) => {
          let myUpdateData: SupplierBankAccount = {
            account_alias: response.data.account_alias,
            bank_name: response.data.bank_name,
            card_number: response.data.card_number,
            account_number: response.data.account_number,
            interbank_key: response.data.interbank_key,
            swift_code: response.data.swift_code,
            phone_number: response.data.phone_number,
            supplier_bank_account_id: supplierBankAccountPayload.supplier_bank_account_id,
            ...response.data
          };

          props.UpdateRow(myUpdateData);
          showSnackBar(t("generic.snackbar.update"), "success");
          setLoadingPost(false);
          props.setOpenDialog(false);
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
          setLoadingPost(false);
        });
    } else {
      let myDataBankAccount: SupplierBankAccount | undefined;
      if (!myDataBankAccount)
        myDataBankAccount = {
          bank_account_id: supplierBankAccountPayload.bank_account_id,
          account_alias: data.account_alias ?? supplierBankAccountPayload.account_alias,
          bank_name: data.bank_name ?? supplierBankAccountPayload.bank_name,
          card_number: data.card_number ?? supplierBankAccountPayload.card_number,
          account_number: data.account_number ?? supplierBankAccountPayload.account_number,
          interbank_key: data.interbank_key ?? supplierBankAccountPayload.interbank_key,
          swift_code: data.swift_code ?? supplierBankAccountPayload.swift_code,
          phone_number: data.phone_number ?? supplierBankAccountPayload.phone_number,
          currency_id: data.currency_id ?? supplierBankAccountPayload.currency_id,
          bank_references: data.bank_references ?? supplierBankAccountPayload.bank_references,
          balance: data.balance ?? supplierBankAccountPayload.bank_references ?? 0,
          is_active: data.is_active ?? supplierBankAccountPayload.is_active
        }
      if (myDataBankAccount)
        myDataBankAccount.supplier_bank_account_id = data.supplier_bank_account_id ?? supplierBankAccountPayload.supplier_bank_account_id;
      props.UpdateRow(myDataBankAccount);
      showSnackBar(t("generic.snackbar.update"), "success");
      setLoadingPost(false);
      props.setOpenDialog(false);
    }
  }

  const onDelete = () => {
    if (!props.add) {
      GenericDeleteResource(`/supplierbankaccounts/${supplierBankAccountPayload?.supplier_bank_account_id}`)
        .then(() => {
          setOpenDialog(false);
          props.DeleteRow(supplierBankAccountPayload?.supplier_bank_account_id);
          props.setOpenDialog(false);
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
          setOpenDialog(false);
        })
    } else {
      setOpenDialog(false);
      props.DeleteRow(supplierBankAccountPayload?.supplier_bank_account_id);
      props.setOpenDialog(false);
    }
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/currencies")
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboCurrencies((prev) => responses[0].data.data);
        reset({
          bank_account_id: supplierBankAccountPayload.bank_account_id ?? undefined,
          account_alias: supplierBankAccountPayload.account_alias ?? undefined,
          bank_name: supplierBankAccountPayload.bank_name ?? undefined,
          balance: supplierBankAccountPayload.balance ?? undefined,
          bank_references: supplierBankAccountPayload.bank_references ?? undefined,
          card_number: supplierBankAccountPayload.card_number ?? undefined,
          currency_id: supplierBankAccountPayload.currency_id ?? undefined,
          account_number: supplierBankAccountPayload.account_number ?? undefined,
          interbank_key: supplierBankAccountPayload.interbank_key ?? undefined,
          swift_code: supplierBankAccountPayload.swift_code ?? undefined,
          phone_number: supplierBankAccountPayload.phone_number ?? undefined,
          is_active: supplierBankAccountPayload.is_active ?? undefined
        });
        setDefaultCurrency(() => responses[0].data.data.find((currency: Currency) => currency.currency_id === supplierBankAccountPayload.currency_id))
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>
            <form onSubmit={handleSubmit(onSubmit)} className='w-100'>
              <div className='w-100'>
                <div className='w-100 d-flex'>
                  <div className='w-50'>
                    <Controller
                      name="account_alias"
                      control={control}
                      rules={{ required: true, maxLength: 50 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.account_alias")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.account_alias}
                          helperText={GetError(
                            errors.account_alias?.type
                          )}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>

                  <div className='w-50'>
                    <Controller
                      name="bank_name"
                      control={control}
                      rules={{ required: true, maxLength: 150 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.bank_name")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.bank_name}
                          helperText={GetError(
                            errors.bank_name?.type
                          )}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="card_number"
                      control={control}
                      rules={{ maxLength: 19 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.card_number")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.card_number}
                          helperText={GetError(
                            errors.card_number?.type
                          )}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>

                  <div className='w-50'>
                    <Controller
                      name="account_number"
                      control={control}
                      rules={{ maxLength: 24 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.account_number")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.account_number}
                          helperText={GetError(
                            errors.account_number?.type
                          )}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="interbank_key"
                      control={control}
                      rules={{ maxLength: 18 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.interbank_key")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.interbank_key}
                          helperText={GetError(
                            errors.interbank_key?.type
                          )}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>

                  <div className='w-50'>
                    <Controller
                      name="swift_code"
                      control={control}
                      rules={{ maxLength: 11 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.swift_code")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.swift_code}
                          helperText={GetError(
                            errors.swift_code?.type
                          )}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="bank_references"
                      control={control}
                      rules={{ maxLength: 100 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.bank_references")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.bank_references}
                          helperText={GetError(
                            errors.bank_references?.type
                          )}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>
                  <div className='w-50'>
                    <Controller
                      name="phone_number"
                      control={control}
                      rules={{ maxLength: 20 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.phone_number")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.phone_number}
                          helperText={GetError(
                            errors.phone_number?.type
                          )}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="currency_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                          options={comboCurrencies}
                          defaultValue={defaultCurrency}
                          getOptionLabel={(option) => `${option.currency_code} - ${option.currency_description}`}
                          renderOption={(props, option: Currency) => (
                            <div key={option.currency_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.currency_code} - {option.currency_description}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.currency_id === value.currency_id}
                          onChange={(_, values) => {
                            field.onChange(values?.currency_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("currencies.title-view")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.currency_id}
                              helperText={GetError(errors.currency_id?.type)}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                  <div className='w-50'>
                    <Controller
                      name="balance"
                      control={control}
                      rules={{ required: true, min: 0 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("bankaccounts.fields.balance")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.balance}
                          helperText={GetError(
                            errors.balance?.type
                          )}
                          size="small"
                          type='number'
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                            "& input[type=number]": { MozAppearance: "textfield", }, width: "100%"
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className='w-100 d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="is_active"
                      control={control}
                      render={({ field }) =>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                          <Typography sx={{ ml: 2 }}>{t("bankaccounts.fields.is_active")}</Typography>
                          <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={supplierBankAccountPayload.is_active} />
                        </Box>
                      }
                    />
                  </div>
                </div>

                <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
                  <div>
                    <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                    {permissions.update && <PrimaryButton onClick={() => { onPut(getValues()) }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
                  </div>
                  {permissions.delete ?
                    <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
                  }
                </div>
              </div>
            </form>
          </Box>
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("supplierbankaccounts.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
