import { Text, View } from '@react-pdf/renderer'

export const PaymentData = ({ data, showTranslatedText }: any) => {
  return (
       <View fixed style={{ width: '90%', alignSelf: 'center', height:65, borderWidth: 1, flexDirection: 'row',marginBottom:10 }}>
       <View style={{ width: '50%', height: '100%', flexDirection: 'column', borderRightWidth: 1, }}>
           <View style={{  justifyContent: 'center', borderBottomWidth: 1, padding:3 }}>
               <Text style={{ fontSize: 7, fontWeight: 700 }}>{showTranslatedText("reports.paymentsPdf.paymentData.receiverDataSupplier")}</Text>
           </View>
           <View style={{  flexDirection:'row', justifyContent:'space-between',padding:3}}>
                <View style={{ width: '30%', justifyContent: 'space-around' }} >
                    <Text style={{ fontSize: 7, fontWeight:700  }}>{showTranslatedText("reports.paymentsPdf.paymentData.bankName")}: </Text>
                    <Text style={{ fontSize: 7, fontWeight:700  }}>{showTranslatedText("reports.paymentsPdf.paymentData.bankAccount")}: </Text>
                    <Text style={{ fontSize: 7, fontWeight:700  }}>{showTranslatedText("reports.paymentsPdf.paymentData.swiftCode")}: </Text>
                    <Text style={{ fontSize: 7, fontWeight:700  }}>{showTranslatedText("reports.paymentsPdf.paymentData.interbankKey")}: </Text>
               </View>
               <View style={{ width: '60%', justifyContent: 'space-around' }}>
                    <Text style={{ fontSize: 7 }}> {data.dataReceiptHeader?.supplier_bank_name || " "}</Text>
                    <Text style={{ fontSize: 7 }}> {data.dataReceiptHeader?.supplier_account_number || " "}</Text>
                    <Text style={{ fontSize: 7 }}> {data.dataSupplierBankDetails?.swift_code || " "}</Text>
                    <Text style={{ fontSize: 7 }}> {data.dataSupplierBankDetails?.interbank_key || " "}</Text>
               </View>
           </View>
       </View>
       <View style={{ width: '50%', height: '100%', flexDirection: 'column', }}>
           <View style={{ justifyContent: 'center', borderBottomWidth: 1, padding:3 }}>
               <Text style={{ fontSize: 7, fontWeight: 700 }}>{showTranslatedText("reports.paymentsPdf.paymentData.issuerData")}</Text>
           </View>
           <View style={{  flexDirection:'row', justifyContent:'space-between',padding:3}} >
                <View style={{ width: '30%', justifyContent: 'space-around' }} >
                    <Text style={{ fontSize: 7, fontWeight:700 }}>{showTranslatedText("reports.paymentsPdf.paymentData.bankName")}: </Text>
                    <Text style={{ fontSize: 7, fontWeight:700 }}>{showTranslatedText("reports.paymentsPdf.paymentData.bankAccount")}: </Text>
                    <Text style={{ fontSize: 7, fontWeight:700 }}>{showTranslatedText("reports.paymentsPdf.paymentData.swiftCode")}: </Text>
                    <Text style={{ fontSize: 7, fontWeight:700 }}>{showTranslatedText("reports.paymentsPdf.paymentData.interbankKey")}: </Text>
               </View>
               <View style={{ width: '60%', justifyContent: 'space-around' }}>
                    <Text style={{ fontSize: 7 }}> {data.dataReceiptHeader?.company_bank_name || " "}</Text>
                    <Text style={{ fontSize: 7 }}> {data.dataReceiptHeader?.company_account_number || " "}</Text>
                    <Text style={{ fontSize: 7 }}> {data.dataCompanyBankDetails?.swift_code || " "}</Text>
                    <Text style={{ fontSize: 7 }}> {data.dataCompanyBankDetails?.interbank_key || " "}</Text>
               </View>
           </View>
       </View>
   </View>
  )
}
