import { Theme } from "@emotion/react";
import { Grid, SxProps } from "@mui/material";

interface StagesContainerProps {
  children: React.ReactNode,
}

const StagesContainerStyles: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  scrollbarWidth: 'none',
  WebkitOverflowScrolling: 'touch',
  padding: '0 24px 24px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const StagesContainer = ({
  children,
}: StagesContainerProps) => {
  return (
    <Grid
      container
      spacing={4}
      wrap="nowrap"
      sx={StagesContainerStyles}
    >
      {children}
    </Grid>
  )
}
