import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { ProjectExtraField } from "../../../../interfaces/Projects/Catalogs/projectExtraFields";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, Box, TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";
import { FieldType } from "../../../../interfaces/Projects/Catalogs/fieldTypes";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";

type AddProjectExtraFieldsProps = {
  project_id: number;
  AddNewRow: (newRow: ProjectExtraField) => void;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  isClosedProject: boolean;
};

export const AddProjectExtraFields = ({
  project_id,
  AddNewRow,
  setOpenDialog,
  isClosedProject,
}: AddProjectExtraFieldsProps) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isTypeFormula, setIsTypeFormula] = useState(false);
  const [comboFieldTypes, setComboFieldTypes] = useState<FieldType[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ProjectExtraField>({
    defaultValues: {
      field_type_id: undefined,
      project_id: undefined,
      project_extra_field_name: undefined,
      project_extra_field_value: undefined,
      project_extra_field_order: undefined,
    },
  });
  const onSubmit: SubmitHandler<ProjectExtraField> = (data) => onPost(data);

  const onPost = (data: ProjectExtraField) => {
    let myData = {
      field_type_id: data.field_type_id ?? null,
      project_id: project_id ?? null,
      project_extra_field_name: data.project_extra_field_name ?? null,
      project_extra_field_value: data.project_extra_field_value ?? null,
      project_extra_field_order: data.project_extra_field_order,
    };
    setLoadingPost(true);
    GenericPostResource("/projectextrafields", myData)
      .then((response) => {
        let myFieldType = comboFieldTypes.find(
          (fieldType: FieldType) =>
            fieldType.field_type_id === myData.field_type_id
        );
        let myNewRow = {
          ...response.data,
          field_type_name: myFieldType?.field_type_name,
        };
        AddNewRow(myNewRow);
        setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios
    setOpenDialog(false);
  };

  useEffect(() => {
    let myPromises = [GenericGetResource("/fieldtypes")];

    Promise.all(myPromises)
      .then((responses) => {
        setComboFieldTypes(responses[0].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }, []);

  useEffect(() => { }, [isTypeFormula]);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4 w-100">
            <div className="d-flex">
              <div className="w-50">
                <Controller
                  name="project_extra_field_name"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectextrafields.fields.project_extra_field_name"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_extra_field_name}
                      helperText={GetError(
                        errors.project_extra_field_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={isClosedProject}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{
                        width: "100%",
                        paddingRight: isTypeFormula ? 2 : 0,
                      }}
                      options={comboFieldTypes}
                      getOptionLabel={(option) => option.field_type_name}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.field_type_id === value.field_type_id
                      }
                      onChange={(_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (
                          comboFieldTypes.find(
                            (item) =>
                              item.field_type_id === values?.field_type_id &&
                              item.field_type_name.toLowerCase() === "formula"
                          )
                        ) {
                          setIsTypeFormula(true);
                        } else {
                          setIsTypeFormula(false);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("projectextrafields.fields.field_type")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.field_type_id}
                          helperText={GetError(errors.field_type_id?.type)}
                          disabled={isClosedProject}
                        />
                      )}
                    />
                  )}
                />
              </div>
              {isTypeFormula && (
                <div className="w-50">
                  <Controller
                    name="project_extra_field_value"
                    control={control}
                    rules={{ required: true, maxLength: 3000 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t(
                          "projectextrafields.fields.project_extra_field_value"
                        )}`}
                        ref={field.ref}
                        value={field.value}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        error={!!errors.project_extra_field_value}
                        helperText={GetError(
                          errors.project_extra_field_value?.type
                        )}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={isClosedProject}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="d-flex">
              <div className="w-100 mt-3">
                <Controller
                  name="project_extra_field_order"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t("projectextrafields.fields.project_extra_field_order")}`}
                      type="number"
                      ref={field.ref}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          { display: "none" },
                        "& input[type=number]": { MozAppearance: "textfield" },
                        paddingRight: 2
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_extra_field_order}
                      helperText={GetError(errors.project_extra_field_order?.type)}
                      size="small"
                      style={{ width: "50%" }}
                    />
                  )}
                />
              </div>
              {isTypeFormula &&
                <div className="w-50"></div>
              }
            </div>

            <div className="d-flex flex-row-reverse mt-3">
              <PrimaryButton
                type="submit"
                variant="outlined"
                className="m-1"
                disabled={loadingPost}
              >
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled
                variant="contained"
                className="m-1"
                onClick={onCancel}
              >
                {t("generic.buttons.cancel")}
              </ButtonStyled>
            </div>
          </form>
        </Box>
      )}
      <SnackbarComponent />
    </>
  );
};
