import { useTranslation } from "react-i18next";

import { ProjectStage } from "../../../../../../interfaces/Projects/Catalogs/projectStages";
import GenericPromises from "../../../../../../api/GenericPromises";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Stage } from "../../../../../../interfaces/Projects/Catalogs/stages";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { myStylePickers, PrimaryButton } from "../../../../../../theme/buttons";
import dayjs from "dayjs";
import { GenericDialog } from "../../../../../../components/Dialogs/Dialog";
import { DialogEntity } from "../../../../../../components/Dialogs/DialogEntity";
import { Header } from "../../../../../../components/Header";
import { ConfirmDialog } from "./confirmDialog";

export const UpdateProjectStages = ({ ...props }) => {
  const [t] = useTranslation("global");
  const {
    GenericPutResource,
    GenericDeleteResource,
    GenericGetResource,
    GenericPostResource,
  } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [comboStages, setComboStages] = useState<Stage[]>([]);
  const { permissions, projectStagePayload } = props;
  const [
    projectStageStartedAt,
    setProjectStageStartedAt,
  ] = useState<Date | null>();

  const [defaultStage, setDefaultStage] = useState<Stage>();
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [dialogConfirmDeleteStage, setDialogConfirmDeleteStage] = useState(false);
  const [inputStage, setInputStage] = useState("");
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    resetField,
    getValues
  } = useForm<ProjectStage>();

  const onSubmit: SubmitHandler<ProjectStage> = (data) => onPut(data);

  const onPut = (data: ProjectStage) => {
    let myData = {
      project_id: props.project_id,
      stage_id: data.stage_id,
      project_stage_name: data.project_stage_name,
      project_stage_consecutive:
        data.project_stage_consecutive,
      project_stage_description:
        data.project_stage_description || null,
      project_stage_started_at: data.project_stage_started_at || null,
      project_stage_due_date: data.project_stage_due_date || null,
    };
    setLoadingPost(true);
    GenericPutResource(
      `/projectstages/${projectStagePayload.project_stage_id}`,
      myData
    )
      .then((response) => {
        let myUpdateData: ProjectStage = response.data;
        props.handleUpdateStage(myUpdateData);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  };

  const onDelete = () => {
    GenericDeleteResource(
      `/projectstages/${projectStagePayload.project_stage_id}`
    )
      .then(() => {
        setOpenDialog(false);
        props.handleDeleteStage(
          projectStagePayload.project_stage_id
        );
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };


  const handleAddStage = (stage_name: string) => {
    const existingStage = comboStages.find(
      (stage) => stage.stage_name === stage_name
    );
    if (existingStage) return existingStage;

    let myData = {
      stage_name: stage_name,
      stage_description: null,
    };
    setLoadingPost(true);
    GenericPostResource("/stages", myData)
      .then((response) => {
        setComboStages([...comboStages, response.data]);

        return response.data;
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  useEffect(() => {
    let myPromises = [GenericGetResource("/stages")];
    Promise.all(myPromises)
      .then((responses) => {
        setComboStages(responses[0].data.data);
        reset({
          project_id: projectStagePayload.project_id,
          stage_id: projectStagePayload.stage_id,
          project_stage_name:
            projectStagePayload.project_stage_name,
          project_stage_consecutive:
            projectStagePayload.project_stage_consecutive,
          project_stage_description:
            projectStagePayload.project_stage_description,
          project_stage_started_at: projectStagePayload.project_stage_started_at || null,
          project_stage_due_date:projectStagePayload?.project_stage_due_date || null
        });
        setProjectStageStartedAt(projectStagePayload.project_stage_started_at);
        setDefaultStage(
          responses[0].data.data.find(
            (stage: Stage) =>
              stage.stage_id === projectStagePayload.stage_id
          )
        );
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1">
              <div className="w-50">
                <Controller
                  name="stage_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboStages}
                      defaultValue={defaultStage}
                      getOptionLabel={(option) => `${option.stage_name}`}
                      renderOption={(props, option: Stage) => (
                        <div key={option.stage_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.stage_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.stage_id === value.stage_id
                      }
                      onInputChange={(_, newInputValue) => {
                        setInputStage(newInputValue);
                      }}
                      onChange={(_, values) => {
                        field.onChange(values?.stage_id || null);
                      }}
                      noOptionsText={
                        <Button
                          onMouseDown={() => {
                            if (inputStage.trim()) {
                              const newStage = handleAddStage(inputStage);
                              field.onChange(newStage?.stage_id || null);
                            }
                          }}
                        >
                         { t("projectstages.snackbar.actions")}
                        </Button>
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("projectstages.fields.stage")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.stage_id}
                          helperText={GetError(errors.stage_id?.type)}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_stage_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstages.fields.project_stage_name"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_name}
                      helperText={GetError(
                        errors.project_stage_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_consecutive"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstages.fields.project_stage_consecutive"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_consecutive}
                      helperText={GetError(
                        errors.project_stage_consecutive?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_stage_description"
                  control={control}
                  rules={{ }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstages.fields.project_stage_description"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_description}
                      helperText={GetError(
                        errors.project_stage_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_started_at"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <DatePicker
                      open={openStartDateDatePicker}
                      onClose={() => setOpenStartDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projectstages.fields.project_stage_started_at"
                      )}`}
                      value={
                        field.value
                          ? dayjs(field.value)
                          : null
                      }
                      onChange={(values:any) => {
                        field.onChange(values?.toDate() || null);
                        setProjectStageStartedAt(values?.toDate() || null);
                        setValue('project_stage_due_date', null, {shouldValidate:true})
                      }}
                      slotProps={{
                        popper: {
                          sx: myStylePickers
                        },
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenStartDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.project_stage_started_at,
                          helperText: GetError(
                            errors.project_stage_started_at?.type
                          ),
                        },
                      }}
                      disabled={isEdit}
                      format="DD/MM/YYYY"
                      sx={{ paddingRight: 2 }}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_stage_due_date"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <DatePicker
                      open={openEndDateDatePicker}
                      onClose={() => setOpenEndDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projectstages.fields.project_stage_due_date"
                      )}`}
                      onChange={(values:any) => {
                        field.onChange(values?.toDate() || null);
                      }}
                      value={
                        field.value
                          ? dayjs(field.value)
                          : null
                      }
                      slotProps={{
                        popper: {
                          sx: myStylePickers
                        },
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenEndDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.project_stage_due_date,
                          helperText: GetError(
                            errors.project_stage_due_date?.type
                          ),
                        },
                      }}
                      minDate={dayjs(projectStageStartedAt)}
                      format="DD/MM/YYYY"
                      disabled={
                        !projectStageStartedAt || isEdit
                          ? true
                          : false
                      }
                      sx={{ paddingRight: 2 }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex flex-row-reverse mt-4 justify-content-between">
              <div>
                <Button
                  variant="outlined"
                  className="m-1"
                  onClick={() => props.setOpenDialog(false)}
                >
                  {t("generic.buttons.goback")}
                </Button>
                {permissions.update && (
                  <PrimaryButton
                    type="submit"
                    variant="outlined"
                    className="m-1"
                    disabled={loadingPost}
                  >
                    {t("generic.buttons.update")}
                  </PrimaryButton>
                )}
              </div>
              {permissions.delete ? (
                <PrimaryButton onClick={() => setDialogConfirmDeleteStage(true)}>
                  {t("generic.buttons.delete")}
                </PrimaryButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </Box>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projectstages.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <DialogEntity
        open={dialogConfirmDeleteStage}
        title={<Header title={t("projectstages.title-view")} />}
        maxWidth={"sm"}
        fullWidth
        content={
          <ConfirmDialog
            getValues={getValues}
            isEdit={isEdit}
            loadingPost={loadingPost}
            setDialogConfirmDeleteStage={setDialogConfirmDeleteStage}
            onDelete={onDelete}
          />
        }
      />
      <SnackbarComponent />
    </>
  );
};
