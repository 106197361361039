import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useTranslation } from "react-i18next"
import { PrimaryButton } from "../../../theme/buttons";

interface DialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    title: string,
    content: string,
    onAcept: () => void,
}

export const GenericDialog = ({ open, setOpen, title, content, onAcept }: DialogProps) => {
    const [t] = useTranslation("global");
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" className="m-1" onClick={() => setOpen(false)}>{t("generic.buttons.cancel")}</Button>
                <PrimaryButton variant="outlined" type="button" className="btn btn-primary m-1" onClick={onAcept}>{t("generic.buttons.accept")}</PrimaryButton>
            </DialogActions>
        </Dialog>
    )
}
