import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import { Menuitem } from "../../../../../../interfaces/Security/menu";
import GenericPromises from "../../../../../../api/GenericPromises";
import { AuthContext } from "../../../../../../context/AuthContext";
import { usePermissions } from "../../../../../../hooks/usePermissions";
import { defaultTheme } from "../../../../../../theme/theme";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";
import { Box, Button, Grid } from "@mui/material";
import { DialogEntity } from "../../../../../../components/Dialogs/DialogEntity";
import { Header } from "../../../../../../components/Header";
import { useColors } from "../../../../../../theme/hooks/useColors";
import { ProjectTask } from "../../../../../../interfaces/Projects/Catalogs/projectTask";
import { CreateButton } from "../../../../../../theme/projects/Buttons/createButton";
import { TaskButton } from "../../../../../../theme/projects/Buttons/taskButton";
import { PrimaryButton } from "../../../../../../theme/buttons";
import { Edit } from "@mui/icons-material";
import { AddProjectTask } from "./add";
import { UpdateProjectTask } from "./update";
import { TaskItem } from "../components/taskItem";

type TableProjectTaskProps={
  project_stage_id: number | undefined,
  project_id: number | undefined
}

export const TableProjectTask = ({ 
  project_stage_id,
  project_id
 }:TableProjectTaskProps) => {
  const [t] = useTranslation("global");
  const [dataloaded, setDataLoaded] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [openDialogAddTask, setOpenDialogAddTask] = useState(false);
  const [openDialogUpdateTask, setOpenDialogUpdateTask] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>();
  const [projectTaskLocal, setProjectTaskLocal] = useState<ProjectTask[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [color, setColor] = useState<number[]>();
  const { HexToRGB } = useColors();
  const { user } = useContext(AuthContext);
  const { GetResourceByUrl } = usePermissions();

  const handleAddTask = (newRow: ProjectTask) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date(),
    };

    setProjectTaskLocal((prevState) => {
      return [...projectTaskLocal, myRow];
    });
  };

  const handleUpdateTask = (updateRow: ProjectTask) => {
    if (projectTaskLocal.length === 0) {
      return;
    }
    setProjectTaskLocal((prevRows) => {
      const rowToUpdateIndex = projectTaskLocal.findIndex(
        (e) =>
          e.project_task_id ===
          updateRow.project_task_id
      );

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row
      );
    });
  };

  const handleDeleteTask = (deleteRow: number) => {
    if (projectTaskLocal.length === 0) {
      return;
    }

    setProjectTaskLocal((prevRows) => {
      const rowToDeleteIndex = projectTaskLocal.findIndex(
        (e) => e.project_task_id === deleteRow
      );

      const updatedData = [
        ...projectTaskLocal.slice(0, rowToDeleteIndex),
        ...projectTaskLocal.slice(rowToDeleteIndex + 1),
      ];

      return updatedData;
    });
  };

  useEffect(() => {
    GenericGetResource(
      `/projecttask/byprojectstageid/${project_stage_id}`
    )
      .then(async (response) => {
        setProjectTaskLocal(response.data.data);
        setColor(HexToRGB(defaultTheme.palette.primary.main));
        GetResourceByUrl(`/projecttask`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <div className="d-flex mt-4">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CreateButton color={color} onClick={() => { setOpenDialogAddTask(true); }}>
                <AddIcon /> {t("projecttasks.actions.create")}
              </CreateButton>
            </Grid>
            {projectTaskLocal.map((task) => (
              <TaskItem
                task={task}
                color={color}
                setSelectedTaskId={setSelectedTaskId}
                setOpenDialogUpdateTask={setOpenDialogUpdateTask}
                isEdit={!resourceScreen?.update}
              />
            ))}
          </Grid>
        </div>
      )}

      <DialogEntity
        content={
          <AddProjectTask
            setOpenDialogAddTask={setOpenDialogAddTask}
            project_id={project_id}
            project_stage_id={project_stage_id}
            handleAddTask={handleAddTask}
          />
        }
        open={openDialogAddTask}
        setOpen={setOpenDialogAddTask}
        title={
          <Header
            title={t("projecttasks.title-view")}
            size="sm"
          />
        }
      />

      <DialogEntity
        content={
          <UpdateProjectTask
            permissions={resourceScreen}
            setOpenDialogUpdateTask={setOpenDialogUpdateTask}
            handleUpdateTask={handleUpdateTask}
            handleDeleteTask={handleDeleteTask}
            project_id={project_id}
            projectTaskPayload={
              projectTaskLocal?.find(
                (task) => task.project_task_id === selectedTaskId
              ) || {}
            }
          />
        }
        open={openDialogUpdateTask}
        setOpen={setOpenDialogUpdateTask}
        title={
          <Header
            title={t("projecttasks.title-view")}
            size="sm"
          />
        }
      />
      {/* {drawerOpen && selectedTaskId !== undefined && (
        <TableProjectTaskReminders
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          projectTaskPayload={
            projectTaskLocal.find((task) => task.project_task_id === selectedTaskId) || {}
          }
        />
      )} */}
      <SnackbarComponent />
    </>
  );
};
