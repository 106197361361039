import { Header } from '../../../../../components/Header/index';
import { Autocomplete, Avatar, Box, Button, Grow, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import GenericPromises from '../../../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ButtonStyled, PrimaryButton } from '../../../../../theme/buttons';
import { useTranslation } from 'react-i18next';
import { Customer, CustomerBankAccount, CustomerLocation } from '../../../../../interfaces/Sales/Catalogs/Customers/customers';
import { emailRegex, rfcRegex } from '../../../../../constants/Regex';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { File } from '../../../../../interfaces/Commons/files';
import { ImageDataComponent } from '../../../../../components/Images/ImageData';
import { useFiles } from '../../../../../hooks/useFiles';
import { TaxRegime } from '../../../../../interfaces/Sales/Catalogs/taxRegimes';
import { Currency } from '../../../../../interfaces/Commons/currencies';
import { Tax } from '../../../../../interfaces/Sales/Catalogs/taxes';
import { PaymentMode } from '../../../../../interfaces/Sales/Catalogs/paymentModes';
import { PaymentMethod } from '../../../../../interfaces/Sales/Catalogs/paymentMethods';
import { CFDI } from '../../../../../interfaces/Sales/Catalogs/CFDIs';
import { Retention } from '../../../../../interfaces/Sales/Catalogs/retentions';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomerDetail from './customerDetail';
import { FinancialDataTab } from './financialData';
import { TableCustomerLocations } from '../CustomerLocations/table';
import { INCOTERM } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/INCOTERMs';
import { PaymentCondition } from '../../../../../interfaces/Sales/Catalogs/paymentConditions';
import { Divider } from '../../../../../components/Commons/Divider';
import { CustomKeyCode } from '../../../../../interfaces/Sales/Catalogs/customsKeyCodes';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { Language } from '../../../../../interfaces/Commons/languages';
import { BrowserCustomerBankAccounts } from '../CustomerBankAccounts';

export const AddCustomer = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericGetResource, GenericPostResource, GenericPutResource, GenericGetResourceGeneric } = GenericPromises();
  const { DecodeFileToBase64, GetContentURLImage, PostFiles } = useFiles();
  const { GetError } = FormsErrors();
  const { SnackbarComponent, showSnackBar } = useSnackBar();
  const [hideDetail, setHideDetail] = useState(true);
  const [loadingPost, setLoadingPost] = useState(false);
  const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
  const [comboTaxRegimes, setComboTaxRegimes] = useState<TaxRegime[]>([]);
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [comboTaxes, setComboTaxes] = useState<Tax[]>([]);
  const [comboPaymentModes, setComboPaymentModes] = useState<PaymentMode[]>([]);
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [comboCFDIs, setComboCFDIs] = useState<CFDI[]>([]);
  const [comboRetentions, setComboRetentions] = useState<Retention[]>([]);
  const [comboINCOTERMs, setComboINCOTERMs] = useState<INCOTERM[]>([]);
  const [comboPaymentConditions, setComboPaymentConditions] = useState<PaymentCondition[]>([]);
  const [comboCustomsKeyCodes, setComboCustomsKeyCodes] = useState<CustomKeyCode[]>([]);
  const [comboLanguages, setComboLanguages] = useState<Language[]>([]);
  const [locationsData, setLocationsData] = useState<CustomerLocation[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [defaultTax, setDefaultTax] = useState<Tax>();
  const [defaultPaymentMode, setDefaultPaymentMode] = useState<PaymentMode>();
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [defaultCFDI, setDefaultCFDI] = useState<CFDI>();
  const [defaultRetention, setDefaultRetention] = useState<Retention>();
  const [defaultINCOTERM, setDefaultINCOTERM] = useState<INCOTERM>();
  const [defaultPaymentCondition, setDefaultPaymentCondition] = useState<PaymentCondition>();
  const [defaultCustomsKeyCode, setDefaultCustomsKeyCode] = useState<CustomKeyCode>();
  const [BankAccountsData, setBankAccountsData] = useState<CustomerBankAccount[]>([])
  const [defaultPrimaryBankAccount, setDefaultPrimaryBankAccount] = useState<CustomerBankAccount>()

  const [isNotMexican, setIsNotMexican] = useState(false);

  const [imageFile, setImageFile] = useState<File>({});
  const [imageLoaded, setImageLoaded] = useState(false);

  const [openCustomerBankAccounts, setOpenCustomerBankAccounts] = useState(false);

  const genericCombo = [
    { id: 1, name: "Cliente" },
    { id: 2, name: "Artículo" }
  ];

  const salesordersCombo = [
    ...genericCombo,
    { id: 3, name: "Cotización" }
  ];

  const documentsCombo = [
    ...genericCombo,
    { id: 4, name: "Pedido" }
  ];

  const invoiceCombo = [
    ...genericCombo,
    { id: 5, name: "Documento de envío" }
  ];

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Customer>({
    defaultValues: {
      business_name: "",
      comercial_name: "",
      customer_rfc: "",
      customer_email: "",
      customer_phone_number: "",
      customer_web: "",
      customer_identifier: "",
      tax_regime_id: undefined,
      default_currency_id: undefined,
      default_tax_id: undefined,
      default_payment_mode_id: undefined,
      default_payment_method_id: undefined,
      default_payment_condition_id: undefined,
      default_cfdi_id: undefined,
      default_retention_id: undefined,
      group_by: undefined,
      group_salesorders_by: undefined,
      group_backorders_by: undefined,
      group_shippingdocuments_by: undefined,
      group_invoices_by: undefined,
      language_id: undefined,
      primary_bank_account: undefined,
      tax_regime: '',
    }
  });

  const onSubmit: SubmitHandler<Customer> = (data) => onPost(data);

  const onPost = async (data: Customer) => {
    if (locationsData.length > 0) {
      trigger()
        .then(async (responseTrigger) => {
          if (responseTrigger) {
            setLoadingPost(true);
            let error = ""
            let defaultBankId: number | undefined;
            if (!(locationsData.find((location: CustomerLocation) => location.fiscal_address == true))) error = t("customers.dialog.no_fiscal")
            if (error == "") {
              let myData = {
                business_name: data.business_name.trim(),
                comercial_name: data.comercial_name ?? null,
                customer_rfc: data.customer_rfc.trim(),
                customer_email: data.customer_email ?? null,
                customer_phone_number: data.customer_phone_number ?? null,
                customer_web: data.customer_web ?? null,
                customer_identifier: data.customer_identifier ?? null,
                tax_regime_id: data.tax_regime_id ?? null,
                language_id: data.language_id,
                default_currency_id: defaultCurrency?.currency_id ?? data.default_currency_id ?? null,
                default_tax_id: data.default_tax_id ?? defaultTax?.tax_id ?? null,
                default_payment_mode_id: data.default_payment_mode_id ?? defaultPaymentMode?.payment_mode_id ?? null,
                default_payment_method_id: data.default_payment_method_id ?? defaultPaymentMethod?.payment_method_id ?? null,
                default_cfdi_id: data.default_cfdi_id ?? defaultCFDI?.cfdi_id ?? null,
                default_retention_id: data.default_retention_id ?? defaultRetention?.retention_id ?? null,
                default_incoterm_id: data.default_incoterm_id ?? defaultINCOTERM?.incoterm_id ?? null,
                default_payment_condition_id: data.default_payment_condition_id ?? defaultPaymentCondition?.payment_condition_id ?? null,
                default_customs_key_code_id: data.default_customs_key_code_id ?? defaultCustomsKeyCode?.customs_key_code_id ?? null,
                group_by: data.group_by ?? 0,
                group_salesorders_by: data.group_salesorders_by ?? null,
                group_backorders_by: data.group_backorders_by ?? null,
                group_shippingdocuments_by: data.group_shippingdocuments_by ?? null,
                group_invoices_by: data.group_invoices_by ?? null,
              }
              await GenericPostResource("/customers", myData)
                .then(async (response) => {
                  locationsData.forEach(location => {
                    let newLocation = {
                      country_id: location.country_id,
                      state_id: location.state_id,
                      city_id: location.city_id,
                      postal_code: location.postal_code ?? null,
                      street: location.street ?? null,
                      contact_name: location.contact_name ?? null,
                      business_name: location.business_name,
                      customer_rfc: location.customer_rfc,
                      customer_email: location.customer_email ?? null,
                      customer_phone_number: location.customer_phone_number ?? null,
                      customer_web: location.customer_web ?? null,
                      fiscal_address: location.fiscal_address ?? false,
                      delivery_address: location.delivery_address ?? false,
                      default_delivery_address: location.default_delivery_address ?? false,
                      customer_id: response.data.customer_id,
                      tax_regime_id: location.tax_regime_id
                    }
                    GenericPostResource("/customerlocations", newLocation).catch((error) => {
                      showSnackBar(error.message, "error");
                    })
                  });
                  for (const bankAccount of BankAccountsData) {
                    let isPrimary = false;
                    let bankAccountId: number | undefined = undefined;
                    if (bankAccount.bank_account_id == 0) {
                      let newBankAccount = {
                        account_alias: bankAccount?.account_alias ?? null,
                        bank_name: bankAccount.bank_name,
                        card_number: bankAccount.card_number ?? null,
                        account_number: bankAccount.account_number ?? null,
                        interbank_key: bankAccount.interbank_key ?? null,
                        swift_code: bankAccount.swift_code ?? null,
                        phone_number: bankAccount.phone_number ?? null,
                        currency_id: bankAccount.currency_id ?? null,
                        bank_references: bankAccount.bank_references ?? null,
                        balance: bankAccount.balance ?? 0,
                      }
                      await GenericPostResource("/bankaccounts", newBankAccount).then((response) => {
                        bankAccountId = response.data.bank_account_id;
                        if (response && response.data && response.data.account_alias && response.data.bank_name && response.data.currency_id &&
                          response.data.account_alias === defaultPrimaryBankAccount?.account_alias &&
                          response.data.bank_name === defaultPrimaryBankAccount?.bank_name &&
                          response.data.currency_id === defaultPrimaryBankAccount?.currency_id)
                          isPrimary = true;
                      }).catch((error) => {
                        showSnackBar(error.message, "error");
                        setLoadingPost(false);
                      });
                    }
                    else {
                      let myDataBank = {
                        bank_name: bankAccount.bank_name,
                        card_number: bankAccount.card_number ?? null,
                        account_number: bankAccount.account_number ?? null,
                        interbank_key: bankAccount.interbank_key ?? null,
                        swift_code: bankAccount.swift_code ?? null,
                        phone_number: bankAccount.phone_number ?? null,
                        currency_id: bankAccount.currency_id ?? null,
                        bank_references: bankAccount.bank_references ?? null,
                        balance: bankAccount.balance ?? 0,
                      }
                      await GenericPutResource(`/bankaccounts/${bankAccount.bank_account_id}`, myDataBank).then((response) => {
                        if (response && response.data && response.data.account_alias && response.data.bank_name && response.data.currency_id &&
                          response.data.account_alias === defaultPrimaryBankAccount?.account_alias &&
                          response.data.bank_name === defaultPrimaryBankAccount?.bank_name &&
                          response.data.currency_id === defaultPrimaryBankAccount?.currency_id)
                          isPrimary = true;
                      })
                    }
                    let myDataCustomerBankAccount = {
                      bank_account_id: bankAccountId ?? bankAccount.bank_account_id,
                      customer_id: response.data.customer_id
                    }
                    await GenericPostResource("/customerbankaccounts", myDataCustomerBankAccount).then((response) => {
                      if (isPrimary) {
                        defaultBankId = response.data.customer_bank_account_id;
                      }
                    }).catch((error) => {
                      showSnackBar(error.message, "error");
                    })
                  }

                  let myPutForBankAccount = {
                    primary_bank_account: defaultBankId,
                  }
                  await GenericPutResource(`/customers/${response.data.customer_id}`, myPutForBankAccount).catch((error) => {
                    showSnackBar(error.message, "error");
                  })
                  if (imageFile.dataURL !== undefined) {
                    let myFile = {
                      entity: "Customers",
                      recordId: response.data.customer_id,
                      fileName: imageFile.file_name,
                      typeOfFile: imageFile.type_of_file,
                      schema_name: localStorage.getItem("schemaName"),
                      Content: GetContentURLImage(imageFile.dataURL),
                    }
                    PostFiles(myFile).then(() => {
                      navigate("/customers", { state: { method: "add" } });
                    })
                  }
                  else {
                    navigate("/customers", { state: { method: "add" } });
                  }
                })
                .catch((error) => {
                  showSnackBar(t(error.response.data), "error");
                  setLoadingPost(false);
                });
            }
            if (error != "") {
              showSnackBar(error, "warning");
            }
          }
        });
    }
    else {
      showSnackBar(t("customers.dialog.no_locations"), "warning");
    }
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/customers", { state: {} });
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("customers.title")}`;
    })

    let myPromises = [
      GenericGetResource("/customers"),
      GenericGetResource("/currencies"),
      GenericGetResource("/taxes"),
      GenericGetResource("/paymentmodes"),
      GenericGetResource("/paymentmethods"),
      GenericGetResource("/cfdi"),
      GenericGetResource("/taxregimes"),
      GenericGetResource("/retentions"),
      GenericGetResource("/incoterms"),
      GenericGetResource("/paymentconditions"),
      GenericGetResource("/customskeycodes"),
      GenericGetResource("/languages"),
      GenericGetResource("/customers/maxcustomeridentifier")
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboCustomers(responses[0].data.data);
        setComboCurrencies(responses[1].data.data);
        setComboTaxes(responses[2].data.data);
        setComboPaymentModes(responses[3].data.data);
        setComboPaymentMethods(responses[4].data.data);
        setComboCFDIs(responses[5].data.data);
        setComboTaxRegimes(responses[6].data.data);
        setComboRetentions(responses[7].data.data);
        setComboINCOTERMs(responses[8].data.data);
        setComboPaymentConditions(responses[9].data.data)
        setComboLanguages(responses[11].data.data);

        let customerIdentifier = responses[12].data.customer_identifier + 1;

        let formattedIdentifier;
        if (customerIdentifier != null) {
          let identifierStr = customerIdentifier.toString();

          if (identifierStr.length === 1) {
            formattedIdentifier = '00' + identifierStr;
          } else if (identifierStr.length === 2) {
            formattedIdentifier = '0' + identifierStr;
          } else {
            formattedIdentifier = identifierStr;
          }
        } else {
          formattedIdentifier = '000';
        }

        setValue("customer_identifier", formattedIdentifier);

        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, [])

  useEffect(() => {
    if (locationsData.length > 0) {
      if (!locationsData.find((location: CustomerLocation) => location.country_name == "MEXICO") && !isNotMexican) {
        setIsNotMexican(true);
        setValue("customer_rfc", "")
      }
      else if (locationsData.find((location: CustomerLocation) => location.country_name == "MEXICO" && isNotMexican)) {
        setIsNotMexican(false);
        setValue("customer_rfc", "")
      }
    }
    reset({
      customer_rfc: getValues("customer_rfc"),
      business_name: getValues("business_name"),
      comercial_name: getValues("comercial_name"),
      customer_email: getValues("customer_email"),
      customer_phone_number: getValues("customer_phone_number"),
      customer_web: getValues("customer_web"),
      tax_regime_id: getValues("tax_regime_id"),
      tax_regime: getValues("tax_regime"),
      language_id: getValues("language_id"),
      default_currency_id: getValues("default_currency_id"),
      default_tax_id: getValues("default_tax_id"),
      default_payment_mode_id: getValues("default_payment_mode_id"),
      default_payment_method_id: getValues("default_payment_method_id"),
      default_cfdi_id: getValues("default_cfdi_id"),
      default_retention_id: getValues("default_retention_id"),
      default_incoterm_id: getValues("default_incoterm_id"),
      default_payment_condition_id: getValues("default_payment_condition_id"),
      group_by: getValues("group_by"),
      group_salesorders_by: getValues("group_salesorders_by"),
      group_backorders_by: getValues("group_backorders_by"),
      group_shippingdocuments_by: getValues("group_shippingdocuments_by"),
      group_invoices_by: getValues("group_invoices_by"),
      primary_bank_account: getValues("primary_bank_account")
    });
  }, [locationsData]);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("customers.title-view")} />

          <div className='d-flex justify-content-center mt-3'>

            <div className='custom-card-view w-75'>

              <Button variant='outlined' className="d-flex justify-content-between w-100" onClick={() => setHideDetail(!hideDetail)}>
                <Typography fontWeight={"Bold"} fontSize={22}></Typography>
                <>
                  {!hideDetail && <KeyboardArrowLeftIcon />}
                  {hideDetail && <ExpandMoreIcon />}
                </>
              </Button>
              {hideDetail &&
                <Grow in={hideDetail}>
                  <form onSubmit={handleSubmit(onSubmit)}>

                    <div>
                      <div className="custom-card-row d-flex justify-content-between">

                        <div className='w-100'>

                          <div className='d-flex'>
                            <div className="w-50">
                              <Controller
                                name="customer_identifier"
                                control={control}
                                rules={{ maxLength: 100, required: true }}
                                render={({ field }) =>
                                  <TextField
                                    variant="filled"
                                    label={`${t("customers.fields.customer_identifier")}`}
                                    ref={field.ref}
                                    sx={{
                                      paddingRight: 2,
                                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                      "& input[type=number]": { MozAppearance: "textfield", }
                                    }}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.customer_identifier}
                                    helperText={GetError(errors.customer_identifier?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                  />
                                }
                              />
                            </div>

                            <div className='w-50'>
                              <Controller
                                name="comercial_name"
                                control={control}
                                rules={{ required: true, maxLength: 50 }}
                                render={({ field }) =>
                                  <TextField
                                    variant="filled"
                                    label={`${t("customers.fields.comercial_name")}`}
                                    ref={field.ref}
                                    sx={{
                                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                      "& input[type=number]": { MozAppearance: "textfield", },
                                    }}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.comercial_name}
                                    helperText={GetError(errors.comercial_name?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                  />
                                }
                              />
                            </div>
                          </div>

                          <div className='d-flex pt-3'>
                            <div className='w-50'>
                              <Controller
                                name="business_name"
                                control={control}
                                rules={{ required: true, maxLength: 50 }}
                                render={({ field }) =>
                                  <TextField
                                    variant="filled"
                                    label={`${t("customers.fields.business_name")}`}
                                    ref={field.ref}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.business_name}
                                    helperText={GetError(errors.business_name?.type)}
                                    size="small"
                                    sx={{ paddingRight: 2 }}
                                    style={{ width: "100%" }}
                                    disabled={true}
                                  />
                                }
                              />
                            </div>
                            <div className='w-50'>
                              <Controller
                                name="customer_rfc"
                                control={control}
                                rules={{ required: true, maxLength: 13, pattern: (isNotMexican) ? undefined : rfcRegex }}
                                render={({ field }) =>
                                  <TextField
                                    variant="filled"
                                    label={`${t("customers.fields.customer_rfc")}`}
                                    ref={field.ref}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.customer_rfc}
                                    helperText={GetError(errors.customer_rfc?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                    disabled={true}
                                  />
                                }
                              />
                            </div>

                          </div>

                          <div className='d-flex pt-3'>
                            <div className='w-50'>
                              <Controller
                                name="customer_email"
                                control={control}
                                rules={{ maxLength: 1000, pattern: emailRegex }}
                                render={({ field }) =>
                                  <TextField
                                    variant="filled"
                                    label={`${t("customers.fields.customer_email")}`}
                                    ref={field.ref}
                                    sx={{
                                      paddingRight: 2,
                                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                      "& input[type=number]": { MozAppearance: "textfield", },
                                    }}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.customer_email}
                                    helperText={GetError(errors.customer_email?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                    disabled={true}
                                  />
                                }
                              />
                            </div>
                            <div className='w-50'>
                              <Controller
                                name="customer_phone_number"
                                control={control}
                                rules={{ maxLength: 20, minLength: 10 }}
                                render={({ field }) =>
                                  <TextField
                                    variant="filled"
                                    label={`${t("customers.fields.customer_phone_number")}`}
                                    ref={field.ref}
                                    type='number'
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.customer_phone_number}
                                    helperText={GetError(errors.customer_phone_number?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                    sx={{
                                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                      "& input[type=number]": { MozAppearance: "textfield", }
                                    }}
                                    disabled={true}
                                  />
                                }
                              />
                            </div>

                          </div>

                          <div className='d-flex pt-3'>
                            <div className='w-50'>
                              <Controller
                                name="customer_web"
                                control={control}
                                rules={{ maxLength: 100 }}
                                render={({ field }) =>
                                  <TextField
                                    variant="filled"
                                    label={`${t("customers.fields.customer_web")}`}
                                    ref={field.ref}
                                    sx={{
                                      paddingRight: 2,
                                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                      "& input[type=number]": { MozAppearance: "textfield", },
                                    }}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.customer_web}
                                    helperText={GetError(errors.customer_web?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                    disabled={true}
                                  />
                                }
                              />
                            </div>
                            <div className='w-50'>
                              <Controller
                                name="language_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Autocomplete
                                    ref={field.ref}
                                    size="small"
                                    sx={{ width: "100%" }}
                                    options={comboLanguages}
                                    getOptionLabel={(option) => `${option.language_code} - ${option.language_description}`}
                                    renderOption={(props, option: Language) => (
                                      <div key={option.language_id}>
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                          {option.language_code} - {option.language_description}
                                        </Box>
                                      </div>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.language_id === value.language_id}
                                    onChange={(_, values) => {
                                      field.onChange(values?.language_id || null)
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={`${t("languages.title-view")}`}
                                        variant="filled"
                                        value={field.value}
                                        error={!!errors.language_id}
                                        helperText={GetError(errors.language_id?.type)}
                                      />
                                    )}
                                  />
                                )}
                              />

                            </div>
                          </div>

                          <div className='d-flex pt-3'>

                            <div className='w-50'>

                              <Controller
                                name="tax_regime"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                  <TextField
                                    variant="filled"
                                    label={`${t("taxregimes.title-view")}`}
                                    ref={field.ref}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.tax_regime}
                                    helperText={GetError(errors.tax_regime?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                    sx={{ paddingRight: 2 }}
                                    disabled={true}
                                    InputLabelProps={{ shrink: field.value ? true : false }}
                                  />
                                }
                              />

                            </div>

                            <div className="w-50">
                              <Controller
                                name="group_by"
                                control={control}
                                rules={{}}
                                render={({ field }) => (
                                  <Autocomplete
                                    ref={field.ref}
                                    size="small"
                                    sx={{ width: "100%" }}
                                    options={comboCustomers}
                                    getOptionLabel={(option) => `${(option.customer_identifier) ? option.customer_identifier : "XXX"} - ${(option.comercial_name) ? option.comercial_name : option.business_name}`}
                                    renderOption={(props, option: Customer) => (
                                      <div key={option.customer_id}>
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                          {(option.customer_identifier) ? option.customer_identifier : "XXX"} - {(option.comercial_name) ? option.comercial_name : option.business_name}
                                        </Box>
                                      </div>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                                    onChange={(_, values) => {
                                      field.onChange(values?.customer_id || null)
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={`${t("customers.fields.group_by")}`}
                                        variant="filled"
                                        value={field.value}
                                        error={!!errors.group_by}
                                        helperText={GetError(errors.group_by?.type)}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </div>
                          </div>

                          <div className="d-flex w-100 pt-3">
                            <div className="w-50">
                              <Controller
                                name="primary_bank_account"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                  <Autocomplete
                                    ref={field.ref}
                                    size="small"
                                    open={openCustomerBankAccounts}
                                    onOpen={() => {
                                      setOpenCustomerBankAccounts(true);
                                    }}
                                    onClose={() => {
                                      setOpenCustomerBankAccounts(false);
                                    }}
                                    options={BankAccountsData}
                                    getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name}`}
                                    renderOption={(props, option: CustomerBankAccount) => (
                                      <div key={option.customer_bank_account_id}>
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                          {option.account_alias} - {option.bank_name}
                                        </Box>
                                      </div>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.customer_bank_account_id === value.customer_bank_account_id}
                                    onChange={(_, values) => {
                                      field.onChange(values?.customer_bank_account_id || undefined);
                                      let myCurrency: Currency = {
                                        currency_id: values?.currency_id ?? 0,
                                        currency_code: values?.currency_code ?? "",
                                        currency_description: values?.currency_description ?? "",
                                        currency_symbol: values?.currency_symbol ?? "",
                                      }
                                      setDefaultCurrency(myCurrency)
                                      setDefaultPrimaryBankAccount(values ?? undefined)
                                      setValue("primary_bank_account", values?.customer_bank_account_id || undefined)
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={`${t("customers.fields.primary_bank_account")}`}
                                        variant="filled"
                                        value={field.value}
                                        error={!!errors.primary_bank_account}
                                        helperText={GetError(errors.primary_bank_account?.type)}
                                        sx={{ paddingRight: 2 }}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <>
                                              {params.InputProps.endAdornment}
                                            </>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                }
                              />
                            </div>
                          </div>

                          <div className='my-2'>
                            <Divider />
                          </div>

                          <div className='w-100'>

                            <Controller
                              name="group_salesorders_by"
                              control={control}
                              rules={{}}
                              render={({ field }) => (
                                <Autocomplete
                                  ref={field.ref}
                                  size="small"
                                  sx={{ width: "100%" }}
                                  options={salesordersCombo}
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(props, option) => (
                                    <div key={option.id}>
                                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.name}
                                      </Box>
                                    </div>
                                  )}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  onChange={(_, values) => {
                                    field.onChange(values?.id || null)
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={`${t("customers.fields.group_salesorders_by")}`}
                                      variant="filled"
                                      value={field.value}
                                      error={!!errors.group_salesorders_by}
                                    />
                                  )}
                                />
                              )}
                            />

                          </div>
                          <div className='w-100 pt-3'>

                            <Controller
                              name="group_backorders_by"
                              control={control}
                              rules={{}}
                              render={({ field }) => (
                                <Autocomplete
                                  ref={field.ref}
                                  size="small"
                                  sx={{ width: "100%" }}
                                  options={salesordersCombo}
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(props, option) => (
                                    <div key={option.id}>
                                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.name}
                                      </Box>
                                    </div>
                                  )}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  onChange={(_, values) => {
                                    field.onChange(values?.id || null)
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={`${t("customers.fields.group_backorders_by")}`}
                                      variant="filled"
                                      value={field.value}
                                      error={!!errors.group_backorders_by}
                                    />
                                  )}
                                />
                              )}
                            />

                          </div>

                          <div className='w-100 pt-3'>

                            <Controller
                              name="group_shippingdocuments_by"
                              control={control}
                              rules={{}}
                              render={({ field }) => (
                                <Autocomplete
                                  ref={field.ref}
                                  size="small"
                                  sx={{ width: "100%" }}
                                  options={documentsCombo}
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(props, option) => (
                                    <div key={option.id}>
                                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.name}
                                      </Box>
                                    </div>
                                  )}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  onChange={(_, values) => {
                                    field.onChange(values?.id || null)
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={`${t("customers.fields.group_shippingdocuments_by")}`}
                                      variant="filled"
                                      value={field.value}
                                      error={!!errors.group_shippingdocuments_by}
                                    />
                                  )}
                                />
                              )}
                            />

                          </div>


                          <div className='w-100 pt-3'>

                            <Controller
                              name="group_invoices_by"
                              control={control}
                              rules={{}}
                              render={({ field }) => (
                                <Autocomplete
                                  ref={field.ref}
                                  size="small"
                                  sx={{ width: "100%" }}
                                  options={invoiceCombo}
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(props, option) => (
                                    <div key={option.id}>
                                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.name}
                                      </Box>
                                    </div>
                                  )}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  onChange={(_, values) => {
                                    field.onChange(values?.id || null)
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={`${t("customers.fields.group_invoices_by")}`}
                                      variant="filled"
                                      value={field.value}
                                      error={!!errors.group_invoices_by}
                                    />
                                  )}
                                />
                              )}
                            />

                          </div>

                        </div>

                        <Avatar className='mx-4' sx={{ bgcolor: 'transparent', border: '1px solid grey', height: 190, width: 190 }} variant='rounded' alt={""}>
                          <Button component="label">
                            {imageLoaded &&
                              <ImageDataComponent url={imageFile.dataURL === undefined ? '' : imageFile.dataURL} />
                            }
                            {!imageLoaded && <img alt='profile-photo' width={190} height={190} src={require("../../../../../assets/img/profile.jpeg")} />}
                            <input type="file" accept="image/*" hidden
                              onChange={async (e) => {
                                await DecodeFileToBase64(e).then(async (response) => {
                                  await setImageFile(response);
                                  setImageLoaded(true);
                                });
                              }
                              }
                            />
                          </Button>
                        </Avatar>

                      </div>
                    </div>

                    <div className='d-flex flex-row-reverse mt-1'>
                      <PrimaryButton onClick={() => onPost(getValues())} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                      <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                    </div>
                  </form>
                </Grow>
              }
            </div>
          </div>
          <div className='pt-3'>
            <CustomerDetail
              Locations={<TableCustomerLocations setValue={setValue} add={true} LocationsData={locationsData} setLocationsData={setLocationsData} />}
              Taxes={
                <FinancialDataTab
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  comboCurrencies={comboCurrencies}
                  defaultCurrency={defaultCurrency}
                  comboTaxes={comboTaxes}
                  defaultTax={defaultTax}
                  comboPaymentModes={comboPaymentModes}
                  defaultPaymentMode={defaultPaymentMode}
                  comboPaymentMethods={comboPaymentMethods}
                  defaultPaymentMethod={defaultPaymentMethod}
                  comboCFDIs={comboCFDIs}
                  defaultCFDI={defaultCFDI}
                  comboRetention={comboRetentions}
                  defaultRetention={defaultRetention}
                  comboINCOTERMs={comboINCOTERMs}
                  defaultINCOTERM={defaultINCOTERM}
                  comboPaymentConditions={comboPaymentConditions}
                  defaultPaymentCondition={defaultPaymentCondition}
                  defaultCustomsKeyCode={defaultCustomsKeyCode}
                  comboCustomsKeyCodes={comboCustomsKeyCodes}
                  setDefaultCurrency={setDefaultCurrency}
                  setDefaultTax={setDefaultTax}
                  setDefaultPaymentMode={setDefaultPaymentMode}
                  setDefaultPaymentMethod={setDefaultPaymentMethod}
                  setDefaultCFDI={setDefaultCFDI}
                  setDefaultRetention={setDefaultRetention}
                  setDefaultINCOTERM={setDefaultINCOTERM}
                  setDefaultPaymentCondition={setDefaultPaymentCondition}
                  setDefaultCustomsKeyCode={setDefaultCustomsKeyCode}
                />
              }
              CustomerBankAccounts={
                <BrowserCustomerBankAccounts
                  add={true}
                  setValue={setValue}
                  BankAccountsData={BankAccountsData}
                  setBankAccountsData={setBankAccountsData}
                />
              }
            />
          </div>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
