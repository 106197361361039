import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Tax } from '../../../../interfaces/Sales/Catalogs/taxes';
import { Header } from '../../../../components/Header';
import { Box, Switch, TextField, Typography } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const AddTaxes = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Tax>({
    defaultValues: {
      tax_name: '',
      tax_percent: undefined,
      tax_code: '',
      is_transferred: undefined,
      is_whithheld: undefined,
    }
  });
  const onSubmit: SubmitHandler<Tax> = (data) => onPost(data);

  const onPost = (data: Tax) => {
    let myData = {
      tax_code: data.tax_code,
      tax_name: data.tax_name,
      tax_percent: data.tax_percent,
      is_transferred: data.is_transferred,
      is_whithheld: (data.is_whithheld) ? 1 : 0,
    }
    setLoadingPost(true);
    GenericPostResource("/taxes", myData)
      .then((response) => {
        navigate("/taxes", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/taxes", { state: {} });
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("taxes.title")}`;
    })
  }, [])

  return (
    <>
      <Header title={t("taxes.title-view")} />
      <div className='d-flex justify-content-center'>

        <div className='custom-card-view w-75'>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

            <div className="custom-card-row d-flex justify-content-between">
              <div className="w-100">
                <div className='d-flex w-100'>
                  <div className='w-50'>
                    <Controller
                      name="tax_name"
                      control={control}
                      rules={{ required: true, maxLength: 30 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("taxes.fields.tax_name")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.tax_name}
                          helperText={GetError(errors.tax_name?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                        />
                      }
                    />
                  </div>

                  <div className="w-50">
                    <Controller
                      name="tax_code"
                      control={control}
                      rules={{ required: true, maxLength: 3 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("taxes.fields.tax_code")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.tax_code}
                          helperText={GetError(errors.tax_code?.type)}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      }
                    />
                  </div>
                </div>

                <div className='d-flex w-100 mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="tax_percent"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>
                        <TextField
                          ref={field.ref}
                          variant="filled"
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                            "& input[type=number]": { MozAppearance: "textfield", },
                            paddingRight: 2
                          }}
                          type="number"
                          value={field.value}
                          onChange={field.onChange}
                          label={t("taxes.fields.tax_percent")}
                          error={!!errors.tax_percent}
                          helperText={GetError(errors.tax_percent?.type)}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      }
                    />
                  </div>
                  <div className="w-25">
                    <Controller
                      name="is_transferred"
                      control={control}
                      render={({ field }) =>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                          <Typography sx={{ ml: 2 }}>{t("taxes.fields.is_transferred")}</Typography>
                          <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
                        </Box>
                      }
                    />
                  </div>
                  <div className="w-25">
                    <Controller
                      name="is_whithheld"
                      control={control}
                      render={({ field }) =>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                          <Typography sx={{ ml: 2 }}>{t("taxes.fields.is_whithheld")}</Typography>
                          <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
                        </Box>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </div>

      </div>
      <SnackbarComponent />
    </>
  )
}
