import { Route, Routes } from 'react-router-dom'
import { AddParameter } from './add'
import { ViewParameter } from './view'
import { TableParameters } from './table'

export const BrowserParameters = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TableParameters />} />
            <Route path={"/add"} element={<AddParameter />} />
            <Route path={"/view"} element={<ViewParameter />} />
        </Routes>
    )
}