import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../../components/Header';
import { TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { CancellationReason } from '../../../../interfaces/Sales/Invoices/Cancellations/cancellationReason';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const AddCancellationReason = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CancellationReason>({
    defaultValues: {
      cancellation_reason_key: '',
      description: '',
    }
  });
  const onSubmit: SubmitHandler<CancellationReason> = (data) => onPost(data);

  const onPost = (data: CancellationReason) => {
    let myData = {
      cancellation_reason_key: data.cancellation_reason_key,
      description: data.description ?? null
    }
    setLoadingPost(true);
    GenericPostResource("/cancellationreasons", myData)
      .then((response) => {
        navigate("/cancellationreasons", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,"error")
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/cancellationreasons", { state: {} });
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("cancellationreasons.title")}`;
    })
  }, []);

  return (
    <>
      <Header title={t("cancellationreasons.title-view")} />
      <div className='d-flex justify-content-center'>

        <div className='custom-card-view w-75'>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

            <div className="custom-card-row d-flex justify-content-between">
              <div className="w-100">
                <div className='d-flex w-100'>
                  <div className='w-50'>
                    <Controller
                      name="cancellation_reason_key"
                      control={control}
                      rules={{ required: true, maxLength: 3 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("cancellationreasons.fields.cancellation_reason_key")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.cancellation_reason_key}
                          helperText={GetError(errors.cancellation_reason_key?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                        />
                      }
                    />
                  </div>

                  <div className="w-50">
                    <Controller
                      name="description"
                      control={control}
                      rules={{ required: true, maxLength: 50 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("cancellationreasons.fields.description")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.description}
                          helperText={GetError(errors.description?.type)}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      }
                    />
                  </div>
                </div>

              </div>

            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </div>

      </div>
      <SnackbarComponent/>
    </>
  )
}
