import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { ReminderCustomType } from "../../../../../interfaces/Projects/Catalogs/reminderCustomTypes";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ReminderType } from "../../../../../interfaces/Projects/Catalogs/reminderTypes";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { ButtonStyled, PrimaryButton } from "../../../../../theme/buttons";
import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";

type UpdateRecurrencePickerProps = {
  openCustomDialog: boolean;
  setOpenCustomDialog: Dispatch<SetStateAction<boolean>>;
  handleNewReminderTypeId: (newReminderType: ReminderType) => void;
  reminderTypePayload: ReminderType;
  reminderTypesCombo: ReminderType[];
};

export const UpdateRecurrencePicker = ({
  openCustomDialog,
  setOpenCustomDialog,
  handleNewReminderTypeId,
  reminderTypePayload,
  reminderTypesCombo,
}: UpdateRecurrencePickerProps) => {
  const [loadingPut, setLoadingPut] = useState(false);
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar("left", "bottom");
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const [weeklySelected, setWeeklySelected] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [defaultRemainderType, setDefaultRemainderType] = useState<ReminderType>()
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch
  } = useForm<ReminderType>({
    defaultValues: {
      reminder_type_name: undefined,
      reminder_type_description: undefined,
      reminder_type_time: undefined,
      reminder_custom_type_id: undefined,
    },
  });

  const onSubmit: SubmitHandler<ReminderType> = (data) => onPut(data);

  type DayOfWeek =
    | "sunday"
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday";

  const [daysOfWeek, setDaysOfWeek] = useState<ReminderCustomType>({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  const daysOfWeekLabels: { [key in DayOfWeek]: string } = {
    sunday: "Sun",
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
  };

  const handleDayOfWeekChange = (day: DayOfWeek) => {
    setDaysOfWeek((prev: ReminderCustomType) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const hasSelectedDays = () => {
    return Object.values(daysOfWeek).some((daySelected) => daySelected);
  };

  const onPut: SubmitHandler<ReminderType> = async (data) => {
    try {
      setLoadingPut(true);
      let reminderCustomTypeId: number | null = null;
      if (hasSelectedDays()) {
        reminderCustomTypeId = await createReminderCustomType();
      }
      const newReminderType = await createReminderType({
        ...data,
        reminder_custom_type_id: reminderCustomTypeId || null,
      });
      if (newReminderType) {
        handleNewReminderTypeId(newReminderType.data);
      }
      setOpenCustomDialog(false);
    } catch (error: any) {
      setLoadingPut(false);
      showSnackBar(error.message, "error");
    } finally {
      setOpenCustomDialog(false);
      setLoadingPut(false);
    }
  };

  const createReminderCustomType = async () => {
    const customTypeData = { ...daysOfWeek };
    return await GenericPostResource("/remindercustomtypes", customTypeData)
      .then((response) => {
        return response.data.reminder_custom_type_id;
      })
      .catch((error) => {
        setLoadingPut(false);
        showSnackBar(error.message, "error");
      });
  };

  const createReminderType = async (reminderTypeData: ReminderType) => {
    const selectedType = reminderTypesCombo.find(
      (type) => type.reminder_type_id === reminderTypeData.reminder_type_id
    );
  
    const reminderTypeName = selectedType
      ? `${selectedType.reminder_type_name} ${reminderTypeData.reminder_type_time}`
      : `${reminderTypeData.reminder_type_name} ${reminderTypeData.reminder_type_time}`;
  
    let myData = {
      reminder_type_name: reminderTypeName,
      reminder_type_description: reminderTypeData.reminder_type_description || null,
      reminder_type_time: reminderTypeData.reminder_type_time,
      reminder_custom_type_id: reminderTypeData.reminder_custom_type_id || null,
    };

    return await GenericPostResource("/remindertypes", myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        return response;
      })
      .catch((error) => {
        setLoadingPut(false);
        showSnackBar(error.message, "error");
      });
  };

  const closeDialog = () => {
    if (reminderTypePayload?.reminder_custom_type_id) {
      let myPromises = [
        GenericGetResource(
          `/remindercustomtypes/${reminderTypePayload.reminder_custom_type_id}`
        ),
      ];
      Promise.all(myPromises)
        .then((responses) => {
          const validDaysOfWeek = {
            sunday: responses[0].data.sunday || false,
            monday: responses[0].data.monday || false,
            tuesday: responses[0].data.tuesday || false,
            wednesday: responses[0].data.wednesday || false,
            thursday: responses[0].data.thursday || false,
            friday: responses[0].data.friday || false,
            saturday: responses[0].data.saturday || false,
          };
          setDaysOfWeek(validDaysOfWeek);
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
    }
    reset({
      reminder_type_name: reminderTypePayload.reminder_type_name,
      reminder_type_description: reminderTypePayload.reminder_type_description,
      reminder_type_time: reminderTypePayload.reminder_type_time,
      reminder_custom_type_id: reminderTypePayload.reminder_custom_type_id,
    });
    const firstWord = reminderTypePayload.reminder_type_name?.split(" ")[0];
    if (firstWord === "Semanal") {
      setWeeklySelected(true);
    }
    setOpenCustomDialog(false);
  };

  useEffect(() => {
    if (reminderTypePayload.reminder_custom_type_id) {
      let myPromises = [
        GenericGetResource(
          `/remindercustomtypes/${reminderTypePayload.reminder_custom_type_id}`
        ),
      ];
      Promise.all(myPromises)
        .then((responses) => {
          const validDaysOfWeek = {
            sunday: responses[0].data.sunday || false,
            monday: responses[0].data.monday || false,
            tuesday: responses[0].data.tuesday || false,
            wednesday: responses[0].data.wednesday || false,
            thursday: responses[0].data.thursday || false,
            friday: responses[0].data.friday || false,
            saturday: responses[0].data.saturday || false,
          };
          setDaysOfWeek(validDaysOfWeek);
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
    }
    reset({
      reminder_type_name: reminderTypePayload.reminder_type_name?.split(' ')[0],
      reminder_type_description: reminderTypePayload.reminder_type_description || null,
      reminder_type_time: reminderTypePayload.reminder_type_time,
      reminder_custom_type_id: reminderTypePayload.reminder_custom_type_id || null,
    }); 

    const firstWord = reminderTypePayload.reminder_type_name?.split(" ")[0];
    if (firstWord === "Semanal") {
      setWeeklySelected(true);
    }

    setDataLoaded(true);
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Dialog open={openCustomDialog} onClose={closeDialog}>
          <DialogTitle>
            {t("projecttaskreminderstemplate.actions.update-custom-reminder")}
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <DialogContent sx={{ width: 500 }}>
              <div className="d-flex justify-content-between">
                <Controller
                  name="reminder_type_time"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TimePicker
                      ref={field.ref}
                      label={t("remindertypes.fields.reminder_type_time")}
                      value={
                        field.value ? dayjs(field.value, "HH:mm:ss") : null
                      }
                      onChange={(value) => {
                        if (value) {
                          const formattedTime = dayjs(value).format("HH:mm:ss");
                          field.onChange(formattedTime);
                        }
                      }}
                      sx={{
                        width: "100%",
                        paddingRight: 2,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "filled",
                          error: !!errors.reminder_type_time,
                          helperText: GetError(errors.reminder_type_time?.type),
                        },
                        digitalClockSectionItem: {
                          sx: {
                            "&.Mui-selected": {
                              color: "white",
                            },
                          },
                        },
                      }}
                    />
                  )}
                />
                <Controller
                  name="reminder_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%" }}
                      options={reminderTypesCombo}
                      getOptionLabel={(option) =>
                        `${option.reminder_type_name}`
                      }
                      defaultValue={reminderTypePayload}
                      isOptionEqualToValue={(option, value) => option.reminder_type_id === value.reminder_type_id}
                      renderOption={(props, option: ReminderType) => (
                        <div key={option.reminder_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.reminder_type_name}
                          </Box>
                        </div>
                      )}
                      onChange={(_, value) => {
                        if (value?.reminder_type_name === "Semanal") {
                          setWeeklySelected(true);
                          setDaysOfWeek({
                            sunday: false,
                            monday: false,
                            tuesday: false,
                            wednesday: false,
                            thursday: false,
                            friday: false,
                            saturday: false,
                          });
                        } else {
                          setWeeklySelected(false);
                        }
                        field.onChange(value?.reminder_type_id || null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "remindertypes.fields.reminder_type_name"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.reminder_type_name}
                          helperText={GetError(errors.reminder_type_name?.type)}
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </div>
              {weeklySelected && (
                <Controller
                  name="reminder_custom_type_id"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <ButtonGroup
                      variant="contained"
                      sx={{ flexWrap: "nowrap", marginTop: 2 }}
                    >
                      {Object.keys(daysOfWeek).map((day) => (
                        <Button
                          key={day}
                          variant={
                            daysOfWeek[day as DayOfWeek]
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() =>
                            handleDayOfWeekChange(day as DayOfWeek)
                          }
                        >
                          {daysOfWeekLabels[day as DayOfWeek]}
                        </Button>
                      ))}
                    </ButtonGroup>
                  )}
                />
              )}
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row-reverse">
                <PrimaryButton
                  type="submit"
                  variant="outlined"
                  className="m-1"
                  disabled={loadingPut}
                >
                  {t("generic.buttons.accept")}
                </PrimaryButton>
                <ButtonStyled
                  variant="contained"
                  className="m-1"
                  onClick={closeDialog}
                >
                  {t("generic.buttons.cancel")}
                </ButtonStyled>
              </div>
            </DialogActions>
          </form>
        </Dialog>
      )}
      <SnackbarComponent />
    </>
  );
};
