import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Autocomplete, Box, TextField } from '@mui/material';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { PurchaseInvoice } from '../../../../interfaces/Purchases/Invoices/purchaseInvoices';
import { PaymentMethod } from '../../../../interfaces/Sales/Catalogs/paymentMethods';
import { PaymentMode } from '../../../../interfaces/Sales/Catalogs/paymentModes';
import { CFDI } from '../../../../interfaces/Sales/Catalogs/CFDIs';
import { PaymentCondition } from '../../../../interfaces/Sales/Catalogs/paymentConditions';
import { Currency } from '../../../../interfaces/Commons/currencies';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useCurrencies } from '../../../../hooks/useCurrencies';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useDates } from '../../../../hooks/useDates';
import { uuidRegex } from '../../../../constants/Regex';
import { myStylePickers } from '../../../../theme/buttons';

type FinancialPurchaseInvoices = {
    control: Control<PurchaseInvoice>,
    errors: FieldErrors<PurchaseInvoice>,
    isEdit?: boolean,
    defaultPaymentMethod: PaymentMethod | undefined,
    defaultPaymentMode: PaymentMode | undefined,
    defaultCurrency: Currency | undefined,
    defaultCFDI: CFDI | undefined,
    defaultPaymentConditions: PaymentCondition | undefined,
    comboPaymentMethods: PaymentMethod[],
    comboPaymentModes: PaymentMode[],
    comboCFDIs: CFDI[],
    comboPaymentConditions: PaymentCondition[],
    comboCurrencies: Currency[],
    isNotExchange?: boolean,
    setValue: UseFormSetValue<PurchaseInvoice>,
    setDefaultPaymentMethod: Dispatch<SetStateAction<PaymentMethod | undefined>>,
    setDefaultPaymentMode: Dispatch<SetStateAction<PaymentMode | undefined>>,
    setDefaultCurrency: Dispatch<SetStateAction<Currency | undefined>>,
    setDefaultCFDI: Dispatch<SetStateAction<CFDI | undefined>>,
    setDefaultPaymentConditions: Dispatch<SetStateAction<PaymentCondition | undefined>>,
    getValues: UseFormGetValues<PurchaseInvoice>,
    defaultExpirationDate: Date | undefined,
    GetExchangeRateFromDates: (currency_id: number, datePurchaseInvoice: Date | undefined, dateSupplierInvoice: Date | undefined) => Promise<void>
}

export const FinancialPurchaseInvoices = ({
    control,
    errors,
    comboPaymentMethods,
    comboPaymentModes,
    defaultPaymentMethod,
    defaultPaymentMode,
    isEdit,
    defaultCurrency,
    defaultCFDI,
    GetExchangeRateFromDates,
    isNotExchange,
    defaultPaymentConditions,
    comboCurrencies,
    comboPaymentConditions,
    comboCFDIs,
    setValue,
    setDefaultPaymentMethod,
    setDefaultPaymentMode,
    setDefaultCurrency,
    setDefaultCFDI,
    setDefaultPaymentConditions,
    getValues,
    defaultExpirationDate
}: FinancialPurchaseInvoices) => {
    const { GetError } = FormsErrors();
    const [t] = useTranslation("global");
    const { TimeConverter } = useDates();
    const [openDatePicker, setopenDatePicker] = useState(false)

    const disableKeyboardEntry = (e: any) => {
        if (e?.preventDefault) {
            e?.preventDefault();
            e?.stopPropagation();
        }
    }

    return (
        <>
            <div className='w-100'>
                <div className='d-flex mt-2'>
                    <Box sx={{ flex: 9 }}>
                        <Controller
                            name="payment_method_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Autocomplete
                                    ref={field.ref}
                                    size="small"
                                    sx={{ width: "100%", paddingRight: 2 }}
                                    options={comboPaymentMethods}
                                    getOptionLabel={(option) => `${option.code} - ${option.name}`}
                                    defaultValue={defaultPaymentMethod}
                                    renderOption={(props, option: PaymentMethod) => (
                                        <div key={option.payment_method_id}>
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.code} - {option.name}
                                            </Box>
                                        </div>
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.payment_method_id === value.payment_method_id
                                    }}
                                    onChange={(_, values) => {
                                        field.onChange(values?.payment_method_id || 0)
                                        setDefaultPaymentMethod(values ?? undefined);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t("paymentmethods.title-view")}`}
                                            variant="filled"
                                            value={field.value}
                                            error={!!errors.payment_method_id}
                                            helperText={GetError(errors.payment_method_id?.type)}
                                        />
                                    )}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={{ flex: 9 }}>

                        <Controller
                            name="payment_mode_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Autocomplete
                                    ref={field.ref}
                                    size="small"
                                    sx={{ width: "100%" }}
                                    options={comboPaymentModes}
                                    defaultValue={defaultPaymentMode}
                                    getOptionLabel={(option) => `${option.code} - ${option.description}`}
                                    renderOption={(props, option: PaymentMode) => (
                                        <div key={option.payment_mode_id}>
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.code} - {option.description}
                                            </Box>
                                        </div>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.payment_mode_id === value.payment_mode_id}
                                    onChange={(_, values) => {
                                        field.onChange(values?.payment_mode_id || null)
                                        setDefaultPaymentMode(values ?? undefined);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t("paymentmodes.title-view")}`}
                                            variant="filled"
                                            value={field.value}
                                            error={!!errors.payment_mode_id}
                                            helperText={GetError(errors.payment_mode_id?.type)}
                                        />
                                    )}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Box>
                </div>
                <div className='d-flex mt-2'>
                    <Box sx={{ flex: 9 }}>
                        <Controller
                            name="currency_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Autocomplete
                                    ref={field.ref}
                                    size="small"
                                    sx={{ width: "100%", paddingRight: 2 }}
                                    options={comboCurrencies}
                                    defaultValue={defaultCurrency}
                                    getOptionLabel={(option) => `${option.currency_code} - ${option.currency_description}`}
                                    renderOption={(props, option: Currency) => (
                                        <div key={option.currency_id}>
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.currency_code} - {option.currency_description}
                                            </Box>
                                        </div>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.currency_id === value.currency_id}
                                    onChange={(_, values) => {
                                        setDefaultCurrency(values ?? undefined);
                                        field.onChange(values?.currency_id || null)
                                        if (values?.currency_id) {
                                            GetExchangeRateFromDates(values?.currency_id, getValues("purchase_invoice_date"), getValues("supplier_sale_invoice_date"))
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t("currencies.title-view")}`}
                                            variant="filled"
                                            value={field.value}
                                            error={!!errors.currency_id}
                                            helperText={GetError(errors.currency_id?.type)}
                                        />
                                    )}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={{ flex: 9 }}>
                        <Controller
                            name="cfdi_id"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                                <Autocomplete
                                    ref={field.ref}
                                    size="small"
                                    sx={{ width: "100%" }}
                                    options={comboCFDIs}
                                    defaultValue={defaultCFDI}
                                    getOptionLabel={(option) => `${option.code} - ${option.description}`}
                                    renderOption={(props, option: CFDI) => (
                                        <div key={option.cfdi_id}>
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.code} - {option.description}
                                            </Box>
                                        </div>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.cfdi_id === value.cfdi_id}
                                    onChange={(_, values) => {
                                        setDefaultCFDI(values ?? undefined);
                                        field.onChange(values?.cfdi_id || null)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t("purchaseinvoices.fields.cfdi")}`}
                                            variant="filled"
                                            value={field.value}
                                            error={!!errors.cfdi_id}
                                            helperText={GetError(errors.cfdi_id?.type)}
                                        />
                                    )}
                                    disabled={isEdit}
                                />
                            )}
                        />
                    </Box>
                </div>
                <div className='d-flex mt-2'>
                    <Box sx={{ flex: 9 }}>
                        <Controller
                            name="payment_condition_id"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    ref={field.ref}
                                    size="small"
                                    sx={{ width: "100%", paddingRight: 2 }}
                                    options={comboPaymentConditions}
                                    defaultValue={defaultPaymentConditions}
                                    getOptionLabel={(option) => `${option.payment_condition_name}`}
                                    renderOption={(props, option: PaymentCondition) => (
                                        <div key={option.payment_condition_id}>
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.payment_condition_name}
                                            </Box>
                                        </div>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.payment_condition_id === value.payment_condition_id}
                                    onChange={(_, values) => {
                                        setDefaultPaymentConditions(values ?? undefined);
                                        field.onChange(values?.payment_condition_id || null)
                                    }}
                                    disabled={isEdit}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t("paymentconditions.title-view")}`}
                                            variant="filled"
                                            value={field.value}
                                            error={!!errors.payment_condition_id}
                                            helperText={GetError(errors.payment_condition_id?.type)}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={{ flex: 9 }}>
                        <Controller
                            name="exchange_rate"
                            control={control}
                            rules={{ required: ((defaultCurrency?.currency_code == "MXN") ? false : true) }}
                            render={({ field }) => (
                                <TextField
                                    variant="filled"
                                    label={`${t("purchaseinvoices.fields.exchange_rate")}`}
                                    ref={field.ref}
                                    value={(field.value == null || field.value == undefined || field.value <= 1) ? 1 : field.value}
                                    onChange={(event) => {
                                        field.onChange(event.target.value)
                                    }}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                        "& input[type=number]": { MozAppearance: "textfield", }, width: "100%"
                                    }}
                                    error={!!errors.exchange_rate}
                                    helperText={GetError(errors.exchange_rate?.type)}
                                    size="small"
                                    type="number"
                                    disabled={isEdit || (defaultCurrency?.currency_code == "MXN") ? true : false}
                                />
                            )}
                        />
                    </Box>
                </div>
                <div className='d-flex mt-2'>
                    <div className="w-50">
                        <Controller
                            name="expiration_date"
                            control={control}
                            rules={{}}
                            render={({ field }) =>
                                <DatePicker
                                    open={openDatePicker}
                                    onClose={() => setopenDatePicker(false)}
                                    ref={field.ref}
                                    label={`${t("purchaseinvoices.fields.expiration_date")}`}
                                    onChange={field.onChange}
                                    sx={{ width: "100%", paddingRight: 2 }}
                                    slotProps={{
                                        popper: {
                                            sx: myStylePickers
                                        },
                                        textField: {
                                            variant: "filled",
                                            size: "small",
                                            fullWidth: true,
                                            onClick: () => setopenDatePicker(true),
                                            onBeforeInput: disableKeyboardEntry,
                                            error: !!errors.expiration_date,
                                            helperText: GetError(errors.expiration_date?.type),
                                        }
                                    }}
                                    defaultValue={defaultExpirationDate ? dayjs(TimeConverter(defaultExpirationDate.toString())) : undefined}
                                    format="DD/MM/YYYY"
                                    disablePast
                                    disabled={isEdit}
                                />
                            }
                        />
                    </div>
                    <div className='w-50'>
                        <Controller
                            name="uuid"
                            control={control}
                            rules={{ minLength: 36, maxLength: 36, pattern: uuidRegex }}
                            render={({ field }) =>
                                <TextField
                                    variant="filled"
                                    label={`${t("purchaseinvoices.fields.uuid")}`}
                                    ref={field.ref}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.uuid}
                                    helperText={GetError(errors.uuid?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                    disabled={isEdit}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    )
}