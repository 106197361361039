import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Group } from '../../../../interfaces/Security/groups';
import { OptionMenu } from '../../../../interfaces/Security/optionsMenu';
import { Header } from '../../../../components/Header';
import { Autocomplete, Button, TextField, Box} from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const AddGroups = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const [comboOptionMenu, setComboOptionMenu] = useState<OptionMenu[]>([]);
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [hiddenPassword] = useState(false);
  const {showSnackBar,SnackbarComponent}= useSnackBar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Group>({
    defaultValues: {
      group_name: '',
      option_menu_id: undefined,
    }
  });

  const onSubmit: SubmitHandler<Group> = (data) => onPost(data);

  const onPost = (data: Group) => {
    let myData = {
      group_name: data.group_name,
      option_menu_id: data.option_menu_id,
    }
    setLoadingPost(true);
    GenericPostResource("/groups", myData)
      .then((response) => {
        navigate("/groups", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,"error");
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/groups", { state: {} });
  }

  useEffect(() => {
      GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
        document.title = `${response.data.data[0].comercial_name} - ${t("groups.title")}`;
      })
    GenericGetResource("/optionmenu")
      .then((response) => {
        setComboOptionMenu(response.data.data);
      }).catch((error) => {
        showSnackBar(error.message,"error");
      })
  }, [])

  useEffect(() => { }, [hiddenPassword]);

  return (
    <>
      <Header title={t("groups.title-view")} />
      <div className='d-flex justify-content-center'>

        <div className='custom-card-view w-75'>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

            <div className="custom-card-row d-flex justify-content-between">
              <div className="w-100">
                <div className='d-flex w-100'>
                  <div className='w-50'>
                    <Controller
                      name="group_name"
                      control={control}
                      rules={{ required: true, maxLength: 30 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("groups.fields.group_name")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.group_name}
                          helperText={GetError(errors.group_name?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                        />
                      }
                    />
                  </div>

                  <div className="w-50">
                    <Controller
                      name="option_menu_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          sx={{ width: "100%" }}
                          options={comboOptionMenu}
                          getOptionLabel={(option) => option.option_name}
                          renderOption={(props, option: OptionMenu) => (
                            <div key={option.option_menu_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.option_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.option_menu_id === value.option_menu_id}
                          onChange={(_, values) => {
                            field.onChange(values?.option_menu_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              label={t("groups.fields.option_name")}
                              value={field.value}
                              error={!!errors.group_id || field.value === null}
                              helperText={(errors.group_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                              sx={{ paddingRight: 2 }}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </div>

      </div>
      <SnackbarComponent/>
    </>
  )
}
