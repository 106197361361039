import { Box, Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UseFormGetValues } from 'react-hook-form'
import InfoIcon from '@mui/icons-material/Info';
import { ButtonLoading } from '../../../../../theme/buttons';
import { ProjectStageTemplate } from '../../../../../interfaces/Projects/Catalogs/Templates/projectStagesTemplate';

type ConfirmDialogProps = {
  isEdit: boolean,
  loadingPost: boolean,
  setDialogConfirmDeleteStage:Dispatch<SetStateAction<boolean>>,
  getValues: UseFormGetValues<ProjectStageTemplate>,
  onDelete: () => void
}

export const ConfirmDialog = ({
  isEdit,
  loadingPost,
  setDialogConfirmDeleteStage,
  getValues,
  onDelete
}: ConfirmDialogProps) => {
  const [t] = useTranslation("global");
  const [projectStageTemplateName, setProjectStageTemplateName] = useState<string>();
  const [isEqualName, setEqualName] = useState(false);

  const handleChange = (e: any) => {
    const value = e.target.value;
    setProjectStageTemplateName(value);
    if (getValues("project_stage_template_name") === value) {
      setEqualName((prev) => true);
    }
    else {
      setEqualName(false);
    }
  }

  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"start"} width={"100%"}>
        <Box width={"100%"} marginBottom={2}>
          <Typography margin={1}>{t("projectstagestemplate.info.confirm-stage")}</Typography>
          <Typography margin={1}><InfoIcon /> {t("projectstagestemplate.info.delete-project-stage-template")}</Typography>
          <TextField
            value={projectStageTemplateName}
            variant='outlined'
            size='small'
            onChange={handleChange}
            label={t("projectstagestemplate.fields.project_stage_template_name")}
            required
            fullWidth
          />
        </Box>
      <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
        <ButtonLoading disabled={ isEdit || !isEqualName} isLoading={loadingPost} onClick={onDelete} className="d-flex align-self-end" variant="outlined">{t("generic.buttons.delete")}</ButtonLoading>
        <Button variant='outlined' onClick={() => { setDialogConfirmDeleteStage(false) }} disabled={loadingPost}>{t("generic.buttons.goback")}</Button>
      </Box>
    </Box>
  )
}
