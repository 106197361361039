import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";
import { Box, Grid } from "@mui/material";
import { GenericDialog } from "../../../../../../components/Dialogs/Dialog";
import { DialogEntity } from "../../../../../../components/Dialogs/DialogEntity";
import GenericPromises from "../../../../../../api/GenericPromises";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import { Menuitem } from "../../../../../../interfaces/Security/menu";
import { usePermissions } from "../../../../../../hooks/usePermissions";
import { AuthContext } from "../../../../../../context/AuthContext";
import { defaultTheme } from "../../../../../../theme/theme";
import { ProjectStage } from "../../../../../../interfaces/Projects/Catalogs/projectStages";
import { AddProjectStages } from "./add";
import { Header } from "../../../../../../components/Header";
import { UpdateProjectStages } from "./update";
import { TableProjectTask } from "../ProjectTask/table";
import { StagesContainer } from "../../../../../../theme/projects/Containers/stagesContainer";
import { StageButton } from "../../../../../../theme/projects/Buttons/stageButton";

export const TableProjectStages = ({ ...props }) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [openDialogAddStage, setOpenDialogAddStage] = useState(false);
  const [openDialogUpdateStage, setOpenDialogUpdateStage] = useState(false);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [selectedStageId, setSelectedStageId] = useState<number | undefined>();
  const [projectStagesLocal, setProjectStagesLocal] = useState<ProjectStage[]>(
    []
  );
  const { GetResourceByUrl } = usePermissions();
  const { user } = useContext(AuthContext);
  const [color, setColor] = useState<number[]>();

  const hexToRgb = (hexColor: string) => {
    hexColor = hexColor.replace("#", "");

    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    return [red, green, blue];
  };

  const onDelete = async () => {
    GenericDeleteResource(`/projects/${props?.project_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/projects", {
          state: { method: "delete" },
          replace: true,
        });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const handleAddStage = (newRow: ProjectStage) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date(),
    };

    setProjectStagesLocal((prevState) => {
      return sortByConsecutive([...projectStagesLocal, myRow]);
    });
  };

  const handleUpdateStage = (updateRow: ProjectStage) => {
    if (projectStagesLocal.length === 0) {
      return;
    }
    setProjectStagesLocal((prevRows) => {
      const rowToUpdateIndex = projectStagesLocal.findIndex(
        (e) => e.project_stage_id === updateRow.project_stage_id
      );

      return sortByConsecutive(
        prevRows.map((row, index) =>
          index === rowToUpdateIndex ? updateRow : row
        )
      );
    });
  };

  const handleDeleteStage = (deleteRow: number) => {
    if (projectStagesLocal.length === 0) {
      return;
    }

    setProjectStagesLocal((prevRows) => {
      const rowToDeleteIndex = projectStagesLocal.findIndex(
        (e) => e.project_stage_id === deleteRow
      );

      const updatedData = [
        ...projectStagesLocal.slice(0, rowToDeleteIndex),
        ...projectStagesLocal.slice(rowToDeleteIndex + 1),
      ];

      return sortByConsecutive(updatedData);
    });
  };

  const compareOrderHint = (a: string, b: string): number => {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  const sortByConsecutive = (data: ProjectStage[]) => {
    return data.sort((a: any, b: any) =>
      compareOrderHint(a.project_stage_consecutive, b.project_stage_consecutive)
    );
  };

  useEffect(() => {
    GenericGetResource(`/projectstages/byprojectid/${props.project_id}`)
      .then(async (response) => {
        setProjectStagesLocal(sortByConsecutive(response.data.data));
        setColor(hexToRgb(defaultTheme.palette.primary.main));
        GetResourceByUrl(`/projectstages`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <>
          <div className="d-flex justify-content-center mt-4">
          <StagesContainer>
              {projectStagesLocal
                .sort(
                  (a, b) =>
                    Number(a.project_stage_consecutive) -
                    Number(b.project_stage_consecutive)
                )
                .map((stage) => (
                  <Grid
                    item
                    key={stage.project_stage_id}
                    xs={8}
                    md={5}
                    style={{ flexShrink: 0 }}
                  >
                    <StageButton
                      color={color}
                      onClick={() => {
                        setSelectedStageId(stage.project_stage_id);
                        setOpenDialogUpdateStage(true);
                      }}
                    >
                      {stage.project_stage_name}
                    </StageButton>
                    <TableProjectTask
                      project_stage_id={stage.project_stage_id}
                      project_id={stage.project_id}
                    />
                  </Grid>
                ))}
              {resourceScreen?.create == true && (
                 <Grid item xs={8} md={5} style={{ flexShrink: 0 }}>
                                  <StageButton
                  color={color}
                  onClick={() => setOpenDialogAddStage(true)}
                >
                  {t("projectstages.actions.create")}
                </StageButton>
                 </Grid>

              )}
            </StagesContainer>
          </div>
        </>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projecttypes.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />

      <DialogEntity
        content={
          <AddProjectStages
            handleAddStage={handleAddStage}
            setOpenDialog={setOpenDialogAddStage}
            project_id={props?.project_id}
          />
        }
        open={openDialogAddStage}
        setOpen={setOpenDialogAddStage}
        title={<Header title={t("projectstages.title-view")} size="sm" />}
      />

      <DialogEntity
        content={
          <UpdateProjectStages
            permissions={resourceScreen}
            setOpenDialog={setOpenDialogUpdateStage}
            handleUpdateStage={handleUpdateStage}
            handleDeleteStage={handleDeleteStage}
            projectStagePayload={
              projectStagesLocal?.find(
                (stage) => stage.project_stage_id === selectedStageId
              ) || {}
            }
          />
        }
        open={openDialogUpdateStage}
        setOpen={setOpenDialogUpdateStage}
        title={<Header title={t("projectstages.title-view")} size="sm" />}
      />
      <SnackbarComponent />
    </>
  );
};
