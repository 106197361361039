import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { Menuitem } from '../../../../interfaces/Security/menu';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../../components/Header';
import { Button } from '@mui/material';
import { usePermissions } from '../../../../hooks/usePermissions';
import { INCOTERM } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/INCOTERMs';
import { UpdateINCOTERMs } from './update';
import { GenericDialog } from '../../../../components/Dialogs/Dialog';
import { ButtonStyled } from '../../../../theme/buttons';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const ViewINCOTERMs = () => {

    const [t] = useTranslation("global");
    const location = useLocation();
    const navigate = useNavigate();
    const [INCOTERMLocal, setINCOTERMLocal] = useState<INCOTERM>();
    const [openDialog, setOpenDialog] = useState(false);
    const [dataloaded, setDataLoaded] = useState(false);
    const { GenericGetResource, GenericDeleteResource } = GenericPromises();
    const [resourceScreen, setResourceScreen] = useState<Menuitem>();
    const { showSnackBar, SnackbarComponent } = useSnackBar();

    const { GetResourceByUrl } = usePermissions();

    const onDelete = () => {
        GenericDeleteResource(`/incoterms/${INCOTERMLocal?.incoterm_id}`)
            .then(() => {
                setOpenDialog(false);
                navigate("/incoterms", { state: { method: "delete" }, replace: true });
            })
            .catch((error) => {
                showSnackBar(error.message, "error");
                setOpenDialog(false);
            })
    }


    useEffect(() => {
        if (location.state === null) return navigate("/incoterms", { state: {}, replace: true });
        GenericGetResource(`/incoterms/${location.state.row.incoterm_id}`)
            .then(
                (response) => {
                    setINCOTERMLocal(response.data);

                    GetResourceByUrl(`/incoterms`)
                        .then((response1) => {
                            setResourceScreen((prev) => response1);
                            setDataLoaded(true);
                        })
                        .catch((error) => {
                            showSnackBar(error.message, "error");
                        });
                }
            ).catch((error) => {
                showSnackBar(error.message, "error");
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!dataloaded && <Spinner />}
            {dataloaded &&
                <>
                    <Header
                        title={t("incoterms.title-view")}
                        child={
                            <div className='d-flex flex-row-reverse m-1'>
                                {resourceScreen?.delete === true &&
                                    <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                                }
                                <Button variant="outlined" onClick={() => { navigate("/incoterms", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
                            </div>
                        }
                    />
                    {resourceScreen?.read &&
                        <>
                            <div>
                                <UpdateINCOTERMs incotermPayload={INCOTERMLocal} isEdit={resourceScreen?.update} />
                            </div>
                        </>
                    }
                </>
            }
            <GenericDialog
                title={t("generic.dialog.delete")}
                content={t("incoterms.dialog.delete")}
                open={openDialog}
                setOpen={setOpenDialog}
                onAcept={onDelete}
            />
            <SnackbarComponent />
        </>
    )
}
