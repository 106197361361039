import React, { useEffect, useState } from "react";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { Box, Button, Popover } from "@mui/material";
import { TabProjectDocuments } from "../ProjectDocuments/table";
import { GenericDialog } from "../../../../components/Dialogs/Dialog";
import { SnackbarComponent } from "../../../../components/Commons/SnackBar";
import { useTranslation } from "react-i18next";
import { Menuitem } from "../../../../interfaces/Security/menu";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { useLocation, useNavigate } from "react-router-dom";
import GenericPromises from "../../../../api/GenericPromises";
import { Project } from "../../../../interfaces/Projects/projects";
import { useBase64 } from "../../../../hooks/useBase64";
import { ProjectExtraField } from "../../../../interfaces/Projects/Catalogs/projectExtraFields";
import { usePermissions } from "../../../../hooks/usePermissions";
import { UpdateProjectDetails } from "./update";
import { useFormulaEvaluator } from "../../../../hooks/useFormulaEvaluator";
import { Parameter } from "../../../../interfaces/Commons/parameters";
import { useCalculateFormulasProjects } from "../../Projects/components/helpers/hookCalculateFormulas/hookCalculateFormulas";
import TabsProjectsDetails from "./tabsDetails";
import { TableProjectStages } from "./Details/ProjectStages/table";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";

export const ViewProjectDetails = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [dataloaded, setDataLoaded] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceProjectExtraFields, setResourceProjectExtraFields] = useState<Menuitem>();
  const [projectLocal, setProjectLocal] = useState<Project>();
  const { GetResourceByUrl } = usePermissions();
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [projectExtraFieldsData, setProjectExtraFieldsData] = useState<ProjectExtraField[]>([]);
  const [isClosedProject, setIsClosedProject] = useState(false);
  const [isUpdateProjectExtraFields, setIsUpdateProjectExtraFields] = useState(true);
  const location = useLocation();
  const { GetParamsFromBase64 } = useParamsFilter();

  useEffect(() => {

    let projectId: any;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      projectId = myParams.get("project_id") ?? '';
    }
    if (location.state === null && (!projectId)) return navigate("/dashboard", { state: {}, replace: true });

    GenericGetResource(
      `/projects/${
        (location.state && location.state.row.project_id ? location.state.row.project_id : projectId)
      }`
    )
      .then(async (responseProject) => {
        setProjectLocal(responseProject.data);
        setIsClosedProject(responseProject.data.project_is_closed);
        let myPromises = [
          GenericGetResource(
            `/projectextrafields/byprojectid/${responseProject.data.project_id}`
          ),
        ];
        await Promise.all(myPromises)
          .then((responses) => {
            responses[0] &&
              setProjectExtraFieldsData((prev) => responses[0].data.data);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
        let myPermissions = [
          GetResourceByUrl(`/projects`),
          GetResourceByUrl(`/projectextrafields`),

        ];
        await Promise.all(myPermissions)
          .then((responsePermissions) => {
            responsePermissions[0] &&
              setResourceScreen((prev) => responsePermissions[0]);
            responsePermissions[1] &&
              setResourceProjectExtraFields((prev) => responsePermissions[1]);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && (
        <>
          <Header
            title={t("projectsdetails.title-view")}
            child={
              <div className="d-flex justify-content-end">
                <div className="d-flex flex-row-reverse m-1">
                  <Button
                    variant="outlined"
                    
                      onClick={() => {
                        navigate("/dashboard", { replace: true });
                      }}
                  >
                    {t("generic.buttons.goback")}
                  </Button>
                </div>
              </div>
            }
          />

          {resourceScreen?.read && (
            <>
              <UpdateProjectDetails
                projectPayload={projectLocal ?? {}}
                isEditProps={resourceScreen?.update}
                projectExtraFieldsData={projectExtraFieldsData}
                setProjectExtraFieldsData={setProjectExtraFieldsData}
                resourceProjectExtraFields={resourceProjectExtraFields}
                showSnackBar={showSnackBar}
                isUpdateProjectExtraFields={isUpdateProjectExtraFields}
                setIsUpdateProjectExtraFields={setIsUpdateProjectExtraFields}
                setIsClosedProject={setIsClosedProject}
                isClosedProject={isClosedProject}
              />
              <div className="my-4" />
              <TabsProjectsDetails
                Details={
                  <TableProjectStages
                  project_id={projectLocal?.project_id}
                  />
                }
                Documents={
                  <TabProjectDocuments
                    showSnackBar={showSnackBar}
                    project_id={projectLocal?.project_id ?? 0}
                    isClosedProject={isClosedProject}
                  />
                }
              />
            </>
          )}
        </>
      )}
      <SnackbarComponent />
    </>
  );
};
