import { useState } from "react";
import { Snackbar, Alert, AlertColor } from "@mui/material";

const SnackbarComponent = ({ open, message, severity, handleClose, positionHorizontal,positionVertical }: any) => {
  return (
    <Snackbar
    
      anchorOrigin={{  horizontal: positionHorizontal, vertical:positionVertical }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const useSnackBar = (positionHorizontal='right', positionVertical='top') => {
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    message: "",
    severity: "error",
    positionHorizontal:positionHorizontal,
    positionVertical:positionVertical
  });

  const showSnackBar = (message: string, severity: AlertColor) => {
    setSnackBarProps({ open: true, message, severity,positionHorizontal, positionVertical});
  };

  const handleClose = () => {
    setSnackBarProps({ ...snackBarProps, open: false });
  };

  return { showSnackBar, SnackbarComponent: () => <SnackbarComponent {...snackBarProps} handleClose={handleClose} /> };
};

export default useSnackBar;
