import { useEffect, useState } from "react"
import { Autocomplete, Box, Button, Grow, TextField, Typography } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import GenericPromises from "../../../api/GenericPromises";
import { PrimaryButton } from "../../../theme/buttons";
import { useTranslation } from "react-i18next";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Warehouse } from "../../../interfaces/Inventory/warehouses";
import { Country } from "../../../interfaces/Commons/Locations/countries";
import { State } from "../../../interfaces/Commons/Locations/states";
import { City } from "../../../interfaces/Commons/Locations/cities";
import { Divider } from "../../../components/Commons/Divider";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";

export const UpdateWarehouse = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericGetResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [hideDetail, setHideDetail] = useState(true);
    const [comboCountries, setComboCountries] = useState<Country[]>([]);
    const [defaultCountry, setDefaultCountry] = useState<Country>();
    const [comboStates, setComboStates] = useState<State[]>([]);
    const [defaultState, setDefaultState] = useState<State>();
    const [comboCities, setComboCities] = useState<City[]>([]);
    const [defaultCity, setDefaultCity] = useState<City>();
    const { warehousePayload } = props;
    const isEdit = !props.isEdit;

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<Warehouse>({
        defaultValues: {
            warehouse_code: "",
            warehouse_description: "",
            stock: undefined,
            country_id: undefined,
            state_id: undefined,
            city_id: undefined,
            postal_code: "",
            street: "",
        }
    });
    const onSubmit: SubmitHandler<Warehouse> = (data) => onPut(data);

    const onPut = (data: Warehouse) => {
        let myData = {
            warehouse_code: data.warehouse_code,
            warehouse_description: data.warehouse_description,
            stock: data.stock ?? 0,
            country_id: data.country_id ?? null,
            state_id: data.state_id ?? null,
            city_id: data.city_id ?? null,
            postal_code: data.postal_code ?? null,
            street: data.street ?? null,
        }
        setLoadingPost(true);
        GenericPutResource(`/warehouses/${warehousePayload.warehouse_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
                setLoadingPost(false);
            });
    }

    useEffect(() => {
        let myPromises = [
            GenericGetResource("/countries"),
            GenericGetResource("/states"),
            GenericGetResource("/cities"),
        ];

        Promise.all(myPromises)
            .then((responses) => {
                setComboCountries(responses[0].data.data);
                setComboStates(responses[1].data.data);
                setComboCities(responses[2].data.data);

                reset({
                    warehouse_code: warehousePayload.warehouse_code,
                    warehouse_description: warehousePayload.warehouse_description,
                    stock: warehousePayload.stock,
                    country_id: warehousePayload.country_id ?? undefined,
                    state_id: warehousePayload.state_id ?? undefined,
                    city_id: warehousePayload.city_id ?? undefined,
                    postal_code: warehousePayload.postal_code ?? undefined,
                    street: warehousePayload.street ?? undefined,
                });

                setDefaultCountry(responses[0].data.data.find((country: Country) => country.country_id === warehousePayload.country_id));
                setDefaultState(responses[1].data.data.find((state: State) => state.state_id === warehousePayload.state_id));
                setDefaultCity(responses[2].data.data.find((city: City) => city.city_id === warehousePayload.city_id));

                setDataLoaded(true);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>
                            
                            <Button variant='outlined' className="d-flex justify-content-between w-100" onClick={() => setHideDetail(!hideDetail)}>
                            <Typography fontWeight={"Bold"} fontSize={22}>{warehousePayload.warehouse_code}</Typography>
                                {!hideDetail && <KeyboardArrowLeftIcon />}
                                {hideDetail && <ExpandMoreIcon />}
                            </Button>

                        {hideDetail &&
                            <Grow in={hideDetail}>

                                <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                                    <div className='w-100'>

                                        <div className='d-flex'>

                                            <div className='w-50'>
                                                <Controller
                                                    name="warehouse_code"
                                                    control={control}
                                                    rules={{ required: true, maxLength: 50 }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            variant="filled"
                                                            label={`${t("warehouses.fields.warehouse_code")}`}
                                                            ref={field.ref}
                                                            value={field.value}
                                                            onChange={(event) => { field.onChange(event.target.value) }}
                                                            error={!!errors.warehouse_code}
                                                            helperText={GetError(errors.warehouse_code?.type)}
                                                            size="small"
                                                            style={{ width: "100%" }}
                                                            sx={{ paddingRight: 2 }}
                                                            disabled={isEdit}
                                                        />
                                                    }
                                                />
                                            </div>

                                            <div className='w-50'>
                                                <Controller
                                                    name="stock"
                                                    control={control}
                                                    rules={{ min: 0 }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            variant="filled"
                                                            label={`${t("warehouses.fields.stock")}`}
                                                            type='number'
                                                            ref={field.ref}
                                                            sx={{
                                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                                "& input[type=number]": { MozAppearance: "textfield", },
                                                            }}
                                                            value={field.value}
                                                            onChange={(event) => { field.onChange(event.target.value) }}
                                                            error={!!errors.stock}
                                                            helperText={GetError(errors.stock?.type)}
                                                            size="small"
                                                            style={{ width: "100%" }}
                                                            disabled={isEdit}
                                                        />
                                                    }
                                                />
                                            </div>

                                        </div>

                                        <div className='my-2 pt-2'>

                                            <Controller
                                                name="warehouse_description"
                                                control={control}
                                                rules={{ required: true, maxLength: 50 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        ref={field.ref}
                                                        value={field.value}
                                                        label={`${t("warehouses.fields.warehouse_description")}`}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.warehouse_description}
                                                        helperText={GetError(errors.warehouse_description?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />

                                        </div>

                                    </div>

                                    <Divider />

                                    <div className='d-flex'>
                                        <div className='w-50'>
                                            <Controller
                                                name="country_id"
                                                control={control}
                                                rules={{}}
                                                render={({ field }) =>
                                                    <Autocomplete
                                                        ref={field.ref}
                                                        size="small"
                                                        options={comboCountries}
                                                        defaultValue={defaultCountry}
                                                        getOptionLabel={(option) => `${option.country_code} - ${option.country_name}`}
                                                        renderOption={(props, option: Country) => (
                                                            <div key={option.country_id}>
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    {option.country_code} - {option.country_name}
                                                                </Box>
                                                            </div>
                                                        )}
                                                        isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                                                        onChange={(_, values) => {
                                                            field.onChange(values?.country_id || null)
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t("warehouses.fields.country")}`}
                                                                variant="filled"
                                                                value={field.value}
                                                                error={!!errors.country_id}
                                                                helperText={GetError(errors.country_id?.type)}
                                                                sx={{ paddingRight: 2 }}
                                                                disabled={isEdit}
                                                            />
                                                        )}
                                                    />
                                                }
                                            />
                                        </div>

                                        <div className='w-50'>
                                            <Controller
                                                name="state_id"
                                                control={control}
                                                rules={{ maxLength: 10 }}
                                                render={({ field }) =>
                                                    <Autocomplete
                                                        ref={field.ref}
                                                        size="small"
                                                        options={comboStates}
                                                        defaultValue={defaultState}
                                                        getOptionLabel={(option) => `${option.state_code} - ${option.state_name}`}
                                                        renderOption={(props, option: State) => (
                                                            <div key={option.state_id}>
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    {option.state_code} - {option.state_name}
                                                                </Box>
                                                            </div>
                                                        )}
                                                        isOptionEqualToValue={(option, value) => option.state_id === value.state_id}
                                                        onChange={(_, values) => {
                                                            field.onChange(values?.state_id || null)
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t("warehouses.fields.state")}`}
                                                                variant="filled"
                                                                value={field.value}
                                                                error={!!errors.state_id}
                                                                helperText={GetError(errors.state_id?.type)}
                                                                disabled={isEdit}
                                                            />
                                                        )}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className='d-flex mt-3'>
                                        <div className='w-50'>
                                            <Controller
                                                name="city_id"
                                                control={control}
                                                rules={{ maxLength: 30 }}
                                                render={({ field }) =>
                                                    <Autocomplete
                                                        ref={field.ref}
                                                        size="small"
                                                        options={comboCities}
                                                        defaultValue={defaultCity}
                                                        getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                                                        renderOption={(props, option: City) => (
                                                            <div key={option.city_id}>
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    {option.city_code} - {option.city_name}
                                                                </Box>
                                                            </div>
                                                        )}
                                                        isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                                                        onChange={(_, values) => {
                                                            field.onChange(values?.city_id || null)
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t("warehouses.fields.city_name")}`}
                                                                variant="filled"
                                                                value={field.value}
                                                                error={!!errors.city_id}
                                                                helperText={GetError(errors.city_id?.type)}
                                                                sx={{ paddingRight: 2 }}
                                                                disabled={isEdit}
                                                            />
                                                        )}
                                                    />
                                                }
                                            />
                                        </div>

                                        <div className='w-50'>
                                            <Controller
                                                name="street"
                                                control={control}
                                                rules={{ maxLength: 60 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("warehouses.fields.street")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.street}
                                                        helperText={GetError(errors.street?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className='d-flex mt-3'>
                                        <div className='w-50'>
                                            <Controller
                                                name="postal_code"
                                                control={control}
                                                rules={{ maxLength: 5 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("warehouses.fields.postal_code")}`}
                                                        ref={field.ref}
                                                        type="number"
                                                        sx={{
                                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                            "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                                                        }}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.postal_code}
                                                        helperText={GetError(errors.postal_code?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>

                                    {!isEdit &&
                                        <div className='d-flex flex-row-reverse mt-4'>
                                            <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                        </div>
                                    }

                                </form>

                            </Grow>
                        }
                    </div>
                </div>
            }
            <SnackbarComponent />
        </>
    )
}
