import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ProjectTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskTemplate";
import { Group } from "../../../../../interfaces/Security/groups";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { ButtonStyled, myStylePickers, PrimaryButton } from "../../../../../theme/buttons";
import { ProjectGroupTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectGroupsTemplate";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { FieldType } from "../../../../../interfaces/Projects/Catalogs/fieldTypes";
import { useBase64 } from "../../../../../hooks/useBase64";
import { TaskState } from "../../../../../interfaces/Projects/Catalogs/taskStates";

type AddProjectTaskTemplateProps = {
  setOpenDialogAddTask: Dispatch<SetStateAction<boolean>>,
  project_template_id: number | undefined,
  project_stage_template_id: number | undefined,
  handleAddTask: (newRow: ProjectTaskTemplate) => void
}

export const AddProjectTaskTemplate = ({ 
  setOpenDialogAddTask,
  project_template_id,
  project_stage_template_id,
  handleAddTask
 }:AddProjectTaskTemplateProps) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource, GenericGetResourceGeneric } =
    GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [comboProjectGroupsTemplate, setComboProjectGroupsTemplate] = useState<
    ProjectGroupTemplate[]
  >([]);
  const [comboGroups, setComboGroups] = useState<Group[]>([]);
  const [comboFieldTypes, setComboFieldTypes] = useState<FieldType[]>([]);
  const [comboTaskStates, setComboTaskStates] = useState<TaskState[]>([]);
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>();
  const [isTypeFormula, setIsTypeFormula] = useState(false);
  const { utf8ToBase64 } = useBase64();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch
  } = useForm<ProjectTaskTemplate>({
    defaultValues: {
      project_task_template_id: undefined,
      project_stage_template_id: undefined,
      project_group_template_id: undefined,
      current_task_state_template_id: undefined,
      project_task_template_description: undefined,
      project_task_template_started_at: undefined,
      project_task_template_due_date: undefined,
      field_type_id: undefined
    },
  });
  const onSubmit: SubmitHandler<ProjectTaskTemplate> = (data) =>
    onPost(data);

  const onPost = (data: ProjectTaskTemplate) => {
    let myData = {
      project_stage_template_id: project_stage_template_id,
      project_group_template_id: data.project_group_template_id,
      current_task_state_template_id: comboTaskStates.find((taskState)=>taskState.task_state_name=='PENDING')?.task_state_id,
      project_task_template_name: data.project_task_template_name,
      project_task_template_description:data.project_task_template_description,
      project_task_template_started_at: data.project_task_template_started_at,
      project_task_template_due_date: data.project_task_template_due_date,
      field_type_id: data.field_type_id
    };
    setLoadingPost(true);
    GenericPostResource("/projecttasktemplate", myData)
      .then((response) => {
        handleAddTask(response.data);
        setOpenDialogAddTask(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const onCancel = () => {
    setOpenDialogAddTask(false);
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };


  const handleAddProjectGroupsTemplate = async (group: Group) => {
    const existingObject = comboProjectGroupsTemplate.find(
      (projectGroupTemplate) =>
        projectGroupTemplate.group_id === group.group_id &&
        projectGroupTemplate.project_template_id === project_template_id
    );

    if (existingObject) {
      return existingObject;
    } else {
      let myData = {
        group_id: group.group_id,
        project_template_id: project_template_id,
        project_group_template_name: group.group_name,
      };

      return await GenericPostResource("/projectgroupstemplate", myData)
        .then((response) => {
          setComboProjectGroupsTemplate([
            ...comboProjectGroupsTemplate,
            response.data,
          ]);
          return response.data;
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
    }
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/projectgroupstemplate"),
      GenericGetResource("/taskstates"),
      GenericGetResourceGeneric("/groups", "/ggroups"),
      GenericGetResource(
        `/fieldtypes?filter=${utf8ToBase64(
          `field_type_name≈formula&field_type_name≈Date`
        )}`
      ),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboProjectGroupsTemplate(responses[0].data.data);
        setComboTaskStates(responses[1].data.data)
        setComboGroups(responses[2].data.data);
        setComboFieldTypes(responses[3].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_group_template_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboGroups}
                      getOptionLabel={(option) => `${option.group_name}`}
                      renderOption={(props, option: Group) => (
                        <div key={option.group_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.group_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.group_id === value.group_id
                      }
                      onChange={async (_, values) => {
                        if (values?.group_id) {
                          let projectGroup =
                            await handleAddProjectGroupsTemplate(values);
                          field.onChange(
                            projectGroup?.project_group_template_id
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projecttaskstemplate.fields.project_group_template"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_group_template_id}
                          helperText={GetError(
                            errors.project_group_template_id?.type
                          )}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_task_template_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projecttaskstemplate.fields.project_task_template_name"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_task_template_name}
                      helperText={GetError(
                        errors.project_task_template_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_task_template_description"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projecttaskstemplate.fields.project_task_template_description"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_task_template_description}
                      helperText={GetError(
                        errors.project_task_template_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_task_template_started_at"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <DatePicker
                      open={openStartDateDatePicker}
                      onClose={() => setOpenStartDateDatePicker(false)}
                      ref={field.ref}
                      label={`${t(
                        "projecttaskstemplate.fields.project_task_template_started_at"
                      )}`}
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(values: any) => {
                        field.onChange(values?.toDate() || null);
                        setStartDate((prev) => values?.toDate() || null);
                        setValue("project_task_template_due_date", null, {
                          shouldValidate: true,
                        });
                      }}
                      slotProps={{
                        popper: {
                          sx: myStylePickers
                        },
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenStartDateDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error:
                            !!errors.project_task_template_started_at,
                          helperText: GetError(
                            errors.project_task_template_started_at?.type
                          ),
                        },
                      }}
                      format="DD/MM/YYYY"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboFieldTypes}
                      getOptionLabel={(option) => `${option.field_type_name}`}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.field_type_id === value.field_type_id
                      }
                      onChange={async (_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (
                          comboFieldTypes.find(
                            (item) =>
                              item.field_type_id === values?.field_type_id &&
                              item.field_type_name.toLowerCase() === "formula"
                          )
                        ) {
                          setIsTypeFormula(true);
                        } else {
                          setIsTypeFormula(false);
                        }
                        setValue("project_task_template_due_date", null, {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projecttaskstemplate.fields.field_type"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.field_type_id}
                          helperText={GetError(errors.field_type_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>

              {isTypeFormula ? (
                <div className="w-50">
                  <Controller
                    name="project_task_template_due_date"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t(
                          "projecttaskstemplate.fields.project_task_template_due_date"
                        )}`}
                        ref={field.ref}
                        value={field.value || ""}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        error={!!errors.project_task_template_due_date}
                        helperText={GetError(
                          errors.project_task_template_due_date?.type
                        )}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={!startDate ? true : false}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="w-50">
                  <Controller
                    name="project_task_template_due_date"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <DatePicker
                        open={openEndDateDatePicker}
                        onClose={() => setOpenEndDateDatePicker(false)}
                        ref={field.ref}
                        label={`${t(
                          "projecttaskstemplate.fields.project_task_template_due_date"
                        )}`}
                        onChange={(values: any) => {
                          field.onChange(values?.toDate() || null);
                        }}
                        value={field.value ? dayjs(field.value) : null}
                        slotProps={{
                          popper: {
                            sx: myStylePickers
                          },
                          textField: {
                            variant: "filled",
                            size: "small",
                            fullWidth: true,
                            onClick: () => setOpenEndDateDatePicker(true),
                            onBeforeInput: disableKeyboardEntry,
                            error:
                              !!errors.project_task_template_due_date,
                            helperText: GetError(
                              errors.project_task_template_due_date?.type
                            ),
                          },
                        }}
                        minDate={dayjs(startDate)}
                        format="DD/MM/YYYY"
                        disabled={!startDate ? true : false}
                      />
                    )}
                  />
                </div>
              )}
            </div>



            <div className="d-flex flex-row-reverse mt-3">
              <PrimaryButton type="submit" variant="outlined" className="m-1">
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled
                variant="contained"
                className="m-1"
                onClick={onCancel}
              >
                {t("generic.buttons.cancel")}
              </ButtonStyled>
            </div>
          </form>
        </Box>
      )}
      <SnackbarComponent />
    </>
  );
};
