import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField } from '@mui/material';
import { ImageDataComponent } from '../../../../components/Images/ImageData';
import DownloadIcon from '@mui/icons-material/Download';
import { useFiles } from '../../../../hooks/useFiles';
import { File } from '../../../../interfaces/Commons/files';
import { Divider } from '../../../../components/Commons/Divider';
import { useDates } from '../../../../hooks/useDates';
import { ButtonStyled } from '../../../../theme/buttons';

export const Invoicing = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GetFiles } = useFiles();
  const { TimeConverter } = useDates();
  const { idSalesInvoice } = props;
  const [fiscalXML, setFiscalXML] = useState(props.fiscalXML);
  const [fiscalQr, setFiscalQr] = useState(props.fiscalQr);
  const { getValues} = props;
  
  const onDownloadXML = () => {
    const downloadLink = document.createElement('a');
    const fileName = `${fiscalXML.file_name}.xml`;
    downloadLink.href = fiscalXML.dataURL ?? '';
    downloadLink.download = fileName;
    downloadLink.click();
  }

  useEffect(() => {
    if(fiscalQr == undefined || fiscalXML.dataURL === ''){
      GetFiles(idSalesInvoice, "SalesInvoices").then((response) =>{
        if (response && response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            const element: File = response[i];
            if (element.file_id === getValues("sales_invoice_QR")) {
              setFiscalQr(() => element);
            }
            if (element.file_id === getValues("sales_invoice_XML")) {
              setFiscalXML(() => element);
            }
          }
        }
      });
    }
  },[getValues("sales_invoice_QR")]);

  return (
    <>
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-100'>
            <div className="custom-card-row d-flex justify-content-between">
              <div className="w-100">
                <div className='d-flex w-100'>
                  <div className='w-100'>
                    <TextField
                      variant="filled"
                      multiline
                      maxRows={4}
                      label={`${t("salesinvoices.fields.original_string_SAT")}`}
                      value={getValues("original_string_SAT")}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className='d-flex w-100 pt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.certificate_number_SAT")}`}
                      value={getValues("certificate_number_SAT")}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.certificate_number_CFDI")}`}
                      value={getValues("certificate_number_CFDI")}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className='d-flex w-100 pt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.uuid")}`}
                      value={getValues("uuid")}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.signing_date")}`}
                      value={(getValues("signing_date") !== null) ? `${TimeConverter(getValues("signing_date"))}` : ''}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className='d-flex w-100 pt-3'>
                  <div className='w-100'>
                    <TextField
                      variant="filled"
                      multiline
                      maxRows={3}
                      label={`${t("salesinvoices.fields.seal_SAT")}`}
                      value={getValues("seal_SAT")}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className='d-flex w-100 pt-3'>
                  <div className='w-100'>
                    <TextField
                      variant="filled"
                      multiline
                      maxRows={3}
                      label={`${t("salesinvoices.fields.seal_CFDI")}`}
                      value={getValues("seal_CFDI")}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <Divider />
                <div className='d-flex w-100 pt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.cancellation_date")}`}
                      value={(getValues("cancellation_date") != null) ? `${TimeConverter(getValues("cancellation_date"))?.toLocaleDateString().toLocaleString()}` : ''}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.cancellation_reason")}`}
                      value={getValues("cancellation_reason")}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className='d-flex w-100 pt-3'>
                  <div className='w-50'>
                    <TextField
                      variant="filled"
                      label={`${t("salesinvoices.fields.cancellation_response_code")}`}
                      value={getValues("cancellation_response_code")}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                      sx={{ paddingRight: 2 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
              </div>
              <Box sx={{ marginLeft: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ImageDataComponent url={fiscalQr.dataURL} />
                <ButtonStyled 
                onClick={onDownloadXML} 
                variant='contained' 
                sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }} 
                disabled={fiscalXML.dataURL === '' ?? true}>
                  <DownloadIcon />
                  {t("salesinvoices.buttons.xml")}
                </ButtonStyled>
              </Box>
            </div>
          </div>
        </div>
    </>
  )
}
