import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../../components/Header";
import GenericPromises from "../../../api/GenericPromises";
import { Button } from '@mui/material';
import { Menuitem } from "../../../interfaces/Security/menu";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { Warehouse } from "../../../interfaces/Inventory/warehouses";
import { UpdateWarehouse } from "./update";
import WarehouseDetail from "./warehouseDetail";
import { TableWarehouseItems } from "../WarehouseItems/table";
import { usePermissions } from "../../../hooks/usePermissions";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../theme/buttons";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";

export const ViewWarehouse = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [warehouseLocal, setWarehouseLocal] = useState<Warehouse>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GetResourceByUrl } = usePermissions();

  const onDelete = () => {
    GenericDeleteResource(`/warehouses/${warehouseLocal?.warehouse_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/warehouses", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    if (location.state === null) return navigate("/warehouses", { state: {}, replace: true });
    GenericGetResource(`/warehouses/${location.state.row.warehouse_id}`)
      .then(
        (response) => {
          setWarehouseLocal(response.data);

          GetResourceByUrl(`/warehouses`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, 'error');
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("warehouses.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="outlined" onClick={() => { navigate("/warehouses", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />
          <div>
            <UpdateWarehouse warehousePayload={warehouseLocal} isEdit={resourceScreen?.update} />
          </div>

          <div className="mt-4">
            <WarehouseDetail
              Items={
                <TableWarehouseItems warehouse_id={warehouseLocal?.warehouse_id} warehouse_code={warehouseLocal?.warehouse_code} warehouse_description={warehouseLocal?.warehouse_description} />
              }
            />
          </div>

        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("warehouses.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
