import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../../components/Header';
import { Autocomplete, Box, Switch, TextField, Typography } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { ProductServiceKey } from '../../../../interfaces/Sales/Catalogs/productServiceKeys';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { HazardousMaterialKey } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/hazardousMaterialsKeys';

export const AddProductServiceKeys = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const { GetError } = FormsErrors();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboHazardousMaterialKeys, setComboHazardousMaterialKeys] = useState<HazardousMaterialKey[]>([]);
  const [hasHazardousMaterial, setHasHazardousMaterial] = useState(true);
  const [couldBeHazardousMaterial, setCouldBeHazardousMaterial] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<ProductServiceKey>({
    defaultValues: {
      product_service_key: '',
      description: '',
      is_dangerous_material: false,
      includes_transportation_document: false,
      hazardous_material_key_id: undefined,
      could_be_hazardous_material: false,
    }
  });

  const onSubmit: SubmitHandler<ProductServiceKey> = (data) => onPost(data);

  const onPost = (data: ProductServiceKey) => {
    let myData = {
      product_service_key: data.product_service_key.trim(),
      description: data.description,
      includes_transportation_document: data.includes_transportation_document ?? false,
      is_dangerous_material: data.is_dangerous_material ?? false,
      hazardous_material_key_id: ((data.is_dangerous_material) ? data.hazardous_material_key_id : null) ?? null,
      could_be_hazardous_material: data.could_be_hazardous_material ?? false,
    }
    setLoadingPost(true);
    GenericPostResource("/productservicekeys", myData)
      .then((response) => {
        navigate("/productservicekeys", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/productservicekeys", { state: {} });
  }


  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("productservicekeys.title")}`;
    })
    GenericGetResource(`/hazardousmaterialskeys`)
      .then((response) => {
        setComboHazardousMaterialKeys(response.data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  useEffect(() => { }, [hasHazardousMaterial]);

  useEffect(() => { }, [couldBeHazardousMaterial]);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("productservicekeys.title-view")} />
          <div className='d-flex justify-content-center'>

            <div className='custom-card-view w-75'>

              <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                <div className="custom-card-row d-flex justify-content-between">
                  <div className="w-100">
                    <div className='d-flex w-100'>
                      <div className='w-50'>
                        <Controller
                          name="product_service_key"
                          control={control}
                          rules={{ required: true, maxLength: 10 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("productservicekeys.fields.product_service_key")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.product_service_key}
                              helperText={GetError(errors.product_service_key?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{ paddingRight: 2 }}
                            />
                          }
                        />
                      </div>

                      <div className='w-50'>
                        <Controller
                          name="description"
                          control={control}
                          rules={{ maxLength: 100 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("productservicekeys.fields.description")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.description}
                              helperText={GetError(errors.description?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>

                    </div>

                    <div className='my-2 pt-2 d-flex'>
                      <div className='w-50'>
                        <div className="w-100">
                          <Controller
                            name="includes_transportation_document"
                            control={control}
                            render={({ field }) =>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                                <Typography>{t("productservicekeys.fields.includes_transportation_document")}</Typography>
                                <Switch
                                  ref={field.ref}
                                  onChange={(_, e) => {
                                    field.onChange(e);
                                  }}
                                  value={field.value}
                                  checked={field.value}
                                />
                              </Box>
                            }
                          />
                        </div>
                        <div className="w-100">
                          <Controller
                            name="could_be_hazardous_material"
                            control={control}
                            render={({ field }) =>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                                <Typography>{t("productservicekeys.fields.could_be_hazardous_material")}</Typography>
                                <Switch
                                  ref={field.ref}
                                  onChange={(_, e) => {
                                    field.onChange(e);
                                    setValue("could_be_hazardous_material", e);
                                    setCouldBeHazardousMaterial(!couldBeHazardousMaterial);
                                    if (!couldBeHazardousMaterial) {
                                      reset({ hazardous_material_key_id: undefined, is_dangerous_material: false }, { keepValues: true, keepErrors: false });
                                      setValue("hazardous_material_key_id", undefined);
                                      setValue("is_dangerous_material", false);
                                    }
                                    else if (couldBeHazardousMaterial) {
                                      reset({ could_be_hazardous_material: false }, { keepValues: true, keepErrors: false });
                                      setValue("could_be_hazardous_material", false);
                                    }
                                  }}
                                  value={field.value}
                                  checked={field.value}
                                  disabled={getValues("is_dangerous_material")}
                                />
                              </Box>
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex w-50'>
                        <div className="w-50">
                          <Controller
                            name="is_dangerous_material"
                            control={control}
                            render={({ field }) =>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                                <Typography>{t("productservicekeys.fields.is_dangerous_material")}</Typography>
                                <Switch
                                  ref={field.ref}
                                  onChange={(_, e) => {
                                    field.onChange(e);
                                    setHasHazardousMaterial(!hasHazardousMaterial);
                                    if (!hasHazardousMaterial) {
                                      reset({ hazardous_material_key_id: undefined, is_dangerous_material: false }, { keepValues: true, keepErrors: false });
                                      setValue("hazardous_material_key_id", undefined);
                                      setValue("is_dangerous_material", false);
                                    }
                                    else if (hasHazardousMaterial) {
                                      reset({ could_be_hazardous_material: false }, { keepValues: true, keepErrors: false });
                                      setValue("could_be_hazardous_material", false);
                                    }
                                  }}
                                  value={field.value}
                                  checked={field.value}
                                  disabled={getValues("could_be_hazardous_material")}
                                />
                              </Box>
                            }
                          />
                        </div>
                        <div className="w-50">
                          <Controller
                            name="hazardous_material_key_id"
                            control={control}
                            rules={{ required: !hasHazardousMaterial }}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%" }}
                                options={comboHazardousMaterialKeys}
                                getOptionLabel={(option) => `${option.hazardous_material_key} - ${option.hazardous_material_description}`}
                                renderOption={(props, option: HazardousMaterialKey) => (
                                  <div key={option.hazardous_material_key_id}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {option.hazardous_material_key} - {option.hazardous_material_description}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.hazardous_material_key_id === value.hazardous_material_key_id}
                                onChange={(_, values) => {
                                  field.onChange(values?.hazardous_material_key_id || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("hazardousmaterialskeys.title-view")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.hazardous_material_key_id}
                                    helperText={GetError(errors.hazardous_material_key_id?.type)}
                                  />
                                )}
                                disabled={hasHazardousMaterial}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className='d-flex flex-row-reverse mt-1'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                  <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                </div>

              </form>

            </div>

          </div>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
