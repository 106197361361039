import { Grid, List, ListItemButton, ListItemText, Popover, Typography } from "@mui/material"
import { DashBoardFilter } from "../DashBoardFilter"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ProjectType } from "../../../../../interfaces/Projects/Catalogs/projectTypes";

interface HeaderDashBoardProps {
  title: string,
  comboProjectTypes: ProjectType[],
  defaultProjectType: ProjectType | undefined,
  setDefaultProjectType: Dispatch<SetStateAction<ProjectType | undefined>>,
  onFilterChange: (searchTerm: string) => void,
  filterValue: string,
  onClearFilter: () => void,
}

export const HeaderDashBoard = ({
  title,
  comboProjectTypes,
  defaultProjectType,
  setDefaultProjectType,
  onFilterChange,
  filterValue,
  onClearFilter,
}: HeaderDashBoardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClickOpenPopOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      sx={{
        position: 'sticky',
        borderRadius: 1,
        backdropFilter: 'blur(5px)',
        boxShadow: '0 2px 3px rgba(0, 0, 0, 0.3)',
        marginBottom: 2,
        height: "6vh",
      }}
      padding={1}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography
        sx={{
          color: "black",
          display: "flex",
          cursor: "pointer",
          padding: 1,
          borderRadius: 1,
          ":hover": {
            backgroundColor: '#e0e0e0',
          }
        }}
        onClick={handleClickOpenPopOver}
        fontWeight={"Bold"}
      >
        {defaultProjectType ? (defaultProjectType.project_type_name ?? '') : title}
        <ArrowDropDownIcon />
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {comboProjectTypes.map((item, index) => {
            return (
              <ListItemButton key={index} onClick={() => setDefaultProjectType(item)}>
                <ListItemText primary={item.project_type_name} />
              </ListItemButton>
            )
          })}
        </List>
      </Popover>
      <DashBoardFilter
        onFilterChange={onFilterChange}
        filterValue={filterValue}
        onClearFilter={onClearFilter}
      />
    </Grid>
  )
}
