import { Text, View } from "@react-pdf/renderer";
import { transportationDocumentStyles } from "../transportationDocument/transportationDocumentStyles";

const {
    transportationDocumentLocationsData,
} = transportationDocumentStyles();


export const Destinations =({data,showTranslatedText}:any) =>{

    return (
        <View style={transportationDocumentLocationsData.container}>
            <View style={transportationDocumentLocationsData.titleContainer} fixed>
                <Text style={transportationDocumentLocationsData.title}>{showTranslatedText("reports.salesInvoicePdf.transportationDocumentLocationsData.SENTTO(DESTINATIONS)")}</Text>
            </View>
            <View style={transportationDocumentLocationsData.tableContainer} >
                <View  style={transportationDocumentLocationsData.titleRow} fixed>
                    <Text style={transportationDocumentLocationsData.firstConceptField} ></Text>
                    <Text style={transportationDocumentLocationsData.rfcConcept} >RFC</Text>
                    <Text style={transportationDocumentLocationsData.businessNameConcept} >{showTranslatedText("reports.salesInvoicePdf.transportationDocumentLocationsData.businessName")}</Text>
                    <Text style={transportationDocumentLocationsData.countryConcept} >{showTranslatedText("reports.salesInvoicePdf.transportationDocumentLocationsData.country")}</Text>
                    <Text style={transportationDocumentLocationsData.stateConcept} >{showTranslatedText("reports.salesInvoicePdf.transportationDocumentLocationsData.state")}</Text>
                    <Text style={transportationDocumentLocationsData.cityConcept} >{showTranslatedText("reports.salesInvoicePdf.transportationDocumentLocationsData.city")}</Text>
                    <Text style={transportationDocumentLocationsData.addressConcept} >{showTranslatedText("reports.salesInvoicePdf.transportationDocumentLocationsData.street")}</Text>
                    <Text style={transportationDocumentLocationsData.postalCodeConcept} >{showTranslatedText("reports.salesInvoicePdf.transportationDocumentLocationsData.postalCode")}</Text>
                    <Text style={transportationDocumentLocationsData.dateConcept} >{showTranslatedText("reports.salesInvoicePdf.transportationDocumentLocationsData.date")}</Text>
                    <Text style={transportationDocumentLocationsData.distanceConcept} >{showTranslatedText("reports.salesInvoicePdf.transportationDocumentLocationsData.distance")}</Text>
                </View>
                {data.dataTransportationDocumentLocations.map((item: any, index: any) => {
                    if(item.location_type=='Destino'){
                         return (
                            <View style={transportationDocumentLocationsData.row} key={index} wrap={false}>
                                <Text style={transportationDocumentLocationsData.firstDataField} >{item.location_type || ' '}</Text>
                                <Text style={transportationDocumentLocationsData.rfcData} >{item.rfc || ' '}</Text>
                                <Text style={transportationDocumentLocationsData.businessNameData} >{item.business_name || ' '}</Text>
                                <Text style={transportationDocumentLocationsData.countryData} >{item.country_name || ' '}</Text>
                                <Text style={transportationDocumentLocationsData.stateData} >{item.state_name || ' '}</Text>
                                <Text style={transportationDocumentLocationsData.cityData} >{item.city_name || ' '}</Text>
                                <Text style={transportationDocumentLocationsData.addressData} >{item.street || ' '}</Text>
                                <Text style={transportationDocumentLocationsData.postalCodeData} >{item.postal_code || ' '}</Text>
                                <Text style={transportationDocumentLocationsData.dateData} >{item.date_hour_departure_arrival || ' '}</Text>
                                <Text style={transportationDocumentLocationsData.distanceData} >{item.distance || ' '}</Text>
                            </View>
                        );
                    }
                })
                }
            </View>
        </View>
    )
}