import { Box, Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UseFormGetValues } from 'react-hook-form'
import InfoIcon from '@mui/icons-material/Info';
import { ButtonLoading } from '../../../../../theme/buttons';
import { Project } from '../../../../../interfaces/Projects/projects';

type ConfirmDialogProps = {
  isEdit: boolean,
  loadingPost: boolean,
  setDialogConfirmCloseProject:Dispatch<SetStateAction<boolean>>,
  getValues: UseFormGetValues<Project>,
  onCloseProject: () => void
}

export const ConfirmDialog = ({
  isEdit,
  loadingPost,
  setDialogConfirmCloseProject,
  getValues,
  onCloseProject
}: ConfirmDialogProps) => {
  const [t] = useTranslation("global");
  const [projectName, setProjectName] = useState<string>();
  const [isEqualName, setEqualName] = useState(false);

  const handleChange = (e: any) => {
    const value = e.target.value;
    setProjectName(value);
    if (getValues("project_name") === value) {
      setEqualName((prev) => true);
    }
    else {
      setEqualName(false);
    }
  }

  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"start"} width={"100%"}>
        <Box width={"100%"} marginBottom={2}>
          <Typography margin={1}>{t("projects.info.confirm-close-project")}</Typography>
          <Typography margin={1}><InfoIcon /> {t("projects.info.close-project-warning")}</Typography>
          <TextField
            value={projectName}
            variant='outlined'
            size='small'
            onChange={handleChange}
            label={t("projects.fields.project_name")}
            required
            fullWidth
          />
        </Box>
      <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
        <ButtonLoading disabled={ isEdit || !isEqualName} isLoading={loadingPost} onClick={onCloseProject} className="d-flex align-self-end" variant="outlined">{t("generic.buttons.close")}</ButtonLoading>
        <Button variant='outlined' onClick={() => { setDialogConfirmCloseProject(false) }} disabled={loadingPost}>{t("generic.buttons.goback")}</Button>
      </Box>
    </Box>
  )
}
