import { useEffect, useState } from "react"
import { Box, Switch, TextField, Typography } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import GenericPromises from "../../../api/GenericPromises";
import { PrimaryButton } from "../../../theme/buttons";
import { useTranslation } from "react-i18next";
import { ItemType } from '../../../interfaces/Commons/items';
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";

export const UpdateItemTypes = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { itemTypePayload } = props;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ItemType>({
    defaultValues: {
      item_type_name: "",
      item_type_description: "",
      item_type_code: undefined,
      sale: true,
      purchase: true,
      stock_management: true,
    }
  });
  const onSubmit: SubmitHandler<ItemType> = (data) => onPut(data);

  const onPut = (data: ItemType) => {
    let myData = {
      item_type_name: data.item_type_name,
      item_type_code: data.item_type_code,
      item_type_description: data.item_type_description ?? null,
      sale: data.sale ?? false,
      purchase: data.purchase ?? false,
      stock_management: data.stock_management ?? false,
    }
    setLoadingPost(true);
    GenericPutResource(`/itemtypes/${data.item_type_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setLoadingPost(false);
      });
  }

  useEffect(() => {
    reset({
      item_type_id: itemTypePayload.item_type_id,
      item_type_name: itemTypePayload.item_type_name,
      item_type_code: itemTypePayload.item_type_code,
      item_type_description: itemTypePayload.item_type_description,
      sale: itemTypePayload.sale,
      purchase: itemTypePayload.purchase,
      stock_management: itemTypePayload.stock_management
    });

    setDataLoaded(true);
  }, [])

  return (
    <>
      {dataLoaded &&
        <div className='d-flex justify-content-center'>

          <div className='custom-card-view w-50'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row">
                <Controller
                  name="item_type_name"
                  control={control}
                  rules={{ required: true, maxLength: 150 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      label={t("item-types.fields.item_type_name")}
                      error={!!errors.item_type_name}
                      helperText={GetError(errors.item_type_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>

              <div className="custom-card-row">
                <Controller
                  name="item_type_code"
                  control={control}
                  rules={{ maxLength: 50 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      ref={field.ref}
                      value={field.value}
                      label={t("item-types.fields.item_type_code")}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.item_type_code}
                      helperText={GetError(errors.item_type_code?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>

              <div className="custom-card-row">
                <Controller
                  name="item_type_description"
                  control={control}
                  rules={{ maxLength: 300 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      label={t("item-types.fields.item_type_description")}
                      error={!!errors.item_type_description}
                      helperText={GetError(errors.item_type_description?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>

              <div className="d-flex flex-column mt-3">
                <Controller
                  name="sale"
                  control={control}
                  render={({ field }) =>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                      <Typography sx={{ ml: 2 }}>{t("item-types.fields.sale")}</Typography>
                      <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={itemTypePayload.sale} />
                    </Box>
                  }
                />
                <Controller
                  name="purchase"
                  control={control}
                  render={({ field }) =>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                      <Typography sx={{ ml: 2 }}>{t("item-types.fields.purchase")}</Typography>
                      <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={itemTypePayload.purchase} />
                    </Box>
                  }
                />
                <Controller
                  name="stock_management"
                  control={control}
                  render={({ field }) =>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                      <Typography sx={{ ml: 2 }}>{t("item-types.fields.stock_management")}</Typography>
                      <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={itemTypePayload.stock_management} />
                    </Box>
                  }
                />
              </div>

              <div className='d-flex flex-row-reverse mt-4'>
                <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
              </div>

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
