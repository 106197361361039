import { useEffect, useState } from 'react'
import { Header } from '../../../components/Header'
import GenericPromises from '../../../api/GenericPromises';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InvoiceDocumentType } from '../../../interfaces/Sales/Invoices/salesinvoices';
import { useTranslation } from 'react-i18next';
import { Currency } from '../../../interfaces/Commons/currencies';
import { PaymentMethod } from '../../../interfaces/Sales/Catalogs/paymentMethods';
import { PaymentMode } from '../../../interfaces/Sales/Catalogs/paymentModes';
import { CFDI } from '../../../interfaces/Sales/Catalogs/CFDIs';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Tax } from '../../../interfaces/Sales/Catalogs/taxes';
import { Company } from '../../../interfaces/Security/companies';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePermissions } from '../../../hooks/usePermissions';
import { PaymentCondition } from '../../../interfaces/Sales/Catalogs/paymentConditions';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { Language } from '../../../interfaces/Commons/languages';
import { PurchaseInvoice } from '../../../interfaces/Purchases/Invoices/purchaseInvoices';
import { Supplier, SupplierLocation } from '../../../interfaces/Purchases/Catalogs/suppliers';
import { PurchaseInvoicesHeader } from './invoiceHeader';
import { SupplierBankAccount } from '../../../interfaces/Purchases/Catalogs/supplierBankAccounts';
import { useCurrencies } from '../../../hooks/useCurrencies';
import { useParamsFilter } from '../../../hooks/useParamsFilter';
import { TaxRegime } from '../../../interfaces/Sales/Catalogs/taxRegimes';

export const AddPurchaseInvoices = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GetParamsFromBase64 } = useParamsFilter();
  const [isChangedTotalValue, setIsChangedTotalValue] = useState(false);
  const { GetExchangeRateFromCurrencyCode } = useCurrencies();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [myConsecutive, setMyConsecutive] = useState("");
  const { GenericGetResource, GenericPostDocument, GenericGetResourceGeneric } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const [company, setCompany] = useState<Company>();
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [comboPaymentModes, setComboPaymentModes] = useState<PaymentMode[]>([]);
  const [comboSuppliers, setComboSuppliers] = useState<Supplier[]>([]);
  const [comboCFDIs, setComboCFDIs] = useState<CFDI[]>([]);
  const [comboPaymentConditions, setComboPaymentConditions] = useState<PaymentCondition[]>([]);
  const [comboLanguages, setComboLanguages] = useState<Language[]>([])
  const [comboInvoiceDocumentTypes, setComboInvoiceDocumentTypes] = useState<InvoiceDocumentType[]>([]);
  const [isSelectedSupplierTextfield, setIsSelectedSupplierTextfield] = useState(false);
  const [projectId, setProjectId] = useState("");

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
  } = useForm<PurchaseInvoice>({
    defaultValues: {
      purchase_invoice_id: undefined,
      purchase_invoice_name: undefined,
      purchase_invoice_date: undefined,
      supplier_sale_invoice_date: undefined,
      sales_order: undefined,
      supplier_id: undefined,
      supplier_business_name: undefined,
      supplier_comercial_name: undefined,
      supplier_rfc: undefined,
      supplier_street_name: undefined,
      supplier_location_id: undefined,
      supplier_postal_code: undefined,
      supplier_city_id: undefined,
      supplier_city_name: undefined,
      supplier_state_id: undefined,
      supplier_state_name: undefined,
      supplier_state_abbr: undefined,
      supplier_country_id: undefined,
      supplier_country_name: undefined,
      supplier_country_code: undefined,
      subtotal_amount: 0,
      total_amount: 0,
      tax_amount: 0,
      currency_id: undefined,
      currency_code: undefined,
      currency_description: undefined,
      project_id: undefined,
      invoice_doc_type_id: 3,
      invoice_doc_status_id: undefined,
      payment_mode_id: undefined,
      payment_mode_code: undefined,
      payment_mode_description: undefined,
      payment_method_id: undefined,
      payment_method_code: undefined,
      payment_method_name: undefined,
      cfdi_id: undefined,
      cfdi_code: undefined,
      cfdi_description: undefined,
      discount_amount: 0,
      supplier_tax_regime_id: undefined,
      supplier_tax_regime_code: undefined,
      supplier_tax_regime_description: undefined,
      is_accounted: undefined,
      id_consecutive: undefined,
      notes: undefined,
      retention_amount: 0,
      payment_condition_id: undefined,
      payment_condition_days: undefined,
      payment_condition_name: undefined,
      payment_condition_description: undefined,
      payment_due_date: undefined,
      supplier_language: undefined,
      supplier_email: undefined,
      supplier_phone_number: undefined,
      date_mail_send: undefined,
      user_mail_send: undefined,
      company_business_name: undefined,
      company_street: undefined,
      company_city_id: undefined,
      company_city_name: undefined,
      company_city_code: undefined,
      company_state_id: undefined,
      company_state_name: undefined,
      company_state_abbr: undefined,
      company_country_id: undefined,
      company_country_name: undefined,
      company_country_code: undefined,
      company_postal_code: undefined,
      company_rfc: undefined,
      company_tax_regime_id: undefined,
      company_tax_regime_code: undefined,
      company_tax_regime_description: undefined,
      company_email: undefined,
      company_phone_number: undefined,
      xml_file_id: undefined,
      qr_file: undefined,
      report_file_id: undefined,
      cancel_xml_file_id: undefined,
      creation_date: undefined,
      last_update_user: undefined,
      last_update_date: undefined,
    }
  });
  const onSubmit: SubmitHandler<PurchaseInvoice> = (data) => onPost(data);

  const onPost = async (data: PurchaseInvoice, mySupplierLocation?: SupplierLocation) => {
    if (data.invoice_doc_type_id) {
      let mySupplier = comboSuppliers.find((supplier: Supplier) => supplier.supplier_id === data.supplier_id);
      if (mySupplier !== undefined) {
        GenericGetResource(`/supplierbankaccounts/bysupplierid/${mySupplier.supplier_id}`)
          .then(async (comboSupplierBankAccounts) => {
            let myPaymentMode = comboPaymentModes.find((item: PaymentMode) => item.payment_mode_id === mySupplier?.default_payment_mode_id);
            let myPaymentMethod = comboPaymentMethods.find((item: PaymentMethod) => item.payment_method_id === mySupplier?.default_payment_method_id);
            let myCFDI = comboCFDIs.find((item: CFDI) => item.cfdi_id === mySupplier?.default_cfdi_id);
            let myCurrency = comboCurrencies.find((item: Currency) => item.currency_id === mySupplier?.default_currency_id);
            let myInvoiceDocumentType = comboInvoiceDocumentTypes.find((item: InvoiceDocumentType) => item.invoice_doc_type_id === data.invoice_doc_type_id);
            let myPaymentCondition = comboPaymentConditions.find((item: PaymentCondition) => item.payment_condition_id === mySupplier?.default_payment_condition_id);
            let mySupplierLanguage = comboLanguages.find((language: Language) => language.language_id === mySupplier?.language_id)
            let myBankAccount = comboSupplierBankAccounts.data.data.find((bank: SupplierBankAccount) => bank.supplier_bank_account_id === mySupplier?.primary_bank_account)
            let myexchangeRate = 1;
            if (myCurrency?.currency_code !== "MXN") {
              await GetExchangeRateFromCurrencyCode(myCurrency?.currency_id ?? 0, data.supplier_sale_invoice_date ?? data.purchase_invoice_date ?? new Date()).then((exchangeRate) => {
                myexchangeRate = exchangeRate?.rate ?? 1
              })
            }

            let mySupplierTaxRegime: TaxRegime | undefined = undefined;
            if (mySupplierLocation) {
              let myTaxRegimeResponse = await GenericGetResource(`/taxregimes/${mySupplierLocation.tax_regime_id}`);
              mySupplierTaxRegime = myTaxRegimeResponse.data;
            }

            const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myInvoiceDocumentType?.consecutive_id}`);
            const responseCompany = await GenericGetResourceGeneric("/companies/1", "/gcompanies");
            const company = responseCompany.data ?? null;
            let myData = {
              invoice_doc_status_id: 1,
              purchase_invoice_name: responseConsecutive.data.data[0].Consecutive,
              purchase_invoice_date: data.purchase_invoice_date ?? new Date(),
              supplier_sale_invoice_date: data.supplier_sale_invoice_date,
              expiration_date: data.expiration_date,
              sales_order: data.sales_order,
              supplier_id: data.supplier_id,
              supplier_business_name: mySupplier?.business_name ?? null,
              supplier_comercial_name: mySupplier?.comercial_name ?? null,
              supplier_rfc: mySupplier?.supplier_rfc ?? null,
              supplier_location_id: mySupplierLocation?.supplier_location_id ?? null,
              supplier_street_name: mySupplierLocation?.street ?? null,
              supplier_postal_code: mySupplierLocation?.postal_code ?? null,
              supplier_city_id: mySupplierLocation?.city_id ?? null,
              supplier_city_name: mySupplierLocation?.city_name ?? null,
              supplier_state_id: mySupplierLocation?.state_id ?? null,
              supplier_state_name: mySupplierLocation?.state_name ?? null,
              supplier_state_abbr: mySupplierLocation?.state_abbr ?? null,
              supplier_country_id: mySupplierLocation?.country_id ?? null,
              supplier_country_name: mySupplierLocation?.country_name ?? null,
              supplier_country_code: mySupplierLocation?.country_code ?? null,
              supplier_bank_account_id: myBankAccount?.supplier_bank_account_id ?? (data.supplier_bank_account_id ?? null),
              supplier_account_alias: myBankAccount?.account_alias ?? (data.supplier_account_alias ?? null),
              supplier_interbank_key: myBankAccount?.interbank_key ?? (data.supplier_interbank_key ?? null),
              supplier_swift_code: myBankAccount?.swift_code ?? (data.supplier_swift_code ?? null),
              supplier_bank_name: myBankAccount?.bank_name ?? (data.supplier_bank_name ?? null),
              supplier_account_number: myBankAccount?.account_number ?? (data.supplier_account_number ?? null),
              subtotal_amount: 0,
              total_amount: 0,
              tax_amount: 0,
              project_id: (projectId === "") ? null : projectId,
              currency_id : myCurrency?.currency_id ?? null,
              currency_code: myCurrency?.currency_code ?? null,
              currency_description: myCurrency?.currency_description ?? null,
              invoice_doc_type_id: data.invoice_doc_type_id,
              payment_mode_code: myPaymentMode?.code ?? null,
              payment_mode_description: myPaymentMode?.description ?? null,
              payment_method_code: myPaymentMethod?.code ?? null,
              payment_method_name: myPaymentMethod?.description ?? null,
              cfdi_code: myCFDI?.code ?? null,
              cfdi_description: myCFDI?.description ?? null,
              discount_amount: 0,
              supplier_tax_regime_code: mySupplierTaxRegime?.tax_regime_code ?? null,
              supplier_tax_regime_description: mySupplierTaxRegime?.description ?? null,
              is_accounted: data.is_accounted,
              id_consecutive: myInvoiceDocumentType?.consecutive_id ?? null,
              notes: data.notes,
              retention_amount: 0,
              payment_condition_days: myPaymentCondition?.payment_condition_days ?? null,
              payment_condition_name: myPaymentCondition?.payment_condition_name ?? null,
              payment_condition_description: myPaymentCondition?.payment_condition_description ?? null,
              payment_due_date: data.payment_due_date,
              supplier_language: mySupplierLanguage?.language_code ?? "ES",
              supplier_email: data.supplier_email,
              supplier_phone_number: data.supplier_phone_number,
              date_mail_send: data.date_mail_send,
              user_mail_send: data.user_mail_send,
              company_business_name: company.business_name ?? null,
              company_street: company.street ?? null,
              company_city_id: company.city_id ?? null,
              company_city_name: company.city_name ?? null,
              company_city_code: company.city_code ?? null,
              company_state_id: company.state_id ?? null,
              company_state_name: company.state_name ?? null,
              company_state_abbr: company.state_abbr ?? null,
              company_country_id: company.country_id ?? null,
              company_country_name: company.country_name ?? null,
              company_country_code: company.country_code ?? null,
              company_postal_code: company.postal_code ?? null,
              company_rfc: company.rfc ?? null,
              company_tax_regime_id: company.tax_regime_id ?? null,
              company_tax_regime_code: company.tax_regime_code ?? null,
              company_tax_regime_description: company.tax_regime_description ?? null,
              company_email: company.email ?? null,
              company_phone_number: company.phone_number ?? null,
              xml_file_id: data.xml_file_id,
              qr_file: data.qr_file,
              report_file_id: data.report_file_id,
              cancel_xml_file_id: data.cancel_xml_file_id,
              exchange_rate: myexchangeRate,
            }
            GenericPostDocument("/purchaseinvoices/add", myData, true)
              .then(async (response) => {
                await navigate("/purchaseinvoices/view", { state: { row: response.data } });
              })
              .catch((error) => {
                showSnackBar(error.message, "error")
                setIsSelectedSupplierTextfield(false);
              });
          })
      }
    }
  };

  const onChangeSupplier = (supplier_id: number | undefined) => {
    let myPromises = [
      GenericGetResource(`/supplierlocations/bysupplierid/${supplier_id}`),
      GenericGetResource(`/suppliers/${supplier_id}`)
    ];
    Promise.all(myPromises)
      .then(async (responses) => {
        let myMainLocation: SupplierLocation = responses[0].data.data.find((supplierLocation: SupplierLocation) => supplierLocation.fiscal_address === true);
        if (responses[0].data.data.length > 0 && myMainLocation) {

          reset({
            supplier_id: supplier_id,
            supplier_location_id: myMainLocation?.supplier_location_id ?? undefined,
            supplier_city_id: myMainLocation.city_id,
            supplier_city_name: myMainLocation.city_name,
            supplier_state_id: myMainLocation.state_id,
            supplier_state_name: myMainLocation.state_name,
            supplier_state_abbr: myMainLocation.state_abbr,
            supplier_country_id: myMainLocation.country_id,
            supplier_country_name: myMainLocation.country_name,
            supplier_country_code: myMainLocation.country_code,
            supplier_postal_code: myMainLocation.postal_code,
            supplier_street_name: myMainLocation.street,
            payment_method_id: responses[1].data.default_payment_method_id ?? undefined,
            payment_mode_id: responses[1].data.default_payment_mode_id ?? undefined,
            currency_id: responses[1].data.default_currency_id ?? undefined,
            cfdi_id: responses[1].data.default_cfdi_id ?? undefined,
            payment_condition_id: responses[1].data.default_payment_condition_id ?? undefined,
            purchase_invoice_date: getValues("purchase_invoice_date"),
            invoice_doc_type_id: getValues("invoice_doc_type_id"),
          });

          onPost(getValues(), myMainLocation);
        }
        else {
          showSnackBar(t("purchaseinvoices.info.no_fiscal"), "error");
          setIsSelectedSupplierTextfield(false);
        }
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setIsSelectedSupplierTextfield(false);
      });
  }

  useEffect(() => { }, [isChangedTotalValue]);

  useEffect(() => {
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      let project = myParams.get("project_id");
      setProjectId(project ?? "");
    }

    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("purchaseinvoices.title")}`;
    })

    let myPromises = [
      GenericGetResource("/currencies"),
      GenericGetResource("/paymentmethods"),
      GenericGetResource("/paymentmodes"),
      GenericGetResource("/suppliers"),
      GenericGetResourceGeneric("/companies", "/gcompanies"),
      GenericGetResource("/cfdi"),
      GenericGetResource("/taxes"),
      GenericGetResource("/invoicedocumenttypes"),
      GetResourceByUrl("/purchaseinvoices/fiscalsignature"),
      GetResourceByUrl("/purchaseinvoices/cancel/fiscalsignature"),
      GenericGetResource("/paymentconditions"),
      GenericGetResource("/languages"),
    ]

    Promise.all(myPromises)
      .then((responses) => {
        setComboCurrencies(responses[0].data.data);
        setComboPaymentMethods(responses[1].data.data);
        setComboPaymentModes(responses[2].data.data);
        setComboSuppliers(responses[3].data.data);
        setCompany(responses[4].data.data[0]);
        setComboCFDIs(responses[5].data.data);
        setComboInvoiceDocumentTypes(responses[7].data.data.filter((element: InvoiceDocumentType) => element.is_type_for_sale == false));
        setComboPaymentConditions(responses[10].data.data);
        setComboLanguages(responses[11].data.data)


        GenericGetResource(`/consecutives/byConsecutiveId/${responses[7].data.data[2].consecutive_id}`)
          .then((response) => {
            reset({
              purchase_invoice_name: response.data.data[0].Consecutive,
              invoice_doc_type_id: 3,
            },
              { keepValues: true });

            setMyConsecutive(response.data.data[0].Consecutive);
            setDataLoaded((prev) => true);
          })
          .catch((error) => {
            setDataLoaded((prev) => true);
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });

  }, []);

  useEffect(() => { }, [myConsecutive]);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("purchaseinvoices.title-view")} size='md' />

          <form onSubmit={handleSubmit(onSubmit)} className='custom-card-view'>

            <Grid container display={'flex'} flexDirection={'column'}>
              <Grid item>
                <PurchaseInvoicesHeader
                  control={control}
                  errors={errors}
                  isSupplierSelected={false}
                  onChangeSupplier={onChangeSupplier}
                  company={company}
                  comboSuppliers={comboSuppliers}
                  comboInvoiceDocumentTypes={comboInvoiceDocumentTypes}
                  setMyConsecutive={setMyConsecutive}
                  myConsecutive={myConsecutive}
                  isSelectedSupplierTextfield={isSelectedSupplierTextfield}
                  setIsSelectedSupplierTextfield={setIsSelectedSupplierTextfield}
                />
              </Grid>
              <Grid item>
              </Grid>
            </Grid>

          </form>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
