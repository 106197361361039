import React from "react";
import { Box, Grid, SxProps } from "@mui/material";
import { Theme } from "@emotion/react";

interface CreateStageButtonProps {
  color: number[] | undefined;
  onClick: () => void;
  children: React.ReactNode;
}

const GetStageButtonStyles = (color: number[] | undefined): SxProps<Theme> | undefined => ({
  padding: "10px 16px",
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  transition: "background-color 0.3s ease, box-shadow 0.3s ease",
  backgroundColor: color
    ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.7)`
    : "rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: color
      ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.3)`
      : "rgba(0, 0, 0, 0.1)",
    color: 'black'
  },
  color: '#ffffff',
});

export const StageButton = ({
  color,
  onClick,
  children,
}: CreateStageButtonProps) => {
  return (
    <Box sx={GetStageButtonStyles(color)} onClick={onClick}>
      {children}
    </Box>
  );
};