import { Control, Controller, FieldErrors, UseFormSetValue } from "react-hook-form";
import { CustomerLocation } from "../../../../../interfaces/Sales/Catalogs/Customers/customers";
import { Country } from "../../../../../interfaces/Commons/Locations/countries";
import { State } from "../../../../../interfaces/Commons/Locations/states";
import { City } from "../../../../../interfaces/Commons/Locations/cities";
import { Autocomplete, Box, Switch, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { emailRegex, rfcRegex } from "../../../../../constants/Regex";
import { TaxRegime } from "../../../../../interfaces/Sales/Catalogs/taxRegimes";
import { useEffect, useState } from "react";
import { Divider } from "../../../../../components/Commons/Divider";

type UpdateGeneralProps = {
  control: Control<CustomerLocation, any>;
  errors: FieldErrors<CustomerLocation>;
  comboCountries: Country[];
  defaultCountry: Country | undefined;
  comboStates: State[];
  defaultState: State | undefined;
  comboCities: City[];
  defaultCity: City | undefined;
  comboTaxRegimes: TaxRegime[];
  defaultTaxRegime: TaxRegime | undefined;
  isEdit: boolean;
  customerLocationPayload: CustomerLocation;
  getCities: (stateId: number) => void,
  setValue: UseFormSetValue<CustomerLocation>
}

export const UpdateGeneral = ({
  control,
  errors,
  comboCountries,
  defaultCountry,
  comboStates,
  defaultState,
  comboCities,
  defaultCity,
  comboTaxRegimes,
  defaultTaxRegime,
  customerLocationPayload,
  isEdit,
  getCities,
  setValue,
}: UpdateGeneralProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();

  const [isStateSelected, setIsStateSelected] = useState(false);
  const [stateId, setStateId] = useState<number | undefined>(defaultState?.state_id);
  const [isMexican, setIsMexican] = useState(false);

  const [city, setCity] = useState<City | null>()

  useEffect(() => {
    if (stateId !== undefined) {
      setCity(null)
      getCities(stateId)
      if (defaultCountry?.country_code === "MEX") {
        setIsMexican(true);
      }
      setIsStateSelected(true);
      return;
    }
    setIsStateSelected(false);
  }, [stateId])

  useEffect(() => {
    setCity((previewState) => defaultCity);
  }, [])



  return (
    <>
      <div className='d-flex'>
        <div className='w-50'>
          <Controller
            name="country_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                size="small"
                options={comboCountries}
                defaultValue={defaultCountry}
                getOptionLabel={(option) => `${option.country_code} - ${option.country_name}`}
                renderOption={(props, option: Country) => (
                  <div key={option.country_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.country_code} - {option.country_name}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                onChange={(_, values) => {
                  field.onChange(values?.country_id || null);
                  if (values?.country_code === "MEX") {
                    setIsMexican(true);
                  }
                  else {
                    setIsMexican(false);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("customerlocations.fields.country")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.country_id}
                    helperText={GetError(errors.country_id?.type)}
                    sx={{ paddingRight: 2 }}
                    disabled={isEdit}
                  />
                )}
              />
            }
          />
        </div>

        <div className='w-50'>
          <Controller
            name="state_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                size="small"
                options={comboStates}
                defaultValue={defaultState}
                getOptionLabel={(option) => `${option.state_code} - ${option.state_name}`}
                renderOption={(props, option: State) => (
                  <div key={option.state_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.state_code} - {option.state_name}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.state_id === value.state_id}
                onChange={(_, values) => {
                  setValue("city_id", undefined);
                  field.onChange(values?.state_id || null);
                  setStateId(values?.state_id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("customerlocations.fields.state")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.state_id}
                    helperText={GetError(errors.state_id?.type)}
                    disabled={isEdit}
                  />
                )}
              />
            }
          />
        </div>
      </div>

      <div className='d-flex mt-3'>
        <div className='w-50'>
          {!isStateSelected &&
            <Controller
              name="city_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) =>
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  options={comboCities}
                  getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                  renderOption={(props, option: City) => (
                    <div key={option.city_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.city_code} - {option.city_name}
                      </Box>
                    </div>
                  )}
                  isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                  onChange={(_, values) => {
                    field.onChange(values?.city_id || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("customerlocations.fields.city_name")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.city_id}
                      helperText={GetError(errors.city_id?.type)}
                      sx={{ paddingRight: 2 }}

                    />
                  )}
                  disabled
                />
              }
            />
          }
          {isStateSelected &&
            <Controller
              name="city_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) =>
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  options={comboCities}
                  value={city}
                  getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                  renderOption={(props, option: City) => (
                    <div key={option.city_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.city_code} - {option.city_name}
                      </Box>
                    </div>
                  )}

                  isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                  onChange={(_, values) => {
                    setCity(values);
                    field.onChange(values?.city_id || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("customerlocations.fields.city_name")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.city_id}
                      helperText={GetError(errors.city_id?.type)}
                      sx={{ paddingRight: 2 }}
                    />
                  )}
                />
              }
            />
          }
        </div>

        <div className='w-50'>
          <Controller
            name="street"
            control={control}
            rules={{ required: true, maxLength: 60 }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("customerlocations.fields.street")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.street}
                helperText={GetError(errors.street?.type)}
                size="small"
                style={{ width: "100%" }}
                disabled={isEdit}
              />
            }
          />
        </div>
      </div>

      <div className='d-flex mt-3'>
        <div className='w-50'>
          <Controller
            name="postal_code"
            control={control}
            rules={{ maxLength: 5, required: true }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("customerlocations.fields.postal_code")}`}
                ref={field.ref}
                type="number"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                }}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.postal_code}
                helperText={GetError(errors.postal_code?.type)}
                size="small"
                style={{ width: "100%" }}
                disabled={isEdit}
              />
            }
          />
        </div>
      </div>

      <Divider />

      <div className='d-flex'>

        <div className='w-50'>
          <Controller
            name="business_name"
            control={control}
            rules={{ maxLength: 50, required: true }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("customerlocations.fields.business_name")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.business_name}
                helperText={GetError(errors.business_name?.type)}
                size="small"
                style={{ width: "100%" }}
                sx={{ paddingRight: 2 }}
                disabled={isEdit}
              />
            }
          />
        </div>

        <div className='w-50'>
          <Controller
            name="customer_rfc"
            control={control}
            rules={{
              required: true, maxLength: 13,
              pattern: (isMexican) ? rfcRegex : undefined
            }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("customerlocations.fields.customer_rfc")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.customer_rfc}
                helperText={GetError(errors.customer_rfc?.type)}
                size="small"
                style={{ width: "100%" }}
                disabled={isEdit}
              />
            }
          />
        </div>
      </div>

      <div className="d-flex mt-3">
        <div className="w-50">
          <Controller
            name="tax_regime_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) =>
              <Autocomplete
                ref={field.ref}
                size="small"
                options={comboTaxRegimes}
                getOptionLabel={(option) => `${option.tax_regime_code} - ${option.description}`}
                defaultValue={defaultTaxRegime}
                renderOption={(props, option: TaxRegime) => (
                  <div key={option.tax_regime_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.tax_regime_code} - {option.description}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.tax_regime_id === value.tax_regime_id}
                onChange={(_, values) => {
                  field.onChange(values?.tax_regime_id || null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("taxregimes.title-view")}`}
                    variant="filled"
                    value={field.value}
                    error={!!errors.tax_regime_id}
                    helperText={GetError(errors.tax_regime_id?.type)}
                    sx={{ paddingRight: 2 }}
                  />
                )}
              />
            }
          />
        </div>
        <div className="w-50"></div>
      </div>

      <div className='d-flex mt-3'>

        <div className='w-50'>
          <Controller
            name="contact_name"
            control={control}
            rules={{ maxLength: 50 }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("customerlocations.fields.contact_name")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.contact_name}
                helperText={GetError(errors.contact_name?.type)}
                size="small"
                style={{ width: "100%" }}
                sx={{ paddingRight: 2 }}
                disabled={isEdit}
              />
            }
          />
        </div>

        <div className='w-50'>
          <Controller
            name="customer_phone_number"
            control={control}
            rules={{ maxLength: 20 }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("customerlocations.fields.phone_number")}`}
                ref={field.ref}
                value={field.value}
                type="number"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", },
                }}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.customer_phone_number}
                helperText={GetError(errors.customer_phone_number?.type)}
                size="small"
                style={{ width: "100%" }}
                disabled={isEdit}
              />
            }
          />
        </div>
      </div>

      <div className='d-flex mt-3'>
        <div className='w-50'>
          <Controller
            name="customer_email"
            control={control}
            rules={{ maxLength: 1000, pattern: emailRegex }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("customerlocations.fields.email")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.customer_email}
                helperText={GetError(errors.customer_email?.type)}
                size="small"
                style={{ width: "100%" }}
                sx={{ paddingRight: 2 }}
                disabled={isEdit}
              />
            }
          />
        </div>

        <div className='w-50'>
          <Controller
            name="customer_web"
            control={control}
            rules={{ maxLength: 100 }}
            render={({ field }) =>
              <TextField
                variant="filled"
                label={`${t("customerlocations.fields.customer_web")}`}
                ref={field.ref}
                value={field.value}
                onChange={(event) => { field.onChange(event.target.value) }}
                error={!!errors.customer_web}
                helperText={GetError(errors.customer_web?.type)}
                size="small"
                style={{ width: "100%" }}
                disabled={isEdit}
              />
            }
          />
        </div>

      </div>

      <div className="d-flex flex-column mt-3">
        <Controller
          name="fiscal_address"
          control={control}
          render={({ field }) =>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
              <Typography sx={{ ml: 2 }}>{t("customerlocations.fields.fiscal_address")}</Typography>
              <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={customerLocationPayload.fiscal_address} disabled={isEdit} />
            </Box>
          }
        />
        <Controller
          name="delivery_address"
          control={control}
          render={({ field }) =>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
              <Typography sx={{ ml: 2 }}>{t("customerlocations.fields.delivery_address")}</Typography>
              <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={customerLocationPayload.delivery_address} disabled={isEdit} />
            </Box>
          }
        />
        <Controller
          name="default_delivery_address"
          control={control}
          render={({ field }) =>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
              <Typography sx={{ ml: 2 }}>{t("customerlocations.fields.default_delivery_address")}</Typography>
              <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={customerLocationPayload.default_delivery_address} disabled={isEdit} />
            </Box>
          }
        />
      </div>

    </>
  )
}
