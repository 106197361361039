import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import {
  myStylePickers,
  PrimaryButton,
} from "../../../../../theme/buttons";
import { ReminderType } from "../../../../../interfaces/Projects/Catalogs/reminderTypes";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { defaultTheme } from "../../../../../theme/theme";
import { UpdateRecurrencePicker } from "./updateRecurrencePicker";
import { AddRecurrencePicker } from "./addRecurrencePicker";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import { useColors } from "../../../../../theme/hooks/useColors";
import { ProjectTaskReminderTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskReminderTemplate";
import { ProjectTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskTemplate";

type UpdateProjectTaskRemindersTemplateProps = {
  permissions: Menuitem;
  handleUpdateReminder: (updateRow: ProjectTaskReminderTemplate) => void;
  handleDeleteReminder: (deleteRow: number | undefined) => void;
  projectTaskTemplatePayload: ProjectTaskTemplate;
  projectTaskReminderTemplateLocal: ProjectTaskReminderTemplate | undefined;
};

export const UpdateProjectTaskRemindersTemplate = ({
  permissions,
  handleUpdateReminder,
  handleDeleteReminder,
  projectTaskTemplatePayload,
  projectTaskReminderTemplateLocal,
}: UpdateProjectTaskRemindersTemplateProps) => {
  const [t] = useTranslation("global");
  const { showSnackBar, SnackbarComponent } = useSnackBar("left", "bottom");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { GetError } = FormsErrors();
  const { HexToRGB } = useColors();
  const [reminderTypesCombo, setReminderTypesCombo] = useState<ReminderType[]>(
    []
  );
  const [color, setColor] = useState<number[]>();
  const [loadingPut, setLoadingPut] = useState(false);
  const [openStartedAtPicker, setOpenStartedAtPicker] = useState(false);

  const [defaultRemainderType, setDefaultRemainderType] =
    useState<ReminderType | null>();
  const { GenericGetResource, GenericDeleteResource, GenericPutResource } =
    GenericPromises();
  const [openUpdateCustomDialog, setOpenUpdateCustomDialog] = useState(false);
  const [openAddCustomDialog, setOpenAddCustomDialog] = useState(false);
  const [customReminder, setCustomReminder] = useState<ReminderType>();
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm<ProjectTaskReminderTemplate>({
    defaultValues: {
      project_task_template_id: undefined,
      reminder_type_id: undefined,
      project_task_reminder_template_name: undefined,
      project_task_reminder_template_description: undefined,
      project_task_reminder_template_started_at: undefined,
      project_task_reminder_template_due_date: undefined,
      project_task_reminder_template_next_execution: undefined,
      project_task_reminder_template_last_execution: undefined,
      project_task_reminder_template_last_time: undefined,
    },
  });

  const onSubmit: SubmitHandler<ProjectTaskReminderTemplate> = (data) =>
    onPut(data);

  const onPut = (data: any) => {
    setLoadingPut(true);
    let myData = {
      project_task_template_id:
        projectTaskTemplatePayload.project_task_template_id,
      reminder_type_id: data.reminder_type_id,
      project_task_reminder_template_name: null,
      project_task_reminder_template_description: null,
      project_task_reminder_template_started_at: null,
      project_task_reminder_template_due_date: null,
      project_task_reminder_template_next_execution: null,
      project_task_reminder_template_last_execution: null,
      project_task_reminder_template_last_time: null,
    };
    GenericPutResource(
      `/projecttaskreminderstemplate/${projectTaskReminderTemplateLocal?.project_task_reminder_template_id}`,
      myData
    )
      .then((response) => {
        handleUpdateReminder(response.data);

        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPut(false);
      })
      .catch((error) => {
        setLoadingPut(false);
        showSnackBar(error.message, "error");
      });
  };

  const onDelete = () => {
    GenericDeleteResource(
      `/projecttaskreminderstemplate/${projectTaskReminderTemplateLocal?.project_task_reminder_template_id}`
    )
      .then(async () => {
        showSnackBar(t("generic.snackbar.delete"), "success");
        handleDeleteReminder(
          projectTaskReminderTemplateLocal?.project_task_reminder_template_id
        );
        setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const handleNewReminderTypeId = (newReminderType: ReminderType) => {
    if (!newReminderType.reminder_type_id === null) return;
    setValue("reminder_type_id", newReminderType.reminder_type_id, {
      shouldValidate: true,
    });
    setReminderTypesCombo((prevTypes) => {
      return prevTypes.map((type) =>
        type.reminder_type_id === defaultRemainderType?.reminder_type_id ? newReminderType : type
      );
    });
    setDefaultRemainderType(newReminderType);
    setCustomReminder(newReminderType);
    setOpenUpdateCustomDialog(false);
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/remindertypes"),
    ];
    Promise.all(myPromises)
      .then((responses) => {
        const firstFourRecords = responses[0].data.data.slice(0, 4);
        const customReminderType = {
          reminder_type_name: t("remindertypes.combo.custom-option"),
          reminder_type_id: -1,
        };
        setReminderTypesCombo([...firstFourRecords, customReminderType]);
        setColor(HexToRGB(defaultTheme.palette.primary.main));

        const myReminderType = responses[0].data.data.find(
          (reminderType: ReminderType) =>
            reminderType.reminder_type_id ===
            projectTaskReminderTemplateLocal?.reminder_type_id
        );
        setDefaultRemainderType(myReminderType);

        const isCustomReminder = !firstFourRecords.some(
          (reminderType: ReminderType) =>
            reminderType.reminder_type_id === myReminderType?.reminder_type_id
        );

        if (isCustomReminder) {
          setReminderTypesCombo((prev) => [...prev, myReminderType]);
          setCustomReminder(myReminderType);
        }
        reset({
          project_task_template_id:
            projectTaskReminderTemplateLocal?.project_task_template_id,
          reminder_type_id: projectTaskReminderTemplateLocal?.reminder_type_id,
          project_task_reminder_template_name:
            projectTaskReminderTemplateLocal?.project_task_reminder_template_name,
          project_task_reminder_template_description:
            projectTaskReminderTemplateLocal?.project_task_reminder_template_description,
          project_task_reminder_template_started_at:
            projectTaskReminderTemplateLocal?.project_task_reminder_template_started_at,
          project_task_reminder_template_due_date:
            projectTaskReminderTemplateLocal?.project_task_reminder_template_due_date,
          project_task_reminder_template_next_execution:
            projectTaskReminderTemplateLocal?.project_task_reminder_template_next_execution,
          project_task_reminder_template_last_execution:
            projectTaskReminderTemplateLocal?.project_task_reminder_template_last_execution,
          project_task_reminder_template_last_time:
            projectTaskReminderTemplateLocal?.project_task_reminder_template_last_time,
        });
        // requestNotificationPermission();
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100 my-3">
            <Controller
              name="project_task_reminder_template_started_at"
              control={control}
              rules={{}}
              render={({ field }) => (
                <DatePicker
                  disabled
                  open={openStartedAtPicker}
                  onClose={() => setOpenStartedAtPicker(false)}
                  ref={field.ref}
                  label={t("projecttaskreminderstemplate.fields.remind-me")}
                  value={field.value ? dayjs(field.value) : null}
                  onChange={(values: any) => {
                    field.onChange(values?.toDate() || null);
                  }}
                  slotProps={{
                    popper: {
                      sx: myStylePickers,
                    },
                    textField: {
                      size: "small",
                      variant: "filled",
                      sx: {
                        width: "100%",
                        mt: 1,
                      },
                      onClick: () => setOpenStartedAtPicker(true),
                      onBeforeInput: disableKeyboardEntry,
                      error: !!errors.project_task_reminder_template_started_at,
                      helperText: GetError(
                        errors.project_task_reminder_template_started_at?.type
                      ),
                    },
                  }}
                  format="D [de] MMMM [de] YYYY"
                />
              )}
            />
            <TextField
              variant="filled"
              label={t(
                "projecttaskstemplate.fields.project_task_template_due_date"
              )}
              value={
                dayjs(
                  projectTaskTemplatePayload?.project_task_template_due_date
                ).isValid()
                  ? dayjs(
                      projectTaskTemplatePayload.project_task_template_due_date
                    ).format("DD-MM-YYYY")
                  : `${
                      projectTaskTemplatePayload?.project_task_template_due_date ||
                      ""
                    }`
              }
              size="small"
              style={{ width: "100%" }}
              sx={{
                mt: 1,
              }}
              disabled
            />
            <Controller
              name="reminder_type_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%", mt: 1 }}
                    options={reminderTypesCombo}
                    getOptionLabel={(option) => `${option.reminder_type_name}`}
                    defaultValue={defaultRemainderType}
                    renderOption={(props, option: ReminderType) => (
                      <div key={option.reminder_type_id}>
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.reminder_type_name}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.reminder_type_id === value.reminder_type_id
                    }
                    value={defaultRemainderType}
                    onChange={(_, selectedOption) => {
                      if (selectedOption?.reminder_type_id == -1) {
                        setOpenAddCustomDialog(true);
                        return;
                      }
                      if (
                        selectedOption?.reminder_type_id ==
                        customReminder?.reminder_type_id
                      ) {
                        setOpenUpdateCustomDialog(true);
                      }
                      field.onChange(selectedOption?.reminder_type_id || null);
                      setDefaultRemainderType(selectedOption || null);

                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("remindertypes.fields.repeat")}
                        variant="filled"
                        value={field.value}
                        error={!!errors.reminder_type_id}
                        helperText={GetError(errors.reminder_type_id?.type)}
                        disabled={isEdit}
                      />
                    )}
                  />
                </>
              )}
            />
            <div className="d-flex flex-row-reverse mt-1 justify-content-between">
              {permissions.update && (
                <PrimaryButton
                  type="submit"
                  variant="outlined"
                  className="m-1"
                  disabled={loadingPut}
                >
                  {t("generic.buttons.update")}
                </PrimaryButton>
              )}

              {/* <PrimaryButton
              variant="outlined"
              className="m-1"
              onClick={() => {
                showNotification(projectTaskTemplatePayload?.project_stage_task_template_name ?? '', projectTaskTemplatePayload.project_stage_task_template_due_date?.toString() ?? '');
              }}
            >
              Prueba
            </PrimaryButton> */}
              {permissions.delete ? (
                <PrimaryButton
                  size="small"
                  className="m-1"
                  variant="outlined"
                  onClick={() => setOpenDialog(true)}
                >
                  {t("generic.buttons.delete")}
                </PrimaryButton>
              ) : (
                <></>
              )}
            </div>
          </form>
          <UpdateRecurrencePicker
            openCustomDialog={openUpdateCustomDialog}
            setOpenCustomDialog={setOpenUpdateCustomDialog}
            handleNewReminderTypeId={handleNewReminderTypeId}
            reminderTypePayload={defaultRemainderType ?? {}}
            reminderTypesCombo={reminderTypesCombo.filter(
              (option) =>
                option.reminder_type_id !== customReminder?.reminder_type_id &&
                option.reminder_type_name !== "Personalizado" 
            )}
          />

          <AddRecurrencePicker
            openCustomDialogAddCustomReminder={openAddCustomDialog}
            setOpenCustomDialogAddCustomReminder={setOpenAddCustomDialog}
            handleNewReminderTypeId={handleNewReminderTypeId}
            reminderTypesCombo={reminderTypesCombo.filter(
              (option) =>
                option.reminder_type_id !== customReminder?.reminder_type_id &&
                option.reminder_type_name !== "Personalizado" 
            )}
          />
        </>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projecttaskstemplate.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  );
};
