import { Dispatch, SetStateAction } from "react";
import { ProjectExtraField } from "../../../../../../interfaces/Projects/Catalogs/projectExtraFields";
import { AlertColor } from "@mui/material";
import { useTranslation } from "react-i18next";

export const HookPutExtraFields = () => {
  const [t] = useTranslation("global");

  const handleUpdateOnChangeField = (
    setProjectExtraFieldsData: Dispatch<SetStateAction<ProjectExtraField[]>>,
    project_extra_field_id: number,
    value: string,
  ) => {
    setProjectExtraFieldsData((prevFields) =>
      prevFields.map((field) =>
        field.project_extra_field_id === project_extra_field_id
          ? { ...field, project_extra_field_value: value }
          : field
      )
    );
  }

  const onPut = async (
    data: ProjectExtraField,
    event: any,
    setProjectExtraFieldsData: Dispatch<SetStateAction<ProjectExtraField[]>>,
    showSnackBar: (message: string, severity: AlertColor) => void,
    GenericPutResource: (url: string, myData: any) => Promise<any>,
    setIsUpdateProjectExtraFields: Dispatch<SetStateAction<boolean>>,
  ) => {
    const { value } = event.target;
    let myData = {
      field_type_id: data.field_type_id,
      project_id: data.project_id,
      project_extra_field_name: data.project_extra_field_name,
      project_extra_field_value: value.toString(),
    }
    // setIsUpdateProjectExtraFields((prev) => false);
    await GenericPutResource(`/projectextrafields/${data.project_extra_field_id}`, myData)
      .then(async () => {
        await showSnackBar(`${data.project_extra_field_name} ${t("projectextrafields.info.updated")}`, "success");
        // setIsUpdateProjectExtraFields((prev) => true);
      })
      .catch(async (error) => {
        await showSnackBar(error.message, "error");
        // setIsUpdateProjectExtraFields((prev) => true);
      });
  }

  const onPutDate = (
    data: ProjectExtraField,
    value: Date | undefined,
    setProjectExtraFieldsData: Dispatch<SetStateAction<ProjectExtraField[]>>,
    showSnackBar: (message: string, severity: AlertColor) => void,
    GenericPutResource: (url: string, myData: any) => Promise<any>,
    setIsUpdateProjectExtraFields: Dispatch<SetStateAction<boolean>>,
  ) => {
    let myData = {
      field_type_id: data.field_type_id,
      project_id: data.project_id,
      project_extra_field_name: data.project_extra_field_name,
      project_extra_field_value: value ?? null,
    }
    // setIsUpdateProjectExtraFields((prev) => false);
    GenericPutResource(`/projectextrafields/${data.project_extra_field_id}`, myData)
      .then(async () => {
        await showSnackBar(`${data.project_extra_field_name} ${t("projectextrafields.info.updated")}`, "success");
        // setIsUpdateProjectExtraFields((prev) => true);
      })
      .catch(async (error) => {
        await showSnackBar(error.message, "error");
        // setIsUpdateProjectExtraFields((prev) => true);
      });
  }

  return {
    onPut,
    onPutDate,
    handleUpdateOnChangeField,
  }
}
