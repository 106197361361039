import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { ProjectExtraFieldTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectExtraFieldsTemplate";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import GenericPromises from "../../../../../api/GenericPromises";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FieldType } from "../../../../../interfaces/Projects/Catalogs/fieldTypes";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { PrimaryButton } from "../../../../../theme/buttons";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";


type UpdateProjectExtraFieldsTemplateProps = {
    projectExtraFieldTemplatePayload: ProjectExtraFieldTemplate,
    permissions: Menuitem,
    setOpenDialogUpdate: Dispatch<SetStateAction<boolean>>,
    DeleteRow: (deleteRow: number) => void,
    UpdateRow: (updateRow: ProjectExtraFieldTemplate) => void,
}

export const UpdateProjectExtraFieldsTemplate = ({
    projectExtraFieldTemplatePayload,
    permissions,
    setOpenDialogUpdate,
    DeleteRow,
    UpdateRow,
}: UpdateProjectExtraFieldsTemplateProps) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [isTypeFormula, setIsTypeFormula] = useState(false);
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const [defaultFieldType, setDefaultFieldType] = useState<FieldType>()
    const [comboFieldTypes, setComboFieldTypes] = useState<FieldType[]>([]);
    const isEdit = !permissions.update;

    const {
        handleSubmit,
        control,
        reset,
        trigger,
        getValues,
        formState: { errors },
    } = useForm<ProjectExtraFieldTemplate>({
        defaultValues: {}
    });
    const onSubmit: SubmitHandler<ProjectExtraFieldTemplate> = (data) => onPut(data);

    const onPut = (data: ProjectExtraFieldTemplate) => {
        let myData = {
            field_type_id: data.field_type_id,
            project_template_id: projectExtraFieldTemplatePayload.project_template_id,
            project_extra_field_template_name: data.project_extra_field_template_name,
            project_extra_field_template_value: data.project_extra_field_template_value,
            project_extra_field_template_order: data.project_extra_field_template_order
        }
        setLoadingPost(true);
        GenericPutResource(`/projectextrafieldstemplate/${projectExtraFieldTemplatePayload.project_extra_field_template_id}`, myData)
            .then((response) => {
                UpdateRow(response.data);
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
                setOpenDialogUpdate(false);
            })
            .catch((error) => {
                showSnackBar(error.message, "error");
                setLoadingPost(false);
            });
    }

    const onDelete = () => {
        GenericDeleteResource(`/projectextrafieldstemplate/${projectExtraFieldTemplatePayload.project_extra_field_template_id}`)
            .then(() => {
                DeleteRow(projectExtraFieldTemplatePayload?.project_extra_field_template_id ?? 0);
                setOpenDialogUpdate(false);
            })
            .catch((error) => {
                showSnackBar(error.message, "error");
                setOpenDialogUpdate(false);
            })
    }

    useEffect(() => {
        let myPromises = [
            GenericGetResource("/fieldtypes")
        ];

        Promise.all(myPromises)
            .then((responses) => {
                setComboFieldTypes(responses[0].data.data);

                reset({
                    field_type_id: projectExtraFieldTemplatePayload.field_type_id,
                    project_template_id: projectExtraFieldTemplatePayload.project_template_id,
                    project_extra_field_template_name: projectExtraFieldTemplatePayload.project_extra_field_template_name,
                    project_extra_field_template_value: projectExtraFieldTemplatePayload.project_extra_field_template_value,
                    project_extra_field_template_order: projectExtraFieldTemplatePayload.project_extra_field_template_order
                });

                if (responses[0].data.data.find((fieldType: FieldType) => (fieldType.field_type_id === projectExtraFieldTemplatePayload.field_type_id) && (fieldType.field_type_name.toLowerCase() === 'formula'))) {
                    setIsTypeFormula(true);
                }

                setDefaultFieldType(responses[0].data.data.find((fieldType: FieldType) => fieldType.field_type_id === projectExtraFieldTemplatePayload.field_type_id));

                setDataLoaded(true);
            }).catch((error) => {
                showSnackBar(error.message, "error");
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
                <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

                    <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

                        <div className='d-flex'>
                            <div className='w-50'>
                                <Controller
                                    name="project_extra_field_template_name"
                                    control={control}
                                    rules={{ required: true, maxLength: 50 }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("projectextrafieldstemplate.fields.project_extra_field_template_name")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.project_extra_field_template_name}
                                            helperText={GetError(errors.project_extra_field_template_name?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            sx={{ paddingRight: 2 }}
                                            disabled={isEdit}
                                        />
                                    }
                                />
                            </div>

                            <div className='w-50'>
                                <Controller
                                    name="field_type_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            ref={field.ref}
                                            size="small"
                                            sx={{ width: "100%", paddingRight: (isTypeFormula ? 2 : 0) }}
                                            options={comboFieldTypes}
                                            getOptionLabel={(option) => option.field_type_name}
                                            defaultValue={defaultFieldType}
                                            renderOption={(props, option: FieldType) => (
                                                <div key={option.field_type_id}>
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        {option.field_type_name}
                                                    </Box>
                                                </div>
                                            )}
                                            isOptionEqualToValue={(option, value) => option.field_type_id === value.field_type_id}
                                            onChange={(_, values) => {
                                                field.onChange(values?.field_type_id || null);
                                                if (comboFieldTypes.find((item) => (item.field_type_id === values?.field_type_id) && (item.field_type_name.toLowerCase() === 'formula'))) {
                                                    setIsTypeFormula(true);
                                                }
                                                else {
                                                    setIsTypeFormula(false);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`${t("projectextrafieldstemplate.fields.field_type")}`}
                                                    variant="filled"
                                                    value={field.value}
                                                    error={!!errors.field_type_id}
                                                    helperText={GetError(errors.field_type_id?.type)}
                                                />
                                            )}
                                            disabled={isEdit}
                                        />
                                    )}
                                />
                            </div>

                            {isTypeFormula &&
                                <div className="w-50">
                                    <Controller
                                        name="project_extra_field_template_value"
                                        control={control}
                                        rules={{ required: true, maxLength: 1500 }}
                                        render={({ field }) =>
                                            <TextField
                                                variant="filled"
                                                label={`${t("projectextrafieldstemplate.fields.project_extra_field_template_value")}`}
                                                ref={field.ref}
                                                value={field.value}
                                                onChange={(event) => { field.onChange(event.target.value) }}
                                                error={!!errors.project_extra_field_template_value}
                                                helperText={GetError(errors.project_extra_field_template_value?.type)}
                                                size="small"
                                                style={{ width: "100%" }}
                                                disabled={isEdit}
                                            />
                                        }
                                    />
                                </div>
                            }
                        </div>

                        <div className='d-flex'>
                          <div className='w-100 mt-3'>
                            <Controller
                              name="project_extra_field_template_order"
                              control={control}
                              rules={{ required: true}}
                              render={({ field }) =>
                                <TextField
                                  variant="filled"
                                  label={`${t("projectextrafieldstemplate.fields.project_extra_field_template_order")}`}
                                  type="number"
                                  ref={field.ref}
                                  sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                      { display: "none" },
                                    "& input[type=number]": { MozAppearance: "textfield" },
                                    paddingRight:2
                                  }}
                                  value={field.value}
                                  onChange={(event) => {
                                    field.onChange(event.target.value);
                                  }}
                                  error={!!errors.project_extra_field_template_order}
                                  helperText={GetError(errors.project_extra_field_template_order?.type)}
                                  size="small"
                                  style={{ width: "50%" }}
                                  disabled={isEdit}
                                />
                              }
                            />
                          </div>
                        </div>

                        <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
                            <div>
                                <Button variant='outlined' className='m-1' onClick={() => setOpenDialogUpdate(false)}>{t("generic.buttons.goback")}</Button>
                                {permissions.update && <PrimaryButton onClick={() => {
                                    trigger().then((response) => response && onSubmit(getValues()));
                                }} variant='outlined' className='m-1'>{t("generic.buttons.update")}</PrimaryButton>}
                            </div>
                            {permissions.delete ?
                                <PrimaryButton onClick={() => setOpenDialogDelete(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
                            }
                        </div>

                    </form>

                </Box>
            }
            <GenericDialog
                title={t("generic.dialog.delete")}
                content={t("projectextrafieldstemplate.dialog.delete")}
                open={openDialogDelete}
                setOpen={setOpenDialogDelete}
                onAcept={onDelete}
            />
            <SnackbarComponent />
        </>
    )
}