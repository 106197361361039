import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import GenericPromises from "../../../../../api/GenericPromises";
import { usePermissions } from "../../../../../hooks/usePermissions";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { useTranslation } from "react-i18next";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import { Drawer, Grid, Typography } from "@mui/material";
import { AuthContext } from "../../../../../context/AuthContext";
import { ProjectTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskTemplate";
import { ProjectTaskReminderTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskReminderTemplate";
import { UpdateProjectTaskRemindersTemplate } from "./update";
import { AddProjectTaskRemindersTemplate } from "./add";

type TableProjectTaskRemindersTemplateProps = {
  open: boolean,
  onClose: (value: React.SetStateAction<boolean>) => void,
  projectTaskTemplatePayload: ProjectTaskTemplate,
}

export const TableProjectTaskRemindersTemplate = ({
  open,
  onClose,
  projectTaskTemplatePayload,
}: TableProjectTaskRemindersTemplateProps) => {
  const [t] = useTranslation("global");
  const [dataloaded, setDataLoaded] = useState(true);
  const { showSnackBar } = useSnackBar('left', 'bottom')
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GenericGetResource } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const [projectTaskRemindersTemplateLocal, setProjectTaskRemindersTemplateLocal] = useState<ProjectTaskReminderTemplate[]>([]);

  const { user } = useContext(AuthContext);

  const handleAddReminder = (newRow: ProjectTaskReminderTemplate) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date(),
    };

    setProjectTaskRemindersTemplateLocal((prevState) => {
      return [...projectTaskRemindersTemplateLocal, myRow];
    });
  };

  const handleUpdateReminder = (
    updateRow: ProjectTaskReminderTemplate
  ) => {
    if (projectTaskRemindersTemplateLocal.length === 0) {
      return;
    }
    setProjectTaskRemindersTemplateLocal((prevRows) => {
      const rowToUpdateIndex =
        projectTaskRemindersTemplateLocal.findIndex(
          (e) =>
            e.project_task_reminder_template_id ===
            updateRow.project_task_reminder_template_id
        );

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row
      );
    });
  };

  const handleDeleteReminder = (deleteRow: number | undefined) => {
    if ((deleteRow === undefined) || (projectTaskRemindersTemplateLocal.length === 0)) {
      return;
    }

    setProjectTaskRemindersTemplateLocal((prevRows) => {
      const rowToDeleteIndex =
        projectTaskRemindersTemplateLocal.findIndex(
          (e) => e.project_task_reminder_template_id === deleteRow
        );

      const updatedData = [
        ...projectTaskRemindersTemplateLocal.slice(0, rowToDeleteIndex),
        ...projectTaskRemindersTemplateLocal.slice(rowToDeleteIndex + 1),
      ];

      return updatedData;
    });
  };

  useEffect(() => {
    GenericGetResource(
      `/projecttaskreminderstemplate/byprojecttasktemplateid/${projectTaskTemplatePayload.project_task_template_id}`
    )
      .then(async (response) => {
        setProjectTaskRemindersTemplateLocal(response.data.data);

        GetResourceByUrl(`/projecttaskreminderstemplate`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <Drawer
          anchor="right"
          open={open}
          onClose={onClose}
          PaperProps={{
            sx: {
              width: 400,
            },
          }}
        >
          <Grid
            container
            flexDirection={"column"}
            columnSpacing={2}
            marginTop={8}
            wrap="nowrap"
            sx={{
              backgroundColor: "#faf9f8",
              height: "100%",
              padding: "20px",
            }}
          >
            <Grid item>
              <Typography
                sx={{
                  backgroundColor: (theme) => theme.palette.background.default,
                  paddingInline: '16px',
                  paddingBlock: '8px',
                  borderRadius: '4px',
                  fontWeight: (theme) => theme.typography.h6
                }}
              >
                {
                  projectTaskTemplatePayload.project_task_template_name
                }
              </Typography>
            </Grid>
            {projectTaskRemindersTemplateLocal.map((reminder) => (
              <Grid key={reminder.project_task_reminder_template_id} item>
                <UpdateProjectTaskRemindersTemplate
                  permissions={resourceScreen}
                  handleUpdateReminder={handleUpdateReminder}
                  handleDeleteReminder={handleDeleteReminder}
                  projectTaskTemplatePayload={projectTaskTemplatePayload}
                  projectTaskReminderTemplateLocal={reminder}
                />
              </Grid>
            ))}

            <Grid item>
              <AddProjectTaskRemindersTemplate
                handleAddReminder={handleAddReminder}
                projectTaskTemplatePayload={
                  projectTaskTemplatePayload
                }
              />
            </Grid>
          </Grid>
        </Drawer>
      )}
    </>
  );
};
