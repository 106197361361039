import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SalesInvoiceRelationshipType } from '../../../../interfaces/Sales/Catalogs/salesInvoiceRelationshipTypes';
import { Header } from '../../../../components/Header';
import { TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';

export const AddSalesInvoiceRelationshipTypes = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SalesInvoiceRelationshipType>({
    defaultValues: {
      sales_invoice_relationship_type_id: undefined,
      sales_invoice_relationship_type_code: undefined,
      sales_invoice_relationship_type_description: undefined,
    }
  });

  const onSubmit: SubmitHandler<SalesInvoiceRelationshipType> = (data) => onPost(data);

  const onPost = (data: SalesInvoiceRelationshipType) => {
    let myData = {
      sales_invoice_relationship_type_code: data.sales_invoice_relationship_type_code ?? null,
      sales_invoice_relationship_type_description: data.sales_invoice_relationship_type_description ?? null,
    }
    setLoadingPost(true);
    GenericPostResource("/salesinvoicerelationshiptypes", myData)
      .then((response) => {
        navigate("/salesinvoicerelationshiptypes", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/salesinvoicerelationshiptypes", { state: {} });
  }

  return (
    <>
      <Header title={t("salesinvoicerelationshiptypes.title-view")} />
      <div className='d-flex justify-content-center'>

        <div className='custom-card-view w-75'>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

            <div className="custom-card-row d-flex justify-content-between">
              <div className="w-100">
                <div className='d-flex w-100'>
                  <div className='w-50'>
                    <Controller
                      name="sales_invoice_relationship_type_code"
                      control={control}
                      rules={{ required: true, maxLength: 3 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("salesinvoicerelationshiptypes.fields.sales_invoice_relationship_type_code")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.sales_invoice_relationship_type_code}
                          helperText={GetError(errors.sales_invoice_relationship_type_code?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                        />
                      }
                    />
                  </div>

                  <div className='w-50'>
                    <Controller
                      name="sales_invoice_relationship_type_description"
                      control={control}
                      rules={{ maxLength: 100 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("salesinvoicerelationshiptypes.fields.sales_invoice_relationship_type_description")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.sales_invoice_relationship_type_description}
                          helperText={GetError(errors.sales_invoice_relationship_type_description?.type)}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      }
                    />
                  </div>

                </div>

              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </div>

      </div>
      <SnackbarComponent />
    </>
  )
}
