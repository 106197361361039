import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../../components/Header';
import { TextField } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { INCOTERM } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/INCOTERMs';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const AddINCOTERMs = () => {

    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const { showSnackBar, SnackbarComponent } = useSnackBar();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<INCOTERM>({
        defaultValues: {
            incoterm_code: '',
            incoterm_description: '',
        }
    });


    const onSubmit: SubmitHandler<INCOTERM> = (data) => onPost(data);


    const onPost = (data: INCOTERM) => {
        let myData = {
            incoterm_code: data.incoterm_code,
            incoterm_description: data.incoterm_description ?? null,
        }
        setLoadingPost(true);
        GenericPostResource("/incoterms", myData)
            .then((response) => {
                navigate("/incoterms", { state: { method: "add" } });
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, "error");
            });
    }

    const onCancel = () => {
        // validacion si quiere cancelar sus cambios 
        navigate("/incoterms", { state: {} });
    }

    useEffect(() => {
        GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
            document.title = `${response.data.data[0].comercial_name} - ${t("incoterms.title")}`;
        })
    }, [])

    return (
        <>
            <Header title={t("incoterms.title-view")} />
            <div className='d-flex justify-content-center'>
                <div className='custom-card-view w-75'>
                    <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>
                        <div className="custom-card-row d-flex justify-content-between">
                            <div className="w-100">
                                <div className='d-flex w-100'>
                                    <div className='w-50'>
                                        <Controller
                                            name="incoterm_code"
                                            control={control}
                                            rules={{ required: true, maxLength: 5 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("incoterms.fields.incoterm_code")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.incoterm_code}
                                                    helperText={GetError(errors.incoterm_code?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="w-50">
                                        <Controller
                                            name="incoterm_description"
                                            control={control}
                                            rules={{ maxLength: 100 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("incoterms.fields.incoterm_description")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.incoterm_description}
                                                    helperText={GetError(errors.incoterm_description?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row-reverse mt-1'>
                            <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                            <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                        </div>
                    </form>
                </div>
            </div>
            <SnackbarComponent />
        </>
    )
}
