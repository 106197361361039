import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { ProjectType } from "../../../../interfaces/Projects/Catalogs/projectTypes";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Header } from "../../../../components/Header";
import { Box, Switch, TextField, Typography } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";

export const AddProjectTypes = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResourceGeneric } =
    GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ProjectType>({
    defaultValues: {
      project_type_name: undefined,
      project_type_description: undefined,
      MS_planner_id: undefined,
      default_show_dashboard: false,
    },
  });

  const onSubmit: SubmitHandler<ProjectType> = (data) => onPost(data);

  const onPost = (data: ProjectType) => {
    let myData = {
      project_type_name: data.project_type_name,
      project_type_description: data.project_type_description,
      MS_planner_id: data.MS_planner_id,
      default_show_dashboard: data.default_show_dashboard ?? false
    };
    setLoadingPost(true);
    GenericPostResource("/projecttypes", myData)
      .then((response) => {
        navigate("/projecttypes", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios
    navigate("/projecttypes", { state: {} });
  };

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t(
        "projecttypes.title"
      )}`;
    });
  }, []);

  return (
    <>
      <Header title={t("projecttypes.title-view")} />
      <div className="d-flex justify-content-center">
        <div className="custom-card-view w-75 mt-4">
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="custom-card-row d-flex justify-content-between">
              <div className="w-100">
                <div className="d-flex w-100">
                  <div className="w-50">
                    <Controller
                      name="project_type_name"
                      control={control}
                      rules={{ required: true, maxLength: 50 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t(
                            "projecttypes.fields.project_type_name"
                          )}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.project_type_name}
                          helperText={GetError(errors.project_type_name?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>
                  <div className="w-50">
                    <Controller
                      name="project_type_description"
                      control={control}
                      rules={{ maxLength: 100 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t(
                            "projecttypes.fields.project_type_description"
                          )}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.project_type_description}
                          helperText={GetError(
                            errors.project_type_description?.type
                          )}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="d-flex w-100 pt-3">
                  <div className="w-50">
                    <Controller
                      name="MS_planner_id"
                      control={control}
                      rules={{ minLength: 28, maxLength: 28 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("projecttypes.fields.MS_planner_id")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.MS_planner_id}
                          helperText={GetError(errors.MS_planner_id?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          sx={{ paddingRight: 2 }}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="default_show_dashboard"
                    control={control}
                    render={({ field }) =>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between", }}>
                        <Typography sx={{ ml: 2 }}>{t("projecttypes.fields.default_show_dashboard")}</Typography>
                        <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
                      </Box>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-row-reverse mt-1">
              <PrimaryButton
                type="submit"
                variant="outlined"
                className="m-1"
                disabled={loadingPost}
              >
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled
                variant="contained"
                className="m-1"
                onClick={onCancel}
              >
                {t("generic.buttons.cancel")}
              </ButtonStyled>
            </div>
          </form>
        </div>
      </div>
      <SnackbarComponent />
    </>
  );
};
