import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Resource } from '../../../../interfaces/Security/resources';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../../components/Header';
import { Autocomplete, Box, CircularProgress, Switch, TextField, Typography } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { Permission } from '../../../../interfaces/Security/permissions';
import { Group } from '../../../../interfaces/Security/groups';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { useBase64 } from '../../../../hooks/useBase64';

export const AddPermission = () => {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const location = useLocation();
    const { GenericPostResource, GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
    const [defaultGroup, setDefaultGroup] = useState<Group>()
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [comboResources, setComboResources] = useState<Resource[]>([])
    const [comboGroup, setcomboGroup] = useState<Group[]>([])
    const [loadingPost, setLoadingPost] = useState(false);
    const [loadingResources, setLoadingResources] = useState(false);
    const { utf8ToBase64 } = useBase64();
    const [resource, setResource] = useState<Resource | null>(null);
    const [dataLoaded, setDataLoaded] = useState(false);

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<Permission>({
        defaultValues: {
            group_id: undefined,
            resource_id: undefined,
            create: false,
            read: false,
            update: false,
            delete: false,
        }
    });
    const onSubmit: SubmitHandler<Permission> = (data) => onPost(data);

    const onPost = (data: Permission) => {
        let myData = {
            group_id: data.group_id,
            resource_id: data.resource_id,
            create: data.create,
            read: data.read,
            update: data.update,
            delete: data.delete,
        }
        setLoadingPost(true);
        GenericPostResource("/permissions", myData)
            .then((response) => {
                navigate("/permissions", { state: { method: "add" } });
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    const onCancel = () => {
        // validacion si quiere cancelar sus cambios 
        navigate("/permissions", { state: {} });
    }

    useEffect(() => {
        GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
            document.title = `${response.data.data[0].comercial_name} - ${t("permissions.title")}`;
        })
        let myPromises = [
            GenericGetResource("/groups"),
        ];
        Promise.all(myPromises)
            .then((responses) => {
                if (location.state && location.state.group_id && location.state.group_id.row) {
                    setDefaultGroup(responses[0].data.data.find((element: Group) => element.group_id === location.state.group_id.row))
                }
                setcomboGroup(responses[0].data.data);
                setDataLoaded(true);
            }).catch((error) => {
                showSnackBar(error.message, "error");
                setDataLoaded(true);
            })
    }, [])

    useEffect(() => {
        if (defaultGroup) {
            setLoadingResources(false);
            let myPromises = [
                GenericGetResource("/resources"),
                GenericGetResource(`/permissions?filter=${utf8ToBase64(`group_id=${defaultGroup.group_id}`)}`),
            ];
            Promise.all(myPromises)
                .then((responses) => {
                    reset({
                        group_id: defaultGroup.group_id,
                    }, { keepValues: true });
                    setValue("group_id", defaultGroup.group_id);
                    let myComboResource = responses[0].data.data;
                    let myComboResourceGroup = responses[1].data.data;
                    setComboResources(myComboResource.filter((resource: Resource) => {
                        if (myComboResourceGroup.find((element: Permission) => element.resource_id === resource.resource_id)) {
                            return;
                        }
                        else {
                            return resource;
                        }
                    }))
                    setDataLoaded(true);
                    setLoadingResources(true);
                }).catch((error) => {
                    showSnackBar(error.message, "error");
                    setDataLoaded(true);
                    setLoadingResources(true);
                })
        }
    }, [defaultGroup])

    return (
        <>
            <Header title={t("permissions.title-view")} />
            <div className='d-flex justify-content-center'>
                {!dataLoaded && <Spinner />}
                {dataLoaded &&
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="custom-card-row d-flex justify-content-between">
                                <div className="w-100 d-flex flex-row">
                                    <div className="w-50 d-flex flex-column">
                                        <div className="w-100 d-flex flex-row">
                                            <Controller
                                                name="group_id"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        ref={field.ref}
                                                        size="small"
                                                        sx={{ width: "100%" }}
                                                        options={comboGroup}
                                                        defaultValue={defaultGroup}
                                                        getOptionLabel={(option) => `${option.group_name}`}
                                                        renderOption={(props, option: Group) => (
                                                            <div key={option.group_id}>
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    {`${option.group_name}`}
                                                                </Box>
                                                            </div>
                                                        )}
                                                        isOptionEqualToValue={(option, value) => option.group_id === value.group_id}
                                                        onChange={(_, values) => {
                                                            field.onChange(values?.group_id || null);
                                                            setValue("resource_id", undefined);
                                                            setResource(null);
                                                            setDefaultGroup(values ?? undefined);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t("groups.title-view")}`}
                                                                variant="filled"
                                                                value={field.value}
                                                                error={!!errors.group_id}
                                                                helperText={GetError(errors.group_id?.type)}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="w-100 d-flex flex-row mt-2">
                                            <Controller
                                                name="resource_id"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        ref={field.ref}
                                                        size="small"
                                                        sx={{ width: "100%" }}
                                                        value={resource}
                                                        options={comboResources}
                                                        getOptionLabel={(option) => `${option.resource_name} - ${option.resource_route}`}
                                                        renderOption={(props, option: Resource) => (
                                                            <div key={option.resource_id}>
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    {`${option.resource_name} - ${option.resource_route}`}
                                                                </Box>
                                                            </div>
                                                        )}
                                                        isOptionEqualToValue={(option, value) => option.resource_id === value.resource_id}
                                                        disabled={!loadingResources}
                                                        onChange={(_, values) => {
                                                            setResource(values);
                                                            field.onChange(values?.resource_id || null);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t("resources.title-view")}`}
                                                                variant="filled"
                                                                value={field.value}
                                                                error={!!errors.resource_id}
                                                                helperText={GetError(errors.resource_id?.type)}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-50 d-flex flex-column">
                                        <div className="w-100 d-flex justify-content-center align-items-center">
                                            <Typography variant="h6" gutterBottom>{t("permissions.info.permissions")} :</Typography>
                                        </div>
                                        <div className="w-100 d-flex flex-row-reverse">
                                            <Controller
                                                name="read"
                                                control={control}
                                                render={({ field }) =>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '55%', flexDirection: 'row-reverse' }}>
                                                        <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
                                                        <Typography sx={{ mr: 1 }}>{t("permissions.fields.read")}</Typography>
                                                        <AutoStoriesIcon sx={{ mr: 1 }} />
                                                    </Box>
                                                }
                                            />
                                            <Controller
                                                name="create"
                                                control={control}
                                                render={({ field }) =>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '55%', flexDirection: 'row-reverse' }}>
                                                        <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
                                                        <Typography sx={{ mr: 1 }}>{t("permissions.fields.create")}</Typography>
                                                        <AddCircleOutlineIcon sx={{ mr: 1 }} />
                                                    </Box>
                                                }
                                            />
                                        </div>
                                        <div className="w-100 d-flex flex-row-reverse mt-1">
                                            <Controller
                                                name="delete"
                                                control={control}
                                                render={({ field }) =>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '55%', flexDirection: 'row-reverse' }}>
                                                        <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
                                                        <Typography sx={{ mr: 1 }}>{t("permissions.fields.delete")}</Typography>
                                                        <DeleteIcon sx={{ mr: 1 }} />
                                                    </Box>
                                                }
                                            />
                                            <Controller
                                                name="update"
                                                control={control}
                                                render={({ field }) =>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '55%', flexDirection: 'row-reverse' }}>
                                                        <Switch ref={field.ref} onChange={field.onChange} value={field.value} />
                                                        <Typography sx={{ mr: 1 }}>{t("permissions.fields.update")}</Typography>
                                                        <EditIcon sx={{ mr: 1 }} />
                                                    </Box>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex flex-row-reverse mt-1'>
                                <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                                <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                            </div>

                        </form>

                    </div>
                }

            </div >
            <SnackbarComponent />
        </>
    )
}
