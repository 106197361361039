import React, { useEffect, useState } from "react";
import { TaskAccordion } from "../components/taskAccordion";
import { addDays, isAfter, isBefore, isToday, isTomorrow } from "date-fns";
import { useTranslation } from "react-i18next";
import { useDates } from "../../../../hooks/useDates";
import { ProjectTaskDashboard } from "../../../../interfaces/Projects/projectTaskDashboard";

interface TableProjectTaskAccordionsProps {
  projectTasksLocal: ProjectTaskDashboard [];
  color: number[] | undefined;
  isEdit:  boolean
}

export const TableProjectTaskAccordions = ({
  projectTasksLocal,
  color,
  isEdit
}: TableProjectTaskAccordionsProps) => {
  const [todaysProjectTasks, setTodaysProjectTasks] = useState<ProjectTaskDashboard[]>([]);
  const [tomorrowsProjectTasks, setTomorrowsProjectTasks] = useState<ProjectTaskDashboard[]>([]);
  const [earlierProjectTasks, setEarlierProjectTasks] = useState<ProjectTaskDashboard[]>([]);
  const [laterProjectTasks, setLaterProjectTasks] = useState<ProjectTaskDashboard[]>([]);
  const [noDueDateTasks, setNoDueDateTasks] = useState<ProjectTaskDashboard[]>([]);
  const [t] = useTranslation("global");
  const { ReverseTimeConverter } = useDates();

  const categorizeTasks = (tasks: ProjectTaskDashboard[]) => {
    const today = new Date();
    const yesterday = addDays(today, -1);
    const tomorrow = addDays(today, 1);
    setTodaysProjectTasks(
      tasks.filter((task: ProjectTaskDashboard) => {
        let dueDate = ReverseTimeConverter(task.project_task_due_date?.toString())
        if (dueDate) {
          return isToday(dueDate);
        } else {
          return false;
        }
      }
      )
    );

    setTomorrowsProjectTasks(
      tasks.filter((task: ProjectTaskDashboard) => {
        let dueDate = ReverseTimeConverter(task.project_task_due_date?.toString())
        if (dueDate) {
          return isTomorrow(dueDate);
        } else {
          return false;
        }
      }
      )
    );
    setEarlierProjectTasks(
      tasks.filter((task: ProjectTaskDashboard) => {
        let dueDate = ReverseTimeConverter(task.project_task_due_date?.toString())
        if (dueDate) {
          return isBefore(dueDate,yesterday);
        } else {
          return false;
        }
      }
      )
    );
    setLaterProjectTasks(
      tasks.filter((task: ProjectTaskDashboard) => {
        let dueDate = ReverseTimeConverter(task.project_task_due_date?.toString())
        if (dueDate) {
          return isAfter(dueDate,tomorrow);
        } else {
          return false;
        }
      }
      )
    );

    setNoDueDateTasks(
      tasks.filter((task) => !task.project_task_due_date)
    );
  };

  useEffect(() => {
    categorizeTasks(projectTasksLocal);
  }, [projectTasksLocal]);

  return (
    <>
      <TaskAccordion title={t("projecttaskdashboard.accordions.earlier")} tasks={earlierProjectTasks} color={color} isEdit={isEdit}/>
      <TaskAccordion title={t("projecttaskdashboard.accordions.today")} tasks={todaysProjectTasks} color={color} isEdit={isEdit} />
      <TaskAccordion title={t("projecttaskdashboard.accordions.tomorrow")} tasks={tomorrowsProjectTasks} color={color} isEdit={isEdit} />
      <TaskAccordion title={t("projecttaskdashboard.accordions.later")} tasks={laterProjectTasks} color={color} isEdit={isEdit} />
      <TaskAccordion title={t("projecttaskdashboard.accordions.no-date")} tasks={noDueDateTasks} color={color} isEdit={isEdit} />
    </>
  );
};
