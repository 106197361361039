import { Route, Routes } from "react-router-dom"
import { ViewProjectTaskDashboard } from "./view"


export const BrowserProjectTaskDashboard = () =>{
    return (
        <Routes>
            <Route path={"/"} element={<ViewProjectTaskDashboard/>}/>
            {/* <Route path={"/assignedtome"} element={<ViewProjectDetails/>}/> */}
        </Routes>
      )
}