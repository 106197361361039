import GenericPromises from "../../api/GenericPromises";
import { ExchangeRateHistory } from '../../interfaces/Commons/currencies';
import { useDof } from "../../screens/Commons/Currencies/helpers/useDof";

export const useCurrencies = () => {
  const { GenericGetResource, GenericPostResource } = GenericPromises();
  const { GetRateBySerie } = useDof();

  const GetExchangeRateFromCurrencyCode = async (
    currencyId: number,
    currencyDate: Date | undefined,
  ): Promise<ExchangeRateHistory | undefined> => {
    if (currencyDate === undefined) {
      let myAux: ExchangeRateHistory = { rate: -1 }
      return myAux;
    }
    else {
      let myExchangeRate: ExchangeRateHistory | undefined = await GenericGetResource(`/exchangeratehistory/bycurrencyid/${(currencyId) ?? 0}`)
        .then(async (responseCurrency) => {
          let currentDate = currencyDate;

          let myNewDate = new Date(currentDate ?? Date.now());
          if (myNewDate.getDay() === 1) {
            myNewDate.setDate(myNewDate?.getDate() - 3);
          }
          else if (myNewDate.getDay() === 0) {
            myNewDate.setDate(myNewDate?.getDate() - 2);
          }
          else {
            myNewDate.setDate(myNewDate?.getDate() - 1);
          }

          let myCurrencyValues: ExchangeRateHistory[] = responseCurrency.data.data;

          return await myCurrencyValues.find((item) => {
            // Convertir la cadena de fecha a un objeto Date
            const itemDate = new Date(item.rate_date!);
            const formattedDate1: Date = new Date(`${itemDate.getFullYear()}-${itemDate.getMonth() + 1}-${itemDate.getDate()}`);
            const formattedDate2: Date = new Date(`${myNewDate.getFullYear()}-${myNewDate.getMonth() + 1}-${myNewDate.getDate()}`);

            return (formattedDate1.getTime() === formattedDate2.getTime());
          });
        });
      if (myExchangeRate === undefined) {
        let myAux: ExchangeRateHistory = { rate: -1 }
        return myAux;
      }
      else {
        return myExchangeRate;
      }
    }
  }

  const GetOperatorToGetDivisa = (
    currencyCodeSource: string,
    currencyCodeTarget: string,
  ) => {
    if (currencyCodeSource === "MXN" && currencyCodeTarget === "USD") {
      return "multiply";
    }
    else if (currencyCodeSource === "USD" && currencyCodeTarget === "MXN") {
      return "divide";
    }
    else {
      return "same";
    }
  }

  const GetAndPostExchangeRateFromCurrencyCodeAndDate = async (
    currencyId: number,
    currencyDate: Date,
  ) => {
    let myResponseDates = await GetRateBySerie("158", currencyDate.toISOString().split('T')[0], currencyDate.toISOString().split('T')[0]);
    let myNewRows = await myResponseDates.data.ListaIndicadores;
    // si myRows tiene valor
    if (myNewRows.length >= 1) {
      const element = myNewRows[0];
      let myDate = element.fecha.split("-");
      // POST EXCHANGERATE
      let rateDate = new Date(myDate[2] + '/' + myDate[1] + '/' + myDate[0]);

      // Ajustar la hora a 00:00:00
      rateDate.setHours(0, 0, 0, 0);
      // POST EXCHANGERATE
      let myExchangeRateHistory = {
        rate_date: rateDate,
        rate: element.valor,
        currency_id: currencyId,
      }
      const newExchangeRate = await GenericPostResource(`/exchangeratehistory`, myExchangeRateHistory);
      return newExchangeRate.data;
    }
    // RETURN VALUE
    return { rate: -1 };
  }

  return {
    GetExchangeRateFromCurrencyCode,
    GetOperatorToGetDivisa,
    GetAndPostExchangeRateFromCurrencyCodeAndDate,
  }
}
