import React, { useEffect, useState } from 'react'
import { MerchandiseDetail, TransportationDocument } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/transportationDocument';
import { GridActionsCellItem, GridColDef, GridEventListener, GridRenderCellParams, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import TableMerchandiseDetail from '../../../../components/Tables/TableMerchandiseDetail';
import { GenericDialog } from '../../../../components/Dialogs/Dialog';
import { DialogEntity } from '../../../../components/Dialogs/DialogEntity';
import { PrimaryButton } from '../../../../theme/buttons';
import { Unit } from '../../../../interfaces/Commons/units';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Autocomplete, Box, Button, IconButton, TextField } from '@mui/material';
import { PackagingType } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/packagingTypes';
import { HazardousMaterialKey } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/hazardousMaterialsKeys';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { User } from '../../../../interfaces/Security/users';

type UnitComboProps = {
  myUnit: Unit | undefined,
  setMyUnit: React.Dispatch<React.SetStateAction<any>>,
}

type HazardousMaterialKeyComboProps = {
  myHazardousMaterialKey: HazardousMaterialKey | undefined,
  setMyHazardousMaterialKey: React.Dispatch<React.SetStateAction<any>>,
}

type PackagingTypeComboProps = {
  myPackagingType: PackagingType | undefined,
  setMyPackagingType: React.Dispatch<React.SetStateAction<any>>,
}

const HazardousMaterialKeyCombo = ({ myHazardousMaterialKey, setMyHazardousMaterialKey }: HazardousMaterialKeyComboProps) => {
  const [comboHazardousMaterialKeys, setComboHazardousMaterialKesy] = useState<HazardousMaterialKey[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const [t] = useTranslation("global");

  useEffect(() => {
    GenericGetResource("/hazardousmaterialskeys")
      .then((response) => {
        setComboHazardousMaterialKesy(response.data.data);
        setMyHazardousMaterialKey(response.data.data.find((hazardousMaterialKey: HazardousMaterialKey) => hazardousMaterialKey.hazardous_material_key === myHazardousMaterialKey?.hazardous_material_key));
        setDataLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, value: any) => {
    setMyHazardousMaterialKey(value);
  }

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Autocomplete
          value={myHazardousMaterialKey || null}
          size="small"
          sx={{ flex: 1, paddingRight: 1, width: 250 }}
          options={comboHazardousMaterialKeys}
          getOptionLabel={(option) => `${option.hazardous_material_key} - ${option.hazardous_material_description}`}
          defaultValue={undefined}
          renderOption={(props, option: HazardousMaterialKey) => (
            <div key={option.hazardous_material_key_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.hazardous_material_key} - {option.hazardous_material_description}
              </Box>
            </div>
          )}
          isOptionEqualToValue={(option, value) => option.hazardous_material_key_id === value.hazardous_material_key_id}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("hazardousmaterialskeys.title-view")}`}
              variant="filled"
            />
          )}
        />
      }
    </>
  );
}

const PackagingTypeCombo = ({ myPackagingType, setMyPackagingType }: PackagingTypeComboProps) => {
  const [comboPackagingTypes, setComboPackagingTypes] = useState<PackagingType[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const [t] = useTranslation("global");

  useEffect(() => {
    GenericGetResource("/packagingtypes")
      .then((response) => {
        setComboPackagingTypes(response.data.data);
        setMyPackagingType(response.data.data.find((packagingTypes: PackagingType) => packagingTypes.packaging_type_code === myPackagingType?.packaging_type_code));
        setDataLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, value: any) => {
    setMyPackagingType(value);
  }

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Autocomplete
          value={myPackagingType || null}
          size="small"
          sx={{ flex: 1, paddingRight: 1, width: 250 }}
          options={comboPackagingTypes}
          getOptionLabel={(option) => `${option.packaging_type_code} - ${option.packaging_type_description}`}
          defaultValue={undefined}
          renderOption={(props, option: PackagingType) => (
            <div key={option.packaging_type_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.packaging_type_code} - {option.packaging_type_description}
              </Box>
            </div>
          )}
          isOptionEqualToValue={(option, value) => option.packaging_type_id === value.packaging_type_id}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("packagingtypes.title-view")}`}
              variant="filled"
            />
          )}
        />
      }
    </>
  );
}

const UnitCombo = ({ myUnit, setMyUnit }: UnitComboProps) => {
  const [comboUnits, setComboUnits] = useState<Unit[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const [t] = useTranslation("global");

  useEffect(() => {
    GenericGetResource("/units")
      .then((response) => {
        setComboUnits(response.data.data);
        setMyUnit(response.data.data.find((item: Unit) => item.unit_code === myUnit?.unit_code));
        setDataLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, value: any) => {
    setMyUnit(value);
  }

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Autocomplete
          value={myUnit || null}
          size="small"
          sx={{ flex: 1, paddingRight: 1, width: 250 }}
          options={comboUnits}
          getOptionLabel={(option) => `${option.unit_description} - ${option.unit_code}`}
          defaultValue={undefined}
          renderOption={(props, option: Unit) => (
            <div key={option.unit_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.unit_description} - {option.unit_code}
              </Box>
            </div>
          )}
          isOptionEqualToValue={(option, value) => option.unit_id === value.unit_id}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("units.title-view")}`}
              variant="filled"
            />
          )}
        />
      }
    </>
  );
}

type MerchandiseProps = {
  rows: MerchandiseDetail[],
  setRows: React.Dispatch<React.SetStateAction<MerchandiseDetail[]>>,
  transportationDocumentPayload?: TransportationDocument | undefined;
  isEdit?: boolean,
  ChangeTotalMerchandise: () => void,
  hasTransportation: boolean,
}

export const TableMerchandise = ({
  rows,
  setRows,
  transportationDocumentPayload,
  isEdit,
  ChangeTotalMerchandise,
  hasTransportation,
}: MerchandiseProps) => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [t] = useTranslation("global");
  const { GenericGetResource, GenericPutResource, GenericDeleteResource } = GenericPromises();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isCanceledRow, setIsCanceledRow] = useState(false);
  const [openUnitDialog, setOpenUnitDialog] = useState(false);
  const [myTempUnit, setMyTempUnit] = useState<Unit>();
  const [openPackagingTypeDialog, setOpenPackagingTypeDialog] = useState(false);
  const [myTempPackagingType, setMyTempPackagingType] = useState<PackagingType>();
  const [openHazardousMaterialKeyDialog, setOpenHazardousMaterialKeyDialog] = useState(false);
  const [myTempHazardousMaterialKey, setMyTempHazardousMaterialKey] = useState<HazardousMaterialKey>();
  const [mySelectedRow, setMySelectedRow] = useState<GridRowModel>();
  const [idToDelete, setIdToDelete] = useState<GridRowId>(0);




  let columns: GridColDef[] = ((isEdit) ?
    (rows.find((row: MerchandiseDetail) => row.is_dangerous_material === true || row.could_be_hazardous_material === true)) ? //material peligroso
      [
        {
          field: 'product_service_key',
          headerName: t("merchandisedetails.fields.product_service_key"),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'description',
          headerName: t("merchandisedetails.fields.concept"),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'quantity',
          headerName: t("merchandisedetails.fields.quantity"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          editable: true,
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'unit_id',
          headerName: t("merchandisedetails.fields.unit_code"),
          cellClassName: 'my-custom-cell',
          renderCell: (params: GridRenderCellParams<any, Date>) => (
            <div>
              <IconButton
                onClick={() => {
                  let myUnit: Unit = {
                    unit_id: params.row.unit_id,
                    unit_code: params.row.unit_code,
                  }
                  setMyTempUnit(myUnit.unit_code ? myUnit : undefined);
                  setMySelectedRow(params);
                  setOpenUnitDialog(true);
                }}
                size="small">
                <EditIcon />
              </IconButton>
              {params.row.unit_code}
            </div>
          ),
          flex: 1
        },
        {
          field: 'weight_in_kg',
          headerName: t("merchandisedetails.fields.weight_in_kg"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          editable: true,
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'hazardous_material_key_id',
          headerName: t("merchandisedetails.fields.hazardous_material_key"),
          cellClassName: 'my-custom-cell',
          renderCell: (params: GridRenderCellParams<any, Date>) => (
            <div>
              {((params.row.is_dangerous_material || params.row.could_be_hazardous_material) && !(parseInt(params.row.quantity ?? "0") <= 0) && !(parseInt(params.row.weight_in_kg ?? "0") <= 0)) &&
                <IconButton
                  onClick={() => {
                    let myHazardousMaterialKey: HazardousMaterialKey = {
                      hazardous_material_key_id: params.row.hazardous_material_key_id,
                      hazardous_material_key: params.row.hazardous_material_key,
                      hazardous_material_description: params.row.hazardous_material_description
                    }
                    setMyTempHazardousMaterialKey(myHazardousMaterialKey.hazardous_material_key ? myHazardousMaterialKey : undefined);
                    setMySelectedRow(params);
                    setOpenHazardousMaterialKeyDialog(true);
                  }}
                  size="small">
                  <EditIcon />
                </IconButton>}
              {params.row.hazardous_material_key} {(params.row.hazardous_material_description) ? `- ${params.row.hazardous_material_description}` : ``}
            </div>
          ),
          flex: 1
        },
        {
          field: 'packaging_type_id',
          headerName: t("merchandisedetails.fields.packaging_type_code"),
          cellClassName: 'my-custom-cell',
          renderCell: (params: GridRenderCellParams<any, Date>) => (
            <div>
              {((params.row.is_dangerous_material || params.row.could_be_hazardous_material) && !(parseInt(params.row.quantity ?? "0") <= 0) && !(parseInt(params.row.weight_in_kg ?? "0") <= 0)) &&
                <IconButton
                  onClick={() => {
                    let myPackagingType: PackagingType = {
                      packaging_type_id: params.row.packaging_type_id,
                      packaging_type_code: params.row.packaging_type_code,
                      packaging_type_description: params.row.packaging_type_description
                    }
                    setMyTempPackagingType(myPackagingType.packaging_type_code ? myPackagingType : undefined);
                    setMySelectedRow(params);
                    setOpenPackagingTypeDialog(true);
                  }}
                  size="small">
                  <EditIcon />
                </IconButton>
              }
              {params.row.packaging_type_code} {(params.row.packaging_type_description) ? `- ${params.row.packaging_type_description}` : ``}
            </div>
          ),
          flex: 1
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: t("generic.grid-table.options"),
          cellClassName: 'actions my-custom-cell',
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  icon={<SaveIcon />}
                  label="Save"
                  sx={{
                    color: 'primary.main',
                  }}
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  icon={<CancelIcon />}
                  label="Cancel"
                  className="textPrimary"
                  onClick={handleCancelClick(id)}
                  color="inherit"
                />,
              ];
            }

            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
              />,
            ];
          },
          flex: 1,
        },
      ]
      : //si no tiene material peligroso
      [
        {
          field: 'product_service_key',
          headerName: t("merchandisedetails.fields.product_service_key"),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'description',
          headerName: t("merchandisedetails.fields.concept"),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'quantity',
          headerName: t("merchandisedetails.fields.quantity"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          editable: true,
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'unit_id',
          headerName: t("merchandisedetails.fields.unit_code"),
          cellClassName: 'my-custom-cell',
          renderCell: (params: GridRenderCellParams<any, Date>) => (
            <div>
              <IconButton
                onClick={() => {
                  let myUnit: Unit = {
                    unit_id: params.row.unit_id,
                    unit_code: params.row.unit_code,
                  }
                  setMyTempUnit(myUnit.unit_code ? myUnit : undefined);
                  setMySelectedRow(params);
                  setOpenUnitDialog(true);
                }}
                size="small">
                <EditIcon />
              </IconButton>
              {params.row.unit_code}
            </div>
          ),
          flex: 1
        },
        {
          field: 'weight_in_kg',
          headerName: t("merchandisedetails.fields.weight_in_kg"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          editable: true,
          flex: 1,
          cellClassName: 'my-custom-cell',
        }, {
          field: 'actions',
          type: 'actions',
          headerName: t("generic.grid-table.options"),
          cellClassName: 'actions my-custom-cell',
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  icon={<SaveIcon />}
                  label="Save"
                  sx={{
                    color: 'primary.main',
                  }}
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  icon={<CancelIcon />}
                  label="Cancel"
                  className="textPrimary"
                  onClick={handleCancelClick(id)}
                  color="inherit"
                />,
              ];
            }

            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
              />,
            ];
          },
          flex: 1,
        },
      ]
    : (rows.find((row: MerchandiseDetail) => row.is_dangerous_material == true)) ?//si no es editable material peligroso
      [
        {
          field: 'product_service_key',
          headerName: t("merchandisedetails.fields.product_service_key"),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'description',
          headerName: t("merchandisedetails.fields.concept"),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'quantity',
          headerName: t("merchandisedetails.fields.quantity"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'unit_id',
          headerName: t("merchandisedetails.fields.unit_code"),
          renderCell: (params: GridRenderCellParams<any, Date>) => (
            <div>
              {params.row.unit_code}
            </div>
          ),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'weight_in_kg',
          headerName: t("merchandisedetails.fields.weight_in_kg"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'hazardous_material_key_id',
          headerName: t("merchandisedetails.fields.hazardous_material_key"),
          renderCell: (params: GridRenderCellParams<any, Date>) => (
            <div>
              {params.row.hazardous_material_key} {(params.row.hazardous_material_description) ? `- ${params.row.hazardous_material_description}` : ``}
            </div>
          ),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'packaging_type_id',
          headerName: t("merchandisedetails.fields.packaging_type_code"),
          renderCell: (params: GridRenderCellParams<any, Date>) => (
            <div>
              {params.row.packaging_type_code} {(params.row.packaging_type_description) ? `- ${params.row.packaging_type_description}` : ``}
            </div>
          ),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
      ]
      : //si no tiene material peligroso y no esta activo
      [
        {
          field: 'product_service_key',
          headerName: t("merchandisedetails.fields.product_service_key"),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'description',
          headerName: t("merchandisedetails.fields.concept"),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'quantity',
          headerName: t("merchandisedetails.fields.quantity"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'unit_id',
          headerName: t("merchandisedetails.fields.unit_code"),
          renderCell: (params: GridRenderCellParams<any, Date>) => (
            <div>
              {params.row.unit_code}
            </div>
          ),
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
        {
          field: 'weight_in_kg',
          headerName: t("merchandisedetails.fields.weight_in_kg"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          cellClassName: 'my-custom-cell',
        },
      ]
  )

  const [myColumns, setMyColumns] = useState<GridColDef[]>(columns);

  const reorderColumns = (originalColumns: GridColDef[], newOrder: string[]) => {
    const orderedColumns: GridColDef[] = [];
    const actionsColumn = originalColumns.find(col => col.field === 'actions');
    const columnsToAdd = originalColumns.filter(col => !newOrder.includes(col.field));

    columnsToAdd.forEach(col => {
      if (col.field !== 'actions') {
        orderedColumns.push(col);
      }
    });

    newOrder.forEach((fieldName) => {
      const column = originalColumns.find((col) => col.field === fieldName);
      if (column && column.field !== 'actions') {
        orderedColumns.push(column);
      }
    });

    if (actionsColumn) {
      orderedColumns.push(actionsColumn);
    }

    return orderedColumns;
  };

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-merchandisedetails-columns");
    if (myOrderColumns !== null) {
      let myJson: any[] = JSON.parse(myOrderColumns);

      myJson.forEach((columnConfig) => {
        if (columnConfig.type === 'date') {
          columnConfig.headerName = t(`generic.${columnConfig.field}`);
          columnConfig.valueGetter = function (params: GridValueGetterParams<User, any, GridTreeNodeWithRender>) {
            return new Date(params.value);
          };
        } else {
          columnConfig.headerName = t(`merchandisedetails.fields.${columnConfig.field}`);
        }
      });

      const reorderedColumns = reorderColumns(columns, myJson.map((col) => col.field));

      setMyColumns(reorderedColumns);
    }else{
      setMyColumns(columns)
    }
  };


  const onAcceptNewUnit = () => {
    if (myTempUnit?.unit_id) {
      let myRow = mySelectedRow?.row;
      myRow.unit_id = myTempUnit?.unit_id ?? null;
      myRow.unit_code = myTempUnit?.unit_code ?? null;
      processRowUpdate(myRow, myRow);
      setOpenUnitDialog(false);
    }
    else {
      showSnackBar(t("merchandisedetails.snackbar.warning"), "warning");
    }
  }

  const onAcceptNewHazardousMaterialKey = () => {
    if (myTempHazardousMaterialKey?.hazardous_material_key_id || (mySelectedRow?.row.could_be_hazardous_material === true)) {
      let myRow = mySelectedRow?.row;
      myRow.hazardous_material_key_id = myTempHazardousMaterialKey?.hazardous_material_key_id ?? null;
      myRow.hazardous_material_key = myTempHazardousMaterialKey?.hazardous_material_key ?? null;
      myRow.hazardous_material_description = myTempHazardousMaterialKey?.hazardous_material_description ?? null;
      processRowUpdate(myRow, myRow);
      setOpenHazardousMaterialKeyDialog(false);
    }
    else {
      showSnackBar(t("merchandisedetails.snackbar.warning"), "warning");
    }
  }

  const onAcceptNewPackagingType = () => {
    if (myTempPackagingType?.packaging_type_id || (mySelectedRow?.row.could_be_hazardous_material === true)) {
      let myRow = mySelectedRow?.row;
      myRow.packaging_type_id = myTempPackagingType?.packaging_type_id ?? null;
      myRow.packaging_type_code = myTempPackagingType?.packaging_type_code ?? null;
      myRow.packaging_type_description = myTempPackagingType?.packaging_type_description ?? null;
      processRowUpdate(myRow, myRow);
      setOpenPackagingTypeDialog(false);
    }
    else {
      showSnackBar(t("merchandisedetails.snackbar.warning"), "warning");
    }
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (merchandise_detail_id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [merchandise_detail_id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (merchandise_detail_id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [merchandise_detail_id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (merchandise_detail_id: GridRowId) => () => {
    setIdToDelete((prev) => merchandise_detail_id);
    setOpenDeleteDialog(true);
  };

  const onDeleteRow = () => {
    GenericDeleteResource(`/merchandisedetails/${idToDelete}`)
      .then(() => {
        setOpenDeleteDialog(false);
        setRows(rows.filter((row) => row.merchandise_detail_id !== idToDelete));
        ChangeTotalMerchandise();
        showSnackBar(t("generic.snackbar.delete"), "success");
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }

  const handleCancelClick = (merchandise_detail_id: GridRowId) => () => {
    setIsCanceledRow(true);
    setRowModesModel({ ...rowModesModel, [merchandise_detail_id]: { mode: GridRowModes.View } });
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const updatedRow: MerchandiseDetail = { ...newRow };
    if (((parseInt(updatedRow.quantity ?? "0")) <= 0) || (parseInt(updatedRow.weight_in_kg ?? "0")) <= 0) {
      if (updatedRow.is_dangerous_material === true) {
        showSnackBar(t("salesinvoicedetails.snackbar.warning"), "warning");
      }
      showSnackBar(t("salesinvoicedetails.snackbar.warning"), "warning");
      return oldRow;
    }
    else if (!isCanceledRow) {
      let myData = {
        transportation_document_id: transportationDocumentPayload?.transportation_document_id,
        description: updatedRow?.description,
        product_service_key_id: updatedRow?.product_service_key_id,
        product_service_key: updatedRow?.product_service_key,
        unit_id: updatedRow?.unit_id,
        unit_code: updatedRow?.unit_code,
        quantity: updatedRow.quantity,
        weight_in_kg: updatedRow.weight_in_kg,
        is_dangerous_material: updatedRow.is_dangerous_material,
        hazardous_material_key_id: updatedRow?.hazardous_material_key_id,
        could_be_hazardous_material: updatedRow?.could_be_hazardous_material,
        hazardous_material_key: updatedRow?.hazardous_material_key,
        hazardous_material_description: updatedRow?.hazardous_material_description,
        packaging_type_id: updatedRow?.packaging_type_id,
        packaging_type_code: updatedRow?.packaging_type_code,
        packaging_type_description: updatedRow?.packaging_type_description
      }
      GenericPutResource(`/merchandisedetails/${updatedRow.merchandise_detail_id}`, myData)
        .then(() => {
          updatedRow.weight_in_kg = myData.weight_in_kg;
          setRows((prev) => rows.map((row) => (row.merchandise_detail_id === newRow.merchandise_detail_id ? updatedRow : row)));
          ChangeTotalMerchandise();

          showSnackBar(t("salesinvoicedetails.snackbar.update"), "success");
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
      return updatedRow;
    }
    else {
      setIsCanceledRow(false);
      return oldRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {

    let updateColumns = [...myColumns]
    const actionsColumnIndex = updateColumns.findIndex(column => column.field === "actions");

    updateColumns[actionsColumnIndex] = {
      field: 'actions',
      type: 'actions',
      headerName: t("generic.grid-table.options"),
      cellClassName: 'actions my-custom-cell',
      getActions: ({ id }: any) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
      flex: 1,
    }

    setMyColumns(updateColumns)
  }, [rowModesModel])

  useEffect(() => {
    loadColumnsOrder();
    setDataLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadColumnsOrder();
  }, [isEdit, hasTransportation,rows.find((row: MerchandiseDetail) => row.is_dangerous_material || row.could_be_hazardous_material)])


  return (
    <React.Fragment>
      {dataLoaded &&
        <TableMerchandiseDetail
          transportationDocument={transportationDocumentPayload?.transportation_document_id ?? 0}
          rows={rows}
          columns={myColumns}
          setColumns={setMyColumns}
          nameOrderColumns={"grid-merchandisedetails-columns"}
          rowModesModel={rowModesModel}
          handleRowModesModelChange={handleRowModesModelChange}
          handleRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          setRows={setRows}
          setRowModesModel={setRowModesModel}
          isEdit={isEdit}
          ChangeTotalMerchandise={ChangeTotalMerchandise}
        />
      }
      <DialogEntity
        open={openUnitDialog}
        setOpen={setOpenUnitDialog}
        content={
          <>
            <UnitCombo myUnit={myTempUnit} setMyUnit={setMyTempUnit} />
            <div className="d-flex flex-row-reverse m-2">
              <PrimaryButton onClick={onAcceptNewUnit} >{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => { setOpenUnitDialog(false); }}>{t("generic.buttons.cancel")}</Button>
            </div>
          </>
        }
        title={<></>}
      />
      <DialogEntity
        open={openHazardousMaterialKeyDialog}
        setOpen={setOpenHazardousMaterialKeyDialog}
        content={
          <>
            <HazardousMaterialKeyCombo myHazardousMaterialKey={myTempHazardousMaterialKey} setMyHazardousMaterialKey={setMyTempHazardousMaterialKey} />
            <div className="d-flex flex-row-reverse m-2">
              <PrimaryButton onClick={onAcceptNewHazardousMaterialKey} >{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => { setOpenHazardousMaterialKeyDialog(false); }}>{t("generic.buttons.cancel")}</Button>
            </div>
          </>
        }
        title={<></>}
      />
      <DialogEntity
        open={openPackagingTypeDialog}
        setOpen={setOpenPackagingTypeDialog}
        content={
          <>
            <PackagingTypeCombo myPackagingType={myTempPackagingType} setMyPackagingType={setMyTempPackagingType} />
            <div className="d-flex flex-row-reverse m-2">
              <PrimaryButton onClick={onAcceptNewPackagingType} >{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => { setOpenPackagingTypeDialog(false); }}>{t("generic.buttons.cancel")}</Button>
            </div>
          </>
        }
        title={<></>}
      />
      <GenericDialog
        open={openDeleteDialog}
        onAcept={onDeleteRow}
        setOpen={setOpenDeleteDialog}
        content={t("merchandisedetails.dialog.delete")}
        title={t("generic.dialog.delete")}
      />
      <SnackbarComponent />
    </React.Fragment>
  );
}
