import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, TextField } from '@mui/material';
import { ProjectExtraFieldTemplate } from '../../../../../interfaces/Projects/Catalogs/Templates/projectExtraFieldsTemplate';
import GenericPromises from '../../../../../api/GenericPromises';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { FieldType } from '../../../../../interfaces/Projects/Catalogs/fieldTypes';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import { ButtonStyled, PrimaryButton } from '../../../../../theme/buttons';

type AddProjectExtraFieldsTemplateProps = {
  project_template_id: number,
  AddNewRow: (newRow: ProjectExtraFieldTemplate) => void,
  setOpenDialog: Dispatch<SetStateAction<boolean>>,
}

export const AddProjectExtraFieldsTemplate = ({
  project_template_id,
  AddNewRow,
  setOpenDialog,
}: AddProjectExtraFieldsTemplateProps) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isTypeFormula, setIsTypeFormula] = useState(false);
  const [comboFieldTypes, setComboFieldTypes] = useState<FieldType[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ProjectExtraFieldTemplate>({
    defaultValues: {
      field_type_id: undefined,
      project_template_id: undefined,
      project_extra_field_template_name: undefined,
      project_extra_field_template_value: undefined,
      project_extra_field_template_order: undefined
    }
  });
  const onSubmit: SubmitHandler<ProjectExtraFieldTemplate> = (data) => onPost(data);

  const onPost = (data: ProjectExtraFieldTemplate) => {
    let myData = {
      field_type_id: data.field_type_id ?? null,
      project_template_id: project_template_id ?? null,
      project_extra_field_template_name: data.project_extra_field_template_name ?? null,
      project_extra_field_template_value: data.project_extra_field_template_value ?? null,
      project_extra_field_template_order: data.project_extra_field_template_order 
    }
    setLoadingPost(true);
    GenericPostResource("/projectextrafieldstemplate", myData)
      .then((response) => {
        let myFieldType = comboFieldTypes.find((fieldType: FieldType) => fieldType.field_type_id === myData.field_type_id);
        let myNewRow = {
          ...response.data,
          field_type_name: myFieldType?.field_type_name,
        }
        AddNewRow(myNewRow);
        setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    setOpenDialog(false);
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/fieldtypes")
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboFieldTypes(responses[0].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }, []);

  useEffect(() => { }, [isTypeFormula]);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='d-flex'>
              <div className='w-50'>
                <Controller
                  name="project_extra_field_template_name"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("projectextrafieldstemplate.fields.project_extra_field_template_name")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.project_extra_field_template_name}
                      helperText={GetError(errors.project_extra_field_template_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                    />
                  }
                />
              </div>

              <div className='w-50'>
                <Controller
                  name="field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: (isTypeFormula ? 2 : 0) }}
                      options={comboFieldTypes}
                      getOptionLabel={(option) => option.field_type_name}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.field_type_id === value.field_type_id}
                      onChange={(_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (comboFieldTypes.find((item) => (item.field_type_id === values?.field_type_id) && (item.field_type_name.toLowerCase() === 'formula'))) {
                          setIsTypeFormula(true);
                        }
                        else {
                          setIsTypeFormula(false);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("projectextrafieldstemplate.fields.field_type")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.field_type_id}
                          helperText={GetError(errors.field_type_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>
              {isTypeFormula &&
                <div className="w-50">
                  <Controller
                    name="project_extra_field_template_value"
                    control={control}
                    rules={{ required: true, maxLength: 1500 }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        label={`${t("projectextrafieldstemplate.fields.project_extra_field_template_value")}`}
                        ref={field.ref}
                        value={field.value}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.project_extra_field_template_value}
                        helperText={GetError(errors.project_extra_field_template_value?.type)}
                        size="small"
                        style={{ width: "100%" }}
                      />
                    }
                  />
                </div>
              }
            </div>

            <div className='d-flex'>
              <div className='w-100 mt-3'>
                <Controller
                  name="project_extra_field_template_order"
                  control={control}
                  rules={{ required: true}}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("projectextrafieldstemplate.fields.project_extra_field_template_order")}`}
                      type="number"
                      ref={field.ref}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          { display: "none" },
                        "& input[type=number]": { MozAppearance: "textfield" },
                        paddingRight:2
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_extra_field_template_order}
                      helperText={GetError(errors.project_extra_field_template_order?.type)}
                      size="small"
                      style={{ width: "50%" }}
                    />
                  }
                />
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-3'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <SnackbarComponent />
    </>
  )
}
