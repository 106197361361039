import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import GenericPromises from "../../../../../api/GenericPromises";
import { Button } from "@mui/material";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../../components/Header";
import { Chassis } from "../../../../../interfaces/Sales/Catalogs/TransportationDocuments/chassis";
import { UpdateChassis } from "./update";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../../../theme/buttons";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";

export const ViewChassis = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [chassisLocal, setChassisLocal] = useState<Chassis>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GetResourceByUrl } = usePermissions();

  const onDelete = () => {
    GenericDeleteResource(`/chassis/${chassisLocal?.chassis_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/chassis", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    if (location.state === null) return navigate("/chassis", { state: {}, replace: true });
    GenericGetResource(`/chassis/${location.state.row.chassis_id}`)
      .then(
        (response) => {
          setChassisLocal(response.data);

          GetResourceByUrl(`/chassis`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, 'error');
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("chassis.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="outlined" onClick={() => { navigate("/chassis", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <div>
                <UpdateChassis chassisPayload={chassisLocal} isEdit={resourceScreen?.update} />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("chassis.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
