import { Image, Text, View, pdf, } from '@react-pdf/renderer';
import { useFormatNumber } from '../../../../hooks/useFormatNumber';
import { useDates } from '../../../../hooks/useDates';

export const Footer = ({ data, showTranslatedText }: any) => {

    const { setFormatNumber } = useFormatNumber();

    const { TimeConverter } = useDates();

    let regex = /\([^)]*\)/g;

    return (
        <>
            <View wrap={false} style={{
                alignSelf: 'center',
                gap: 10
            }}>
                <View style={{ width: '90%', alignSelf: 'center', height: 50, borderWidth: 1, flexDirection: 'row' }}>
                    <View style={{ width: '30%', flexDirection: 'column', }}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, height: '50%' }}>
                            <Text style={{ fontSize: 9, fontWeight: 700 }}>{showTranslatedText("reports.receiptsPdf.footer.dateAndTimeOfPayment")}</Text>
                        </View>
                        <View style={{ alignItems: 'center', justifyContent: 'center', borderRightWidth: 1, height: '50%' }}>
                            <Text style={{ fontSize: 9 }}>{!data.dataReceiptHeader.payment_date ? " " : TimeConverter(data.dataReceiptHeader.payment_date)?.toString().replace(regex, '').trim()}</Text>
                        </View>
                    </View>
                    <View style={{ width: '40%', flexDirection: 'column', }}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, height: '50%' }}>
                            <Text style={{ fontSize: 9, fontWeight: 700 }}>{showTranslatedText("reports.receiptsPdf.footer.paymentMethod")}</Text>
                        </View>
                        <View style={{ alignItems: 'center', justifyContent: 'center', borderRightWidth: 1, height: '50%' }}>
                            <Text style={{ fontSize: 9 }}>{data.dataReceiptHeader?.payment_method_code || " "} - {data.dataReceiptHeader?.payment_method_name || " "}</Text>
                        </View>
                    </View>
                    <View style={{ width: '30%', flexDirection: 'column', }}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, height: '50%' }}>
                            <Text style={{ fontSize: 9, fontWeight: 700 }}>{showTranslatedText("reports.receiptsPdf.footer.paymentTotal")}</Text>
                        </View>
                        <View style={{ alignItems: 'center', justifyContent: 'center', height: '50%' }}>
                            <Text style={{ fontSize: 9 }}>{setFormatNumber({ value: data.dataReceiptHeader?.total_amount || 0 }) || ' '} {data.dataReceiptHeader?.currency_description || " "}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </>
    )
}
