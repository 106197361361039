import { Theme } from "@emotion/react";
import { Box, SxProps } from "@mui/material";

interface CreateButtonProps {
  color: number[] | undefined;
  onClick: () => void;
  children: React.ReactNode;
}

const GetCreateButtonStyles = (color: number[] | undefined): SxProps<Theme> | undefined => ({
  padding: '0.5rem 1rem',
  borderRadius: '8px',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  cursor: 'pointer',
  "&:hover": {
    backgroundColor: color
      ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]
      },0.09)`
      : "rgba(0, 0, 0, 0.1)",
  },
});

export const CreateButton = ({
  color,
  onClick,
  children,
}: CreateButtonProps) => {
  return (
    <Box sx={GetCreateButtonStyles(color)} onClick={onClick}>
      {children}
    </Box>
  )
}
