import { AccordionDetails, AccordionSlots, AccordionSummary, Fade, Typography, Accordion } from "@mui/material";
import React, { useState } from "react";
import { TaskCard } from "./taskCard";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useTranslation } from "react-i18next";
import { ProjectTaskDashboard } from "../../../../interfaces/Projects/projectTaskDashboard";

type TaskAccordionProps = {
  title: string;
  tasks: ProjectTaskDashboard[];
  color: number[] | undefined;
  isEdit: boolean
};

export const TaskAccordion = ({ title, tasks, color, isEdit }: TaskAccordionProps) => {

  const [expanded, setExpanded] = useState(false);
  const [t] = useTranslation("global");

  const handleExpansion = () => {
    setExpanded((prevExpanded:boolean) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots['transition'] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={[
          {
            boxShadow: 'none', 
            border: 'none',   
            borderBottom: '1px solid #e0e0e0',
          },
          expanded
            ? {
                '& .MuiAccordion-region': {
                  height: 'auto',
                },
                '& .MuiAccordionDetails-root': {
                  display: 'block',
                },
              }
            : {
                '& .MuiAccordion-region': {
                  height: 0,
                },
                '& .MuiAccordionDetails-root': {
                  display: 'none',
                },
              },
        ]}
        
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon />}
        >
          <Typography fontWeight={"bold"} sx={{marginRight:1}}>{title}</Typography> {tasks.length}
        </AccordionSummary>
        <AccordionDetails>
          {tasks.length > 0 ? (
            tasks.map((task) => (
              <TaskCard key={task.project_task_id} task={task} color={color} isEdit={isEdit}/>
            ))
          ) : (
            <Typography>{t('projecttaskdashboard.messages.no-tasks')}</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
