import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import GenericPromises from "../../../api/GenericPromises";
import { useCallback, useEffect, useState } from "react";
import { GridColDef, GridRenderCellParams, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../interfaces/Security/menu";
import { SalesInvoice } from "../../../interfaces/Sales/Invoices/salesinvoices";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../components/Header";
import { ButtonStyled, PrimaryButton } from "../../../theme/buttons";
import DataTable from "../../../components/Tables/GridTableMaterialUI/DataTable";
import { usePermissions } from "../../../hooks/usePermissions";
import { useFormatNumber } from "../../../hooks/useFormatNumber";
import { Currency } from "../../../interfaces/Commons/currencies";
import { useDof } from "../../Commons/Currencies/helpers/useDof";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { useDates } from "../../../hooks/useDates";
import { DialogSendEmails } from "./helpers/dialogSendEmails";
import EmailIcon from '@mui/icons-material/Email';
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useParamsFilter } from "../../../hooks/useParamsFilter";
import { useBase64 } from "../../../hooks/useBase64";

export const TableSalesInvoices = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { GetRateBySerie } = useDof();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { utf8ToBase64 } = useBase64();
  const invoiceDocumentStatusActiveId = 1;
  const invoiceDocumentStatusDeleteId = 2;
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [salesInvoiceData, setSalesInvoiceData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [resourceSendEmail, setResourceSendEmail] = useState<Menuitem>();
  const [openEmail, setOpenEmail] = useState(false);
  const [dataSalesInvoiceSelected, setDataSalesInvoiceSelected] = useState<GridRowsProp>([]);
  const [dataEmails, setDataEmails] = useState<any>([]);
  const [defaultFilter, setDefaultFilter] = useState<{
    columnfilter: string;
    value: string;
  }[]>([]);
  const [comboEnum, setComboEnum] = useState([
    { enum_id: 1, option_name: t("invoices.comboEnum.all"), route: `/salesinvoices` },
    { enum_id: 2, option_name: t("invoices.comboEnum.onlyactive"), route: `/salesinvoices?filter=${utf8ToBase64(`invoice_doc_status_id=${invoiceDocumentStatusActiveId}`)}` },
    { enum_id: 3, option_name: t("invoices.comboEnum.onlydelete"), route: `/salesinvoices?filter=${utf8ToBase64(`invoice_doc_status_id=${invoiceDocumentStatusDeleteId}`)}` },
  ]);
  const [myEnumValue, setMyEnumValue] = useState(comboEnum[1]);
  const [defaultEnum, setDefaultEnum] = useState(comboEnum[1]);
  const [columns, setColumns] = useState<GridColDef<SalesInvoice>[]>([
    { field: 'sales_invoice_name', headerName: t("salesinvoices.fields.sales_invoice_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'project_name', headerName: t("projects.fields.project_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`project_id=${params.row.project_id}`);
          window.open(`/projects/view?${stringConverted}`, '_blank');
        }}>
          {params.row.project_name}
        </Button>
      }
    },
    { field: 'uuid', headerName: t("salesinvoices.fields.uuid"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'sales_invoice_date', headerName: t("salesinvoices.fields.sales_invoice_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'customer_business_name', headerName: t("salesinvoices.fields.customer_business_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'purchase_order', headerName: t("salesinvoices.fields.purchase_order"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'invoice_doc_status_name', headerName: t("salesinvoices.fields.invoice_doc_status_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'total_amount', headerName: t("salesinvoices.fields.total_amount"), headerClassName: 'header-grid-table', flex: 1, type: 'number', align: "left", headerAlign: "left",
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    { field: 'user_mail_send', headerName: t("salesinvoices.fields.user_mail_send"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'date_mail_send', headerName: t("salesinvoices.fields.date_mail_send"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value)?.toLocaleString();
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-salesinvoices");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-salesinvoices-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          if (element.field === 'sales_invoice_date' || element.field === 'date_mail_send') {
            element.headerName = t(`salesinvoices.fields.${element.field}`);
            element.valueGetter = function (params: GridValueGetterParams<SalesInvoice, any, GridTreeNodeWithRender>) {
              return TimeConverter(params.value);
            };
          }
          else {
            element.headerName = t(`generic.${element.field}`);
          }
        }
        else {
          if ((element.field === 'total_amount') || (element.field === 'total_company_currency_amount')) {
            element.valueGetter = function (params: GridValueGetterParams<SalesInvoice, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'currency_code') { element.headerName = t("currencies.title-view"); }
          else if (element.field === 'total_company_currency_amount') {
            element.headerName = t("companies.configuration.fields.total_company_currency_amount");
            element.valueGetter = function (params: GridValueGetterParams<SalesInvoice, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'customer_business_name') { element.headerName = t(`salesinvoices.fields.customer_business_name`); }
          else if (element.field === 'project_name') {
            element.headerName = t(`projects.fields.project_name`);
            element.renderCell = (params: GridRenderCellParams<SalesInvoice, any, any, GridTreeNodeWithRender>) => {
              return <Button onClick={() => {
                let stringConverted = ConvertParamsToBase64(`project_id=${params.row.project_id}`);
                window.open(`/projects/view?${stringConverted}`, '_blank');
              }}>
                {params.row.project_name}
              </Button>
            }
          }
          else {
            element.headerName = t(`salesinvoices.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

  const onSendEmail = () => {
    let dataEmails: any = [];
    setDataEmails(dataSalesInvoiceSelected.forEach((element: any) => {
      let data = {
        data: element,
        status: (element.customer_email) ? "Waiting" : "Error",
        status_description:
          (element.customer_email) ? "generic.send_email.waiting" :
            "generic.send_email.no_email"
      }
      dataEmails.push(data)
    }));
    setDataEmails(dataEmails);
    setOpenEmail(true);
  }

  const onSelectedRows = (rowsIds: any) => {
    let selectedSalesInvoices = rowsIds.map((element: number) => salesInvoiceData.find((elementFind) => elementFind["sales_invoice_id"] === element));
    setDataSalesInvoiceSelected(selectedSalesInvoices);
  };

  const loadExchangeRateHistory = (currenciesData: Currency[]) => {

    // obtener ID por codigo (USD)
    let myCurrency = currenciesData.find((currency) => currency.currency_code === "USD")
    if (myCurrency !== undefined) {
      // obtener valores del dolar en db con la ultima fecha capturada
      GenericGetResource(`/exchangeratehistory/bycurrencyid/${myCurrency.currency_id}`)
        .then(async (responseRates) => {
          let myRates = responseRates.data.data;
          // obtener cual registro tiene la ultima fecha capturada 
          const orderedRanges = myRates.slice().sort((a: any, b: any) => {
            let first: any = new Date(a.rate_date);
            let second: any = new Date(b.rate_date);
            return second - first
          });
          let myDate = orderedRanges.length > 0 ? new Date(orderedRanges[0].rate_date) : new Date();
          // myDate.setDate(myDate.getDate() + 1);
          // si la ultima fecha no es hoy entonces postea hasta la actual
          if (orderedRanges.length > 0 && new Date().toISOString().split('T')[0] > myDate.toISOString().split('T')[0]) {
            // verificar valor de dolares desde la ultima fecha capurada
            let myResponseDates = await GetRateBySerie("158", orderedRanges[0].rate_date.split('T')[0], new Date().toISOString().split('T')[0]);
            let myNewRows = await myResponseDates.data.ListaIndicadores;
            // si la fecha no es la misma que el primer valor del arreglo entonces postea 
            let d = (myNewRows.at(-1).fecha).split("-");
            if (new Date(d[2] + '/' + d[1] + '/' + d[0]) !== new Date(orderedRanges[0].rate_date)) {
              // Post valores en DB
              for (let i = 1; i < myNewRows.length; i++) {
                const element = myNewRows[i];
                let myDate = element.fecha.split("-");
                let myExchangeRateHistory = {
                  rate_date: new Date(myDate[2] + '/' + myDate[1] + '/' + myDate[0]),
                  rate: element.valor,
                  currency_id: myCurrency?.currency_id,
                }
                GenericPostResource(`/exchangeratehistory`, myExchangeRateHistory);
              }
            }
          }
          // si esta vacio
          else if (orderedRanges.length === 0) {
            // traer valores desde la ultima fecha hacia atrás
            let myResponseDates;
            let myDate = new Date().toISOString().split('T')[0].split('-');
            let myYear = myDate[0];
            let myMonth = myDate[1];
            let myDay = myDate[2];
            do {
              if (myDay.length === 1) { myDay = `0${myDay}` }
              myResponseDates = await GetRateBySerie("158", `${myYear}-${myMonth}-${myDay}`, `${myYear}-${myMonth}-${myDay}`);
              if (parseInt(myDay) === 1) { myDay = '31' }
              else { myDay = (parseInt(myDay) - 1).toString(); }
            }
            while (myResponseDates?.data.ListaIndicadores.lenght === 0)

            let myNewRows = await myResponseDates.data.ListaIndicadores;
            for (let i = 0; i < myNewRows.length; i++) {
              const element = myNewRows[i];
              let myDate = element.fecha.split("-");
              let myExchangeRateHistory = {
                rate_date: new Date(myDate[2] + '/' + myDate[1] + '/' + myDate[0]),
                rate: element.valor,
                currency_id: myCurrency?.currency_id,
              }
              await GenericPostResource(`/exchangeratehistory`, myExchangeRateHistory)
            }
          }
        });
    }
  };

  useEffect(() => {
    setIsLoadingDataTable(false);
    GenericGetResource(myEnumValue.route)
      .then(async (response) => {
        await setSalesInvoiceData(response.data.data);
        setIsLoadingDataTable(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }, [myEnumValue]);

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("salesinvoices.title")}`;
    })

    if (location.state !== null) {
      if (location.state.method) {
        methodUsed();
      }
      else if (location.state.row && location.state.row.sales_invoice_id) {
        navigate("/salesinvoices/view", { state: { row: location.state.row } })
      }
    }

    let myResources = [
      GenericGetResource(comboEnum[1].route),
      GenericGetResource(`/invoicedocumentstatus`)
    ]

    Promise.all(myResources)
      .then(async (responses) => {
        setSalesInvoiceData(responses[0].data.data);

        await GetResourceByUrl(`/salesinvoices/sendemail`)
          .then((response1) => {
            setResourceSendEmail(response1);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });

        await GetResourceByUrl(`/salesinvoices`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            loadColumnsOrder();
            loadPreferences();
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });

        await GenericGetResource(`/currencies`)
          .then(async (responseCurrencies) => {
            await loadExchangeRateHistory(responseCurrencies.data.data);
          });
        setDataLoaded(true);
      }
      ).catch((error) => {
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read && <div className='screen-container'>
        <Header
          title={t("salesinvoices.title")}
          child={
            <div className='d-flex flex-row-reverse my-1'>
              {resourceScreen?.create === true &&
                <PrimaryButton variant='outlined' className="m-1" onClick={() => { navigate("add", { state: { ...location.state } }) }}>{t("generic.buttons.add")}</PrimaryButton>
              }
              {resourceSendEmail?.create === true &&
                <ButtonStyled variant='outlined' className="m-1" sx={{ color: 'primary.main' }} disabled={dataSalesInvoiceSelected.length === 0} onClick={onSendEmail}>{t("generic.buttons.send_email")}<EmailIcon sx={{ marginLeft: 1 }} /></ButtonStyled>
              }
              <Autocomplete
                size="small"
                options={comboEnum}
                defaultValue={defaultEnum}
                getOptionLabel={(option) => option.option_name}
                renderOption={(props, option) => (
                  <div key={option.enum_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.option_name}
                    </Box>
                  </div>
                )}
                sx={{ width: "20%", paddingRight: 1 }}
                isOptionEqualToValue={(option, value) => option.enum_id === value.enum_id}
                onChange={(_, values) => {
                  setMyEnumValue(values ?? { enum_id: 2, option_name: t("purchaseinvoices.comboEnum.onlyactive"), route: `/purchaseinvoices?filter=${utf8ToBase64(`invoice_doc_status_id=${invoiceDocumentStatusActiveId}`)}` },);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("generic.filter")}`}
                    variant="filled"
                  />
                )}
                clearOnEscape={false}
                clearIcon={null}
              />
            </div>
          }
        />
        <div className="d-flex justify-content-center">
          {!isLoadingDataTable && <Spinner />}
          {isLoadingDataTable &&
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={salesInvoiceData}
              entityId={"sales_invoice_id"}
              entity={`SalesInvoices`}
              preferences={myPreferences}
              namePreferences={"grid-salesinvoices"}
              nameOrderColumns={"grid-salesinvoices-columns"}
              selectRows={true}
              countColumns={["total_company_currency_amount"]}
              onRowSelectionModelChange={(rowsIds) => onSelectedRows(rowsIds)}
              initFilter={defaultFilter}
            />
          }
        </div>
        <DialogSendEmails
          open={openEmail}
          setOpen={setOpenEmail}
          data={dataEmails}
          showSnackBar={showSnackBar}
        />
        <SnackbarComponent />
      </div>
      }
    </>
  )
}
