import React, { useEffect, useState, ReactNode } from 'react';
import { Box } from '@mui/material';
import { defaultTheme } from '../../theme';

interface Cube {
  width: number;
  height: number;
  top: string;
  left: string;
  rotate: number;
}

interface RandomCubesBackgroundWrapperProps {
  children: ReactNode;
}

const generateRandomCube = (): Cube => {
  return {
    width: 20,
    height: 20,
    top: Math.random() * 100 + '%',
    left: Math.random() * 100 + '%',
    rotate: Math.random() * 360,
  };
};

const RandomCubesBackgroundWrapper: React.FC<RandomCubesBackgroundWrapperProps> = ({ children }) => {
  const [cubes, setCubes] = useState<Cube[]>([]);

  useEffect(() => {
    const newCubes = Array.from({ length: 200 }, generateRandomCube);
    setCubes(newCubes);
  }, []);

  return (
    <Box sx={{
      paddingInline: 1,
      position: 'relative',
      minWidth: '100%',
      overflow: 'hidden',
      zIndex: 0,
    }}>
      {cubes.map((cube, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            width: `${cube.width}px`,
            height: `${cube.height}px`,
            top: cube.top,
            left: cube.left,
            background: `linear-gradient(${defaultTheme.palette.primary.main}, ${defaultTheme.palette.primary.light})`,
            transform: `rotate(${cube.rotate}deg)`,
            opacity: 0.3,
            borderRadius: '4px',
          }}
        />
      ))}

      <Box sx={{
        position: 'relative',
        zIndex: 1,
      }}>
        {children}
      </Box>
    </Box>
  );
};

export default RandomCubesBackgroundWrapper;
