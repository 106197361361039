import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { UserLogin } from '../../../interfaces/Security/loginInterfaces';
import "./index.css";
import { Box, Button, Container, IconButton, TextField, useTheme } from '@mui/material';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { emailRegex } from '../../../constants/Regex';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2';
import axios from 'axios';
import sxNetCalaApi from '../../../api/sxNetCala';
import { defaultTheme } from '../../../theme/theme';
import { MemoryRounded } from '@mui/icons-material';

export const Login = () => {
    const [t] = useTranslation("global");
    const { signIn } = useContext(AuthContext);
    const theme = useTheme();
    const { GetError } = FormsErrors();
    const [isDisable, setIsDisable] = useState(false);
    const [hiddenPassword, setHiddenPassword] = useState(false);
    const [logoImage, setLogoImage] = useState("");
    const [wallPaperImage, setWallPaperImage] = useState("");

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<UserLogin>({
        defaultValues: {
            email: '',
            password: '',
        }
    });
    const onSubmit: SubmitHandler<UserLogin> = async (data) => {
        setIsDisable(true);
        await signIn(data, toggleDisabled, true).catch((error) => {
            let myMessage = "login.errors.ERR_NETWORK";
            try {
                if (error.response.data) {
                    myMessage = error.response.data;
                }
                else if (error) {
                    myMessage = error;
                }
            } finally {
                Swal.fire({
                    customClass: { container: "swalfire" },
                    title: `${t(myMessage)}`,
                    confirmButtonColor: theme.palette.primary.main,
                    icon: 'error'
                })
            }
        })
    };

    const toggleDisabled = () => setIsDisable(false);

    useEffect(() => {
        sxNetCalaApi.get(`/schemas/default/logo`)
            .then((response) => {
                setLogoImage((prev) => response.data.logo);
            });
        sxNetCalaApi.get(`/schemas/default/wallpaper`)
            .then((response) => {
                setWallPaperImage((prev) => response.data.wallpaper);
            });
    }, []);

    return (
        <div className='login-container w-100'>
            <div
                className="d-flex w-100"
                style={{
                    backgroundImage: `${(wallPaperImage.length > 0) ? `url(${wallPaperImage})` : ''}`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '100vh',
                }}
            >

                <Container className='d-flex flex-column align-items-center justify-content-around w-100'>
                    <div className='header'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box className='box-login p-4 d-flex flex-column align-items-center' height={500} width={400} justifyContent={"space-between"}>

                                {(logoImage.length > 0) ?
                                    <img className="img-fluid" style={{ width: '15rem', height: '15rem' }} src={logoImage} alt="Logo" />
                                    :
                                    <Box className="img-fluid" style={{ width: '15rem', height: '15rem' }} sx={{ border: `1px solid ${theme.palette.primary.main}`, borderRadius: 2 }} />
                                }

                                <Box className='box-login p-4 d-flex flex-column' height={200} justifyContent={"space-between"}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{ required: true, pattern: emailRegex }}
                                        render={({ field }) =>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                ref={field.ref}
                                                value={field.value}
                                                label={t("login.email")}
                                                onChange={field.onChange}
                                                error={!!errors.email}
                                                helperText={GetError(errors.email?.type)}
                                                size="small"
                                                sx={{ width: 350 }}
                                            />
                                        }
                                    />

                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{ required: true, minLength: 6 }}
                                        render={({ field }) =>
                                            <TextField
                                                InputProps={{
                                                    endAdornment: <IconButton children={hiddenPassword ? <VisibilityOffIcon /> : <VisibilityIcon />} onClick={() => { setHiddenPassword((prev) => (!hiddenPassword)) }} />
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                ref={field.ref}
                                                type={`${hiddenPassword ? 'text' : 'password'}`}
                                                value={field.value}
                                                label={t("login.password")}
                                                onChange={field.onChange}
                                                error={!!errors.password}
                                                helperText={GetError(errors.password?.type)}
                                                size="small"
                                            />
                                        }
                                    />

                                    <Button variant="outlined" type="submit" disabled={isDisable} ><strong>{t("login.login")}</strong></Button>
                                </Box>

                            </Box>
                        </form>

                    </div>
                    <Box sx={{ width: "20em", height: "40em" }}></Box>
                </Container>

            </div>
        </div>
    )
}
