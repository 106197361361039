import { useTranslation } from "react-i18next"
import { Header } from "../../components/Header"
import { useEffect } from "react";
import GenericPromises from "../../api/GenericPromises";

export const HomeComponent = () => {
    const [t] = useTranslation("global");
    const { GenericGetResourceGeneric } = GenericPromises();

    useEffect(() => {
        GenericGetResourceGeneric("/companies", "/gcompanies").then((response)=>{
            document.title = `${response.data.data[0].comercial_name} - ${t("home.title")}`;
          })
    }, [])

    return (
        <div className="screen-container">
            <Header title={t("home.title")} />
        </div>
    )
}