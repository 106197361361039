import React from 'react'
import GenericPromises from '../../../../api/GenericPromises'
import axios from 'axios';
import { bmxToken } from '../../../../constants/Currencies';

export const useDof = () => {

  const GetRateBySerie = async (serieId: string, initDate: string, endDate: string): Promise<any> => {
    try {
      let myInitDate = `${initDate.split("-")[2]}-${initDate.split("-")[1]}-${initDate.split("-")[0].substring(2)}`;
      let myEndDate = `${endDate.split("-")[2]}-${endDate.split("-")[1]}-${endDate.split("-")[0].substring(2)}`;
      const response = await axios.get(
        `https://sidofqa.segob.gob.mx/dof/sidof/indicadores/${serieId}/${myInitDate}/${myEndDate}`,
        {
          headers: {
            "Accept": "application/json",
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  return {
    GetRateBySerie
  }

}
