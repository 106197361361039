import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { MeasureUnitConversion } from '../../../interfaces/Commons/measureUnitConversions';
import GenericPromises from '../../../api/GenericPromises';
import { Menuitem } from '../../../interfaces/Security/menu';
import { usePermissions } from '../../../hooks/usePermissions';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../components/Header';
import { ButtonStyled } from '../../../theme/buttons';
import { Button } from '@mui/material';
import { UpdateMeasureUnitConversions } from './update';
import { GenericDialog } from '../../../components/Dialogs/Dialog';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const ViewMeasureUnitConversions = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [measureUnitConversionLocal, setMeasureUnitConversionLocal] = useState<MeasureUnitConversion>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();

  const { GetResourceByUrl } = usePermissions();

  const onDelete = () => {
    GenericDeleteResource(`/measureunitconversions/${measureUnitConversionLocal?.measure_unit_conversion_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/measureunitconversions", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      })
  }


  useEffect(() => {
    if (location.state === null) return navigate("/measureunitconversions", { state: {}, replace: true });
    GenericGetResource(`/measureunitconversions/${location.state.row.measure_unit_conversion_id}`)
      .then(
        (response) => {
          setMeasureUnitConversionLocal(response.data)
          GetResourceByUrl(`/measureunitconversions`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, 'error');
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("measureunitconversions.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="outlined" onClick={() => { navigate("/measureunitconversions", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <div>
                <UpdateMeasureUnitConversions measureUnitConversionPayload={measureUnitConversionLocal} isEdit={resourceScreen?.update} />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("measureunitconversions.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
