import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { AuthContext } from "../../../../../context/AuthContext";
import GenericPromises from "../../../../../api/GenericPromises";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import { Grid } from "@mui/material";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Edit } from "@mui/icons-material";
import { defaultTheme } from "../../../../../theme/theme";
import AddIcon from "@mui/icons-material/Add";
import { TaskButton } from "../../../../../theme/projects/Buttons/taskButton";
import { CreateButton } from "../../../../../theme/projects/Buttons/createButton";
import { PrimaryButton } from "../../../../../theme/buttons";
import { useColors } from "../../../../../theme/hooks/useColors";
import { ProjectTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskTemplate";
import { DialogEntity } from "../../../../../components/Dialogs/DialogEntity";
import { AddProjectTaskTemplate } from "./add";
import { Header } from "../../../../../components/Header";
import { UpdateProjectTaskTemplate } from "./update";
import { TableProjectTaskRemindersTemplate } from "../ProjectTaskRemindersTemplate/table";

type TableProjectTaskTemplateProps={
  project_stage_template_id:number | undefined,
  project_template_id: number | undefined
}

export const TableProjectTaskTemplate = ({ 
  project_stage_template_id,
  project_template_id
 }:TableProjectTaskTemplateProps) => {
  const [t] = useTranslation("global");
  const [dataloaded, setDataLoaded] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [openDialogAddTask, setOpenDialogAddTask] = useState(false);
  const [openDialogUpdateTask, setOpenDialogUpdateTask] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>();
  const [projectTaskTemplateLocal, setProjectTaskTemplateLocal] = useState<ProjectTaskTemplate[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [color, setColor] = useState<number[]>();
  const { HexToRGB } = useColors();
  const { user } = useContext(AuthContext);
  const { GetResourceByUrl } = usePermissions();

  const handleAddTask = (newRow: ProjectTaskTemplate) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date(),
    };

    setProjectTaskTemplateLocal((prevState) => {
      return [...projectTaskTemplateLocal, myRow];
    });
  };

  const handleUpdateTask = (updateRow: ProjectTaskTemplate) => {
    if (projectTaskTemplateLocal.length === 0) {
      return;
    }
    setProjectTaskTemplateLocal((prevRows) => {
      const rowToUpdateIndex = projectTaskTemplateLocal.findIndex(
        (e) =>
          e.project_task_template_id ===
          updateRow.project_task_template_id
      );

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row
      );
    });
  };

  const handleDeleteTask = (deleteRow: number) => {
    if (projectTaskTemplateLocal.length === 0) {
      return;
    }

    setProjectTaskTemplateLocal((prevRows) => {
      const rowToDeleteIndex = projectTaskTemplateLocal.findIndex(
        (e) => e.project_task_template_id === deleteRow
      );

      const updatedData = [
        ...projectTaskTemplateLocal.slice(0, rowToDeleteIndex),
        ...projectTaskTemplateLocal.slice(rowToDeleteIndex + 1),
      ];

      return updatedData;
    });
  };

  useEffect(() => {
    GenericGetResource(
      `/projecttasktemplate/byprojectstagetemplateid/${project_stage_template_id}`
    )
      .then(async (response) => {
        setProjectTaskTemplateLocal(response.data.data);
        setColor(HexToRGB(defaultTheme.palette.primary.main));
        GetResourceByUrl(`/projecttasktemplate`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <div className="d-flex mt-4">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CreateButton color={color} onClick={() => { setOpenDialogAddTask(true); }}>
                <AddIcon /> {t("projecttaskstemplate.actions.create")}
              </CreateButton>
            </Grid>
            {projectTaskTemplateLocal.map((task) => (
              <Grid item key={task.project_task_template_id} xs={12}>
                <TaskButton
                  color={color}
                  onClick={() => {
                    setSelectedTaskId(task.project_task_template_id);
                    setOpenDialogUpdateTask(true);
                  }}
                >
                  {task.project_task_template_name}
                  <PrimaryButton variant="outlined" onClick={(e: any) => {
                    e.stopPropagation();
                    setDrawerOpen(true);
                    setSelectedTaskId(task.project_task_template_id);
                  }}>
                    <Edit />
                  </PrimaryButton>
                </TaskButton>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      <DialogEntity
        content={
          <AddProjectTaskTemplate
            setOpenDialogAddTask={setOpenDialogAddTask}
            project_template_id={project_template_id}
            project_stage_template_id={project_stage_template_id}
            handleAddTask={handleAddTask}
          />
        }
        open={openDialogAddTask}
        setOpen={setOpenDialogAddTask}
        title={
          <Header
            title={t("projecttaskstemplate.title-view")}
            size="sm"
          />
        }
      />

      <DialogEntity
        content={
          <UpdateProjectTaskTemplate
            permissions={resourceScreen}
            setOpenDialogUpdateTask={setOpenDialogUpdateTask}
            handleUpdateTask={handleUpdateTask}
            handleDeleteTask={handleDeleteTask}
            project_template_id={project_template_id}
            projectTaskTemplatePayload={
              projectTaskTemplateLocal?.find(
                (task) => task.project_task_template_id === selectedTaskId
              ) || {}
            }
          />
        }
        open={openDialogUpdateTask}
        setOpen={setOpenDialogUpdateTask}
        title={
          <Header
            title={t("projecttaskstemplate.title-view")}
            size="sm"
          />
        }
      />
      {drawerOpen && selectedTaskId !== undefined && (
        <TableProjectTaskRemindersTemplate
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          projectTaskTemplatePayload={
            projectTaskTemplateLocal.find((task) => task.project_task_template_id === selectedTaskId) || {}
          }
        />
      )}
      <SnackbarComponent />
    </>
  );
};
