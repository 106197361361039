import { useTranslation } from "react-i18next"
import { Autocomplete, Box, TextField } from "@mui/material";
import { Driver } from "../../../../interfaces/Sales/Catalogs/TransportationDocuments/drivers";
import { Transportation } from "../../../../interfaces/Sales/Catalogs/TransportationDocuments/transportations";
import { TransportationDocument } from "../../../../interfaces/Sales/Catalogs/TransportationDocuments/transportationDocument";
import { Control, Controller, FieldErrors, UseFormReset } from "react-hook-form";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { Divider } from "../../../../components/Commons/Divider";

type GeneralProps = {
  isEditIndex: boolean;
  control: Control<TransportationDocument, any>;
  haveDangerousMaterial: boolean;
  errors: FieldErrors<TransportationDocument>;
  reset: UseFormReset<TransportationDocument>;
  comboDrivers: Driver[];
  defaultDriver: Driver | undefined;
  comboTransportations: Transportation[];
  defaultTransportation: Transportation | undefined;
  resourceScreenTransportationDocument: Menuitem | undefined;
}

export const General = ({
  isEditIndex,
  control,
  errors,
  reset,
  haveDangerousMaterial,
  comboDrivers,
  defaultDriver,
  comboTransportations,
  defaultTransportation,
  resourceScreenTransportationDocument,
}: GeneralProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();

  const isEdit = (isEditIndex && !resourceScreenTransportationDocument?.update) ? true : isEditIndex;

  return (
    <>
      <div className='d-flex flex-column'>
        <div className="mt-2 d-flex">
          <div className="w-50">
            <Controller
              name="driver_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  sx={{ width: "100%", paddingRight: 2 }}
                  options={comboDrivers}
                  defaultValue={defaultDriver}
                  getOptionLabel={(option) => option.driver_name ?? ''}
                  renderOption={(props, option: Driver) => (
                    <div key={option.driver_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.driver_name}
                      </Box>
                    </div>
                  )}
                  isOptionEqualToValue={(option, value) => option.driver_id === value.driver_id}
                  onChange={(_, values) => {
                    field.onChange(values?.driver_id || null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("drivers.title-view")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.driver_id}
                      helperText={GetError(errors.driver_id?.type)}
                    />
                  )}
                  disabled={isEdit}
                />
              )}
            />
          </div>
          <div className="w-50">
            <Controller
              name="transportation_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  sx={{ width: "100%" }}
                  options={comboTransportations}
                  getOptionLabel={(option) => `${option.transportation_code} - ${option.plate_number}`}
                  defaultValue={defaultTransportation}
                  renderOption={(props, option: Transportation) => (
                    <div key={option.transportation_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.transportation_code} - {option.plate_number}
                      </Box>
                    </div>
                  )}
                  isOptionEqualToValue={(option, value) => option.transportation_id === value.transportation_id}
                  onChange={(_, values) => {
                    field.onChange(values?.transportation_id || null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("transportations.title-view")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.transportation_id}
                      helperText={GetError(errors.transportation_id?.type)}
                    />
                  )}
                  disabled={isEdit}
                />
              )}
            />
          </div>
        </div>
        {haveDangerousMaterial &&
          <>
            <Divider />
            <div className="d-flex w-100">
              <div className='w-50'>
                <Controller
                  name="environmental_insurance_company"
                  control={control}
                  rules={{ required: haveDangerousMaterial, maxLength: 200 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("transportationdocuments.fields.environmental_insurance_company")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.environmental_insurance_company}
                      helperText={GetError(errors.environmental_insurance_company?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={isEdit}
                    />
                  }
                />
              </div>
              <div className='w-50'>
                <Controller
                  name="environmental_insurance_policy"
                  control={control}
                  rules={{ required: haveDangerousMaterial, maxLength: 50 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("transportationdocuments.fields.environmental_insurance_policy")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.environmental_insurance_policy}
                      helperText={GetError(errors.environmental_insurance_policy?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  }
                />
              </div>
            </div>
          </>
        }
      </div >
    </>
  )
}
