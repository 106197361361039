import { useTranslation } from "react-i18next";
import GenericPromises from "../../../api/GenericPromises";
import { useEffect, useState } from "react";
import { Country } from "../../../interfaces/Commons/Locations/countries";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { SubmitHandler, useForm } from "react-hook-form";
import { State } from "../../../interfaces/Commons/Locations/states";
import { City } from "../../../interfaces/Commons/Locations/cities";
import { SupplierLocation } from "../../../interfaces/Purchases/Catalogs/suppliers";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Box } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../theme/buttons";
import { AddGeneral } from "./addGeneral";
import { TaxRegime } from "../../../interfaces/Sales/Catalogs/taxRegimes";


export const AddSupplierLocations = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource, GenericPutResource } = GenericPromises();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboCountries, setComboCountries] = useState<Country[]>([]);
  const [comboStates, setComboStates] = useState<State[]>([]);
  const [comboCities, setComboCities] = useState<City[]>([]);
  const [comboTaxRegimes, setComboTaxRegimes] = useState<TaxRegime[]>([]);
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    trigger,
    setValue
  } = useForm<SupplierLocation>({
    defaultValues: {
      country_id: undefined,
      state_id: undefined,
      city_id: undefined,
      postal_code: undefined,
      street: "",
      contact_name: undefined,
      business_name: undefined,
      supplier_rfc: undefined,
      supplier_email: undefined,
      supplier_phone_number: undefined,
      supplier_web: undefined,
      fiscal_address: false,
      supply_address: false,
      default_supply_address: false,
    }
  });
  const onSubmit: SubmitHandler<SupplierLocation> = (data) => onPost(data);

  const onPost = (data: SupplierLocation) => {
    let myData = {
      country_id: data.country_id,
      state_id: data.state_id,
      city_id: data.city_id,
      postal_code: data.postal_code ?? null,
      street: data.street ?? null,
      contact_name: data.contact_name ?? null,
      business_name: data.business_name?.trim(),
      supplier_rfc: data.supplier_rfc?.trim(),
      supplier_email: data.supplier_email ?? null,
      supplier_phone_number: data.supplier_phone_number ?? null,
      supplier_web: data.supplier_web ?? null,
      fiscal_address: data.fiscal_address ?? false,
      supply_address: data.supply_address ?? false,
      default_supply_address: data.default_supply_address ?? false,
      supplier_id: props.supplier_id ?? null,
      tax_regime_id: data.tax_regime_id ?? null,
    }
    setLoadingPost(true);
    if (!props.add) {
      GenericPostResource("/supplierlocations", myData)
        .then((response) => {
          GenericGetResource(`/supplierlocations/bysupplierid/${response.data.supplier_id}`)
            .then((response1) => {
              if (data.fiscal_address === true) {
                for (let i = 0; i < response1.data.data.length; i++) {
                  if (response.data.supplier_location_id !== (response1.data.data[i]).supplier_location_id) {
                    let suppliers = {
                      "fiscal_address": false
                    };
                    GenericPutResource(`/supplierlocations/${(response1.data.data[i]).supplier_location_id}`, suppliers);
                    let updateSuppliers = {
                      ...response1.data.data[i],
                      supplier_location_id: (response1.data.data[i]).supplier_location_id,
                      "fiscal_address": false
                    };
                    props.UpdateRow(updateSuppliers);
                  }
                }
                if (props.supplier_id && props.setValue) {
                  let supplierUpdate = {
                    business_name: data.business_name?.trim(),
                    supplier_rfc: data.supplier_rfc?.trim(),
                    supplier_email: data.supplier_email ?? null,
                    supplier_phone_number: data.supplier_phone_number ?? null,
                    supplier_web: data.supplier_web ?? null,
                    tax_regime_id: data.tax_regime_id ?? null,
                  }
                  GenericPutResource(`/suppliers/${props.supplier_id}`, supplierUpdate).then(() => {
                    let myTaxRegime = comboTaxRegimes.find((item) => item.tax_regime_id === data.tax_regime_id);
                    props.setValue("business_name", data.business_name?.trim());
                    props.setValue("supplier_rfc", data.supplier_rfc?.trim());
                    props.setValue("supplier_email", data.supplier_email);
                    props.setValue("supplier_phone_number", data.supplier_phone_number);
                    props.setValue("supplier_web", data.supplier_web);
                    props.setValue("tax_regime_id", data.tax_regime_id);
                    props.setValue("tax_regime", `${myTaxRegime?.tax_regime_code} - ${myTaxRegime?.description}`);
                  })
                }
              }
            }).catch((error) => {
              setLoadingPost(false);
              showSnackBar(error.message, "error");
            });
          let myNewData: SupplierLocation = response.data;
          myNewData.country_name = comboCountries.find((country: Country) => country.country_id === response.data.country_id)?.country_name;
          myNewData.state_name = comboStates.find((state: State) => state.state_id === response.data.state_id)?.state_name;
          myNewData.city_name = comboCities.find((city: City) => city.city_id === response.data.city_id)?.city_name;
          props.AddNewRow(myNewData);
          props.setOpenDialog(false);
        })
        .catch((error) => {
          setLoadingPost(false);
          showSnackBar(error.message, 'error');
        });
    }
    else {
      if (myData.fiscal_address === true) {
        for (let i = 0; i < props.supplierLocationsData.length; i++) {
          if (props.supplierLocationsData[i].fiscal_address == true) {
            props.supplierLocationsData[i].fiscal_address = false;
            props.UpdateRow(props.supplierLocationsData[i]);
          }
        }
        if (props.setValue) {
          let myTaxRegime = comboTaxRegimes.find((item) => item.tax_regime_id === data.tax_regime_id);
          props.setValue("business_name", data.business_name?.trim());
          props.setValue("supplier_rfc", data.supplier_rfc?.trim());
          props.setValue("supplier_email", data.supplier_email);
          props.setValue("supplier_phone_number", data.supplier_phone_number);
          props.setValue("supplier_web", data.supplier_web);
          props.setValue("tax_regime_id", data.tax_regime_id);
          props.setValue("tax_regime", `${myTaxRegime?.tax_regime_code} - ${myTaxRegime?.description}`);
        }
      }
      let myNewData = {
        country_id: myData.country_id,
        state_id: myData.state_id,
        city_id: myData.city_id,
        postal_code: myData.postal_code ?? undefined,
        street: myData.street ?? undefined,
        contact_name: myData.contact_name ?? undefined,
        business_name: myData.business_name?.trim(),
        supplier_rfc: myData.supplier_rfc?.trim(),
        supplier_email: myData.supplier_email ?? undefined,
        supplier_phone_number: myData.supplier_phone_number ?? undefined,
        supplier_web: myData.supplier_web ?? undefined,
        fiscal_address: myData.fiscal_address ?? false,
        supply_address: myData.supply_address ?? false,
        default_supply_address: myData.default_supply_address ?? false,
        supplier_id: undefined,
        supplier_location_id: props.numTemp,
        country_name: comboCountries.find((country: Country) => country.country_id === myData.country_id)?.country_name,
        state_name: comboStates.find((state: State) => state.state_id === myData.state_id)?.state_name,
        city_name: comboCities.find((city: City) => city.city_id === myData.city_id)?.city_name,
        tax_regime_id: data.tax_regime_id ?? undefined,
      };
      props.AddNewRow(myNewData);
      props.numTemp++;
      props.setOpenDialog(false);
    }
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  const getCities = async (stateId: number) => {
    await GenericGetResource(`/cities/bystateid/${stateId}`)
      .then((response) => {
        setComboCities(response.data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setDataLoaded(true);
      });

  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/countries"),
      GenericGetResource("/states"),
      GenericGetResource("/taxregimes"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboCountries(responses[0].data.data);
        setComboStates(responses[1].data.data);
        setComboTaxRegimes(responses[2].data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setDataLoaded(true);
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

            <AddGeneral 
              setValue={setValue} 
              control={control} 
              errors={errors} 
              comboCountries={comboCountries} 
              comboStates={comboStates} 
              comboCities={comboCities} 
              getCities={getCities} 
              comboTaxRegimes={comboTaxRegimes}
            />

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton onClick={() => {
                trigger().then((response) => response && onSubmit(getValues()));
              }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <SnackbarComponent />
    </>
  )
}
