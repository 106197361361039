import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import GenericPromises from "../../../../api/GenericPromises";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { Button } from "@mui/material";
import { usePermissions } from "../../../../hooks/usePermissions";
import { UpdatePaymentConditions } from "./update";
import { PaymentCondition } from "../../../../interfaces/Sales/Catalogs/paymentConditions";
import { GenericDialog } from "../../../../components/Dialogs/Dialog";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";

export const ViewPaymentConditions = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentConditionLocal, setPaymentConditionLocal] = useState<PaymentCondition>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();

  const { GetResourceByUrl } = usePermissions();
  const [unauthorized, setUnauthorized] = useState(true);
  const {showSnackBar,SnackbarComponent}= useSnackBar();


  

  const onDelete = () => {
    GenericDeleteResource(`/paymentconditions/${paymentConditionLocal?.payment_condition_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/paymentconditions", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message,"error")
        setOpenDialog(false);
      })
  }

  

  useEffect(() => {
    if (location.state === null) return navigate("/paymentconditions", { state: {}, replace: true });
    GenericGetResource(`/paymentconditions/${location.state.row.payment_condition_id}`)
        .then(
            (response) => {
              setPaymentConditionLocal(response.data);

                GetResourceByUrl(`/paymentconditions`)
                    .then((response1) => {
                        setResourceScreen((prev) => response1);
                        setDataLoaded(true);
                    })
                    .catch((error) => {
                        showSnackBar(error.message,"error")
                        setUnauthorized(false);
                    });
            }
        ).catch((error) => {
            showSnackBar(error.message,"error")
            setUnauthorized(false);
        });
}, []);

  return (
    <>
    {!dataloaded && <Spinner />}
    {dataloaded &&
      <>
        <Header title={t("paymentconditions.title-view")} />
        {resourceScreen?.read &&
          <>
            <div className='d-flex flex-row-reverse m-1'>
              {resourceScreen?.delete === true &&
                <Button variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</Button>
              }
              <Button variant="outlined" onClick={() => { navigate("/paymentconditions", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
            </div>
            <div>
              <UpdatePaymentConditions paymentConditionLocal={paymentConditionLocal} isEdit={resourceScreen?.update} />
            </div>
          </>
        }
      </>
    }
    <GenericDialog
      title={t("generic.dialog.delete")}
      content={t("paymentconditions.dialog.delete")}
      open={openDialog}
      setOpen={setOpenDialog}
      onAcept={onDelete}
    />
    <SnackbarComponent/>
  </>
  )
}