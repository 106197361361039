import React from 'react'

export const useValidateCentinelValue = () => {

  const HasValidateResponse = (response: any) => {
    if (isNaN(response)) {
      return false;
    }

    switch (response) {
      case undefined:
      case null:
        return false;
      default:
        return true;
    }
  }

  const HasCentinelValue = (proyectExtraFieldName: string) => {
    switch (proyectExtraFieldName) {
      case "ALMACENAJES":
      case "DEMORAS GENERADAS":
        return true;
    }
    return false;
  }

  const GetNameParamToReplace = (proyectExtraFieldName: string) => {
    switch (proyectExtraFieldName) {
      case "ALMACENAJES":
        return "DESADUANADO";
      case "DEMORAS GENERADAS":
        return "INGRESÓ VACÍO";
    }
    return undefined;
  }

  return {
    HasValidateResponse,
    HasCentinelValue,
    GetNameParamToReplace,
  };
}
