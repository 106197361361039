import { Route, Routes } from "react-router-dom"
import { ViewProjectDetails } from "./view"


export const BrowserProjectDetails = () =>{
    return (
        <Routes>
            <Route path={"/view"} element={<ViewProjectDetails/>}/>
        </Routes>
      )
}