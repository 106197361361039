import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { deliverAndPaymentInfoStyles } from "./deliverAndPaymentInfoStyles";

const { billingDataTo, deliverAndPaymentInfo } = deliverAndPaymentInfoStyles();

const DeliverAndPayment = ({ data, showTranslatedText }: any) => {
  return (
    <View style={deliverAndPaymentInfo.secondSubContainer}>
      <View style={deliverAndPaymentInfo.dataSubContainerConcepts}>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.concept}>
            {showTranslatedText(
              "reports.salesInvoicePdf.shippingData.customerPO"
            )}
            :
          </Text>
        </View>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.concept}>
            {showTranslatedText("reports.salesInvoicePdf.shippingData.shipVia")}
            :
          </Text>
        </View>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.concept}>
            {showTranslatedText("reports.salesInvoicePdf.shippingData.dueDate")}
            :
          </Text>
        </View>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.concept}>
            {showTranslatedText("reports.salesInvoicePdf.paymentData.CFDIUse")}:
          </Text>
        </View>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.concept}>
            {showTranslatedText(
              "reports.salesInvoicePdf.paymentData.methodPayment"
            )}
            :
          </Text>
        </View>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.concept}>
            {showTranslatedText(
              "reports.salesInvoicePdf.paymentData.paymentConditions"
            )}
            :
          </Text>
        </View>
        <View style={{paddingLeft:2}} >
          <Text style={billingDataTo.concept}>
            {showTranslatedText(
              "reports.salesInvoicePdf.paymentData.paymentType"
            )}
            :
          </Text>
        </View>
      </View>

      <View style={deliverAndPaymentInfo.dataSubContainerData}>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.data}>
            {data.dataInvoiceGeneral.purchase_order || " "}
          </Text>
        </View>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.data}>
            {data.dataShippingTracking.track_id || " "}
          </Text>
        </View>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.data}>
            {data.dataInvoiceGeneral.payment_due_date || " "}
          </Text>
        </View>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.data}>
            {data.dataInvoiceGeneral.cfdi_code || ""}{" "}
            {data.dataInvoiceGeneral.cfdi_description || " "}
          </Text>
        </View>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.data}>
            {data.dataInvoiceGeneral.payment_mode_code || ""} -{" "}
            {data.dataInvoiceGeneral.payment_mode_description || " "}
          </Text>
        </View>
        <View style={deliverAndPaymentInfo.field}>
          <Text style={billingDataTo.data}>
            {data.dataInvoiceGeneral.payment_condition_name}
          </Text>
        </View>
        <View style={{paddingLeft:2}}>
          <Text style={billingDataTo.data}>
            {data.dataInvoiceGeneral.payment_method_code || ""} -{" "}
            {data.dataInvoiceGeneral.payment_method_name || " "}
          </Text>
        </View>
      </View>
    </View>
  );
};

const CustomerData = ({ data, showTranslatedText }: any) => {
  return (
    <View style={billingDataTo.firstSubContainer}>
      <View style={billingDataTo.firstContainer}>
        <Text style={billingDataTo.data}>
          {data.dataInvoiceGeneral.customer_business_name || " "}
        </Text>
        <Text style={billingDataTo.data}>
          {data.dataInvoiceGeneral.customer_street_name || " "}
        </Text>
        <Text style={billingDataTo.data}>
          {data.dataInvoiceGeneral.customer_city_name || " "}
        </Text>
        <Text style={billingDataTo.data}>
          {data.dataInvoiceGeneral.customer_state_name || " "}{" "}
          {data.dataInvoiceGeneral.customer_postal_code || " "},{" "}
          {data.dataInvoiceGeneral.customer_country_name || " "}
        </Text>
      </View>
      <View style={billingDataTo.secondContainer}>
        <View style={billingDataTo.field}>
          <View style={{ width: "50%", paddingBottom: 5 }}>
            <Text style={billingDataTo.concept}>
              {showTranslatedText("reports.salesInvoicePdf.header.taxId")}:
            </Text>
          </View>
          <Text style={billingDataTo.data}>
            {data.dataInvoiceGeneral.customer_rfc || " "}
          </Text>
        </View>
        <View style={billingDataTo.field}>
          <Text style={billingDataTo.concept}>
            {data.dataInvoiceGeneral.customer_tax_regime_code || " "}:{" "}
          </Text>
          <Text style={billingDataTo.data}>
            {data.dataInvoiceGeneral.customer_tax_regime_description || " "}
          </Text>
        </View>
        <View style={billingDataTo.field}>
          <Text style={billingDataTo.concept}>
            {showTranslatedText("reports.salesInvoicePdf.customerData.email")}:
          </Text>
          <Text style={billingDataTo.data}>
            {data.dataInvoiceGeneral.customer_email || " "}
          </Text>
        </View>
        <View style={billingDataTo.field}>
          <Text style={billingDataTo.concept}>
            {showTranslatedText(
              "reports.salesInvoicePdf.customerData.telephone"
            )}
            :
          </Text>
          <Text style={billingDataTo.data}>
            {data.dataInvoiceGeneral.customer_phone_number || " "}
          </Text>
        </View>
      </View>
    </View>
  );
};

export const DeliverAndPaymentInfo = ({ data, showTranslatedText }: any) => {
  return (
    <View fixed style={billingDataTo.container}>
      <View style={billingDataTo.titleContainer}>
        <Text style={billingDataTo.title}>
          {showTranslatedText("reports.salesInvoicePdf.customerData.billTo")}:
        </Text>
      </View>
      <View style={{ flexDirection: "row", height:'100%' }}>
        <CustomerData data={data} showTranslatedText={showTranslatedText} />
        <DeliverAndPayment
          data={data}
          showTranslatedText={showTranslatedText}
        />
      </View>
    </View>
  );
};
