
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { Menuitem } from '../../../../interfaces/Security/menu';
import { usePermissions } from '../../../../hooks/usePermissions';
import { CustomKeyCode } from '../../../../interfaces/Sales/Catalogs/customsKeyCodes';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../../components/Header';
import { ButtonStyled } from '../../../../theme/buttons';
import { Button } from '@mui/material';
import { UpdateCustomsKeyCodes } from './update';
import { GenericDialog } from '../../../../components/Dialogs/Dialog';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const ViewCustomsKeyCodes = () => {

  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [customsKeyCodesLocal, setCustomsKeyCodesLocal] = useState<CustomKeyCode>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GetResourceByUrl } = usePermissions();
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const onDelete = () => {
    GenericDeleteResource(`/customskeycodes/${customsKeyCodesLocal?.customs_key_code_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/customskeycodes", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    if (location.state === null) return navigate("/customskeycodes", { state: {}, replace: true });
    GenericGetResource(`/customskeycodes/${location.state.row.customs_key_code_id}`)
      .then(
        (response) => {
          setCustomsKeyCodesLocal(response.data);

          GetResourceByUrl(`/customskeycodes`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, "error");
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("customskeycodes.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="outlined" onClick={() => { navigate("/customskeycodes", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <div>
                <UpdateCustomsKeyCodes customsKeyCodesPayload={customsKeyCodesLocal} isEdit={resourceScreen?.update} />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("customskeycodes.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
