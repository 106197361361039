import { CheckCircle, CircleOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ProjectTask } from "../../../../../../interfaces/Projects/Catalogs/projectTask";
import GenericPromises from "../../../../../../api/GenericPromises";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import { useTranslation } from "react-i18next";
import { TaskState } from "../../../../../../interfaces/Projects/Catalogs/taskStates";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";
import { useDates } from "../../../../../../hooks/useDates";
import { DialogEntity } from "../../../../../../components/Dialogs/DialogEntity";
import { Header } from "../../../../../../components/Header";
import { ConfirmDialog } from "../../../../ProjectTaskDashboard/components/dialogs/confirmDialog";

interface TaskItemProps {
  task: ProjectTask;
  color: number[] | undefined;
  setSelectedTaskId: (id: number) => void;
  setOpenDialogUpdateTask: (open: boolean) => void;
  isEdit:boolean
}

export const TaskItem = ({
  task,
  color,
  setSelectedTaskId,
  setOpenDialogUpdateTask,
  isEdit
}: TaskItemProps) => {
  const [t] = useTranslation("global");
  const [onChecked, setOnChecked] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const [taskStates, setTaskStates] = useState<TaskState[]>([]);
  const [dataloaded, setDataLoaded] = useState(false);
  const { TimeConverter } = useDates();
  const [lastUpdateUser, setLastUpdateUser] = useState<string | null>(null);
  const [lastUpdateDate, setLastUpdateDate] = useState<string | null>(null);
  const [dialogConfirmUncheckTask, setDialogConfirmUncheckTask] = useState(false);

  const onPut = (currentTaskStateId: number) => {
    const myData = {
      // project_stage_id: task?.project_stage_id,
      // project_group_id: projectTask?.project_group_id,
      current_task_state_id: currentTaskStateId,
      // MS_task_id: projectTask?.MS_task_id ?? null,
      // MS_due_date_time: projectTask?.MS_due_date_time ?? null,
      // MS_start_date_time: projectTask?.MS_start_date_time ?? null,
      // MS_priority: projectTask?.MS_priority ?? null,
      // MS_applied_categories: projectTask?.MS_applied_categories ?? null,
      // MS_description: projectTask?.MS_description ?? null,
      // MS_show_in_planner: projectTask?.MS_show_in_planner ?? null,
      // project_task_name: task.project_task_name,
      // project_task_description: projectTask?.project_task_description ?? null,
      // project_task_started_at: projectTask?.project_task_started_at ?? null,
      // project_task_due_date:projectTask?.project_task_due_date ?? null,
      // project_task_notes: projectTask?.project_task_notes ?? null,
      // field_type_id:projectTask?.field_type_id
    };
    setLoadingPut(true);
    GenericPutResource(`/projecttask/${task.project_task_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPut(false);
      })
      .catch((error) => {
        setOnChecked((prevChecked) => !prevChecked);
        setLoadingPut(false);
        showSnackBar(t(error.response.data), "error");
        return;
      });
  };

  const fetchLastTaskState = async () => {
    let projectTaskIdBase64 = btoa(`project_task_id=${task.project_task_id}`);
    await GenericGetResource(`/projecttaskstates?filter=${projectTaskIdBase64}`)
      .then((response) => {
        const allStates = response.data.data;

        const lastState = allStates.reduce(
          (prev: any, current: any) =>
            current.project_task_state_id > prev.project_task_state_id
              ? current
              : prev,
          allStates[0]
        );
        setLastUpdateUser(lastState.last_update_user);
        setLastUpdateDate(lastState.last_update_date);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  };

  const handleTaskCompletion = () => {
    const doneState = taskStates.find(
      (state) => state.task_state_name === "DONE"
    );
    const pendingState = taskStates.find(
      (state) => state.task_state_name === "PENDING"
    );

    if (onChecked && pendingState?.task_state_id) {
      onPut(pendingState.task_state_id);
    } else if (!onChecked && doneState?.task_state_id) {
      onPut(doneState.task_state_id);
      fetchLastTaskState();
    }
    setOnChecked((prevChecked) => !prevChecked);
  };

  useEffect(() => {
    GenericGetResource(`/taskstates`)
      .then(async (response) => {
        setTaskStates(response.data.data);
        const currentState = response.data.data.find(
          (state: TaskState) =>
            state.task_state_id === task.current_task_state_id
        );

        if (currentState && currentState.task_state_name === "DONE") {
          setOnChecked((prevChecked) => true);
          fetchLastTaskState();
        } else {
          setOnChecked((prevChecked) => false);
        }
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && (
        <div className="w-100 my-2">
          <Spinner isBox={false} />
        </div>
      )}
      {dataloaded && (
        <Grid item key={task.project_task_id} xs={12}>
          <Box
            sx={{
              borderRadius: "8px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: color
                ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.2)`
                : "rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: color
                  ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.09)`
                  : "rgba(0, 0, 0, 0.1)",
              },
              display: "flex",
              alignItems: "center",
              padding: "8px",
            }}
            onClick={() => {
              if (task.project_task_id) {
                setSelectedTaskId(task.project_task_id);
                setOpenDialogUpdateTask(true);
              }
            }}
          >
            <FormControlLabel
              sx={{ marginLeft: 0.1 }}
              control={
                <Checkbox
                  checked={onChecked}
                  onChange={(e) => {
                    e.stopPropagation();
                    if(onChecked){
                      setOpenDialogUpdateTask(false);
                      setDialogConfirmUncheckTask(true);
                      return
                    }
                    setOpenDialogUpdateTask(false);
                    handleTaskCompletion();
                  }}
                  icon={<CircleOutlined />}
                  checkedIcon={<CheckCircle />}
                  sx={{
                    color: "primary.main",
                    "&:hover": {
                      color: "primary.main",
                    },
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                  }}
                />
              }
              label={<></>}
              disabled={loadingPut}
            />
            <div>
              <span
                style={{
                  textDecoration: onChecked ? "line-through" : "none",
                  color: onChecked ? "gray" : "inherit",
                }}
              >
                {task.project_task_name}
              </span>
              {onChecked && lastUpdateUser && lastUpdateDate && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  {`${TimeConverter(task.project_task_started_at?.toString())}`}{" "}
                  <br />
                  Fecha de vencimiento{`${TimeConverter(
                    task.project_task_due_date?.toString()
                  )}`}{" "}
                  <br />
                  Ultima modificación: {`${TimeConverter(lastUpdateDate.toLocaleString())}`} 
                  <br />
                  {`${lastUpdateUser}`}
                </Typography>
              )}
            </div>
          </Box>
        </Grid>
      )}
      <DialogEntity
        open={dialogConfirmUncheckTask}
        title={<Header title={t("projecttasks.title-view")} />}
        maxWidth={"sm"}
        fullWidth
        content={
          <ConfirmDialog
            projectTaskName={task.project_task_name}
            isEdit={isEdit}
            loadingPut={loadingPut}
            setDialogConfirmUncheckTask={setDialogConfirmUncheckTask}
            handleTaskCompletion={handleTaskCompletion}
          />
        }
      />
      <SnackbarComponent />
    </>
  );
};
