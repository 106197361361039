import { StyleSheet } from "@react-pdf/renderer";

export const secondSectionStyles = () => {
  const secondSection = StyleSheet.create({
    container: {
      flexDirection: "column",
      gap: 5,
    },

    titleContainer: {
      width: "90%",
      alignSelf: "center",
    },

    title: {
      fontSize: 8,
      fontWeight: 700,
    },

    conceptsContainer: {
      width: "90%",
      alignSelf: "center",
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: "center",
    },

    conceptContent: {
      borderRightWidth: 1,
      width: "11%",
      fontSize: 7,
      fontWeight: 700,
      textAlign: "center",
    },

  });

  return {
    secondSection,
  };
};
