import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import TabsProjectTaskDashboard from "./tabsDetails";
import { TableProjectTaskAccordions } from "./ProjectTaskAccordions/table";
import { TableProjectTaskList } from "./ProjectTaskList/table";
import { defaultTheme } from "../../../theme/theme";
import { useColors } from "../../../theme/hooks/useColors";
import GenericPromises from "../../../api/GenericPromises";
import { usePermissions } from "../../../hooks/usePermissions";
import { Menuitem } from "../../../interfaces/Security/menu";
import { ProjectTask } from "../../../interfaces/Projects/Catalogs/projectTasks";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../components/Header";
import { ProjectTaskDashboard } from "../../../interfaces/Projects/projectTaskDashboard";

export const ViewProjectTaskDashboard = () => {
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [projectTasksLocal, setProjectTasksLocal] = useState<ProjectTaskDashboard[]>([]);
  const [dataloaded, setDataLoaded] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [color, setColor] = useState<number[]>();
  const { HexToRGB } = useColors();

  useEffect(() => {
    GenericGetResource(`/projecttask`)
      .then(async (response) => {
        setProjectTasksLocal(response.data.data);
        setColor(HexToRGB(defaultTheme.palette.primary.main));
        GetResourceByUrl(`/projecttask`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <>
          <Header title={t("projecttaskdashboard.title")} />
          <div className="my-4" />
          <TabsProjectTaskDashboard
            planned={
              <TableProjectTaskAccordions
                projectTasksLocal={projectTasksLocal}
                color={color}
                isEdit={!resourceScreen.update}
              />
            }
            assignedToMe={
              <TableProjectTaskList color={color} isEdit={!resourceScreen.update}/>
            }
          />
        </>
      )}
      <SnackbarComponent />
    </>
  );
};
