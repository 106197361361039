import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Project } from "../../../interfaces/Projects/projects";
import GenericPromises from "../../../api/GenericPromises";
import { Menuitem } from "../../../interfaces/Security/menu";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { usePermissions } from "../../../hooks/usePermissions";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../components/Header";
import { ButtonStyled } from "../../../theme/buttons";
import {
  Box,
  Button,
  Grid,
  ListItemButton,
  Popover,
  Switch,
  Typography,
} from "@mui/material";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { UpdateProject } from "./update";
import TabsProjectsDetails from "./tabsDetails";
import { TableProjectExtraFields } from "../Catalogs/ProjectExtraFields/table";
import { TableProjectGroups } from "../Catalogs/ProjectGroups/table";
import { ProjectRelatedInvoices } from "../Catalogs/ProjectRelatedInvoices";
import { GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { ProjectExtraField } from "../../../interfaces/Projects/Catalogs/projectExtraFields";
import { useParamsFilter } from "../../../hooks/useParamsFilter";
import { SalesInvoice } from "../../../interfaces/Sales/Invoices/salesinvoices";
import { PurchaseInvoice } from '../../../interfaces/Purchases/Invoices/purchaseInvoices';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { TabProjectDocuments } from "../Catalogs/ProjectDocuments/table";
import FunctionsIcon from '@mui/icons-material/Functions';
import { useCalculateFormulasProjects } from "./components/helpers/hookCalculateFormulas/hookCalculateFormulas";
import { Item } from '../../../interfaces/Commons/items';
import { Parameter } from "../../../interfaces/Commons/parameters";
import { useFormulaEvaluator } from "../../../hooks/useFormulaEvaluator";
import { DialogEntity } from "../../../components/Dialogs/DialogEntity";
import { useBase64 } from "../../../hooks/useBase64";


interface UpdateProjectHandle {
  onCloseProject?: () => void;
}

export const ViewProjects = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const { GetResourceByUrl } = usePermissions();
  const { utf8ToBase64 } = useBase64();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetParamsFromBase64 } = useParamsFilter();
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { fetchParameters } = useFormulaEvaluator();
  const { CheckAndValidateFormulasPerProject } = useCalculateFormulasProjects();
  const invoiceDocumentStatusActiveId = 1;
  const [projectLocal, setProjectLocal] = useState<Project>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const [isClosedProject, setIsClosedProject] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const [isUpdateProjectExtraFields, setIsUpdateProjectExtraFields] = useState(true);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceProjectExtraFields, setResourceProjectExtraFields] = useState<Menuitem>();
  const [resourceClosedProject, setResourceClosedProject] = useState<Menuitem>();
  const [resourceCalculateCost, setResourceCalculateCost] = useState<Menuitem>();
  const [purchaseInvoicesData, setPurchaseInvoicesData] = useState<GridRowsProp>([]);
  const [salesInvoicesData, setSalesInvoicesData] = useState<GridRowsProp>([]);
  const [projectExtraFieldsData, setProjectExtraFieldsData] = useState<ProjectExtraField[]>([]);
  const [totalSales, setTotalSales] = useState(0);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [projectUserClosed, setProjectUserClosed] = useState<String | undefined>();
  const [projectUserDateClosed, setProjectUserDateClosed] = useState<Date | undefined>();
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;
  const UpdateProjectRef = useRef<UpdateProjectHandle>(null);
  const [hasPurchaseOrSalesInvoices, setHasPurchaseOrSalesInvoices] = useState(false);
  const [dialogConfirmCloseProject, setDialogConfirmCloseProject] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState<{
    columnfilter: string;
    value: string;
  }[]>([])

  const onDelete = async () => {
    let canDelete = true;
    let myPromises = [
      GenericGetResource(
        `/purchaseinvoices/byprojectid/${projectLocal?.project_id}`
      ),
      GenericGetResource(
        `/salesinvoices/byprojectid/${projectLocal?.project_id}`
      ),
      GenericGetResource(
        `/projectdocuments/byprojectid/${projectLocal?.project_id}`
      ),
    ];
    await Promise.all(myPromises)
      .then((responses) => {
        if (responses[0].data.data.length > 0) {
          showSnackBar(`${t("projects.info.there_are_purchase_invoices")}`, 'warning');
          canDelete = false;
        }
        if (responses[1].data.data.length > 0) {
          showSnackBar(`${t("projects.info.there_are_payment_invoices")}`, 'warning');
          canDelete = false;
        }
        if (responses[2].data.data.length > 0) {
          showSnackBar(`${t("projects.info.there_are_documents")}`, 'warning');
          canDelete = false;
        }
      }).catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });

    if (!canDelete) {
      setOpenDialog(false);
      return;
    }

    GenericDeleteResource(`/projects/${projectLocal?.project_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/projects", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const ValidateResourceCalculateFormulas = async () => {
    GetResourceByUrl(`/projectextrafields/updateprojectextrafields/byprojectid`)
      .then((responsePermissions: Menuitem) => {
        if (responsePermissions.update === true) {
          onCalculateFormulas();
        }
        else {
          showSnackBar(t("generic.snackbar.unauthorize"), "error");
        }
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }

  const CalculateTotals = (
    myPurchaseInvoices: SalesInvoice[] | readonly GridValidRowModel[],
    mySalesInvoices: PurchaseInvoice[] | readonly GridValidRowModel[]
  ) => {
    let mySalesTotals = 0;
    let myPurchasesTotals = 0;

    for (const item of mySalesInvoices) {
      mySalesTotals += (item.exchange_rate ?? 0) * (item.total_amount ?? 0);
    }

    for (const item of myPurchaseInvoices) {
      myPurchasesTotals += (item.exchange_rate ?? 0) * (item.total_amount ?? 0);
    }

    setTotalPurchases((prev) => myPurchasesTotals);
    setTotalSales((prev) => mySalesTotals);
    setTotalAmount((prev) => mySalesTotals - myPurchasesTotals);
  };

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const loadPurchaseIncvoices = async () => {
    await GenericGetResource(`/purchaseinvoices/byprojectid/${projectLocal?.project_id ?? 0}`)
      .then((responsePurchaseInvoices) => {
        setPurchaseInvoicesData(responsePurchaseInvoices.data.data);
        CalculateTotals(responsePurchaseInvoices.data.data, salesInvoicesData);
      });
  }

  const onCalculateFormulas = async () => {
    await setIsClosedProject((prev) => !prev);
    await setIsUpdateProjectExtraFields((prev) => false);
    let myComboParameters: Parameter[] = await fetchParameters();
    try {
      await CheckAndValidateFormulasPerProject(projectLocal, projectExtraFieldsData, true, myComboParameters);
      await loadPurchaseIncvoices();
      await setIsUpdateProjectExtraFields((prev) => true);
      await setIsClosedProject((prev) => !prev);
    } catch {
      await loadPurchaseIncvoices();
      await setIsUpdateProjectExtraFields((prev) => true);
      await setIsClosedProject((prev) => !prev);
    }
  }

  useEffect(() => {
    let projectId;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      projectId = myParams.get("project_id");
    }

    if (location.state === null && !projectId) {
      return navigate("/projects", { state: {}, replace: true });
    }

    GenericGetResource(`/projects/${(location.state && location.state.row.project_id) ?? projectId}`)
      .then(async (responseProject) => {
        setProjectLocal(responseProject.data);
        setIsClosedProject(responseProject.data.project_is_closed);
        let myPromises = [
          GenericGetResource(`/purchaseinvoices?filter=${utf8ToBase64(`project_id=${responseProject.data.project_id}&invoice_doc_status_id=${invoiceDocumentStatusActiveId}`)}`),
          GenericGetResource(`/salesinvoices?filter=${utf8ToBase64(`project_id=${responseProject.data.project_id}&invoice_doc_status_id=${invoiceDocumentStatusActiveId}`)}`),
          GenericGetResource(`/projectextrafields/byprojectid/${responseProject.data.project_id}`),
          GenericGetResource(`/invoicedocumentstatus`)
        ];
        await Promise.all(myPromises)
          .then((responses) => {
            responses[0] && setPurchaseInvoicesData((prev) => responses[0].data.data);
            responses[1] && setSalesInvoicesData((prev) => responses[1].data.data);
            responses[2] && setProjectExtraFieldsData((prev) => responses[2].data.data);

            let myPurchaseInvoices = responses[0].data.data;
            let mySalesInvoices = responses[1].data.data;
        
            if (myPurchaseInvoices.length > 0 || mySalesInvoices.length > 0) {
              setHasPurchaseOrSalesInvoices(true);
            }
            CalculateTotals(myPurchaseInvoices, mySalesInvoices);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
        let myPermissions = [
          GetResourceByUrl(`/projects`),
          GetResourceByUrl(`/projectextrafields`),
          GetResourceByUrl(`/projects/isclosed`),
          GetResourceByUrl(`/projectextrafields/updateprojectextrafields/byprojectid`),
        ];
        await Promise.all(myPermissions)
          .then((responsePermissions) => {
            responsePermissions[0] && setResourceScreen((prev) => responsePermissions[0]);
            responsePermissions[1] && setResourceProjectExtraFields((prev) => responsePermissions[1]);
            responsePermissions[2] && setResourceClosedProject((prev) => responsePermissions[2]);
            responsePermissions[3] && setResourceCalculateCost((prev) => responsePermissions[3]);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (salesInvoicesData.length > 0 || purchaseInvoicesData.length > 0) {
      setHasPurchaseOrSalesInvoices(true);
    } else {
      setHasPurchaseOrSalesInvoices(false);
    }
  }, [salesInvoicesData, purchaseInvoicesData]);

  useEffect(() => { }, [isUpdateProjectExtraFields]);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && (
        <>
          <Header
            title={t("projects.title-view")}
            child={
              <div className="d-flex justify-content-end">
                <div className="d-flex flex-row-reverse">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingLeft: 2,
                    }}
                  >
                    <Typography>
                      {t("projects.fields.project_is_closed")}
                    </Typography>
                    <Switch
                      readOnly={true}
                      disabled={true}
                      checked={isClosedProject}
                    />
                  </Box>

                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{ minWidth: 200 }}
                  >
                    <ListItemButton
                      onClick={() => setDialogConfirmCloseProject(true)}
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        minWidth: 200,
                      }}
                      disabled={
                        !resourceClosedProject?.update ? true : isClosedProject
                      }
                    >
                      {t("projects.actions.closeProject")}
                      <AssignmentTurnedInIcon />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => ValidateResourceCalculateFormulas()}
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        minWidth: 200,
                      }}
                      disabled={
                        !resourceCalculateCost?.update ? true : isClosedProject
                      }
                    >
                      {t("projects.actions.calculate-cost")}
                      <FunctionsIcon />
                    </ListItemButton>
                  </Popover>
                  {isClosedProject && (
                    <div className="d-flex justify-content-center align-items-center">
                      {projectUserClosed && (
                        <Grid item marginInline={1}>
                          <Typography fontWeight={"bold"}>
                            {projectUserClosed}
                          </Typography>
                        </Grid>
                      )}
                      {projectUserDateClosed && (
                        <Grid item marginInline={1}>
                          <Typography fontWeight={"bold"}>
                            {projectUserDateClosed?.toLocaleString()}
                          </Typography>
                        </Grid>
                      )}
                    </div>
                  )}
                </div>
                <div className="d-flex flex-row-reverse m-1">
                  <Button
                    variant="outlined"
                    aria-describedby={id1}
                    onClick={handleClick1}
                    disabled={loadingPut}
                  >
                    <MoreHorizIcon />
                  </Button>
                  {resourceScreen?.delete === true && (
                    <ButtonStyled
                      variant="contained"
                      className="my-1"
                      onClick={() => setOpenDialog(true)}
                      disabled={isClosedProject}
                    >
                      {t("generic.buttons.delete")}
                    </ButtonStyled>
                  )}
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate("/projects", { replace: true });
                    }}
                  >
                    {t("generic.buttons.goback")}
                  </Button>
                </div>
              </div>
            }
          />

          {resourceScreen?.read && (
            <>
              <UpdateProject
                ref={UpdateProjectRef}
                projectPayload={projectLocal ?? {}}
                isEditProps={resourceScreen?.update}
                totalSales={totalSales}
                totalPurchases={totalPurchases}
                totalAmount={totalAmount}
                projectExtraFieldsData={projectExtraFieldsData}
                setProjectExtraFieldsData={setProjectExtraFieldsData}
                resourceProjectExtraFields={resourceProjectExtraFields}
                resourceClosedProject={resourceClosedProject}
                setIsClosedProject={setIsClosedProject}
                isClosedProject={isClosedProject}
                setProjectUserClosed={setProjectUserClosed}
                setProjectUserDateClosed={setProjectUserDateClosed}
                isUpdateProjectExtraFields={isUpdateProjectExtraFields}
                setIsUpdateProjectExtraFields={setIsUpdateProjectExtraFields}
                showSnackBar={showSnackBar}
                setDialogConfirmCloseProject={setDialogConfirmCloseProject}
                dialogConfirmCloseProject={dialogConfirmCloseProject}

              />
              <div className="my-4" />
              <TabsProjectsDetails
                ProjectGroups={
                  <TableProjectGroups
                    project_id={projectLocal?.project_id ?? 0}
                    project_name={projectLocal?.project_name ?? ""}
                    isClosedProject={isClosedProject}
                  />
                }
                ProjectExtraFields={
                  <TableProjectExtraFields
                    projectExtraFieldsData={projectExtraFieldsData}
                    setProjectExtraFieldsData={setProjectExtraFieldsData}
                    project_id={projectLocal?.project_id ?? 0}
                    isClosedProject={isClosedProject}
                    showSnackBar={showSnackBar}
                  />
                }
                RelatedInvoices={
                  <ProjectRelatedInvoices
                    projectPayload={projectLocal ?? {}}
                    purchaseInvoicesData={purchaseInvoicesData}
                    salesInvoicesData={salesInvoicesData}
                    isClosedProject={isClosedProject}
                    defaultFilter={defaultFilter}
                  />
                }
                Documents={
                  <TabProjectDocuments
                    showSnackBar={showSnackBar}
                    project_id={projectLocal?.project_id ?? 0}
                    isClosedProject={isClosedProject}
                  />
                }
              />
            </>
          )}
        </>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projecttypes.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  );
};
