import { Text, View } from '@react-pdf/renderer'
import { useFormatNumber } from '../../../../hooks/useFormatNumber';
import { useDates } from '../../../../hooks/useDates';

export const SupplierData = ({ data, showTranslatedText }: any) => {

    const { setFormatNumber } = useFormatNumber();

    const { TimeConverter } = useDates();

    let regex = /\([^)]*\)/g;

    return (
        <View fixed style={{ width: '90%', alignSelf: 'center', height:80, borderWidth: 1,marginBottom:10 }}>
            <View style={{ width: '100%', height: '80%', flexDirection: 'row' }}>
               <View style={{width:'50%', flexDirection:'row', borderRightWidth: 1,justifyContent:'space-between',padding:3}}>
                    <View style={{ width: '30%', justifyContent: 'space-around' }} >
                        <Text style={{ fontSize: 7, fontWeight: 700 }}>{showTranslatedText("reports.paymentsPdf.supplierData.supplier")}:</Text>
                        <Text style={{ fontSize: 7, fontWeight: 700 }}>{showTranslatedText("reports.paymentsPdf.supplierData.address")}:</Text>
                        <Text style={{ fontSize: 7, fontWeight: 700 }}>{showTranslatedText("reports.paymentsPdf.supplierData.city")} - {showTranslatedText("reports.paymentsPdf.supplierData.state")}:</Text>
                        <Text style={{ fontSize: 7, fontWeight: 700 }}>{showTranslatedText("reports.paymentsPdf.supplierData.zipCode")}:</Text>
                        <Text style={{ fontSize: 7, fontWeight: 700 }}>{showTranslatedText("reports.paymentsPdf.supplierData.taxId")}:</Text>
                    </View>
                    <View style={{ width: '65%', justifyContent: 'space-around' }}>
                        <Text style={{ fontSize: 7 }}>{data.dataReceiptHeader?.supplier_business_name || " "}</Text>
                        <Text style={{ fontSize: 7 }}>{data.dataReceiptHeader?.supplier_street || " "}</Text>
                        <Text style={{ fontSize: 7 }}>{data.dataReceiptHeader?.supplier_city_name || " "} - {data.dataReceiptHeader?.supplier_state_name || " "}</Text>
                        <Text style={{ fontSize: 7 }}>{data.dataReceiptHeader?.supplier_postal_code || " "}</Text>
                        <Text style={{ fontSize: 7 }}>{data.dataReceiptHeader?.supplier_rfc || " "}</Text>
                    </View>
               </View>
               <View style={{width:'50%', flexDirection:'row',justifyContent:'space-between',padding:3}}>
                    <View style={{ width: '40%', justifyContent: 'space-around' }} >
                        <Text style={{ fontSize: 7, fontWeight: 700 }}>{showTranslatedText("reports.receiptsPdf.footer.dateAndTimeOfPayment")}:</Text>
                        <Text style={{ fontSize: 7, fontWeight: 700 }}>{showTranslatedText("reports.receiptsPdf.footer.paymentMethod")}:</Text>
                        <Text style={{ fontSize: 7, fontWeight: 700 }}>{showTranslatedText("reports.receiptsPdf.footer.paymentTotal")}:</Text>
                    </View>
                    <View style={{ width: '60%', justifyContent: 'space-around', }} >
                        <Text style={{ fontSize: 7 }}>{!data.dataReceiptHeader.payment_date ? " " : TimeConverter(data.dataReceiptHeader.payment_date)?.toString().replace(regex, '').trim()}</Text>
                        <Text style={{ fontSize: 7 }}>{data.dataReceiptHeader?.payment_method_code || " "} - {data.dataReceiptHeader?.payment_method_description || " "}</Text>
                        <Text style={{ fontSize: 7 }}>{setFormatNumber({ value: data.dataReceiptHeader?.total_amount || 0 }) || ' '} {data.dataReceiptHeader?.currency_description || " "}</Text>
                    </View>
                </View>
            </View>
            <View style={{ height: '20%', borderTopWidth: 1, borderColor: 'black', flexDirection: 'row' }}>
                <View style={{ width: '15%', height: '100%', borderRightWidth: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 6, fontWeight: 700 }}>{showTranslatedText("reports.paymentsPdf.supplierData.taxRegime")}</Text>
                </View>
                <View style={{ width: '85%', height: '100%', justifyContent: 'center', paddingLeft: 5 }}>
                    <Text style={{ fontSize: 6, fontWeight: 700 }}> {data.dataReceiptHeader?.supplier_tax_regime_code || " "} {data.dataReceiptHeader?.supplier_tax_regime_description || " "}</Text>
                </View>
            </View>
        </View>
    )
}
