import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { ButtonLoading } from '../../../../theme/buttons';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { SalesInvoice } from '../../../../interfaces/Sales/Invoices/salesinvoices';
import { CancellationReason } from '../../../../interfaces/Sales/Invoices/Cancellations/cancellationReason';
import { Dispatch, SetStateAction } from 'react';
import { uuidRegex } from '../../../../constants/Regex';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';

const getUUIDFormat = (uuid: string, keyCode: string, setValue: UseFormSetValue<SalesInvoice>) => {
  let myUuid = uuid;
  if (keyCode !== 'Backspace' && keyCode !== '-') {
    switch (uuid.length) {
      case 8:
      case 13:
      case 18:
      case 23:
        myUuid = `${myUuid}-`
        break;
    }
    setValue("replacement_fiscal_folio", myUuid);
  }
}

interface DialogProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  onAcept: (e: any) => void,
  control: Control<SalesInvoice, any>,
  errors: FieldErrors<SalesInvoice>,
  comboCancellationReasons: CancellationReason[],
  isLoading: boolean,
  getValues: UseFormGetValues<SalesInvoice>,
  setValue: UseFormSetValue<SalesInvoice>,
  hasCancelUuid: boolean,
  setHasCancelUuid: Dispatch<SetStateAction<boolean>>,
  trigger: UseFormTrigger<SalesInvoice>,
}

export const DialogCancelFiscalSignature = ({
  open,
  setOpen,
  setIsLoading,
  onAcept,
  control,
  errors,
  comboCancellationReasons,
  isLoading,
  getValues,
  setValue,
  hasCancelUuid,
  setHasCancelUuid,
  trigger
}: DialogProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setValue("cancellation_reason_id", undefined);
          setValue("replacement_fiscal_folio", undefined);
          setHasCancelUuid(false);
        }}
        aria-labelledby="responsive-dialog-title"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">
          {t("salesinvoices.dialog.cancel.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className='mb-2'>
            {t("salesinvoices.dialog.cancel.content")}
          </DialogContentText>
          <Controller
            name="cancellation_reason_id"
            control={control}
            rules={{}}
            render={({ field }) => (
              <Autocomplete
                ref={field.ref}
                size="small"
                sx={{ width: "100%", marginBottom: 2 }}
                options={comboCancellationReasons}
                getOptionLabel={(option) => option.description}
                renderOption={(props, option: CancellationReason) => (
                  <div key={option.cancellation_reason_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.description}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.cancellation_reason_id === value.cancellation_reason_id}
                onChange={(_, values) => {
                  field.onChange(values?.cancellation_reason_id || null);
                  if (comboCancellationReasons.find((item) => item.cancellation_reason_id === values?.cancellation_reason_id)?.cancellation_reason_key === "01") {
                    setHasCancelUuid(true);
                    setValue("cancellation_reason_aux", "01");
                  }
                  else {
                    setHasCancelUuid(false);
                    setValue("cancellation_reason_aux", undefined);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("salesinvoices.dialog.cancel.cancellation-reason")}`}
                    variant="filled"
                    value={field.value}
                  />
                )}
              />
            )}
          />
          {hasCancelUuid &&
            <Controller
              name="replacement_fiscal_folio"
              control={control}
              rules={{ required: hasCancelUuid, minLength: 36, maxLength: 36, pattern: uuidRegex }}
              render={({ field }) =>
                <TextField
                  variant="filled"
                  label={`${t("relatedsalesinvoices.fields.related_sales_invoice_uuid")}`}
                  ref={field.ref}
                  value={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    trigger();
                  }}
                  onKeyDown={(event) => {
                    getUUIDFormat(getValues("replacement_fiscal_folio") ?? "", event.key, setValue);
                    trigger();
                  }}
                  error={!!errors.replacement_fiscal_folio}
                  helperText={GetError(errors.replacement_fiscal_folio?.type)}
                  size="small"
                  style={{ width: "100%" }}
                />
              }
            />
          }
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" className="m-1" onClick={() => { setOpen(false); setIsLoading(false); }}>{t("generic.buttons.goback")}</Button>
          <ButtonLoading isLoading={isLoading} variant="outlined" type="button" className="m-1"
            onClick={(e: any) => trigger().then((response) => {
              if (response) { onAcept(e) }
            })}
          >{t("generic.buttons.accept")}</ButtonLoading>
        </DialogActions>
      </Dialog>
    </>
  )
}
