import { Route, Routes } from "react-router-dom"
import { TableCompanyBankAccounts } from "./table"


export const BrowserCompanyBankAccounts = ({ ...props }) => {
  return (
    <Routes>
      {(props.company_id) ?
        <Route path={"/"} element={<TableCompanyBankAccounts company_id={props.company_id} />} /> :
        <Route path={"/"} element={<TableCompanyBankAccounts setValue={props.setValue} add={props.add} BankAccountsData={props.BankAccountsData} setBankAccountsData={props.setBankAccountsData} />} />
      }
    </Routes>
  )
}
