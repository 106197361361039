import { Dispatch, SetStateAction, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { OptionMenu } from "../../../interfaces/Security/optionsMenu";
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DehazeIcon from '@mui/icons-material/Dehaze';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ResourceEntities from "../AccordionMenu/resourceEntities";
import { Menuitem } from "../../../interfaces/Security/menu";
import { useTranslation } from "react-i18next";

type DrawAccordionProps = {
    data: OptionMenu[];
    children?: string;
    setOpenDialogAdd: (optionMenu: OptionMenu) => void;
    changeIsVisible: (optionMenuId: number) => void;
    setOpenDialogUpdate: (optionMenu: OptionMenu) => void;
    permissions: Menuitem;
    ChangeOrderMenu: (optionMenuId: number, order: number) => void
};

export const AccordionMenuOption = ({ data, children = "", changeIsVisible, setOpenDialogAdd, setOpenDialogUpdate, permissions, ChangeOrderMenu }: DrawAccordionProps) => {
    const [t] = useTranslation("global");
    const GetResource = ResourceEntities();
    const [expandedLevels, setExpandedLevels] = useState<{ [key: number]: string | null }>({});
    const [rowEdit, setRowEdit] = useState<number | null>(null);
    const [currentValue, setCurrentValue] = useState<number | null>(null);

    const handleAccordionChange = (panelId: string, level: number) => {
        setExpandedLevels((prevExpanded) => ({
            ...Object.fromEntries(Object.entries(prevExpanded).filter(([key]) => parseInt(key) < level)),
            [level]: prevExpanded[level] === panelId ? null : panelId
        }));
    };

    const handleSaveOrder = (row: number) => {
        if (row && currentValue) {
            ChangeOrderMenu(row, currentValue);
            setCurrentValue(null);
            setRowEdit(null);
        }
        else {
            setCurrentValue(null);
            setRowEdit(null);
        }
    };

    return (
        <div className="w-100 d-flex justify-content-center ">
            <div className="w-100">
                {data.map((row: any) => (
                    (!row.resource_id) ? (
                        <Accordion
                            key={row.option_menu_id}
                            expanded={expandedLevels[row.level] === row.option_menu_id}
                            onChange={() => handleAccordionChange(row.option_menu_id, row.level)}
                        >
                            <AccordionSummary
                                aria-controls={`panel${children}-${row.option_menu_id}-content`}
                                id={`panel${children}-${row.option_menu_id}-header`}
                                expandIcon={<ArrowDropDownIcon />}
                            >
                                <div className="w-100">
                                    <div className='d-flex justify-content-between'>
                                        <div className="d-flex flex-row">
                                            <Button
                                                disabled={!(permissions.update)}
                                                onClick={() => {
                                                    setOpenDialogUpdate(row)
                                                }}
                                                sx={{
                                                    textTransform: 'none',
                                                    color: 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                }}>
                                                <div className="me-2">
                                                    {GetResource(row.resource_name ?? row.option_name)?.icon}
                                                </div>
                                                <div>
                                                    <Typography>{row.description ?? row.option_name}</Typography>
                                                </div>
                                            </Button>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                                <TextField
                                                    defaultValue={row.menu_order}
                                                    type="number"
                                                    value={row.option_menu_id !== rowEdit ? row.menu_order : undefined}
                                                    sx={{
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                                                        "& input[type=number]": { MozAppearance: "textfield" },
                                                        "& .MuiInputBase-input": {
                                                            fontSize: "0.875rem",
                                                            lineHeight: "1.2",
                                                        },
                                                        paddingRight: 1
                                                    }}
                                                    onChange={(e) => setCurrentValue(Number(e.target.value))}
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled={row.option_menu_id !== rowEdit}
                                                    InputProps={{
                                                        startAdornment: <Typography sx={{marginRight:0.5}} variant="subtitle1" gutterBottom>{t("optionsmenu.fields.menu_order")}: </Typography>,
                                                        endAdornment: row.option_menu_id !== rowEdit ? (
                                                            <IconButton
                                                                disabled={!permissions.update}
                                                                onClick={() => {
                                                                    setCurrentValue(null);
                                                                    setRowEdit(row.option_menu_id);
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton
                                                                disabled={!permissions.update}
                                                                onClick={() => handleSaveOrder(row.option_menu_id)}
                                                            >
                                                                <SaveIcon />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                    size="small"
                                                    style={{
                                                        width: "55%",
                                                    }}
                                                />
                                            </div>

                                            <div className="me-2">
                                                <IconButton
                                                    disabled={!(permissions.update)}
                                                    onClick={() => {
                                                        changeIsVisible(row.option_menu_id)
                                                    }}
                                                >
                                                    {(row.is_visible) ?
                                                        <VisibilityIcon /> :
                                                        <VisibilityOffIcon />
                                                    }
                                                </IconButton>
                                            </div>
                                            <div>
                                                <IconButton
                                                    disabled={!(permissions.create ?? false)}
                                                    onClick={() => {
                                                        setOpenDialogAdd(row)
                                                    }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AccordionMenuOption data={row.children} children={children} changeIsVisible={changeIsVisible} setOpenDialogAdd={setOpenDialogAdd} setOpenDialogUpdate={setOpenDialogUpdate} permissions={permissions} ChangeOrderMenu={ChangeOrderMenu} />
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <Box key={row.option_menu_id} sx={{
                            padding: 0.7,
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                        }}>
                            <div className="w-100">
                                <div className='d-flex justify-content-between'>
                                    <div className="d-flex flex-row">
                                        <Button
                                            disabled={!(permissions.update)}
                                            onClick={() => {
                                                setOpenDialogUpdate(row)
                                            }}
                                            sx={{
                                                textTransform: 'none',
                                                color: 'inherit',
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}>
                                            <div className="me-2">
                                                {GetResource(row.resource_name ?? row.option_name)?.icon}
                                            </div>
                                            <div>
                                                <Typography>{row.description ?? row.option_name}</Typography>
                                            </div>
                                        </Button>
                                    </div>
                                    <div className="d-flex flex-row me-4">
                                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                            <TextField
                                                defaultValue={row.menu_order}
                                                type="number"
                                                hiddenLabel
                                                value={row.option_menu_id !== rowEdit ? row.menu_order : undefined}
                                                sx={{
                                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                                                    "& input[type=number]": { MozAppearance: "textfield" },
                                                    "& .MuiInputBase-input": {
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1.2",
                                                    },
                                                    marginRight: 4,
                                                    marginTop: 1,
                                                }}
                                                onChange={(e) => setCurrentValue(Number(e.target.value))}
                                                InputLabelProps={{ shrink: true }}
                                                disabled={row.option_menu_id !== rowEdit}
                                                InputProps={{
                                                    startAdornment: <Typography sx={{marginRight:0.5}} variant="subtitle1" gutterBottom>{t("optionsmenu.fields.menu_order")}: </Typography>,
                                                    endAdornment: row.option_menu_id !== rowEdit ? (
                                                        <IconButton
                                                            disabled={!permissions.update}
                                                            onClick={() => {
                                                                setCurrentValue(null);
                                                                setRowEdit(row.option_menu_id);
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton
                                                            disabled={!permissions.update}
                                                            onClick={() => handleSaveOrder(row.option_menu_id)}
                                                        >
                                                            <SaveIcon />
                                                        </IconButton>
                                                    ),
                                                }}
                                                variant="standard"
                                                size="small"
                                                style={{
                                                    width: "55%",
                                                }}
                                            />
                                        </div>
                                        <div className="me-2 mt-2">
                                            <IconButton
                                                disabled={!(permissions.update)}
                                                onClick={() => {
                                                    changeIsVisible(row.option_menu_id)
                                                }}
                                            >
                                                {(row.is_visible) ?
                                                    <VisibilityIcon /> :
                                                    <VisibilityOffIcon />
                                                }
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    )
                ))}
            </div>
        </div >
    );
};
