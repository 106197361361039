import { Route, Routes, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { usePermissions } from '../../hooks/usePermissions'
import { HomeComponent } from './home'

export const Home = () => {
    const { GetResourceByUrl } = usePermissions();
    const navigate = useNavigate();

    useEffect(() => {
        GetResourceByUrl(`/dashboard`)
            .then((responsePermission) => {
                if(responsePermission){
                    navigate(`/dashboard`, { replace: true })
                }
            })
    }, [])

    return (
        <Routes>
            <Route path={"/"} element={<HomeComponent />} />
        </Routes>
    )
}