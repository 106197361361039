import { Text, View } from '@react-pdf/renderer'
import { secondSectionStyles } from './SecondSectionStyles';

const { secondSection } = secondSectionStyles();

export const SecondSection = ({ showTranslatedText }: any) => {
    return (
        <View fixed style={secondSection.container}>
            <View style={secondSection.titleContainer}>
                <Text style={secondSection.title}>{showTranslatedText("reports.paymentsPdf.secondSection.invoices")}</Text>
            </View>
            <View >
                <View style={{ width: '90%', alignSelf: 'center',borderLeft:1,borderBottom:1,borderTop:1, flexDirection: 'row', justifyContent:'center', alignItems: 'stretch'}}>
                    <Text style={secondSection.conceptContent}>{showTranslatedText("reports.paymentsPdf.secondSection.invoice")}</Text>
                    <Text style={[secondSection.conceptContent, { minWidth:"13%", paddingHorizontal:1,}]}>{showTranslatedText("reports.paymentsPdf.secondSection.quantityConcept")}</Text>
                    <Text style={secondSection.conceptContent}>{showTranslatedText("reports.paymentsPdf.secondSection.project")}</Text>
                    <Text style={secondSection.conceptContent}>{showTranslatedText("reports.paymentsPdf.secondSection.currency")}</Text>
                    <Text style={secondSection.conceptContent}>{showTranslatedText("reports.paymentsPdf.secondSection.taxesTotal")}</Text>
                    <Text style={secondSection.conceptContent}>{showTranslatedText("reports.paymentsPdf.secondSection.retentionsTotal")}</Text>
                    <Text style={secondSection.conceptContent}>{showTranslatedText("reports.paymentsPdf.secondSection.previousAmount")}</Text>
                    <Text style={secondSection.conceptContent}>{showTranslatedText("reports.paymentsPdf.secondSection.paidAmount")}</Text>
                    <Text style={secondSection.conceptContent}>{showTranslatedText("reports.paymentsPdf.secondSection.pendingAmount")}</Text>
                </View>
            </View>
        </View>
    )
}
