import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { ProjectDocument } from "../../../../interfaces/Projects/Catalogs/projectDocuments"
import { Menuitem } from "../../../../interfaces/Security/menu"
import { GenericDialog } from "../../../../components/Dialogs/Dialog"
import { useTranslation } from "react-i18next"
import { Spinner } from "../../../../components/Commons/Spinner/Spinner"
import { Box, Button, TextField } from "@mui/material"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { PrimaryButton } from "../../../../theme/buttons"
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors"
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar"
import GenericPromises from "../../../../api/GenericPromises"

type UpdateProjectDocumentsProps = {
  projectDocumentPayload: ProjectDocument,
  permissions: Menuitem | undefined,
  setOpenDialogUpdate: Dispatch<SetStateAction<boolean>>,
  DeleteRow: (deleteRow: number) => void,
  UpdateRow: (updateRow: ProjectDocument) => void,
  isClosedProject: boolean
}
export const UpdateProjectDocuments = ({
  projectDocumentPayload,
  permissions,
  setOpenDialogUpdate,
  DeleteRow,
  UpdateRow,
  isClosedProject,
}: UpdateProjectDocumentsProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GenericDeleteResource, GenericPutResource, GenericGetResource } = GenericPromises();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const isEdit = permissions ? permissions?.update : true;

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    trigger,
    reset,
  } = useForm<ProjectDocument>({
    defaultValues: {
      project_document_id: undefined,
      project_id: undefined,
      document_id: undefined,
      document_name: undefined,
      document_description: undefined,
      document_contents: undefined,
      creation_date: undefined,
      last_update_user: undefined,
      last_update_date: undefined,
    }
  });
  const onSubmit: SubmitHandler<ProjectDocument> = (data) => onPut(data);

  const onPut = (data: ProjectDocument) => {
    let myDataDocument = {
      document_name: data.document_name ?? null,
      document_description: data.document_description ?? null,
      document_contents: data.document_contents ?? null,
    }
    setLoadingPost(true);

    GenericPutResource(`/documents/${data.document_id}`, myDataDocument)
      .then((responsePut) => {
        let myUpdateRow = {
          ...responsePut.data,
          project_document_id: data.project_document_id,
        }
        setOpenDialogUpdate(false);
        UpdateRow(myUpdateRow);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }

  const onDelete = () => {
    GenericDeleteResource(`/projectdocuments/${projectDocumentPayload.project_document_id ?? 0}`)
      .then(async () => {
        await GenericDeleteResource(`/documents/${projectDocumentPayload.document_id ?? 0}`);
        DeleteRow(projectDocumentPayload.project_document_id ?? 0);
        setOpenDialogUpdate(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialogUpdate(false);
      })
  }

  useEffect(() => {
    GenericGetResource(`/projectdocuments/${projectDocumentPayload.project_document_id ?? 0}`)
      .then((response) => {
        reset({
          ...response.data
        });
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='d-flex'>
              <div className='w-50'>
                <Controller
                  name="document_name"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("projectdocuments.fields.document_name")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.document_name}
                      helperText={GetError(errors.document_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                      disabled={isClosedProject}
                    />
                  }
                />
              </div>

              <div className='w-50'>
                <Controller
                  name="document_description"
                  control={control}
                  rules={{ maxLength: 100 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("projectdocuments.fields.document_description")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.document_description}
                      helperText={GetError(errors.document_description?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isClosedProject}
                    />
                  }
                />
              </div>

            </div>

            <div className='mt-3 w-100'>
              <Controller
                name="document_contents"
                control={control}
                rules={{ required: true, maxLength: 500 }}
                render={({ field }) =>
                  <TextField
                    variant="filled"
                    label={`${t("projectdocuments.fields.document_contents")}`}
                    ref={field.ref}
                    value={field.value}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.document_contents}
                    helperText={GetError(errors.document_contents?.type)}
                    size="small"
                    style={{ width: "100%" }}
                    disabled={isClosedProject}
                  />
                }
              />
            </div>

            <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
              <div>
                <Button variant='outlined' className='m-1' onClick={() => setOpenDialogUpdate(false)}>{t("generic.buttons.goback")}</Button>
                {permissions?.update && <PrimaryButton onClick={() => {
                  trigger().then((response) => response && onSubmit(getValues()));
                }} variant='outlined' className='m-1' disabled={loadingPost || isClosedProject} isClosedProject={isClosedProject}>{t("generic.buttons.update")}</PrimaryButton>}
              </div>
              {permissions?.delete ?
                <PrimaryButton onClick={() => setOpenDialogDelete(true)} disabled={isClosedProject}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
              }
            </div>

          </form>

        </Box>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projectdocuments.dialog.delete")}
        open={openDialogDelete}
        setOpen={setOpenDialogDelete}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
