import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { ReminderCustomType } from "../../../../../interfaces/Projects/Catalogs/reminderCustomTypes";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ReminderType } from "../../../../../interfaces/Projects/Catalogs/reminderTypes";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { ButtonStyled, PrimaryButton } from "../../../../../theme/buttons";
import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { use } from "i18next";

type AddRecurrencePickerprops = {
  openCustomDialogAddCustomReminder: boolean;
  setOpenCustomDialogAddCustomReminder: Dispatch<SetStateAction<boolean>>;
  handleNewReminderTypeId: (newReminderType: ReminderType) => void;
  reminderTypesCombo: ReminderType[];
};

export const AddRecurrencePicker = ({
  openCustomDialogAddCustomReminder,
  setOpenCustomDialogAddCustomReminder,
  handleNewReminderTypeId,
  reminderTypesCombo,
}: AddRecurrencePickerprops) => {
  const [loadingPost, setLoadingPost] = useState(false);
  const { GenericPostResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar("left", "bottom");
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const [weeklySelected, setWeeklySelected] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<ReminderType>({
    defaultValues: {
      reminder_type_name: undefined,
      reminder_type_description: undefined,
      reminder_type_time: undefined,
      reminder_custom_type_id: undefined,
    },
  });

  type DayOfWeek =
    | "sunday"
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday";
  const [daysOfWeek, setDaysOfWeek] = useState<ReminderCustomType>({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  const daysOfWeekLabels: { [key in DayOfWeek]: string } = {
    sunday: "Sun",
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
  };

  const handleDayOfWeekChange = (day: DayOfWeek) => {
    setDaysOfWeek((prev: ReminderCustomType) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const hasSelectedDays = () => {
    return Object.values(daysOfWeek).some((daySelected) => daySelected);
  };

  const onSubmit: SubmitHandler<ReminderType> = (data) => onPost(data);

  const onPost: SubmitHandler<ReminderType> = async (data) => {
    setLoadingPost(true);
    try {
      let reminderCustomTypeId: number | null = null;
      if (hasSelectedDays()) {
        reminderCustomTypeId = await createReminderCustomType();
      }
      const newReminderType = await createReminderType({
        ...data,
        reminder_custom_type_id: reminderCustomTypeId || null,
      });

      if (newReminderType) {
        handleNewReminderTypeId(newReminderType.data);
      }

      closeDialog();
      setLoadingPost(false);
    } catch (error: any) {
      setLoadingPost(false);
      showSnackBar(error.message, "error");
    }
  };

  const createReminderCustomType = async () => {
    const customTypeData = { ...daysOfWeek };

    return await GenericPostResource("/remindercustomtypes", customTypeData)
      .then((response) => {
        return response.data.reminder_custom_type_id;
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const createReminderType = async (reminderTypeData: ReminderType) => {
    let myReminderType = reminderTypesCombo.find(
      (item) => item.reminder_type_id === reminderTypeData.reminder_type_id
    );
    let myData = {
      reminder_type_name: `${myReminderType?.reminder_type_name} ${reminderTypeData.reminder_type_time}`,
      reminder_type_description:
        reminderTypeData.reminder_type_description || null,
      reminder_type_time: reminderTypeData.reminder_type_time,
      reminder_custom_type_id: reminderTypeData.reminder_custom_type_id || null,
    };
    return await GenericPostResource("/remindertypes", myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.add"), "success");
        return response;
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const closeDialog = () => {
    reset();
    setWeeklySelected(false);
    setDaysOfWeek({
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    });
    setOpenCustomDialogAddCustomReminder(false);
  };


  return (
    <>
      <Dialog
        open={openCustomDialogAddCustomReminder}
        onClose={closeDialog}
        fullWidth
      >
        <DialogTitle>
          {t("projecttaskreminderstemplate.actions.create-custom-reminder")}
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} className="w-100">
          <DialogContent>
            <div className="d-flex justify-content-between">
              <Controller
                name="reminder_type_time"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TimePicker
                    ref={field.ref}
                    label={t("remindertypes.fields.reminder_type_time")}
                    value={
                      field.value ? dayjs(field.value, "HH:mm:ss") : null
                    }
                    onChange={(value) => {
                      if (value) {
                        const formattedTime = dayjs(value).format("HH:mm:ss");
                        field.onChange(formattedTime);
                      }
                    }}
                    sx={{
                      width: "100%",
                      paddingRight: 2,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "filled",
                        error: !!errors.reminder_type_time,
                        helperText: GetError(errors.reminder_type_time?.type),
                      },
                      digitalClockSectionItem: {
                        sx: {
                          "&.Mui-selected": {
                            color: "white",
                          },
                        },
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="reminder_type_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%" }}
                    options={reminderTypesCombo}
                    getOptionLabel={(option) => `${option.reminder_type_name}`}
                    renderOption={(props, option: ReminderType) => (
                      <div key={option.reminder_type_id}>
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.reminder_type_name}
                        </Box>
                      </div>
                    )}
                    onChange={(_, value) => {
                      if (value?.reminder_type_name === "Semanal") {
                        setWeeklySelected(true);
                        setDaysOfWeek({
                          sunday: false,
                          monday: false,
                          tuesday: false,
                          wednesday: false,
                          thursday: false,
                          friday: false,
                          saturday: false,
                        });
                      } else {
                        setWeeklySelected(false);
                      }
                      field.onChange(value?.reminder_type_id || null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t(
                          "remindertypes.fields.reminder_type_name"
                        )}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.reminder_type_name}
                        helperText={GetError(errors.reminder_type_name?.type)}
                        size="small"
                      />
                    )}
                  />
                )}
              />
            </div>
            {weeklySelected && (
              <Controller
                name="reminder_custom_type_id"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <ButtonGroup
                    variant="contained"
                    sx={{ flexWrap: "nowrap", marginTop: 2 }}
                  >
                    {Object.keys(daysOfWeek).map((day) => (
                      <ButtonStyled
                        key={day}
                        variant={
                          !!daysOfWeek[day as DayOfWeek]
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleDayOfWeekChange(day as DayOfWeek)}
                      >
                        {daysOfWeekLabels[day as DayOfWeek]}
                      </ButtonStyled>
                    ))}
                  </ButtonGroup>
                )}
              />
            )}
          </DialogContent>
          <DialogActions>
            <div className="d-flex flex-row-reverse">
              <PrimaryButton
                type="submit"
                variant="outlined"
                className="m-1"
                disabled={loadingPost}
              >
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled
                variant="contained"
                className="m-1"
                onClick={closeDialog}
              >
                {t("generic.buttons.cancel")}
              </ButtonStyled>
            </div>
          </DialogActions>
        </form>
      </Dialog>
      <SnackbarComponent />
    </>
  );
};
