import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../context/AuthContext";
import GenericPromises from "../../../api/GenericPromises";
import { Country } from "../../../interfaces/Commons/Locations/countries";
import { State } from "../../../interfaces/Commons/Locations/states";
import { City } from "../../../interfaces/Commons/Locations/cities";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { SupplierLocation } from "../../../interfaces/Purchases/Catalogs/suppliers";
import { SubmitHandler, useForm } from "react-hook-form";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Box, Button } from "@mui/material";
import { PrimaryButton } from "../../../theme/buttons";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { UpdateGeneral } from "./updateGeneral";
import { TaxRegime } from "../../../interfaces/Sales/Catalogs/taxRegimes";

export const UpdateSupplierLocations = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { user } = useContext(AuthContext);
  const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboCountries, setComboCountries] = useState<Country[]>([]);
  const [defaultCountry, setDefaultCountry] = useState<Country>();
  const [comboStates, setComboStates] = useState<State[]>([]);
  const [defaultState, setDefaultState] = useState<State>();
  const [comboCities, setComboCities] = useState<City[]>([]);
  const [defaultCity, setDefaultCity] = useState<City>();
  const [comboTaxRegimes, setComboTaxRegimes] = useState<TaxRegime[]>([]);
  const [defaultTaxRegime, setDefaultTaxRegime] = useState<TaxRegime>();
  const [openDialog, setOpenDialog] = useState(false);
  const { supplierLocationPayload, permissions, supplierLocationsData } = props;
  const isEdit = !permissions.update;
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    getValues,
    formState: { errors },
    setValue
  } = useForm<SupplierLocation>({
    defaultValues: {
      country_id: undefined,
      state_id: undefined,
      city_id: undefined,
      postal_code: undefined,
      street: undefined,
      contact_name: undefined,
      business_name: undefined,
      supplier_rfc: undefined,
      supplier_email: undefined,
      supplier_phone_number: undefined,
      supplier_web: undefined,
      fiscal_address: false,
      supply_address: false,
      default_supply_address: false,
      supplier_id: 0,
      tax_regime_id: 0,
    }
  });
  const onSubmit: SubmitHandler<SupplierLocation> = (data) => onPut(data);

  const onPut = (data: SupplierLocation) => {
    if (!props.add) {
      GenericGetResource(`/supplierlocations/bysupplierid/${supplierLocationPayload.supplier_id}`).then((response) => {
        if (data.fiscal_address === true) {
          for (let length = 0; length < response.data.data.length; length++) {
            if (supplierLocationPayload.supplier_location_id !== (response.data.data[length]).supplier_location_id) {
              let suppliers = {
                "fiscal_address": false
              };
              GenericPutResource(`/supplierlocations/${(response.data.data[length]).supplier_location_id}`, suppliers);
              let updateSuppliers = {
                ...response.data.data[length],
                supplier_location_id: (response.data.data[length]).supplier_location_id,
                "fiscal_address": false
              };
              props.UpdateRow(updateSuppliers);
            }
          }
          if (props.setValue) {
            let supplierUpdate = {
              business_name: data.business_name,
              supplier_rfc: data.supplier_rfc?.trim(),
              supplier_email: data.supplier_email ?? null,
              supplier_phone_number: data.supplier_phone_number ?? null,
              supplier_web: data.supplier_web ?? null,
              tax_regime_id: data.tax_regime_id ?? null,
            }
            GenericPutResource(`/suppliers/${supplierLocationPayload.supplier_id}`, supplierUpdate).then(() => {
              let myTaxRegime = comboTaxRegimes.find((item) => item.tax_regime_id === data.tax_regime_id);
              props.setValue("business_name", data.business_name);
              props.setValue("supplier_rfc", data.supplier_rfc?.trim());
              props.setValue("supplier_email", data.supplier_email);
              props.setValue("supplier_phone_number", data.supplier_phone_number);
              props.setValue("supplier_web", data.supplier_web);
              props.setValue("tax_regime_id", data.tax_regime_id);
              props.setValue("tax_regime", `${myTaxRegime?.tax_regime_code} - ${myTaxRegime?.description}`);
            })
          }
        }
        else if(supplierLocationPayload.fiscal_address === true && data.fiscal_address === false){
          showSnackBar(t("customers.dialog.no_fiscal"), "error");
          return;
        }
      }).catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
      let myData = {
        country_id: data.country_id,
        state_id: data.state_id,
        city_id: data.city_id,
        postal_code: data.postal_code ?? null,
        street: data.street ?? null,
        contact_name: data.contact_name ?? null,
        business_name: data.business_name,
        supplier_rfc: data.supplier_rfc?.trim(),
        supplier_email: data.supplier_email ?? null,
        supplier_phone_number: data.supplier_phone_number ?? null,
        supplier_web: data.supplier_web ?? null,
        fiscal_address: data.fiscal_address ?? false,
        supply_address: data.supply_address ?? false,
        default_supply_address: data.default_supply_address ?? false,
        supplier_id: data.supplier_id,
        tax_regime_id: data.tax_regime_id ?? null,
      }
      setLoadingPost(true);
      GenericPutResource(`/supplierlocations/${supplierLocationPayload.supplier_location_id}`, myData)
        .then((response) => {
          let myUpdateData: SupplierLocation = response.data;
          myUpdateData.country_name = comboCountries.find((country: Country) => country.country_id === response.data.country_id)?.country_name;
          myUpdateData.state_name = comboStates.find((state: State) => state.state_id === response.data.state_id)?.state_name;
          myUpdateData.city_name = comboCities.find((city: City) => city.city_id === response.data.city_id)?.city_name;
          props.UpdateRow(myUpdateData);
          showSnackBar(t("generic.snackbar.update"), "success");
          setLoadingPost(false);
          props.setOpenDialog(false);
        })
        .catch((error) => {
          setLoadingPost(false);
          showSnackBar(error.message, "error");
        });
    } else {
      if (data.fiscal_address === true) {
        for (let i = 0; i < props.supplierLocationsData.length; i++) {
          if (props.supplierLocationsData[i].fiscal_address === true) {
            props.supplierLocationsData[i].fiscal_address = false;
            props.UpdateRow(props.supplierLocationsData[i]);
          }
        }
        if (props.setValue) {
          let myTaxRegime = comboTaxRegimes.find((item) => item.tax_regime_id === data.tax_regime_id);
          props.setValue("business_name", data.business_name);
          props.setValue("supplier_rfc", data.supplier_rfc?.trim());
          props.setValue("supplier_email", data.supplier_email);
          props.setValue("supplier_phone_number", data.supplier_phone_number);
          props.setValue("supplier_web", data.supplier_web);
          props.setValue("tax_regime_id", data.tax_regime_id);
          props.setValue("tax_regime", `${myTaxRegime?.tax_regime_code} - ${myTaxRegime?.description}`);
        }
      }
      let country = comboCountries.find((country: Country) => country.country_id === data.country_id)
      let city = comboCities.find((city: City) => city.city_id === data.city_id)
      let state = comboStates.find((state: State) => state.state_id === data.state_id)
      let myData: SupplierLocation = {
        supplier_location_id: supplierLocationPayload.supplier_location_id,
        country_id: data.country_id,
        country_name: country?.country_name ?? "",
        country_code: country?.country_code ?? "",
        state_id: data.state_id,
        state_name: state?.state_name ?? "",
        state_code: state?.state_code ?? "",
        city_id: data.city_id,
        city_name: city?.city_name ?? "",
        city_code: city?.city_code ?? "",
        postal_code: data.postal_code ?? undefined,
        street: data.street ?? undefined,
        contact_name: data.contact_name ?? undefined,
        business_name: data.business_name,
        supplier_rfc: data.supplier_rfc?.trim(),
        supplier_email: data.supplier_email ?? undefined,
        supplier_phone_number: data.supplier_phone_number ?? undefined,
        supplier_web: data.supplier_web ?? undefined,
        fiscal_address: data.fiscal_address ?? false,
        supply_address: data.supply_address ?? false,
        default_supply_address: data.default_supply_address ?? false,
        supplier_id: undefined,
        creation_date: new Date(),
        last_update_user: user?.user_email,
        last_update_date: new Date(),
        tax_regime_id: data.tax_regime_id ?? undefined,
      };
      props.UpdateRow(myData);
      showSnackBar(t("generic.snackbar.update"), "success");
      setLoadingPost(false);
      props.setOpenDialog(false);
    }
  }

  const onDelete = () => {
    if (supplierLocationsData.length > 1) {
      if (!(supplierLocationPayload.fiscal_address)) {
        if (!props.add) {
          GenericDeleteResource(`/supplierlocations/${supplierLocationPayload?.supplier_location_id}`)
            .then(() => {
              setOpenDialog(false);
              props.DeleteRow(supplierLocationPayload?.supplier_location_id);
              props.setOpenDialog(false);
            })
            .catch((error) => {
              showSnackBar(error.message, "error");
              setOpenDialog(false);
            })
        } else {
          setOpenDialog(false);
          props.DeleteRow(supplierLocationPayload?.supplier_location_id);
          props.setOpenDialog(false);
        }
      }
      else {
        showSnackBar(t("customers.dialog.no_fiscal"), "error");
      }
    }
    else {
      showSnackBar(t("suppliers.dialog.last_location"), "error");
    }
  }

  const getCities = async (stateId: number) => {
    await GenericGetResource(`/cities/bystateid/${stateId}`)
      .then((response) => {
        setComboCities(response.data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/countries"),
      GenericGetResource("/states"),
      GenericGetResource("/cities"),
      GenericGetResource("/taxregimes"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboCountries(responses[0].data.data);
        setComboStates(responses[1].data.data);
        setComboCities(responses[2].data.data);
        setComboTaxRegimes(responses[3].data.data);

        reset({
          country_id: supplierLocationPayload.country_id,
          state_id: supplierLocationPayload.state_id,
          city_id: supplierLocationPayload.city_id,
          postal_code: supplierLocationPayload.postal_code ?? undefined,
          street: supplierLocationPayload.street ?? undefined,
          contact_name: supplierLocationPayload.contact_name ?? undefined,
          business_name: supplierLocationPayload.business_name,
          supplier_rfc: supplierLocationPayload.supplier_rfc,
          supplier_email: supplierLocationPayload.supplier_email ?? undefined,
          supplier_phone_number: supplierLocationPayload.supplier_phone_number ?? undefined,
          supplier_web: supplierLocationPayload.supplier_web ?? undefined,
          fiscal_address: supplierLocationPayload.fiscal_address ?? false,
          supply_address: supplierLocationPayload.supply_address ?? false,
          default_supply_address: supplierLocationPayload.default_supply_address ?? false,
          supplier_id: supplierLocationPayload.supplier_id,
          tax_regime_id: supplierLocationPayload.tax_regime_id,
        });


        setDefaultCountry(responses[0].data.data.find((country: Country) => country.country_id === supplierLocationPayload.country_id));
        setDefaultState(responses[1].data.data.find((state: State) => state.state_id === supplierLocationPayload.state_id));
        setDefaultCity(responses[2].data.data.find((city: City) => city.city_id === supplierLocationPayload.city_id));
        setDefaultTaxRegime(responses[3].data.data.find((taxregime: TaxRegime) => taxregime.tax_regime_id === supplierLocationPayload.tax_regime_id));

        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

            <UpdateGeneral
              control={control}
              errors={errors}
              comboCountries={comboCountries}
              defaultCountry={defaultCountry}
              comboStates={comboStates}
              defaultState={defaultState}
              comboCities={comboCities}
              defaultCity={defaultCity}
              supplierLocationPayload={supplierLocationPayload}
              isEdit={isEdit}
              getCities={getCities}
              setValue={setValue}
              comboTaxRegimes={comboTaxRegimes}
              defaultTaxRegime={defaultTaxRegime}
            />
            <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
              <div>
                <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                {permissions.update && <PrimaryButton onClick={() => {
                  trigger().then((response) => response && onSubmit(getValues()));
                }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
              </div>
              {permissions.delete && <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton>}
            </div>

          </form>

        </Box>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("supplierlocations.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
