import { Theme } from "@emotion/react";
import { Box, Grid, SxProps } from "@mui/material";

interface CreateStageButtonProps {
  color: number[] | undefined;
  onClick: () => void;
  children: React.ReactNode;
}

const GetTaskButtonStyles = (color: number[] | undefined): SxProps<Theme> | undefined => ({
  paddingInline: "16px",
  paddingBlock: "5px",
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  backgroundColor: color
    ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]},0.09)`
    : "rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: color
      ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.3)`
      : "rgba(0, 0, 0, 0.1)",
  },
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

export const TaskButton = ({
  color,
  onClick,
  children,
}: CreateStageButtonProps) => {
  return (
    <Box sx={GetTaskButtonStyles(color)} onClick={onClick}>
      {children}
    </Box>
  );
};