import { Document, Page, Text, Font, pdf, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { generalStyles } from "./styles/generalStyles";
import { GeneratePaymentDetailsCFDIsRows } from "./components/secondSection/GeneratePaymentDetailsCFDIsRows";
import { Header } from "./components/header/Header";
import { SupplierData } from "./components/supplierData/SupplierData";
import { PaymentData } from "./components/paymentData/PaymentData";
import { FirstSection } from "./components/fistSection/FirstSection";
import { SecondSection } from "./components/secondSection/SecondSection";
import { Footer } from "./components/footer/Footer";
import { secondSectionStyles } from "./components/secondSection/SecondSectionStyles";
import { useFormatNumber } from "../../hooks/useFormatNumber";

export const PaymentsPDF = () => {
  const { general, pageNumberAndTotalPages, pdfGenerationDate } =
    generalStyles();

  const { secondSection } = secondSectionStyles();
  const { setFormatNumber, formatNumberWithLineBreaks } = useFormatNumber();

  const [t, i18next] = useTranslation("global");

  let pages: number;
  let regex = /\([^)]*\)/g;

  const currentDate = new Date();

  const generatePDF = async ({ data }: any) => {
    if (
      !data.dataReceiptHeader.supplier_language ||
      data.dataReceiptHeader.supplier_language === " " ||
      data.dataReceiptHeader.supplier_language.toLowerCase() === "es"
    ) {
      data.dataReceiptHeader.supplier_language = "es";
    }

    const showTranslatedText = (key: string) => {
      return t(key, {
        lng: data.dataReceiptHeader.supplier_language.toLowerCase(),
      });
    };

    // const desiredLength = 27;
    // const repeatedData = [];

    // while (repeatedData.length < desiredLength) {
    //   repeatedData.push(...data?.dataReceiptsDetails);
    // }

    // data.dataReceiptsDetails = repeatedData.slice(0, desiredLength);

    // const { renderRows } = GeneratePaymentDetailsCFDIsRows(data);

    // const {rows,rowsRemainder}= renderRows();

    const content = (
      <Document>
        <Page size={"A4"} style={general.pageStyles}>
          <Text
            render={({ pageNumber, totalPages }) => {
              pages = totalPages;
              return <Text></Text>;
            }}
            fixed
          />

          <Header data={data} showTranslatedText={showTranslatedText} />

          <SupplierData data={data} showTranslatedText={showTranslatedText} />

          <PaymentData data={data} showTranslatedText={showTranslatedText} />

          <SecondSection showTranslatedText={showTranslatedText} />

          {data.dataReceiptsDetails.map((item: any, index: any) => (
            <View
              key={index}
              style={secondSection.conceptsContainer}
              wrap={false}
            >
              <Text style={secondSection.conceptContent}>
                {item?.purchase_invoice_name || " "}
              </Text>
              <View style={[secondSection.conceptContent, { minWidth:"13%", paddingHorizontal:1,}]}>
                {item?.purchase_invoices_details &&
                item.purchase_invoices_details.length > 0 ? (
                  item.purchase_invoices_details.map((detail: any, i: any) => (
                    <Text key={i} style={{ marginBottom: 2 }}>
                      {`${detail.quantity}: ${detail.item_description}`}
                    </Text>
                  ))
                ) : (
                  <Text> </Text>
                )}

                {item?.projectExtraFields &&
                item.projectExtraFields.length > 0 ? (
                  Object.entries(item.projectExtraFields[0]).map(
                    ([key, value], i) => (
                      <Text key={i} style={{ marginBottom: 2 }}>
                        {`${value}: ${key}`}
                      </Text>
                    )
                  )
                ) : (
                  <Text> </Text>
                )}
              </View>
              <Text style={secondSection.conceptContent}>
                {item?.project_name || " "}
              </Text>
              <Text style={secondSection.conceptContent}>
                {item?.currency_description || " "}
              </Text>
              <Text style={secondSection.conceptContent}>
                {item?.total_tax_amount
                  ? setFormatNumber({
                      value: item?.total_tax_amount || 0,
                      decimals: 2,
                    })
                  : 0}
              </Text>
              <Text style={secondSection.conceptContent}>
                {item?.total_retention_amount
                  ? setFormatNumber({
                      value: item?.total_retention_amount || 0,
                      decimals: 2,
                    })
                  : 0}
              </Text>
              <Text style={secondSection.conceptContent}>
                {item?.previous_amount
                  ? formatNumberWithLineBreaks(item?.previous_amount || 0)
                  : 0}
              </Text>
              <Text style={secondSection.conceptContent}>
                {item?.payment_amount
                  ? formatNumberWithLineBreaks(item?.payment_amount || 0)
                  : 0}
              </Text>
              <Text style={secondSection.conceptContent}>
                {item?.amount_outstanding
                  ? formatNumberWithLineBreaks(item?.amount_outstanding || 0)
                  : 0}
              </Text>
            </View>
          ))}

          <View fixed style={{ flexGrow: 1 }}></View>

          <Footer data={data} showTranslatedText={showTranslatedText} />

          <Text
            style={pageNumberAndTotalPages.text}
            render={({ pageNumber, totalPages }) => {
              return <Text>{`Page ${pageNumber} / ${totalPages}`}</Text>;
            }}
            fixed
          />

          <Text style={pdfGenerationDate.text} fixed>
            {" "}
            {currentDate.toString().replace(regex, "").trim()}
          </Text>
        </Page>
      </Document>
    );

    const downloadLink = document.createElement("a");
    const fileName = `${data.dataReceiptHeader?.payment_name || null}`;
    const blob = await pdf(content).toBlob();
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
    URL.revokeObjectURL(url);

    const reader = new FileReader();
    await reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
    });
  };

  return {
    generatePDF,
  };
};
