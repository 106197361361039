import { StyleSheet } from "@react-pdf/renderer"

export const merchandiseDetailsStyles=(existsDangerousMaterial:boolean)=>{

        const merchandiseDetailsData = StyleSheet.create({

            productServiceKeyConcept:{
                width: existsDangerousMaterial ? '15%' : '20%', 
                fontSize: 5.5, 
                borderRightWidth: 1, 
                padding: 4, 
                borderBottomWidth: 1, 
                fontWeight: 500
            },
            descriptionConcept:{
                width: existsDangerousMaterial ? '23%' : '30%', 
                fontSize: 5.5, 
                borderRightWidth: 1, 
                padding: 4, 
                borderBottomWidth: 1, 
                fontWeight: 500
            },
            quantityConcept:{
                width: existsDangerousMaterial ? '9%' : '15%', 
                fontSize: 5.5, 
                borderRightWidth: 1, 
                padding: 4, 
                borderBottomWidth: 1, 
                fontWeight: 500
            },
            unitCodeConcept:{
                width: existsDangerousMaterial ? '13%' : '20%', 
                fontSize: 5.5, 
                borderRightWidth: 1, 
                padding: 4, 
                borderBottomWidth: 1, 
                fontWeight: 500
            },
            weightInKGConcept:{
                width: existsDangerousMaterial ? '9%' : '15%', 
                fontSize: 5.5, 
                borderRightWidth: 1, 
                padding: 4, 
                borderBottomWidth: 1, 
                fontWeight: 500
            },
    
            hazardousMaterialKeyConcept:{
                width: '16%', 
                fontSize: 5.5, 
                borderRightWidth: 1, 
                padding: 4, 
                borderBottomWidth: 1, 
                fontWeight: 500
            },
    
            productServiceKeyData:{
                width: existsDangerousMaterial ? '15%' : '20%', 
                fontSize: 5, 
                borderRightWidth: 1, 
                padding: 5, 
                borderBottomWidth: 1,
            },
            descriptionData:{
                width: existsDangerousMaterial ? '23%' : '30%', 
                fontSize: 5, 
                borderRightWidth: 1, 
                padding: 5, 
                borderBottomWidth: 1,
            },
            quantityData:{
                width: existsDangerousMaterial ? '9%' : '15%', 
                fontSize: 5, 
                borderRightWidth: 1, 
                padding: 5, 
                borderBottomWidth: 1,
            },
            unitCodeData:{
                width: existsDangerousMaterial ? '13%' : '20%', 
                fontSize: 5, 
                borderRightWidth: 1, 
                padding: 5, 
                borderBottomWidth: 1,
            },
            weightInKGData:{
                width: existsDangerousMaterial ?  '9%' : '15%', 
                fontSize: 5, 
                borderRightWidth: 1, 
                padding: 5, 
                borderBottomWidth: 1,
            },
    
            hazardousMaterialKeyData:{
                width: '16%', 
                fontSize: 5, 
                borderRightWidth: 1, 
                padding: 5, 
                borderBottomWidth: 1,
            }
        })

    

        return {
            merchandiseDetailsData
        }
    

}