import { useCallback, useEffect, useState } from "react";
import { usePermissions } from "../../../../hooks/usePermissions";
import { Menuitem } from "../../../../interfaces/Security/menu";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import GenericPromises from "../../../../api/GenericPromises";
import { useLocation, useNavigate } from "react-router-dom";
import { useDates } from "../../../../hooks/useDates";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { PrimaryButton } from "../../../../theme/buttons";
import { OptionMenu } from "../../../../interfaces/Security/optionsMenu";
import { AccordionMenuOption } from "../../../../components/Security/AccordionOptionMenu";
import { Autocomplete, Backdrop, Box, Button, CircularProgress, TextField } from "@mui/material";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { AddOptionMenu } from "./add";
import { UpdateOptionMenu } from "./update";
import { AddOptionMenuTree } from "./AddTree";

interface EnumRoutes {
  enum_id: number,
  option_name: string,
  route: string
}

export const TableOptionMenu = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { GenericGetResource, GenericGetResourceGeneric, GenericPatchResource, GenericPutResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [defaultOptionMenuData, setDefaultOptionMenuData] = useState<OptionMenu>();
  const [optionMenuScreen, setOptionMenuScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [menuData, setMenuData] = useState<OptionMenu[]>([]);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [dataMenuLoaded, setDataMenuLoaded] = useState(false);
  const [defaultOptionMenu, setDefaultOptionMenu] = useState<OptionMenu>()
  const [openDialogNewTree, setOpenDialogNewTree] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [isLoadingDefault, setIsLoadingDefault] = useState(false);
  const { GetResourceByUrl } = usePermissions();

  const [comboEnum, setComboEnum] = useState<EnumRoutes[]>([]);
  const [myEnumValue, setMyEnumValue] = useState<EnumRoutes>();

  const handleAddRow = ((newRow: any) => {
    const findAddRowToTree = (row: OptionMenu, level: number) => {
      if ((newRow.parent_option_id === row?.option_menu_id) && row.children) {
        let myNewRow = {
          ...newRow,
          children: [],
          level: level
        }
        row.children.push(myNewRow)
      }
      else {
        row.children?.map((element) => {
          findAddRowToTree(element, level + 1)
        })
      }
    }
    let level = 2;
    let myOptionMenuData = defaultOptionMenuData;
    if (myOptionMenuData) {

      if ((newRow.parent_option_id === myOptionMenuData?.option_menu_id) && myOptionMenuData && myOptionMenuData.children) {
        let myNewRow = {
          ...newRow,
          children: [],
          level: level
        }
        myOptionMenuData.children.push(myNewRow)
      }
      else {
        myOptionMenuData.children?.map((element) => {
          findAddRowToTree(element, level + 1)
        })
      }
      if (myOptionMenuData.children) {
        orderMenu(myOptionMenuData.children);
      }
      setDefaultOptionMenuData(() => myOptionMenuData);
      setMenuData(myOptionMenuData.children ?? [])
    }
  })

  const handleUpdateRow = ((newRow: any) => {
    const findAddRowToTree = (row: OptionMenu) => {
      if (newRow.option_menu_id === row?.option_menu_id) {
        row.resource_id = newRow.resource_id;
        row.option_name = newRow.option_name;
        row.description = newRow.description;
        row.menu_order = newRow.menu_order;
        row.is_visible = newRow.is_visible;
      }
      else {
        row.children?.map((element) => {
          findAddRowToTree(element)
        })
      }
    }
    let myOptionMenuData = defaultOptionMenuData;
    if (myOptionMenuData) {
      myOptionMenuData.children?.map((element) => {
        findAddRowToTree(element)
      })
      if (myOptionMenuData.children) {
        myOptionMenuData.children = orderMenu(myOptionMenuData.children);
      }
      setDefaultOptionMenuData(() => myOptionMenuData);
      setMenuData(myOptionMenuData.children ?? [])
    }
  })

  const handleDeleteRow = ((newRow: any) => {
    const findAddRowToTree = (row: OptionMenu) => {
      if (row?.children?.some((element) => element.option_menu_id === newRow.option_menu_id)) {
        row.children = row.children.filter((element) => element.option_menu_id !== newRow.option_menu_id)
      }
      else {
        row.children?.map((element) => {
          findAddRowToTree(element)
        })
      }
    }
    let myOptionMenuData = defaultOptionMenuData;
    if (myOptionMenuData) {
      if (myOptionMenuData.children?.some((element) => element.option_menu_id === newRow.option_menu_id)) {
        myOptionMenuData.children = myOptionMenuData.children.filter((element) => element.option_menu_id !== newRow.option_menu_id)
      }
      else {
        myOptionMenuData.children?.map((element) => {
          findAddRowToTree(element)
        })
      }
      if (myOptionMenuData.children) {
        myOptionMenuData.children = orderMenu(myOptionMenuData.children);
      }
      setDefaultOptionMenuData(() => myOptionMenuData);
      setMenuData(myOptionMenuData.children ?? [])
    }
  })

  const orderMenu = ((subleveltree: OptionMenu[]) => {
    if (subleveltree.length > 0) {
      subleveltree = subleveltree.sort((a: any, b: any) => a.menu_order - b.menu_order);
      subleveltree.map((element) => {
        if (element.children) {
          element.children = orderMenu(element.children)
        }
      })
    }
    return subleveltree;
  })

  const handleOpenDialogAdd = ((optionMenu: OptionMenu | undefined) => {
    setDefaultOptionMenu(optionMenu);
    setOpenDialogAdd(true);
  })

  const handleOpenDialogUpdate = ((optionMenu: OptionMenu | undefined) => {
    setDefaultOptionMenu(optionMenu);
    setOpenDialogUpdate(true);
  })

  const ChangeOrderMenu = ((optionMenuId: number, order: number) => {
    setIsLoadingDefault(true);
    let myOptionMenu = {
      menu_order: order,
    }
    GenericPutResource(`/optionmenu/${optionMenuId}`, myOptionMenu)
      .then(async (response) => {
        let myNewRow = {
          ...response.data,
        }
        await handleUpdateRow(myNewRow);
        setIsLoadingDefault(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setIsLoadingDefault(false);
      });
  })

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          setMessageSnack(t("generic.snackbar.delete"));
          break;
        }
    }

  }, [location.state, t]);

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("optionsmenu.title")}`;
    })
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/optionmenu`)
      .then((responses) => {
        if (responses.data.data && responses.data.data.length > 0) {
          let myComboEnum = responses.data.data.map((element: any) => ({
            enum_id: element.option_menu_id,
            option_name: element.description ?? element.option_name,
            route: `/menu/parentid/${element.option_menu_id}`,
          }));
          let myEnum = myComboEnum[0];
          setDefaultOptionMenuData(responses.data.data[0])
          setMyEnumValue(myEnum);
          setComboEnum(myComboEnum);
        }
        GetResourceByUrl(`/optionmenu`)
          .then((response1) => {
            setOptionMenuScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            setMessageSnack(error.message);
            showSnackBar(error.message, 'error');
            setUnauthorized(false);
          });
      }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, 'error');
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataMenuLoaded(false);
    if (myEnumValue) {
      GenericGetResource(myEnumValue?.route)
        .then((response) => {
          setDefaultOptionMenuData(response.data);
          if (response.data && response.data.children && response.data.children.length > 0) {
            let children = response.data.children;
            if (children) {
              children = orderMenu(children);
            }
            children = children.sort((a: any, b: any) => a.menu_order - b.menu_order);
            setMenuData(children);
            setDataMenuLoaded(true);
          }
          else {
            setMenuData([]);
            setDataMenuLoaded(true);
          }
        }).catch((error) => {
          setMenuData([]);
          setDataMenuLoaded(true);
          setMessageSnack(error.message);
          showSnackBar(error.message, 'error');
          setUnauthorized(false);
        });
    }
    else {
      setMenuData([]);
    }
  }, [myEnumValue])

  const changeIsVisible = (optionMenuId: number) => {
    const findRowTree = (row: OptionMenu, isVisible: boolean) => {
      if (row.option_menu_id === optionMenuId) {
        row.is_visible = isVisible
      }
      else if (row.children && row.children.length > 0) {
        row.children.map((element: OptionMenu) => { findRowTree(element, isVisible) })
      }
    }
    setIsLoadingDefault(true);
    GenericPatchResource(`/optionmenu/changestatus/${optionMenuId}`)
      .then((response) => {
        const updatedMenuData = menuData.map((element) => {
          if (element.option_menu_id === optionMenuId) {
            return {
              ...element,
              is_visible: response.data.is_visible,
            };
          }
          else if (element.children && element.children.length > 0) {
            element.children.map((element: OptionMenu) => { findRowTree(element, response.data.is_visible) })
          }
          return element;
        });
        setMenuData(updatedMenuData);
        setIsLoadingDefault(false);
      })
      .catch((error) => {
        setMessageSnack(error.message);
        setIsLoadingDefault(false);
        showSnackBar(error.message, 'error');
      });
  };


  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && optionMenuScreen?.read && <div className='screen-container'>
        <Header
          title={t("optionsmenu.title")}
          child={
            <div className='d-flex flex-row-reverse'>
              {optionMenuScreen?.create === true &&
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { handleOpenDialogAdd(defaultOptionMenuData); }}>{t("generic.buttons.add")}</PrimaryButton>
              }
              <div className='w-25'>
                <Autocomplete
                  size="small"
                  options={comboEnum}
                  defaultValue={myEnumValue}
                  getOptionLabel={(option) => option.option_name}
                  renderOption={(props, option) => (
                    <div key={option.enum_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.option_name}
                      </Box>
                    </div>
                  )}
                  sx={{ width: "100%", paddingRight: 1, paddingTop: 1 }}
                  isOptionEqualToValue={(option, value) => option.enum_id === value.enum_id}
                  onChange={(_, values) => {
                    setMyEnumValue(values ?? { enum_id: 1, option_name: t("authorizationpayments.comboEnum.all"), route: `/authorizationpayments` });
                  }}
                  noOptionsText={
                    <Button onMouseDown={() => {
                      setOpenDialogNewTree(true);
                    }}>
                      {t("optionsmenu.actions.create")}
                    </Button>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("generic.filter")}`}
                      variant="filled"
                    />
                  )}
                  clearOnEscape={false}
                  clearIcon={null}
                />
              </div>
            </div>
          }
        />
        <div className="d-flex justify-content-center">
          {(dataMenuLoaded) ?
            <div className="w-100 custom-card-view">
              <AccordionMenuOption
                data={menuData}
                changeIsVisible={changeIsVisible}
                setOpenDialogAdd={handleOpenDialogAdd}
                setOpenDialogUpdate={handleOpenDialogUpdate}
                permissions={optionMenuScreen}
                ChangeOrderMenu={ChangeOrderMenu}
              />
            </div>
            :
            <Spinner />
          }
        </div>


      </div>
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingDefault}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogEntity
        content={<AddOptionMenu defaultOptionMenu={defaultOptionMenu} defaultOptionMenuData={defaultOptionMenuData} openDialog={openDialogAdd} setOpenDialog={setOpenDialogAdd} AddNewRow={handleAddRow} />}
        open={openDialogAdd}
        setOpen={setOpenDialogAdd}
        title={<Header title={t("optionsmenu.title-view")} size='md' />}
      />
      <DialogEntity
        content={<UpdateOptionMenu defaultOptionMenu={defaultOptionMenu} defaultOptionMenuData={defaultOptionMenuData} openDialog={openDialogUpdate} setOpenDialog={setOpenDialogUpdate} updateNewRow={handleUpdateRow} deleteNewRow={handleDeleteRow} permissions={optionMenuScreen} />}
        open={openDialogUpdate}
        setOpen={setOpenDialogUpdate}
        title={<Header title={t("optionsmenu.title-view")} size='md' />}
      />
      <DialogEntity
        content={<AddOptionMenuTree openDialog={openDialogNewTree} setOpenDialog={setOpenDialogNewTree} comboEnum={comboEnum} setComboEnum={setComboEnum} />}
        open={openDialogNewTree}
        setOpen={setOpenDialogNewTree}
        title={<Header title={t("optionsmenu.title-view")} size='md' />}
      />
      <SnackbarComponent />
    </>
  )
}
