import React from "react";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@emotion/react";

interface CardStageProps {
  key?: React.Key | null | undefined,
  color: number[] | undefined;
  onClick: () => void;
  children: React.ReactNode;
}

const GetCardStageStyles = (color: number[] | undefined): SxProps<Theme> | undefined => {
  const borderColor = color ? `rgb(${color.join(",")})` : "transparent";

  return {
    paddingBlock: 1.5,
    paddingInline: 2,
    borderRadius: 1,
    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.2)',
    display: "flex",
    flexDirection: "column",
    backgroundColor: 'white',
    backdropFilter: 'blur(5px)',
    width: "100%",
    border: `solid 1px ${borderColor}`,
  };
};

export const CardStage = ({
  key,
  color,
  onClick,
  children,
}: CardStageProps) => {
  return (
    <Box key={key} sx={GetCardStageStyles(color)} onClick={onClick}>
      {children}
    </Box>
  );
};