import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import GenericPromises from "../../../../api/GenericPromises";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { PaymentMethod } from "../../../../interfaces/Sales/Catalogs/paymentMethods";
import { Header } from "../../../../components/Header";
import { TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";

export const AddPaymentMethods = () => {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const {showSnackBar,SnackbarComponent}= useSnackBar();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<PaymentMethod>({
        defaultValues: {

        }
    });
    const onSubmit: SubmitHandler<PaymentMethod> = (data) => onPost(data);

    const onPost = (data: PaymentMethod) => {
        let myData = {
            code: data.code ?? null,
            name: data.name ?? null,
            description: data.description ?? null,
        }
        setLoadingPost(true);
        GenericPostResource("/paymentmethods", myData)
            .then((response) => {
                navigate("/paymentmethods", { state: { method: "add" } });
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message,"error");
            });        
    }
    
    const onCancel = () => {
        // validacion si quiere cancelar sus cambios 
        navigate("/paymentmethods", { state: {} });
    }

    useEffect(() => {
        GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
            document.title = `${response.data.data[0].comercial_name} - ${t("paymentmethods.title")}`;
        })
    }, [])
    
    return (
        <>
            <Header title={t("paymentmethods.title-view")} />
            <div className='d-flex justify-content-center'>

                <div className='custom-card-view w-75'>

                    <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                        <div className='d-flex w-100'>
                            <div className='w-50'>
                                <Controller
                                    name="code"
                                    control={control}
                                    rules={{ required: true, maxLength: 2 }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("paymentmethods.fields.code")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.code}
                                            helperText={GetError(errors.code?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            sx={{ paddingRight: 2 }}
                                        />
                                    }
                                />
                            </div>

                            <div className='w-50'>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true, maxLength: 50 }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("paymentmethods.fields.name")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.name}
                                            helperText={GetError(errors.name?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            sx={{ paddingRight: 2 }}
                                        />
                                    }
                                />
                            </div>

                            <div className="w-50">
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{ maxLength: 100 }}
                                    render={({ field }) =>
                                        <TextField
                                            ref={field.ref}
                                            variant="filled"
                                            value={field.value}
                                            onChange={field.onChange}
                                            label={t("paymentmethods.fields.description")}
                                            error={!!errors.description}
                                            helperText={GetError(errors.description?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <div className='d-flex flex-row-reverse mt-1'>
                            <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                            <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                        </div>

                    </form>

                </div>

            </div>
            <SnackbarComponent/>
        </>
    )
}