import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDates } from "../../../../hooks/useDates";
import GenericPromises from "../../../../api/GenericPromises";
import { useCallback, useEffect, useState } from "react";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../../interfaces/Security/menu";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { usePermissions } from "../../../../hooks/usePermissions";
import { ReceiptDetail, ReceiptStatus } from "../../../../interfaces/Sales/Receipts/receipts";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";
import MoneyIcon from '@mui/icons-material/Money';
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { DialogReceipt } from "../DialogReceipt";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { ViewReceiptDetails } from "./view";
import { Button } from "@mui/material";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";
import EmailIcon from '@mui/icons-material/Email';
import { DialogSendEmailsTable } from "../helpers/dialogSendEmailsTable";

export const TableReceiptsDetails = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [dialogPayment, setDialogPayment] = useState(false);
  const [hiddenHeaderCheckBox, setHiddenHeaderCheckBox] = useState(true);
  const [openDialogView, setOpenDialogView] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [clickedButtonGoBack, setClickedButtonGoBack] = useState(false);
  const [dataEmails, setDataEmails] = useState<any>([]);
  const [receiptDetailPayload, setReceiptDetailPayload] = useState<ReceiptDetail>({});
  const [selectedRows, setSelectedRows] = useState<GridRowsProp>([]);
  const [receiptsDetailsData, setReceiptsDetailsData] = useState<GridRowsProp>([]);
  const [receiptsDetailsDataAll, setReceiptsDetailsDataAll] = useState<GridRowsProp>([]);
  const [myCustomerId, setMyCustomerId] = useState(0);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceReceipts, setResourceReceipts] = useState<Menuitem>();
  const [resourceSendEmail, setResourceSendEmail] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [myRfcCustomer, setMyRfcCustomer] = useState("");
  const [isSelectedUuid, setIsSelectedUuid] = useState(true);
  const [defaultFilter, setDefaultFilter] = useState<{
    columnfilter: string;
    value: string;
  }[]>([])
  const [columns, setColumns] = useState<GridColDef<ReceiptDetail>[]>([
    {
      field: 'sales_invoice_name', headerName: t("salesinvoices.fields.sales_invoice_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`sales_invoice_id=${params.row.sales_invoice_id}&customer_id=${params.row.customer_id}`);
          window.open(`/salesinvoices/view?${stringConverted}`, '_blank');
        }}>
          {params.row.sales_invoice_name}
        </Button>
      }
    },
    {
      field: 'project_name', headerName: t("projects.fields.project_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`project_id=${params.row.project_id}`);
          window.open(`/projects/view?${stringConverted}`, '_blank');
        }}>
          {params.row.project_name}
        </Button>
      }
    },
    { field: 'customer_business_name', headerName: t("receiptsdetails.fields.customer_bussines_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'receipt_name', headerName: t("receipts.fields.receipt_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'uuid', headerName: t("receipts.fields.uuid"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'status_name', headerName: t("receiptsdetails.fields.status_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'related_currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'amount_outstanding', headerName: t("receiptsdetails.fields.amount_outstanding"), headerClassName: 'header-grid-table', flex: 1, type: 'number', align: 'left', headerAlign: 'left',
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'payment_deadline', headerName: t("receiptsdetails.fields.payment_deadline"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'user_mail_send', headerName: t("receipts.fields.user_mail_send"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'date_mail_send', headerName: t("receipts.fields.date_mail_send"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value)?.toLocaleString();
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-receiptsdetails");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-receiptsdetails-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<ReceiptDetail, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
          if (element.field === 'payment_deadline') {
            element.headerName = t(`receiptsdetails.fields.${element.field}`);
          }
        }
        else {
          if ((element.field === 'amount_outstanding') || (element.field === 'amount_outstanding')) {
            element.valueGetter = function (params: GridValueGetterParams<ReceiptDetail, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'sales_invoice_name') {
            element.headerName = t(`salesinvoices.fields.sales_invoice_name`);
          }
          else if (element.field === 'uuid') {
            element.headerName = t(`receipts.fields.uuid`);
            element.renderCell = function (params: GridRenderCellParams<ReceiptDetail, any, any, GridTreeNodeWithRender>) {
              return <Button onClick={() => {
                let stringConverted = ConvertParamsToBase64(`sales_invoice_id=${params.row.sales_invoice_id}&customer_id=${params.row.customer_id}`);
                window.open(`/salesinvoices/view?${stringConverted}`, '_blank');
              }}>
                {params.row.sales_invoice_name}
              </Button>
            }
          }
          else if (element.field === 'project_name') {
            element.headerName = t(`projects.fields.project_name`);
            element.renderCell = (params: GridRenderCellParams<ReceiptDetail, any, any, GridTreeNodeWithRender>) => {
              return <Button onClick={() => {
                let stringConverted = ConvertParamsToBase64(`project_id=${params.row.project_id}`);
                window.open(`/projects/view?${stringConverted}`, '_blank');
              }}>
                {params.row.project_name}
              </Button>
            }
          }
          else {
            element.headerName = t(`receiptsdetails.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

  const onSendEmail = async () => {
    let myDataEmails: any = [];
    const filteredRows = selectedRows.filter(element => element.receipt_id && element.uuid)
      .reduce((arrayIndex, currentRow) => {
        if (!arrayIndex.some((item: any) => item.receipt_id === currentRow.receipt_id)) {
          arrayIndex.push(currentRow)
        }
        return arrayIndex;
      }, []);
    let myCustomer: any;
    if (filteredRows.length > 0) {
      myCustomer = await GenericGetResource(`/customers/${filteredRows[0].customer_id}`);
    }
    filteredRows.forEach((element: any) => {
      let data = {
        data: {
          ...element,
          customer_email: (myCustomer?.data.customer_email) ?? '',
          customer_language: (myCustomer?.data.customer_language) ?? '',
        },
        status: (myCustomer?.data.customer_email) ? "Waiting" : "Error",
        status_description: (myCustomer?.data.customer_email) ? "generic.send_email.waiting" : "generic.send_email.no_email"
      }
      myDataEmails.push(data);
    });
    setDataEmails((prev: any) => myDataEmails);
  }

  const onRowSelection = (rowsIds: GridRowSelectionModel) => {
    if (rowsIds.length === 1) {
      if (hiddenHeaderCheckBox) {
        setReceiptsDetailsDataAll(receiptsDetailsData);
      }
      let myRow = receiptsDetailsData.find(item => item.receipt_detail_id === rowsIds[0])
      setMyRfcCustomer(myRow?.customer_rfc ?? "");
      setMyCustomerId(myRow?.customer_id ?? 0);
      setIsSelectedUuid(myRow?.uuid_related ? true : false)
      setReceiptsDetailsData((prev) => prev.filter((item) => item.customer_id === myRow?.customer_id &&
        item.customer_business_name === myRow?.customer_business_name));
      setDataEmails(receiptsDetailsData.filter(receipt => rowsIds.includes(receipt.receip_detail_id)));
      setHiddenHeaderCheckBox(false);
    }
    else if (rowsIds.length === 0) {
      setReceiptsDetailsData(receiptsDetailsDataAll);
      setDataEmails([]);
      setHiddenHeaderCheckBox(true);
    }
    setSelectedRows((prev) => {
      return receiptsDetailsData.filter(item => rowsIds.includes(item.receipt_detail_id));
    });
  }

  useEffect(() => {
    setDataLoaded(false);
    GenericGetResource(`/receiptsdetails`)
      .then(
        (response) => {
          setReceiptsDetailsData(response.data.data);

          let myPermissions = [
            GetResourceByUrl(`/receipts`),
            GetResourceByUrl(`/receiptsdetails`),
            GetResourceByUrl(`/receiptsdetails/sendemail`),
          ]
          Promise.all(myPermissions)
            .then((responses) => {
              setResourceReceipts((prev) => responses[0]);
              setResourceScreen((prev) => responses[1]);
              setResourceSendEmail((prev) => responses[2]);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, "error");
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
  }, [clickedButtonGoBack])


  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("receiptsdetails.title")}`;
    })

    GenericGetResource("/receiptstatuses").then((response) => {
      let statusFilter = response.data.data.find((element: ReceiptStatus) => element.receipt_status_id == 1);
      if (statusFilter) {
        setDefaultFilter([...defaultFilter, { columnfilter: "status_name", value: statusFilter.status_name }])
      }
    })

    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/receiptsdetails`)
      .then(
        (response) => {
          setReceiptsDetailsData(response.data.data);

          let myPermissions = [
            GetResourceByUrl(`/receipts`),
            GetResourceByUrl(`/receiptsdetails`),
            GetResourceByUrl(`/receiptsdetails/sendemail`),
          ]
          Promise.all(myPermissions)
            .then((responses) => {
              setResourceReceipts((prev) => responses[0]);
              setResourceScreen((prev) => responses[1]);
              setResourceSendEmail((prev) => responses[2]);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, "error");
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { }, [receiptsDetailsData]);

  useEffect(() => { }, [hiddenHeaderCheckBox]);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read &&
        <div className='screen-container'>
          <Header
            title={t("receiptsdetails.title")}
            child={
              <div className="d-flex flex-row-reverse">
                <PrimaryButton size="small" variant='outlined' className="m-1" sx={{ color: 'primary.main' }} disabled={(selectedRows?.length === 0) || !resourceReceipts?.create} onClick={() => setDialogPayment(true)}>
                  {t("receiptsdetails.buttons.apply-receipt")}<MoneyIcon sx={{ marginLeft: 1 }} />
                </PrimaryButton>
                {resourceSendEmail?.create === true &&
                  <ButtonStyled size="small" variant='outlined' className="m-1" sx={{ color: 'primary.main' }} disabled={selectedRows.length === 0} onClick={async () => { await onSendEmail(); setOpenEmail(true); }}>
                    {t("generic.buttons.send_email")}<EmailIcon sx={{ marginLeft: 1 }} />
                  </ButtonStyled>
                }
              </div>
            }
          />
          <div className="d-flex justify-content-center mt-2">
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={receiptsDetailsData}
              entityId={"receipt_detail_id"}
              entity={`ReceiptsDetails`}
              preferences={myPreferences}
              namePreferences={"grid-receiptsdetails"}
              nameOrderColumns={"grid-receiptsdetails-columns"}
              selectRows
              hiddenHeaderCheckBox={hiddenHeaderCheckBox}
              onRowSelectionModelChange={(rowsIds) => onRowSelection(rowsIds)}
              isChildren={true}
              setOpenDialog={setOpenDialogView}
              setDataRow={setReceiptDetailPayload}
              hasDoubleClick
              initFilter={defaultFilter}
              conditionalOpenRow={`receipt_id`}
              countColumns={["total_company_currency_amount"]}
            />
          </div>
          <DialogEntity
            open={openDialogView}
            setOpen={setOpenDialogView}
            title={
              <></>
            }
            fullWidth
            content={
              <ViewReceiptDetails
                receiptDetailPayload={receiptDetailPayload}
                permissionsReceiptsDetails={resourceScreen}
                permissionsReceipts={resourceReceipts}
                setOpenDialogView={setOpenDialogView}
                setClickedButtonGoBack={setClickedButtonGoBack}
              /* UpdateRow={handleUpdateRow} */
              />
            }
          />
          <DialogEntity
            open={dialogPayment}
            title={<></>}
            fullWidth={true}
            content={
              <DialogReceipt
                rows={receiptsDetailsData}
                isSelectedUuid={isSelectedUuid}
                myRfcCustomer={myRfcCustomer}
                customer_id={myCustomerId}
                selectedRows={selectedRows}
                setDialogPayment={setDialogPayment}
                setClickedButtonGoBack={setClickedButtonGoBack}
              />
            }
          />
          <DialogSendEmailsTable
            open={openEmail}
            setOpen={setOpenEmail}
            dataReceiptsDetailsSelected={dataEmails}
            showSnackBar={showSnackBar}
          />
          <SnackbarComponent />

        </div>
      }
    </>
  )
}
