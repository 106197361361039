import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { Warehouse } from '../../../interfaces/Inventory/warehouses';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../components/Header';
import { ButtonStyled, PrimaryButton } from '../../../theme/buttons';
import { Autocomplete, Box, TextField } from '@mui/material';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Country } from '../../../interfaces/Commons/Locations/countries';
import { State } from '../../../interfaces/Commons/Locations/states';
import { City } from '../../../interfaces/Commons/Locations/cities';
import { Divider } from '../../../components/Commons/Divider';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const AddWarehouse = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericGetResource, GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboCountries, setComboCountries] = useState<Country[]>([]);
  const [comboStates, setComboStates] = useState<State[]>([]);
  const [comboCities, setComboCities] = useState<City[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Warehouse>({
    defaultValues: {
      warehouse_code: "",
      warehouse_description: "",
      stock: undefined,
      country_id: undefined,
      state_id: undefined,
      city_id: undefined,
      postal_code: "",
      street: "",
    }
  });
  const onSubmit: SubmitHandler<Warehouse> = (data) => onPost(data);

  const onPost = (data: Warehouse) => {
    let myData = {
      warehouse_code: data.warehouse_code,
      warehouse_description: data.warehouse_description,
      stock: data.stock ?? 0,
      country_id: data.country_id ?? null,
      state_id: data.state_id ?? null,
      city__id: data.city_name ?? null,
      postal_code: data.postal_code ?? null,
      street: data.street ?? null,
    }
    setLoadingPost(true);
    GenericPostResource("/warehouses", myData)
      .then((response) => {
        navigate("/warehouses", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    navigate("/warehouses", { state: {} });
  }

  useEffect(() => {

    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("warehouses.title")}`;
    })

    let myPromises = [
      GenericGetResource("/countries"),
      GenericGetResource("/states"),
      GenericGetResource("/cities"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboCountries(responses[0].data.data);
        setComboStates(responses[1].data.data);
        setComboCities(responses[2].data.data);

        
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {dataLoaded &&
        <>
          <Header title={t("warehouses.title-view")} />

          <div className='d-flex justify-content-center'>

            <div className='custom-card-view w-75'>

              <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                <div className='w-100'>

                  <div className='d-flex'>

                    <div className='w-50'>
                      <Controller
                        name="warehouse_code"
                        control={control}
                        rules={{ required: true, maxLength: 50 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("warehouses.fields.warehouse_code")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.warehouse_code}
                            helperText={GetError(errors.warehouse_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="stock"
                        control={control}
                        rules={{ min: 0 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("warehouses.fields.stock")}`}
                            type='number'
                            ref={field.ref}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.stock}
                            helperText={GetError(errors.stock?.type)}
                            size="small"
                            style={{ width: "100%" }}
                          />
                        }
                      />
                    </div>

                  </div>

                  <div className='my-2 pt-2'>

                    <Controller
                      name="warehouse_description"
                      control={control}
                      rules={{ required: true, maxLength: 40 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          ref={field.ref}
                          value={field.value}
                          label={`${t("warehouses.fields.warehouse_description")}`}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.warehouse_description}
                          helperText={GetError(errors.warehouse_description?.type)}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      }
                    />

                  </div>

                </div>

                <Divider />

                <div className='d-flex'>
                  <div className='w-50'>
                    <Controller
                      name="country_id"
                      control={control}
                      rules={{}}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          options={comboCountries}
                          getOptionLabel={(option) => `${option.country_code} - ${option.country_name}`}
                          renderOption={(props, option: Country) => (
                            <div key={option.country_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.country_code} - {option.country_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                          onChange={(_, values) => {
                            field.onChange(values?.country_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("warehouses.fields.country")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.country_id}
                              helperText={GetError(errors.country_id?.type)}
                              sx={{ paddingRight: 2 }}
                            />
                          )}
                        />
                      }
                    />
                  </div>

                  <div className='w-50'>
                    <Controller
                      name="state_id"
                      control={control}
                      rules={{ maxLength: 10 }}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          options={comboStates}
                          getOptionLabel={(option) => `${option.state_code} - ${option.state_name}`}
                          renderOption={(props, option: State) => (
                            <div key={option.state_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.state_code} - {option.state_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.state_id === value.state_id}
                          onChange={(_, values) => {
                            field.onChange(values?.state_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("warehouses.fields.state")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.state_id}
                              helperText={GetError(errors.state_id?.type)}
                            />
                          )}
                        />
                      }
                    />
                  </div>
                </div>

                <div className='d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="city_id"
                      control={control}
                      rules={{ maxLength: 30 }}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          options={comboCities}
                          getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                          renderOption={(props, option: City) => (
                            <div key={option.city_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.city_code} - {option.city_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                          onChange={(_, values) => {
                            field.onChange(values?.city_id || null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("warehouses.fields.city_name")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.city_id}
                              helperText={GetError(errors.city_id?.type)}
                              sx={{ paddingRight: 2 }}
                            />
                          )}
                        />
                      }
                    />
                  </div>

                  <div className='w-50'>
                    <Controller
                      name="street"
                      control={control}
                      rules={{ maxLength: 60 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("warehouses.fields.street")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.street}
                          helperText={GetError(errors.street?.type)}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      }
                    />
                  </div>
                </div>

                <div className='d-flex mt-3'>
                  <div className='w-50'>
                    <Controller
                      name="postal_code"
                      control={control}
                      rules={{ maxLength: 5 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("warehouses.fields.postal_code")}`}
                          ref={field.ref}
                          type="number"
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                            "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                          }}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.postal_code}
                          helperText={GetError(errors.postal_code?.type)}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      }
                    />
                  </div>
                </div>

                <div className='d-flex flex-row-reverse mt-1'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                  <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                </div>

              </form>

            </div>

          </div>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
