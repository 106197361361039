import React, { useEffect, useState } from 'react'
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { ProjectTask } from '../../../../interfaces/Projects/Catalogs/projectTasks';
import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import GenericPromises from '../../../../api/GenericPromises';
import { TaskState } from '../../../../interfaces/Projects/Catalogs/taskStates';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { useTranslation } from 'react-i18next';
import { ProjectStage } from '../../../../interfaces/Projects/Catalogs/projectStages';
import { Project } from '../../../../interfaces/Projects/projects';
import { useDates } from '../../../../hooks/useDates';
import { DialogEntity } from '../../../../components/Dialogs/DialogEntity';
import { Header } from '../../../../components/Header';
import { ConfirmDialog } from './dialogs/confirmDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useParamsFilter } from '../../../../hooks/useParamsFilter';
import { ProjectTaskDashboard } from '../../../../interfaces/Projects/projectTaskDashboard';
import { format } from 'date-fns';


interface TaskCardProps {
  task: ProjectTaskDashboard,
  color: number[] | undefined;
  isEdit: boolean
}

export const TaskCard = ({
  task,
  color,
  isEdit
}: TaskCardProps) => {
  const [t] = useTranslation("global");
  const [taskStates, setTaskStates] = useState<TaskState[]>([]);
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const [dataloaded, setDataLoaded] = useState(false);
  const [onChecked, setOnChecked] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [projectStage, setProjectStage] = useState<ProjectStage>();
  const [project, setProject] = useState<Project>();
  const { TimeConverter, ReverseTimeConverter } = useDates();
  const [dialogConfirmUncheckTask, setDialogConfirmUncheckTask] = useState(false);
  const { ConvertParamsToBase64 } = useParamsFilter();

  const onPut = (currentTaskStateId: number) => {
    const myData = {
      // project_stage_id: task?.project_stage_id,
      // project_group_id: projectTask?.project_group_id,
      current_task_state_id: currentTaskStateId,
      // MS_task_id: projectTask?.MS_task_id ?? null,
      // MS_due_date_time: projectTask?.MS_due_date_time ?? null,
      // MS_start_date_time: projectTask?.MS_start_date_time ?? null,
      // MS_priority: projectTask?.MS_priority ?? null,
      // MS_applied_categories: projectTask?.MS_applied_categories ?? null,
      // MS_description: projectTask?.MS_description ?? null,
      // MS_show_in_planner: projectTask?.MS_show_in_planner ?? null,
      // project_task_name: task.project_task_name,
      // project_task_description: projectTask?.project_task_description ?? null,
      // project_task_started_at: projectTask?.project_task_started_at ?? null,
      // project_task_due_date:projectTask?.project_task_due_date ?? null,
      // project_task_notes: projectTask?.project_task_notes ?? null,
      // field_type_id:projectTask?.field_type_id
    };
    setLoadingPut(true);
    GenericPutResource(`/projecttask/${task.project_task_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPut(false);
      })
      .catch((error) => {
        setOnChecked((prevChecked) => !prevChecked);
        setLoadingPut(false);
        showSnackBar(t(error.response.data), "error");
        return;
      });
  };

  const handleTaskCompletion = () => {
    const doneState = taskStates.find(
      (state) => state.task_state_name === "DONE"
    );
    const pendingState = taskStates.find(
      (state) => state.task_state_name === "PENDING"
    );

    if (onChecked) {
      if (pendingState) {
        if (pendingState.task_state_id) {
          onPut(pendingState.task_state_id);
        }
      }
    } else {
      if (doneState) {
        if (doneState.task_state_id) {
          onPut(doneState.task_state_id);
        }
      }
    }

    setOnChecked((prevChecked) => !prevChecked);
  };

  const handleOpenProjectDetail = (event: any) => {
    event.stopPropagation();
    if (project) {
      const stringConverted = ConvertParamsToBase64(`project_id=${project?.project_id}`);
      window.open(`/projectsdetails/view?${stringConverted}`, '_blank');
    }
  };


  useEffect(() => {
    GenericGetResource(`/taskstates`)
      .then(async (response) => {
        setTaskStates(response.data.data);
        const currentState = response.data.data.find(
          (state: TaskState) =>
            state.task_state_id === task.current_task_state_id
        );
        if (currentState && currentState.task_state_name === "DONE") {
          setOnChecked((prevChecked) => true);
        } else {
          setOnChecked((prevChecked) => false);
        }
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GenericGetResource(`/projectstages/${task.project_stage_id}`)
      .then(async (response) => {
        setProjectStage(response.data)
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, [])

  useEffect(() => {
    if (projectStage) {
      GenericGetResource(`/projects/${projectStage.project_id}`)
        .then(async (response) => {
          setProject(response.data)
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        })
    }

  }, [projectStage])



  return (
    <>
      {!dataloaded &&
        <div className="w-100 my-2">
          <Spinner isBox={false} />
        </div>
      }
      {dataloaded && (
        <Grid item key={task.project_task_id} xs={12} sx={{ marginBlock: 2 }}>
          <Box
            sx={{
              borderRadius: "8px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              justifyContent: "space-between",
              backgroundColor: color
                ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.2)`
                : "rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: color
                  ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.09)`
                  : "rgba(0, 0, 0, 0.1)",
              },
              display: "flex",
              alignItems: "center",
              padding: "8px",
            }}

          >
            <FormControlLabel
              sx={{ marginLeft: 0.1 }}
              control={
                <Checkbox
                  checked={onChecked}
                  onChange={(e) => {
                    if (onChecked) {
                      setDialogConfirmUncheckTask(true);
                      return
                    }
                    handleTaskCompletion();
                  }}
                  icon={<CircleOutlined />}
                  checkedIcon={<CheckCircle />}
                  sx={{
                    color: "primary.main",
                    "&:hover": {
                      color: "primary.main",
                    },
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                  }}
                />
              }
              label={
                <>
                </>
              }

              disabled={loadingPut}
            />
            <Box>
              <span
                style={{
                  textDecoration: onChecked ? "line-through" : "none",
                  color: onChecked ? "gray" : "inherit",
                }}
              >
                {task.project_task_name} {project && project.project_name} {projectStage && projectStage.project_stage_name}
              </span>
              {project && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  <>
                    {t("projecttaskdashboard.fields.due-date")}: {
                      task.project_task_due_date 
                      ? (() => {
                          const convertedDate = ReverseTimeConverter(task.project_task_due_date.toString());
                          return convertedDate ? format(convertedDate, 'dd/MM/yyyy HH:mm:ss') : ' ';
                        })()
                      : ' '
                    }. {' '}
                    {t("projecttaskdashboard.fields.customer")}: {project.business_name}. {' '}
                    {t("projecttaskdashboard.fields.project-manager")}: {project.user_name}. {' '}
                    {t("projecttaskdashboard.fields.notes")}: {project.project_comments}. {' '}
                  </>
                </Typography>
              )}
            </Box>
            <FontAwesomeIcon icon={faCircleInfo} style={{ marginLeft: 'auto', cursor: 'pointer', width: 'auto', height: 25, marginRight: 1 }} onClick={handleOpenProjectDetail} />

          </Box>
        </Grid>
      )}
      <DialogEntity
        open={dialogConfirmUncheckTask}
        title={<Header title={t("projecttasks.title-view")} />}
        maxWidth={"sm"}
        fullWidth
        content={
          <ConfirmDialog
            projectTaskName={task.project_task_name}
            isEdit={isEdit}
            loadingPut={loadingPut}
            setDialogConfirmUncheckTask={setDialogConfirmUncheckTask}
            handleTaskCompletion={handleTaskCompletion}
          />
        }
      />
      <SnackbarComponent />
    </>
  )
}
