import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { PrimaryButton } from "../../../../../theme/buttons";
import { ProjectTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectsTemplate";
import { ProjectType } from "../../../../../interfaces/Projects/Catalogs/projectTypes";
import { Menuitem } from "../../../../../interfaces/Security/menu";

type UpdateProjectsTemplateProps = {
  projectsTemplatePayload: ProjectTemplate | null;
  permissions: Menuitem;
  setOpenDialogUpdateProjectTemplate: Dispatch<SetStateAction<boolean>>;
  projectTypeIds: number[];
  onUpdate: (updatedTemplate: ProjectTemplate) => void;
  onDeleteRow: (deleteTemplate: ProjectTemplate) => void;
};

export const UpdateProjectsTemplate = ({
  projectsTemplatePayload,
  permissions,
  setOpenDialogUpdateProjectTemplate,
  projectTypeIds,
  onUpdate,
  onDeleteRow,
}: UpdateProjectsTemplateProps) => {
  const {
    GenericPutResource,
    GenericDeleteResource,
    GenericGetResource,
    GenericGetResourceGeneric,
  } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboProjectTypes, setComboProjectTypes] = useState<ProjectType[]>([]);
  const [defaultProjectType, setDefaultProjectType] = useState<ProjectType>();
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const [openDialogDeleteProjectTemplate, setOpenDialogDeleteProjectTemplate] =
    useState(false);
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ProjectTemplate>();

  const onSubmit: SubmitHandler<ProjectTemplate> = (data) => onPut(data);

  const onPut = (data: ProjectTemplate) => {
    let myData = {
      project_type_id: data.project_type_id,
      project_template_name: data.project_template_name,
      project_template_comments: data.project_template_comments,
    };
    setLoadingPost(true);
    GenericPutResource(
      `/projectstemplate/${projectsTemplatePayload?.project_template_id}`,
      myData
    )
      .then((response) => {
        onUpdate(response.data);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        setOpenDialogUpdateProjectTemplate(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  };

  const onDelete = () => {
    GenericDeleteResource(
      `/projectstemplate/${projectsTemplatePayload?.project_template_id}`
    )
      .then((response) => {
        if (projectsTemplatePayload) {
          onDeleteRow(projectsTemplatePayload);
        }
        setOpenDialogUpdateProjectTemplate(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialogUpdateProjectTemplate(false);
      });
  };

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t(
        "projectstemplate.title"
      )}`;
    });

    let myPromises = [
      GenericGetResource("/projecttypes"),
      GenericGetResource("/projectstemplate"),
    ];
    Promise.all(myPromises)
      .then((responses) => {
        const validProjectTypeIds = projectTypeIds.filter(
          (id): id is number => id !== undefined
        );
        const filteredProjectTypes = responses[0].data.data.filter(
          (projectType: ProjectType) =>
            (projectType.project_type_id !== undefined &&
              !projectTypeIds.includes(projectType.project_type_id)) ||
            projectType.project_type_id ===
              projectsTemplatePayload?.project_type_id
        );
        setComboProjectTypes(filteredProjectTypes);

        reset({
          project_type_id: projectsTemplatePayload?.project_type_id,
          project_template_name: projectsTemplatePayload?.project_template_name,
          project_template_comments:
            projectsTemplatePayload?.project_template_comments,
        });

        setDefaultProjectType(
          responses[0].data.data.find(
            (projectType: ProjectType) =>
              projectType.project_type_id ===
              projectsTemplatePayload?.project_type_id
          )
        );
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1">
              <div className="w-50">
                <Controller
                  name="project_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboProjectTypes}
                      defaultValue={defaultProjectType}
                      getOptionLabel={(option) => `${option.project_type_name}`}
                      renderOption={(props, option: ProjectType) => (
                        <div key={option.project_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.project_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.project_type_id === value.project_type_id
                      }
                      onChange={(_, values) => {
                        field.onChange(values?.project_type_id || null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("projectstemplate.fields.project_type")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_type_id}
                          helperText={GetError(errors.project_type_id?.type)}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_template_name"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstemplate.fields.project_template_name"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_template_name}
                      helperText={GetError(errors.project_template_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex w-100 pt-3">
              <div className="w-100">
                <Controller
                  name="project_template_comments"
                  control={control}
                  rules={{ maxLength: 1500 }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstemplate.fields.project_template_comments"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      multiline
                      minRows={5}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_template_comments}
                      helperText={GetError(
                        errors.project_template_comments?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex flex-row-reverse mt-4 justify-content-between">
              <div>
                <Button
                  variant="outlined"
                  className="m-1"
                  onClick={() => setOpenDialogUpdateProjectTemplate(false)}
                >
                  {t("generic.buttons.goback")}
                </Button>
                {permissions.update && (
                  <PrimaryButton
                    type="submit"
                    variant="outlined"
                    className="m-1"
                    disabled={loadingPost}
                  >
                    {t("generic.buttons.update")}
                  </PrimaryButton>
                )}
              </div>
              {permissions.delete ? (
                <PrimaryButton
                  onClick={() => setOpenDialogDeleteProjectTemplate(true)}
                >
                  {t("generic.buttons.delete")}
                </PrimaryButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </Box>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projectstagestemplate.dialog.delete")}
        open={openDialogDeleteProjectTemplate}
        setOpen={setOpenDialogDeleteProjectTemplate}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  );
};
