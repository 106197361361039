import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import GenericPromises from '../../../../api/GenericPromises';
import { Controller, useForm } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import { ButtonLoading, ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { UserCompanies } from '../../../../interfaces/Security/userCompanies';
import { GenericDialog } from '../../../../components/Dialogs/Dialog';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { Schema } from '../../../../interfaces/Security/schemas';
import { AuthContext } from '../../../../context/AuthContext';

export const UpdateUserCompanies = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericDeleteResource, GenericGetResource } = GenericPromises();
  const { user, logOut } = React.useContext(AuthContext);
  const { GetError } = FormsErrors();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogAcept, setopenDialogAcept] = useState(false);
  const [messageSnack, setMessageSnack] = useState("");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [isLoading, setisLoading] = useState(false)
  const { userCompaniesPayload, permissions } = props;
  const isEdit = !permissions.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UserCompanies>({
    defaultValues: {
      company_id: undefined,
      business_name: "",
    }
  });

  const onDelete = (isAccepted: boolean = false) => {
    setopenDialogAcept(false);
    setOpenDialog(false);
    setisLoading(true);
    if (userCompaniesPayload.user_id === user?.user_id &&
      localStorage.getItem("schemaName")?.toLocaleUpperCase() === userCompaniesPayload.schema_name &&
      !isAccepted) {
      setisLoading(false);
      setopenDialogAcept(true)
      return;
    }
    GenericDeleteResource(`/usercompanies/${userCompaniesPayload.user_company_id}/byschema/${userCompaniesPayload.schema_id}`)
      .then(() => {
        setisLoading(false);
        props.DeleteRow(userCompaniesPayload?.user_company_id);
        props.setOpenDialog(false);
        if (isAccepted) {
          logOut();
        }
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setMessageSnack(error.message);
        setOpenDialog(false);
        setisLoading(false);
      })
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  useEffect(() => {
    reset({
      business_name: userCompaniesPayload.business_name ?? "",
    })
    setDataLoaded(true)
  }, []);

  return (
    <>
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 500 }}>

          <form className='mt-4 w-100'>

            <div className='d-flex'>

              <div className='w-100'>
                <Controller
                  name="business_name"
                  control={control}
                  rules={{ required: true, maxLength: 60 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("userCompanies.title-view")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.business_name}
                      helperText={GetError(errors.business_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={true}
                    />
                  }
                />
              </div>

            </div>

            <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
              {permissions.delete ?
                <ButtonLoading isLoading={isLoading} onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonLoading> : <></>
              }
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel} disabled={isLoading}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("userCompanies.fields.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={() => { onDelete() }}
      />
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("userCompanies.fields.acept_same_schema")}
        open={openDialogAcept}
        setOpen={setopenDialogAcept}
        onAcept={() => { onDelete(true) }}
      />
      <SnackbarComponent />
    </>
  )
}
