import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { InvoiceDocumentType, SalesInvoice, SalesInvoiceDetail } from '../../../interfaces/Sales/Invoices/salesinvoices';
import GenericPromises from '../../../api/GenericPromises';
import { Menuitem } from '../../../interfaces/Security/menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../components/Header';
import { UpdateSalesInvoices } from './update';
import InvoiceDetail from './invoiceDetail';
import { Invoicing } from './Tabs/invoicing';
import { usePermissions } from '../../../hooks/usePermissions';
import Swal from 'sweetalert2';
import { File } from '../../../interfaces/Commons/files';
import { Autocomplete, Backdrop, Box, Button, CircularProgress, ListItemButton, Popover, TextField, useTheme } from '@mui/material';
import { Company } from '../../../interfaces/Security/companies';
import { UpdateInvoiceHeader } from './updateInvoiceHeader';
import { PaymentMethod } from '../../../interfaces/Sales/Catalogs/paymentMethods';
import { PaymentMode } from '../../../interfaces/Sales/Catalogs/paymentModes';
import { Currency, ExchangeRateHistory } from '../../../interfaces/Commons/currencies';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { CFDI } from '../../../interfaces/Sales/Catalogs/CFDIs';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CustomerLocation } from '../../../interfaces/Sales/Catalogs/Customers/customers';
import { CancellationReason } from '../../../interfaces/Sales/Invoices/Cancellations/cancellationReason';
import { AnnotationsSalesInvoice } from './Tabs/annotations';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { DialogCancelFiscalSignature } from './helpers/dialogCancelFiscalSignature';
import { useFiles } from '../../../hooks/useFiles';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { ForeignTradetab } from './ForeignTrade/index';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import { CopySalesInvoice } from './actions/copySalesInvoice';
import { GenericDialog } from '../../../components/Dialogs/Dialog';
import { DialogEntity } from '../../../components/Dialogs/DialogEntity';
import { ButtonStyled, myStylePickers } from '../../../theme/buttons';
import { PaymentCondition } from '../../../interfaces/Sales/Catalogs/paymentConditions';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { MerchandiseDetail } from '../../../interfaces/Sales/Catalogs/TransportationDocuments/transportationDocument';
import { IndexTransportationDocument } from './TransportationDocument';
import { ShippingTrackingTab } from './ShippingTracking';
import { DialogFiscalSignature } from './helpers/dialogFiscalSignature';
import { useDates } from '../../../hooks/useDates';
import { SalesInvoicePDF } from '../../../reports/salesInvoicePDF/SalesInvoicePDF';
import { RelatedSalesInvoicesIndex } from './RelatedSalesInvoices';
import { DialogSendEmailSingular } from './helpers/dialogSendEmailSingular';
import { useParamsFilter } from '../../../hooks/useParamsFilter';
import { Project } from '../../../interfaces/Projects/projects';
import { TaxRegime } from '../../../interfaces/Sales/Catalogs/taxRegimes';

export const ViewSalesInvoices = () => {
  const [rows, setRows] = useState<SalesInvoiceDetail[]>([]);
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const { TimeConverter, GetDateCalculatedByAfterDays } = useDates();
  const { GetParamsFromBase64 } = useParamsFilter();
  const [salesInvoiceLocal, setSalesInvoiceLocal] = useState<SalesInvoice>();
  const [dataloaded, setDataLoaded] = useState(false);
  const [isAccounted, setIsAccounted] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetFiles, GetFileById, GetFileIfExists } = useFiles();
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [comboPaymentModes, setComboPaymentModes] = useState<PaymentMode[]>([]);
  const [comboCFDIs, setComboCFDIs] = useState<CFDI[]>([]);
  const [comboInvoiceDocumentTypes, setComboInvoiceDocumentTypes] = useState<InvoiceDocumentType[]>([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [defaultPaymentMode, setDefaultPaymentMode] = useState<PaymentMode>();
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [defaultCFDI, setDefaultCFDI] = useState<CFDI>();
  const [defaultInvoiceDocumentType, setDefaultInvoiceDocumentType] = useState();
  const [isDeletedInvoice, setIsDeletedInvoice] = useState(false);
  const [comboCustomerLocations, setComboCustomerLocations] = useState<CustomerLocation[]>([]);
  const [defaultCustomerLocation, setDefaultCustomerLocation] = useState<CustomerLocation>();
  const [fiscalQr, setFiscalQr] = useState<File>({});
  const [fiscalXML, setFiscalXML] = useState<File>({ dataURL: '' });
  const [myConsecutive, setMyConsecutive] = useState("");
  const [includesTransportationDocument, setIncludesTransportationDocument] = useState(true);
  const [includesExteriorComplement, setIncludesExteriorComplement] = useState(true);
  const [resourceFiscalSignature, setResourceFiscalSignature] = useState<Menuitem>();
  const [resourceCancelFiscalSignature, setResourceCancelFiscalSignature] = useState<Menuitem>();
  const [resourceSendEmail, setResourceSendEmail] = useState<Menuitem>();
  const [resourceCopySalesInvoice, setResourceCopySalesInvoice] = useState<Menuitem>();
  const [resourceAccounted, setResourceAccounted] = useState<Menuitem>();
  const { GenericPutResource, GenericGet, GenericPostResource, GenericPatchResource, GenericPost, GenericGetResource, GenericPutAction, GenericGetReport, GenericDeleteResource, GenericGetResourceGeneric } = GenericPromises();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [isChangedTotalValue, setIsChangedTotalValue] = useState(false);
  const { GetResourceByUrl } = usePermissions();
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [comboCancellationReasons, setComboCancellationReasons] = useState<CancellationReason[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [openDialogCancelFiscalSignature, setOpenDialogCancelFiscalSignature] = useState(false);
  const [openDialogCopySalesInvoice, setOpenDialogCopySalesInvoice] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [company, setCompany] = useState<Company>();
  const [myLocalConsecutive, setMyLocalConsecutive] = useState("");
  const [myLocalInvoiceDocType, setMyLocalInvoiceDocType] = useState(0);
  const [locationEdit, setLocationEdit] = useState(false);
  const [defaultPaymentConditions, setDefaultPaymentConditions] = useState<PaymentCondition>()
  const [comboPaymentConditions, setComboPaymentConditions] = useState<PaymentCondition[]>([])
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogFiscalSignature, setOpenDialogFiscalSignature] = useState(false);
  const [isLoadingFiscal, setIsLoadingFiscal] = useState(false);
  const [isNotExchange, setIsNotExchange] = useState(false);
  const [hasCancelUuid, setHasCancelUuid] = useState(false);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [openEmail, setOpenEmail] = useState(false);
  const [dataSalesInvoiceSelected, setDataSalesInvoiceSelected] = useState<any>([]);
  const [loadingPut, setLoadingPut] = useState(false);
  const [comboProjects, setComboProjects] = useState<Project[]>([]);
  const [defaultProject, setDefaultProject] = useState<Project>();
  const [customerId, setCustomerId] = useState(0);

  const refTransportationDocument = useRef<any>();
  const refForeignTrade = useRef<any>();

  const { generatePDF } = SalesInvoicePDF();

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const { GetError } = FormsErrors();

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? 'simple-popover' : undefined;
  const id2 = open2 ? 'simple-popover' : undefined;

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    trigger
  } = useForm<SalesInvoice>({
    defaultValues: {
      sales_invoice_id: undefined,
      sales_invoice_name: undefined,
      sales_invoice_date: undefined,
      purchase_order: undefined,
      customer_id: undefined,
      customer_business_name: undefined,
      customer_comercial_name: undefined,
      customer_rfc: undefined,
      customer_street_name: undefined,
      customer_location_id: 0,
      customer_postal_code: undefined,
      customer_city_id: undefined,
      customer_city_name: undefined,
      customer_state_id: undefined,
      customer_state_name: undefined,
      customer_state_abbr: undefined,
      customer_country_id: undefined,
      customer_country_name: undefined,
      customer_country_code: undefined,
      subtotal_amount: 0,
      total_amount: 0,
      tax_amount: 0,
      currency_id: undefined,
      currency_code: undefined,
      currency_description: undefined,
      project_id: undefined,
      invoice_doc_type_id: undefined,
      invoice_doc_status_id: undefined,
      payment_mode_id: undefined,
      payment_mode_code: undefined,
      payment_mode_description: undefined,
      payment_method_id: undefined,
      payment_method_code: undefined,
      payment_method_name: undefined,
      cfdi_id: undefined,
      cfdi_code: undefined,
      cfdi_description: undefined,
      discount_amount: 0,
      original_string_SAT: undefined,
      certificate_number_SAT: undefined,
      certificate_number_CFDI: undefined,
      uuid: undefined,
      seal_SAT: undefined,
      seal_CFDI: undefined,
      signing_date: undefined,
      cancellation_date: undefined,
      cancellation_reason: undefined,
      cancellation_reason_description: undefined,
      cancellation_response_code: undefined,
      customer_tax_regime_id: undefined,
      customer_tax_regime_code: undefined,
      customer_tax_regime_description: undefined,
      includes_transportation_document: undefined,
      includes_exterior_complement: undefined,
      id_consecutive: undefined,
      is_accounted: undefined,
      notes: undefined,
      retention_amount: undefined,
      customer_language: undefined,
      customer_email: undefined,
      payment_condition_id: undefined,
      payment_condition_days: undefined,
      payment_condition_description: undefined,
      payment_condition_name: undefined,
      payment_due_date: undefined,
      customer_phone_number: undefined,
      sales_invoice_QR: undefined,
      sales_invoice_XML: undefined,
      cancelled_sales_invoice_XML: undefined,
      sales_invoice_pdf: undefined,
      company_business_name: undefined,
      company_street: undefined,
      company_city_id: undefined,
      company_city_name: undefined,
      company_city_code: undefined,
      company_state_id: undefined,
      company_state_name: undefined,
      company_state_abbr: undefined,
      company_country_id: undefined,
      company_country_name: undefined,
      company_country_code: undefined,
      company_postal_code: undefined,
      company_rfc: undefined,
      company_tax_regime_id: undefined,
      company_tax_regime_code: undefined,
      company_tax_regime_description: undefined,
      company_email: undefined,
      company_phone_number: undefined,
      company_account_alias: undefined,
      company_primary_account_alias: undefined,
      company_primary_bank_name: undefined,
      company_primary_account_number: undefined,
      company_primary_interbank_key: undefined,
      company_primary_swift_code: undefined,
      company_secondary_account_alias: undefined,
      company_secondary_bank_name: undefined,
      company_secondary_account_number: undefined,
      company_secondary_interbank_key: undefined,
      company_secondary_swift_code: undefined,
      creation_date: undefined,
      last_update_user: undefined,
      last_update_date: undefined,
    }
  });

  const onSubmit: SubmitHandler<SalesInvoice> = (data) => onPut(data);

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const CheckExchangeRateUsdForeignTrade = async (includes_exterior_complement: boolean, myCurrency: Currency) => {
    if (includes_exterior_complement) {
      // get Exchange rate USD 
      let myExchangeRate: ExchangeRateHistory | undefined;

      if (myCurrency?.currency_code === "USD") {
        return myExchangeRate = { rate: 1 };
      }
      else {
        let myCurrencyExchange = comboCurrencies.find((item) => (item.currency_code).toUpperCase() === "USD")

        myExchangeRate = await GenericGetResource(`/exchangeratehistory/bycurrencyid/${(myCurrencyExchange?.currency_id) ?? 0}`)
          .then((responseCurrency) => {
            let currentDate = getValues("sales_invoice_date");

            let myNewDate = new Date(currentDate ?? Date.now());
            if (myNewDate.getDay() === 1) {
              myNewDate.setDate(myNewDate?.getDate() - 3);
            }
            else if (myNewDate.getDay() === 0) {
              myNewDate.setDate(myNewDate?.getDate() - 2);
            }
            else {
              myNewDate.setDate(myNewDate?.getDate() - 1);
            }

            let myCurrencyValues: ExchangeRateHistory[] = responseCurrency.data.data;

            return myExchangeRate = myCurrencyValues.find((item) => {
              // Convertir la cadena de fecha a un objeto Date
              const itemDate = new Date(item.rate_date!);
              const formattedDate1: Date = new Date(`${itemDate.getFullYear()}-${itemDate.getMonth() + 1}-${itemDate.getDate()}`);
              const formattedDate2: Date = new Date(`${myNewDate.getFullYear()}-${myNewDate.getMonth() + 1}-${myNewDate.getDate()}`);

              return (formattedDate1.getTime() === formattedDate2.getTime());
            });
          });
      }
      // si no existe mandar advertencia para cambiar los valores de la moneda
      if (!myExchangeRate) {
        Swal.fire({
          customClass: { container: "swalfire" },
          title: `${t("salesinvoices.info.add-exchangeratehistory")}`,
          confirmButtonColor: theme.palette.primary.main,
          icon: "warning",
          allowOutsideClick: false
        });
        return undefined;
      }
      else {
        return myExchangeRate;
      }
    }
    return undefined;
  }

  const onPut = async (data: SalesInvoice) => {
    setLoadingPut(true);
    let myCustomerLocation = await comboCustomerLocations.find((element: CustomerLocation) => element.customer_location_id === data.customer_location_id);
    let myPaymentMode = await comboPaymentModes.find((item: PaymentMode) => item.payment_mode_id === data.payment_mode_id);
    let myPaymentMethod = await comboPaymentMethods.find((item: PaymentMethod) => item.payment_method_id === data.payment_method_id);
    let myCFDI = await comboCFDIs.find((item: CFDI) => item.cfdi_id === data.cfdi_id);
    let myPaymentCondition = await comboPaymentConditions.find((item: PaymentCondition) => item.payment_condition_id === data.payment_condition_id);
    let myCurrency = await comboCurrencies.find((item: Currency) => item.currency_id === data.currency_id);
    let myInvoiceDocumentType = await comboInvoiceDocumentTypes.find((item: InvoiceDocumentType) => item.invoice_doc_type_id === myLocalInvoiceDocType);

    let myCustomerTaxRegime: TaxRegime | undefined = undefined;
    if (myCustomerLocation) {
      let myTaxRegimeResponse = await GenericGetResource(`/taxregimes/${myCustomerLocation.tax_regime_id}`);
      myCustomerTaxRegime = myTaxRegimeResponse.data;
    }

    let myPutConsecutive = salesInvoiceLocal?.sales_invoice_name;

    if (myConsecutive !== salesInvoiceLocal?.sales_invoice_name) {
      const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myInvoiceDocumentType?.consecutive_id}`);
      myPutConsecutive = await responseConsecutive.data.data[0].Consecutive;
    }

    // get Exchange rate USD 
    let myExchangeRate: ExchangeRateHistory | undefined;

    if (myCurrency?.currency_code === "MXN") {
      myExchangeRate = { rate: 1 };
    }
    else {
      let myCurrencyExchange = await comboCurrencies.find((item) => (item.currency_code).toUpperCase() === myCurrency?.currency_code)

      await GenericGetResource(`/exchangeratehistory/bycurrencyid/${(myCurrencyExchange?.currency_id) ?? 0}`)
        .then((responseCurrency) => {
          let currentDate = data.sales_invoice_date;

          let myNewDate = new Date(currentDate ?? Date.now());
          if (myNewDate.getDay() === 1) {
            myNewDate.setDate(myNewDate?.getDate() - 3);
          }
          else if (myNewDate.getDay() === 0) {
            myNewDate.setDate(myNewDate?.getDate() - 2);
          }
          else {
            myNewDate.setDate(myNewDate?.getDate() - 1);
          }

          let myCurrencyValues: ExchangeRateHistory[] = responseCurrency.data.data;

          myExchangeRate = myCurrencyValues.find((item) => {
            // Convertir la cadena de fecha a un objeto Date
            const itemDate = new Date(item.rate_date!);
            const formattedDate1: Date = new Date(`${itemDate.getFullYear()}-${itemDate.getMonth() + 1}-${itemDate.getDate()}`);
            const formattedDate2: Date = new Date(`${myNewDate.getFullYear()}-${myNewDate.getMonth() + 1}-${myNewDate.getDate()}`);

            return (formattedDate1.getTime() === formattedDate2.getTime());
          });
        });
    }
    // si no existe mandar advertencia para cambiar los valores de la moneda
    if (!myExchangeRate) {
      Swal.fire({
        customClass: { container: "swalfire" },
        title: `${t("salesinvoices.info.add-exchangeratehistory")}`,
        confirmButtonColor: theme.palette.primary.main,
        icon: "warning",
        allowOutsideClick: false
      });

      setDefaultCurrency(comboCurrencies.find((currency: Currency) => currency.currency_id == salesInvoiceLocal?.currency_id));
      setValue("currency_id", comboCurrencies.find((currency: Currency) => currency.currency_id == salesInvoiceLocal?.currency_id)?.currency_id)
      setDefaultDate(new Date(salesInvoiceLocal?.sales_invoice_date ?? Date.now()));
      setValue("sales_invoice_date", new Date(salesInvoiceLocal?.sales_invoice_date ?? Date.now()))
      setIsNotExchange(true);
      setLoadingPut(false);
      return;
    }

    let myExchangeRateFT = await CheckExchangeRateUsdForeignTrade(getValues("includes_exterior_complement") ?? false, myCurrency!);

    var date = new Date();
    if (typeof data.sales_invoice_date == 'string')
      date = TimeConverter(data.sales_invoice_date) ?? new Date();
    else if (data.sales_invoice_date) {
      let currentDate = new Date();
      let invoiceDate = new Date(data.sales_invoice_date);
      invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
      date = invoiceDate;
    }
    let myData = {
      sales_invoice_name: myPutConsecutive,
      sales_invoice_date: date ?? new Date(data.sales_invoice_date ?? new Date()),
      purchase_order: data.purchase_order ?? null,
      customer_id: data.customer_id,
      customer_business_name: myCustomerLocation?.business_name ?? data?.customer_business_name,
      customer_comercial_name: data?.customer_comercial_name,
      customer_rfc: myCustomerLocation?.customer_rfc ?? data?.customer_rfc,
      customer_street_name: myCustomerLocation?.street ?? (data.customer_street_name ?? null),
      customer_location_id: myCustomerLocation?.customer_location_id ?? (data.customer_location_id ?? null),
      customer_postal_code: myCustomerLocation?.postal_code ?? (data.customer_postal_code ?? null),
      customer_city_id: 0,
      customer_city_name: myCustomerLocation?.city_name ?? (data.customer_city_name ?? null),
      customer_state_id: 0,
      customer_state_name: myCustomerLocation?.state_name ?? (data.customer_state_name ?? null),
      customer_state_abbr: myCustomerLocation?.state_abbr ?? (data.customer_state_abbr ?? null),
      customer_country_id: 0,
      customer_country_name: myCustomerLocation?.country_name ?? (data.customer_country_name ?? null),
      customer_country_code: myCustomerLocation?.country_code ?? (data.customer_country_code ?? null),
      subtotal_amount: data.subtotal_amount ?? 0,
      total_amount: data.total_amount ?? 0,
      tax_amount: data.tax_amount ?? null,
      currency_id: data.currency_id ?? null,
      currency_code: myCurrency?.currency_code ?? (data.currency_code ?? null),
      currency_description: myCurrency?.currency_description ?? (data.currency_description ?? null),
      project_id: data.project_id ?? null,
      invoice_doc_type_id: myInvoiceDocumentType?.invoice_doc_type_id ?? 1,
      invoice_doc_status_id: data.invoice_doc_status_id ?? 1,
      payment_mode_id: null,
      payment_mode_code: myPaymentMode?.code ?? (data.payment_mode_code ?? null),
      payment_mode_description: myPaymentMode?.description ?? (data.payment_mode_description ?? null),
      payment_method_id: null,
      payment_method_code: myPaymentMethod?.code ?? (data.payment_method_code ?? null),
      payment_method_name: myPaymentMethod?.name ?? (data.payment_method_name ?? null),
      cfdi_id: null,
      cfdi_code: myCFDI?.code ?? (data.cfdi_code ?? null),
      cfdi_description: myCFDI?.description ?? (data.cfdi_description ?? null),
      discount_amount: data.discount_amount ?? null,
      original_string_SAT: data.original_string_SAT ?? null,
      certificate_number_SAT: data.certificate_number_SAT ?? null,
      certificate_number_CFDI: data.certificate_number_CFDI ?? null,
      uuid: data.uuid ?? null,
      seal_SAT: data.seal_SAT ?? null,
      seal_CFDI: data.seal_CFDI ?? null,
      signing_date: data.signing_date ?? null,
      cancellation_date: data.cancellation_date ?? null,
      cancellation_reason: data.cancellation_reason ?? null,
      cancellation_reason_description: data.cancellation_reason_description ?? null,
      cancellation_response_code: data.cancellation_response_code ?? null,
      customer_tax_regime_id: 0,
      customer_tax_regime_code: myCustomerTaxRegime?.tax_regime_code ?? (data.customer_tax_regime_code ?? null),
      customer_tax_regime_description: myCustomerTaxRegime?.description ?? (data.customer_tax_regime_description ?? null),
      includes_transportation_document: data.includes_transportation_document ?? false,
      includes_exterior_complement: data.includes_exterior_complement ? (myExchangeRateFT ? true : false) : false,
      is_accounted: data.is_accounted ?? false,
      id_consecutive: myInvoiceDocumentType?.consecutive_id,
      notes: data.notes ?? null,
      retention_amount: data.retention_amount ?? null,
      payment_condition_id: data.payment_condition_id ?? null,
      payment_condition_days: myPaymentCondition?.payment_condition_days ?? (data.payment_condition_days ?? null),
      payment_condition_name: myPaymentCondition?.payment_condition_name ?? (data.payment_condition_name ?? null),
      payment_condition_description: myPaymentCondition?.payment_condition_description ?? (data.payment_condition_description ?? null),
      payment_due_date: data.payment_due_date ?? null,
      customer_language: data.customer_language ?? null,
      customer_email: myCustomerLocation?.customer_email ?? data.customer_email ?? null,
      customer_phone_number: myCustomerLocation?.customer_phone_number ?? data.customer_phone_number ?? null,
      sales_invoice_QR: data.sales_invoice_QR ?? null,
      sales_invoice_XML: data.sales_invoice_XML ?? null,
      cancelled_sales_invoice_XML: data.cancelled_sales_invoice_XML ?? null,
      sales_invoice_pdf: data.sales_invoice_pdf ?? null,
      exchange_rate: myExchangeRate?.rate,
      date_mail_send: data.date_mail_send ?? null,
      user_mail_send: data.user_mail_send ?? null,
      company_business_name: data.company_business_name ?? null,
      company_street: data.company_street ?? null,
      company_city_id: data.company_city_id ?? null,
      company_city_name: data.company_city_name ?? null,
      company_city_code: data.company_city_code ?? null,
      company_state_id: data.company_state_id ?? null,
      company_state_name: data.company_state_name ?? null,
      company_state_abbr: data.company_state_abbr ?? null,
      company_country_id: data.company_country_id ?? null,
      company_country_name: data.company_country_name ?? null,
      company_country_code: data.company_country_code ?? null,
      company_postal_code: data.company_postal_code ?? null,
      company_rfc: data.company_rfc ?? null,
      company_tax_regime_id: data.company_tax_regime_id ?? null,
      company_tax_regime_code: data.company_tax_regime_code ?? null,
      company_tax_regime_description: data.company_tax_regime_description ?? null,
      company_email: data.company_email ?? null,
      company_phone_number: data.company_phone_number ?? null,
      company_account_alias: data.company_account_alias ?? null,
      company_primary_account_alias: data.company_primary_account_alias ?? null,
      company_primary_bank_name: data.company_primary_bank_name ?? null,
      company_primary_account_number: data.company_primary_account_number ?? null,
      company_primary_interbank_key: data.company_primary_interbank_key ?? null,
      company_primary_swift_code: data.company_primary_swift_code ?? null,
      company_secondary_account_alias: data.company_secondary_account_alias ?? null,
      company_secondary_bank_name: data.company_secondary_bank_name ?? null,
      company_secondary_account_number: data.company_secondary_account_number ?? null,
      company_secondary_interbank_key: data.company_secondary_interbank_key ?? null,
      company_secondary_swift_code: data.company_secondary_swift_code ?? null,
    }
    if ((myData.invoice_doc_type_id == 2 && myData.total_amount > 0) || (myData.invoice_doc_type_id == 1 && myData.total_amount < 0)) {
      await GenericGetResource(`/salesinvoicedetails/bysalesinvoiceid/${salesInvoiceLocal?.sales_invoice_id}`)
        .then(async (response) => {
          let rowsToChange = rows;
          const updatedRows = await Promise.all(
            response.data.data.map(async (element: SalesInvoiceDetail) => {
              const myDataDetail = {
                price_by_unit: element.price_by_unit ? -element.price_by_unit : element.price_by_unit,
                subtotal_tax_amount: element.subtotal_tax_amount ? -element.subtotal_tax_amount : element.subtotal_tax_amount,
                subtotal_amount: element.subtotal_amount ? -element.subtotal_amount : element.subtotal_amount,
                total_amount: element.total_amount ? -element.total_amount : element.total_amount,
              };

              const responseDetail = await GenericPutResource(`/salesinvoicedetails/${element.sales_invoice_detail_id}`, myDataDetail);
              return responseDetail.data;
            })
          );

          const rowsToChangeUpdated = rowsToChange.map((row) =>
            updatedRows.find((updatedRow) => updatedRow.sales_invoice_detail_id === row.sales_invoice_detail_id) || row
          );

          setRows(rowsToChangeUpdated);
        })
    }
    GenericPutResource(`/salesinvoices/${salesInvoiceLocal?.sales_invoice_id}`, myData)
      .then(async (response) => {
        setValue("tax_amount", response.data.tax_amount);
        setValue("sales_invoice_name", response.data.sales_invoice_name);
        setValue("retention_amount", response.data.retention_amount);
        setValue("subtotal_amount", response.data.subtotal_amount);
        setValue("discount_amount", response.data.discount_amount);
        setValue("total_amount", response.data.total_amount);
        setValue("customer_business_name", response.data.customer_business_name);
        setValue("customer_street_name", response.data.customer_street_name);
        setValue("customer_city_name", response.data.customer_city_name);
        setValue("customer_state_name", response.data.customer_state_name);
        setValue("customer_state_abbr", response.data.customer_state_abbr);
        setValue("customer_country_name", response.data.customer_country_name);
        setValue("customer_country_code", response.data.customer_country_code);
        setValue("invoice_doc_type_id", response.data.invoice_doc_type_id);
        setValue("sales_invoice_name", response.data.sales_invoice_name);
        setValue("sales_invoice_id", response.data.sales_invoice_id);
        setValue("uuid", response.data.uuid);
        setValue("sales_invoice_name", response.data.sales_invoice_name);
        setValue("currency_id", response.data.currency_id);
        setValue("currency_code", response.data.currency_code);
        setValue("currency_description", response.data.currency_description);
        setValue("payment_condition_id", response.data.payment_condition_id);
        setValue("payment_condition_days", response.data.payment_condition_days);
        setValue("payment_condition_name", response.data.payment_condition_name);
        setValue("customer_tax_regime_code", response.data.customer_tax_regime_code);
        setValue("customer_tax_regime_description", response.data.customer_tax_regime_description);
        setMyLocalInvoiceDocType(response.data.invoice_doc_type_id);
        reset({
          ...response.data,
          original_string_SAT: response.data.original_string_SAT,
          certificate_number_SAT: response.data.certificate_number_SAT,
          certificate_number_CFDI: response.data.certificate_number_CFDI,
          customer_street_name: response.data.customer_street_name,
          customer_city_name: response.data.customer_city_name,
          customer_state_name: response.data.state_name,
          customer_state_abbr: response.data.state_abbr,
          customer_country_name: response.data.customer_country_name,
          customer_country_code: response.data.customer_country_code,
          uuid: response.data.uuid,
          seal_SAT: response.data.seal_SAT,
          seal_CFDI: response.data.seal_CFDI,
          signing_date: response.data.signing_date,
          cancellation_date: response.data.cancellation_date,
          cancellation_reason: response.data.cancellation_reason,
          cancellation_response_code: response.data.cancellation_response_code,
          is_accounted: response.data.is_accounted,
          sales_invoice_QR: response.data.sales_invoice_QR,
          sales_invoice_XML: response.data.sales_invoice_XML,
          cancelled_sales_invoice_XML: response.data.cancelled_sales_invoice_XML,
          sales_invoice_pdf: response.data.sales_invoice_pdf,
          invoice_doc_type_id: response.data.invoice_doc_type_id,
          payment_condition_id: response.data.payment_condition_id,
          payment_condition_days: response.data.payment_condition_days,
          payment_condition_name: response.data.payment_condition_name,
          customer_tax_regime_code: response.data.customer_tax_regime_code,
          customer_tax_regime_description: response.data.customer_tax_regime_description,
        }, { keepValues: true })
        let myCustomerLocation: CustomerLocation = {
          customer_location_id: 0,
          business_name: getValues("customer_business_name"),
          street: getValues("customer_street_name"),
          city_name: getValues("customer_city_name"),
          state_name: getValues("customer_state_name"),
          state_abbr: getValues("customer_state_abbr"),
          country_name: getValues("customer_country_name"),
          country_code: getValues("customer_country_code"),
        }
        setDefaultCustomerLocation((prev) => myCustomerLocation);
        setIsChangedTotalValue((prev) => !prev);
        setSalesInvoiceLocal((prev) => response.data);
        setLocationEdit(false);

        if (response.data.includes_transportation_document) {
          await GenericGetResource(`/transportationdocuments/bysalesinvoiceid/${response.data.sales_invoice_id}`)
            .then(async (response1) => {
              if (response1.data.data.length < 1) {
                let myTransportationDocument = {
                  sales_invoice_id: response.data.sales_invoice_id,
                  transportation_id: null,
                  vehicle_config_code: null,
                  transportation_code: null,
                  plate_number: null,
                  vin: null,
                  model_name: null,
                  model_year: null,
                  motor_number: null,
                  permission_sct: null,
                  permission_number_sct: null,
                  transportation_weight: null,
                  insurance_company: null,
                  insurance_policy: null,
                  insurance_expiration_date: null,
                  driver_id: null,
                  driver_type_id: null,
                  driver_name: null,
                  driver_curp: null,
                  driver_rfc: null,
                  driver_license: null,
                  transportation_document_date: null,
                  id_cpp: null,
                  is_transport_international: null,
                  departure_date: null,
                  arrival_date: null,
                  gross_total_weight: 0,
                  total_distance: 0,
                  total_merchandise: 0,
                }
                await GenericPostResource("/transportationdocuments", myTransportationDocument)
                  .catch((error) => {
                    showSnackBar(error.message, "error");
                    setLoadingPut(false);
                  });
              }
              await setIncludesTransportationDocument((prev) => !response.data.includes_transportation_document);
            });
        }
        if (response.data.includes_exterior_complement && myExchangeRateFT) {
          let myCustomerResponse = await GenericGetResource(`/customers/${salesInvoiceLocal?.customer_id}`);
          let myINCOTERM = await GenericGetResource(`/incoterms/${myCustomerResponse.data.default_incoterm_id ?? 0}`);
          let myCustomsKeyCode = await GenericGetResource(`/customskeycodes/${myCustomerResponse.data.default_customs_key_code_id ?? 0}`);
          await GenericGetResource(`/foreigntrades/bysalesinvoiceid/${response.data.sales_invoice_id}`)
            .then(async (response1) => {
              if (response1.data.data.length < 1) {

                let myForeignTrade = {
                  certificate_origin: '0',
                  customs_key_code_id: myCustomsKeyCode.data.customs_key_code_id ?? null,
                  customs_key_code: myCustomsKeyCode.data.customs_key_code ?? null,
                  customs_key_code_description: myCustomsKeyCode.data.customs_key_code_description ?? null,
                  incoterm_id: myINCOTERM.data.incoterm_id ?? null,
                  incoterm_code: myINCOTERM.data.incoterm_code ?? null,
                  incoterm_description: myINCOTERM.data.incoterm_description ?? null,
                  total_USD: 0,
                  exchange_rate_USD: myExchangeRateFT?.rate ?? 0,
                  sales_invoice_id: response.data.sales_invoice_id,
                }
                await GenericPostResource("/foreigntrades", myForeignTrade)
                  .catch((error) => {
                    showSnackBar(error.message, "error");
                    setLoadingPut(false);
                  });
              }
              else if (response1.data.data.length === 1) {
                let myForeignTrade = {
                  certificate_origin: '0',
                  customs_key_code_id: myCustomsKeyCode.data.customs_key_code_id ?? (response1.data.data[0].customs_key_code_id ?? null),
                  customs_key_code: myCustomsKeyCode.data.customs_key_code ?? (response1.data.data[0].customs_key_code ?? null),
                  customs_key_code_description: myCustomsKeyCode.data.customs_key_code_description ?? (response1.data.data[0].customs_key_code_description ?? null),
                  incoterm_id: myINCOTERM.data.incoterm_id ?? (response1.data.data[0].incoterm_id ?? null),
                  incoterm_code: myINCOTERM.data.incoterm_code ?? (response1.data.data[0].incoterm_code ?? null),
                  incoterm_description: myINCOTERM.data.incoterm_description ?? (response1.data.data[0].incoterm_description ?? null),
                  total_USD: response1.data.data[0].total_USD,
                  exchange_rate_USD: myExchangeRateFT?.rate ?? (response1.data.data[0].echange_rate_USD ?? 0),
                  sales_invoice_id: response.data.sales_invoice_id,
                }
                await GenericPutResource(`/foreigntrades/${response1.data.data[0].foreign_trade_id}`, myForeignTrade)
                  .then((responseForeignTrades) => { refForeignTrade && refForeignTrade.current.ChangeTotals(responseForeignTrades.data.foreign_trade_id) })
                  .catch((error) => {
                    showSnackBar(error.message, "error");
                    setLoadingPut(false);
                  });
              }
            });
          await setIncludesExteriorComplement((prev) => !response.data.includes_exterior_complement);
        }
        else {
          await setIncludesTransportationDocument((prev) => !response.data.includes_transportation_document);
          await setIncludesExteriorComplement((prev) => !response.data.includes_exterior_complement);
        }
        setLoadingPut(false);
        showSnackBar(t("generic.snackbar.update"), "success");
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPut(false);
      });
  }

  const onDeaccounted = async () => {
    await GenericPutAction(`/salesinvoices/isaccounted/${salesInvoiceLocal?.sales_invoice_id}`, { is_accounted: false }, false)
      .then(async () => {
        setIsAccounted((prev) => false);
        reset({ is_accounted: false }, { keepValues: true });
        setValue("is_accounted", false);
        setIsBackdrop(false);
        showSnackBar(t("generic.snackbar.update"), "success");
      })
      .catch((error) => {
        setIsAccounted(true);
        setIsBackdrop(false);
        showSnackBar(t(error.response.data), "error");
      });
  }

  const onAccounted = () => {
    trigger()
      .then(async (responseTrigger) => {
        if (responseTrigger) {
          let missingData = false;
          let missingTariffFraction = false;
          if (getValues("includes_transportation_document") && (getValues("uuid") === null || getValues("uuid") === undefined)) {
            await refTransportationDocument.current?.putTransportationDocument();
            await GenericGetResource(`/transportationdocuments/bysalesinvoiceid/${getValues("sales_invoice_id")}`)
              .then(async (response) => {
                setAnchorEl1(null);
                // si no tiene datos de transportationdocument
                if (response.data.data[0].driver_name === null || response.data.data[0].transportation_code === null) {
                  missingData = true;
                }
                else {
                  let myPromises = [
                    GenericGetResource(`/transportationdocumentlocations/bytransportationdocumentid/${response.data.data[0].transportation_document_id}`),
                    GenericGetResource(`/merchandisedetails/bytransportationdocumentid/${response.data.data[0].transportation_document_id}`),
                  ];

                  await Promise.all(myPromises)
                    .then((responses) => {
                      var dangerousMerchandises = responses[1].data.data.filter((merchandise: MerchandiseDetail) => merchandise.is_dangerous_material === true)
                      var CouldDangerousMerchandises = responses[1].data.data.filter((merchandise: MerchandiseDetail) => merchandise.could_be_hazardous_material === true)
                      // si no tiene por lo menos dos localizaciones
                      if (responses[0].data.data.length < 2) {
                        missingData = true;
                      }
                      // si no tiene por lo menos una linea de mercancías
                      else if (responses[1].data.data.length < 1) {
                        missingData = true;
                      }
                      //si no tiene poliza de ambiente
                      else if ((dangerousMerchandises.length > 0) &&
                        (response.data.data[0].environmental_insurance_company === undefined ||
                        response.data.data[0].environmental_insurance_policy === undefined ||
                        response.data.data[0].environmental_insurance_company === null ||
                        response.data.data[0].environmental_insurance_policy === null
                      )) {
                        missingData = true;
                      }
                      //si algun merchandise details no tiene cantidad o peso
                      else if (responses[1].data.data.find((merchandise: MerchandiseDetail) => parseInt(merchandise.quantity ?? "0") <= 0) ||
                        responses[1].data.data.find((merchandise: MerchandiseDetail) => parseInt(merchandise.weight_in_kg ?? "0") <= 0)) {
                        missingData = true;
                      }
                      //comprueba que tenga unidad
                      else if (responses[1].data.data.find((merchandise: MerchandiseDetail) => merchandise.unit_code === undefined) ||
                        responses[1].data.data.find((merchandise: MerchandiseDetail) => merchandise.unit_code === null)) {
                        missingData = true;
                      }
                      //si algun merchandise details no tiene algun codigo de material peligroso o embalaje
                      else if (dangerousMerchandises.length > 0 && (
                        dangerousMerchandises.find((merchandise: MerchandiseDetail) => merchandise.hazardous_material_key === undefined) ||
                        dangerousMerchandises.find((merchandise: MerchandiseDetail) => merchandise.packaging_type_code === undefined) ||
                        dangerousMerchandises.find((merchandise: MerchandiseDetail) => merchandise.hazardous_material_key === null) ||
                        dangerousMerchandises.find((merchandise: MerchandiseDetail) => merchandise.packaging_type_code === null)
                      )) {
                        missingData = true;
                      }
                      CouldDangerousMerchandises.forEach((element: MerchandiseDetail) => {
                        if (
                          (element.hazardous_material_key === undefined || element.hazardous_material_key === null) &&
                          (element.packaging_type_code === undefined || element.packaging_type_code === null)
                        ) {
                          return;
                        }
                        else if (
                          (element.hazardous_material_key !== undefined && element.hazardous_material_key !== null) &&
                          (element.packaging_type_code !== undefined && element.packaging_type_code !== null)
                        ) {
                          if(response.data.data[0].environmental_insurance_company === undefined ||
                            response.data.data[0].environmental_insurance_policy === undefined ||
                            response.data.data[0].environmental_insurance_company === null ||
                            response.data.data[0].environmental_insurance_policy === null
                          ){
                            missingData = true;
                          }
                          return;
                        }
                        else {
                          missingData = true;
                        }
                      });
                    })
                    .catch((error) => {
                      showSnackBar(error.message, "error");
                    });
                }
              })
              .catch((error) => {
                showSnackBar(error.message, "error");
              });
            if (missingData) {
              showSnackBar(t("transportationdocuments.info.missing_data"), "warning");
              await setIsBackdrop((prev) => false);
              return;
            }
          }
          if (getValues("includes_exterior_complement") && (getValues("uuid") === null || getValues("uuid") === undefined)) {
            await GenericGetResource(`/foreigntrades/bysalesinvoiceid/${getValues("sales_invoice_id")}`)
              .then(async (response) => {
                if (response.data.data.length > 0) {
                  setAnchorEl1(null);
                  await refForeignTrade && refForeignTrade.current?.trigger();
                  // si no tiene datos de foreigntrade
                  if (response.data.data[0].customs_key_code === null || response.data.data[0].incoterm_code === null) {
                    missingData = true;
                  }
                  else {
                    let myPromises = [
                      GenericGetResource(`/foreigntrademerchandises/byforeigntradeid/${response.data.data[0].foreign_trade_id}`),
                    ];

                    await Promise.all(myPromises)
                      .then((responses) => {
                        // si no tiene por lo menos dos localizaciones
                        if (responses[0].data.data.length < 1) {
                          missingData = true;
                        }
                      })
                      .catch((error) => {
                        showSnackBar(error.message, "error");
                      });
                  }
                  await GenericGetResource(`/salesinvoicedetails/bysalesinvoiceid/${salesInvoiceLocal?.sales_invoice_id}`)
                    .then((responseDetails) => {
                      for (let i = 0; i < responseDetails.data.data.length; i++) {
                        const element: SalesInvoiceDetail = responseDetails.data.data[i];
                        if (!missingTariffFraction && element.tariff_fraction_code === null) {
                          missingTariffFraction = true;
                        }
                      }
                    });
                  if (missingData) {
                    showSnackBar(t("foreigntrades.info.missing_data"), "warning");
                    await setIsBackdrop((prev) => false);
                    return;
                  }
                  else if (missingTariffFraction) {
                    showSnackBar(t("foreigntrades.info.missing_tariff_fraction_in_details"), "warning");
                    await setIsBackdrop((prev) => false);
                    return;
                  }
                }
              })
              .catch((error) => {
                showSnackBar(error.message, "error");
              });
          }
          await GenericGetResource(`/salesinvoicedetails/bysalesinvoiceid/${salesInvoiceLocal?.sales_invoice_id}`)
            .then(async (response) => {
              if (response.data.total > 0 && missingData === false && missingTariffFraction === false) {
                setValue("is_accounted", true);
                await GenericPutAction(`/salesinvoices/isaccounted/${salesInvoiceLocal?.sales_invoice_id}`, { is_accounted: true }, false)
                  .then(async () => {
                    reset({
                      is_accounted: true,
                    }, { keepValues: true })
                    setIsAccounted((prev) => getValues("is_accounted") ?? false);
                    await PostRelatedReceiptsDetails()
                      .catch(async (error) => {
                        setValue("is_accounted", false);
                        await GenericPutAction(`/salesinvoices/isaccounted/${salesInvoiceLocal?.sales_invoice_id}`, { is_accounted: true }, false)
                          .then(() => {
                            reset({
                              is_accounted: false,
                            }, { keepValues: true })
                            setIsAccounted((prev) => getValues("is_accounted") ?? false);
                            showSnackBar(error.message, 'error');
                          })
                        await setIsBackdrop((prev) => false);
                      });
                    await setIsBackdrop((prev) => false);
                  }).catch(async (error) => {
                    setValue("is_accounted", false);
                    showSnackBar(error.message, "error");
                    await setIsBackdrop((prev) => false);
                  });
              }
              else if (response.data.total === 0) {
                showSnackBar(t("salesinvoices.info.no_details"), "warning");
                await setIsBackdrop((prev) => false);
              }
            }).catch(async (error) => {
              showSnackBar(error.message, "error");
              await setIsBackdrop((prev) => false);
            });
        }
        else {
          await setIsBackdrop((prev) => false);
        }
      })
  }

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const onPutRelatedReceiptDetail = (uuid: string) => {
    // get receiptdetail by salesinvoice id
    GenericGetResource(`/relatedreceiptsdetails/bysalesinvoiceid/${salesInvoiceLocal?.sales_invoice_id}`)
      .then(async (response) => {
        // put uuid_related 
        let myRelatedReceiptDetail = {
          uuid_related: uuid,
        }

        await GenericPutResource(`/relatedreceiptsdetails/${response.data.data[0].related_receipt_detail_id}`, myRelatedReceiptDetail)
          .catch((error) => showSnackBar(error.message, 'error'))
      })
      .catch((error) => showSnackBar(error.message, 'error'));
  }

  const PostRelatedReceiptsDetails = async () => {

    let myPaymentDeadline: Date | undefined = undefined;
    if (getValues("payment_condition_days")) {
      myPaymentDeadline = await GetDateCalculatedByAfterDays(getValues("sales_invoice_date") ?? new Date(), getValues("payment_condition_days") ?? 0);
    }

    try {
      let myReceiptDetail = {
        current_receipt_status_id: 1,
        receipt_id: null,
        payment_deadline: myPaymentDeadline ?? null,
        payment_date: null,
        tax_id: null,
        tax_code: null,
        tax_description: null,
        tax_percent: null,
        retention_id: null,
        retention_code: null,
        retention_description: null,
        retention_percent: null,
        currency_id: null,
        currency_code: null,
        currency_description: null,
        exchange_rate: 0,
        payment_method_id: null,
        payment_method_code: null,
        payment_method_description: null,
        related_total_tax_amount: 0,
        related_total_retention_amount: 0,
        total_tax_amount: 0,
        total_retention_amount: 0,
        subtotal_amount: 0,
        total_amount: 0,
      }
      await GenericPostResource(`/receiptsdetails`, myReceiptDetail)
        .then(async (response) => {
          let myRelatedReceiptDetail = {
            receipt_detail_id: response.data.receipt_detail_id,
            sales_invoice_id: getValues("sales_invoice_id"),
            uuid_related: getValues("uuid"),
            sales_invoice_name: getValues("sales_invoice_name"),
            currency_id: getValues("currency_id"),
            currency_code: getValues("currency_code"),
            currency_description: getValues("currency_description"),
            equivalence: 0,
            partiality_number: 1,
            previous_amount: getValues("total_amount"),
            payment_amount: 0,
            amount_outstanding: getValues("total_amount"),
            tax_object_id: null,
            tax_object_code: null,
            tax_object_description: null,
            tax_id: null,
            tax_code: null,
            tax_description: null,
            tax_percent: 0,
            retention_id: null,
            retention_code: null,
            retention_description: null,
            retention_percent: 0,
            total_tax_amount: getValues("tax_amount") ?? 0,
            total_retention_amount: getValues("retention_amount") ?? 0,
          }
          myReceiptDetail.related_total_tax_amount = getValues("tax_amount") ?? 0;
          myReceiptDetail.related_total_retention_amount = getValues("retention_amount") ?? 0;
          let myPromises = [
            GenericPostResource(`/relatedreceiptsdetails`, myRelatedReceiptDetail),
            GenericPutResource(`/receiptsdetails/${response.data.receipt_detail_id}`, myReceiptDetail),
          ];

          await Promise.all(myPromises)
            .then(async (responses) => {
              let myNewStatus = {
                new_receipt_status_id: 1,
                previous_receipt_status_id: 1,
                receipt_detail_id: response.data.receipt_detail_id,
              }
              await GenericPostResource(`/receiptdetailstatuses`, myNewStatus)
                .catch(async (error) => {
                  await GenericDeleteResource(`/relatedreceiptsdetails/${responses[0].data.related_receipt_detail_id}`);
                  throw error;
                });
            })
            .catch(async (error) => {
              await GenericDeleteResource(`/receiptsdetails/${response.data.receipt_detail_id}`)
              showSnackBar(error.message, 'error');
              setValue("is_accounted", false);
              await onPut(getValues());
              reset({
                is_accounted: false,
              }, { keepValues: true })
              setIsAccounted((prev) => getValues("is_accounted") ?? false);
            })
        })
    }
    catch (error) {
      throw error
    }
  }

  const onPatchFiscalSignature = () => {
    setIsBackdrop((prev) => true);
    setOpenDialogFiscalSignature((prev) => false);
    trigger()
      .then(async (responseTrigger) => {
        if (getValues("uuid") === null && responseTrigger && getValues("is_accounted")) {
          setAnchorEl1(null);
          setIsLoadingFiscal(true);
          await GenericPatchResource(`/salesinvoices/fiscalsignature/${salesInvoiceLocal?.sales_invoice_id}`)
            .then(async (response) => {
              if (response.data.error) {
                Swal.fire({
                  customClass: { container: "swalfire" },
                  confirmButtonColor: theme.palette.primary.main,
                  icon: "error",
                  title: "Error",
                  html: `${response.data.error}${(response.data.details) != null ? '<br> <br>' + response.data.details : ""}`,
                });
                setIsLoadingFiscal(false);
              }
              else {
                setValue("original_string_SAT", response.data.original_string_SAT ?? null);
                setValue("certificate_number_SAT", response.data.certificate_number_SAT ?? null);
                setValue("certificate_number_CFDI", response.data.certificate_number_CFDI ?? null);
                setValue("uuid", response.data.uuid ?? null);
                setValue("seal_SAT", response.data.seal_SAT ?? null);
                setValue("seal_CFDI", response.data.seal_CFDI ?? null);
                setValue("signing_date", response.data.signing_date ?? null);
                await onPut(getValues());
                await onPutRelatedReceiptDetail(response.data.uuid);
                let myFileQR = await {
                  entity: "SalesInvoices",
                  recordId: response.data.sales_invoice_id,
                  fileName: response.data.sales_invoice_name,
                  typeOfFile: "image/jpeg",
                  schema_name: localStorage.getItem("schemaName"),
                  Content: response.data.QR,
                }
                let myFileXML = await {
                  entity: "SalesInvoices",
                  recordId: response.data.sales_invoice_id,
                  fileName: response.data.sales_invoice_name,
                  typeOfFile: "text/xml",
                  schema_name: localStorage.getItem("schemaName"),
                  Content: await window.btoa(response.data.XML)
                }
                let myPromises = await [
                  GenericPost(`/files`, myFileQR),
                  GenericPost(`/files`, myFileXML),
                ];
                Promise.all(myPromises)
                  .then(async (responses) => {
                    await setValue("sales_invoice_QR", responses[0].file_id);
                    await setValue("sales_invoice_XML", responses[1].file_id);
                    await onPut(getValues());
                    let pdfDoc = await getDataAndGeneratePDF(getValues("sales_invoice_id") ?? 0);
                    const fileId = await savePDFToDatabase(getValues("sales_invoice_id") ?? 0, pdfDoc);
                    setValue("sales_invoice_pdf", fileId);
                    onPut(getValues());
                  })
                  .catch((error) => {
                    showSnackBar(t("salesinvoices.info.error-gnereate-pdf"), "error");
                  });
                Swal.fire({
                  customClass: { container: "swalfire" },
                  title: `${t("salesinvoices.info.stamped")}`,
                  confirmButtonColor: theme.palette.primary.main,
                  icon: "success",
                  allowOutsideClick: false
                });
                setOpenDialogFiscalSignature(false);
                setIsLoadingFiscal(false);
                setIsEdit((prev) => true);
              }
            }).catch((error) => {
              Swal.fire({
                customClass: { container: "swalfire" },
                title: `${t("salesinvoices.info.need-save-before-stamped")}`,
                confirmButtonColor: theme.palette.primary.main,
                icon: "info"
              });
              setIsLoadingFiscal(false);
            });
          await setIsBackdrop((prev) => false);
        }
        else if (responseTrigger) {
          Swal.fire({
            customClass: { container: "swalfire" },
            title: `${t("salesinvoices.info.already-stamped")}`,
            confirmButtonColor: theme.palette.primary.main,
            icon: "info"
          });
          await setIsBackdrop((prev) => false);
        }
        else {
          Swal.fire({
            customClass: { container: "swalfire" },
            title: `${t("salesinvoices.info.less-fields")}`,
            confirmButtonColor: theme.palette.primary.main,
            icon: "info"
          });
          await setIsBackdrop((prev) => false);
        }
      }).catch(async (error) => {
        showSnackBar(error.message, "error");
        setIsLoadingFiscal(false);
        await setIsBackdrop((prev) => false);
      })
  }

  const onCheckCancelFiscalSignature = async () => {

    await GenericGetResource(`/salesinvoices/existsreceipt/${salesInvoiceLocal?.sales_invoice_id}`)
      .then((response) => {
        if (!response.data) {
          // si no hay un uuid lanzar exception de no timbrada
          if (getValues("uuid") === null || getValues("uuid") === undefined) {
            Swal.fire({
              customClass: { container: "swalfire" },
              title: `${t("salesinvoices.info.no-stamped")}`,
              confirmButtonColor: theme.palette.primary.main,
              icon: "error"
            });
          }
          // si tiene pero ya está cancelada mandar exception
          else if ((getValues("cancellation_reason")?.length ?? 0) > 0) {
            Swal.fire({
              customClass: { container: "swalfire" },
              title: `${t("salesinvoices.info.already-canceled")}`,
              confirmButtonColor: theme.palette.primary.main,
              icon: "info"
            });
          }
          // si no - abrir dialog,  cancelar y actualizar
          else {
            setOpenDialogCancelFiscalSignature(true);
          }
        } else {
          Swal.fire({
            customClass: { container: "swalfire" },
            title: `${t("salesinvoices.info.need-to-cancel-the-receipts-related-to-this-sales-invoice")}`,
            confirmButtonColor: theme.palette.primary.main,
            icon: "info"
          });
        }
      }).catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });

  }

  const onCheckFiscalSignature = () => {
    // si no hay un uuid lanzar el dialog
    if (getValues("uuid") === null || getValues("uuid") === undefined) {
      reset({ cancellation_reason_id: undefined, replacement_fiscal_folio: undefined }, { keepValues: true });
      setHasCancelUuid((prev) => false);
      setOpenDialogFiscalSignature(true);
    }
    // si tiene pero ya está timbrada mandar exception
    else {
      Swal.fire({
        customClass: { container: "swalfire" },
        title: `${t("salesinvoices.info.already-stamped")}`,
        confirmButtonColor: theme.palette.primary.main,
        icon: "info"
      });
    }
  }

  const onCancelFiscalSignature = async (e: any) => {
    if (getValues("cancellation_reason_id") === undefined || getValues("cancellation_reason_id") === null) {
      showSnackBar(t("generic.snackbar.select-option"), "error");
    }
    else {
      setIsLoading(true);

      if (getValues("cancellation_reason_aux") === "01") {
        await GenericPatchResource(`/salesinvoices/cancel/fiscalsignature/${salesInvoiceLocal?.sales_invoice_id}`,
          {
            cancellation_reason_key: comboCancellationReasons.find((item) => item.cancellation_reason_id === getValues("cancellation_reason_id"))?.cancellation_reason_key,
            replacement_fiscal_folio: getValues("replacement_fiscal_folio")
          })
          .then(async (response1) => {
            setOpenDialogCancelFiscalSignature(false);
            if (response1.data.error) {
              Swal.fire({
                customClass: { container: "swalfire" },
                title: `${response1.data.error}`,
                text: `${t(response1.data.details)}`,
                confirmButtonColor: theme.palette.primary.main,
                icon: "error",
                allowOutsideClick: false
              });
              setIsLoading(false);
              return;
            }
            else {
              Swal.fire({
                customClass: { container: "swalfire" },
                title: `${t("salesinvoices.info.already-canceled")}`,
                confirmButtonColor: theme.palette.primary.main,
                icon: "success",
                allowOutsideClick: false
              });
              setValue("invoice_doc_status_id", 2);
              setValue("cancellation_date", response1.data.cancellation_date);
              setValue("cancelled_sales_invoice_XML", response1.data.cancelled_sales_invoice_XML);
              setValue("cancellation_reason", response1.data.cancellation_reason);
              setValue("cancellation_response_code", response1.data.cancellation_response_code);
              navigate("/salesinvoices");
            }
          })
          .catch(async (error) => {
            showSnackBar(error.message, "error");
            setIsLoading(false);
          })

      }
      else {
        await GenericPatchResource(
          `/salesinvoices/cancel/fiscalsignature/${salesInvoiceLocal?.sales_invoice_id}`,
          { cancellation_reason_key: comboCancellationReasons.find((item) => item.cancellation_reason_id === getValues("cancellation_reason_id"))?.cancellation_reason_key })
          .then(async (response1) => {
            setOpenDialogCancelFiscalSignature(false);
            if (response1.data.error) {
              Swal.fire({
                customClass: { container: "swalfire" },
                title: `${response1.data.error}`,
                text: `${t(response1.data.details)}`,
                confirmButtonColor: theme.palette.primary.main,
                icon: "error",
                allowOutsideClick: false
              });
              setIsLoading(false);
              return;
            }
            else {
              Swal.fire({
                customClass: { container: "swalfire" },
                title: `${t("salesinvoices.info.already-canceled")}`,
                confirmButtonColor: theme.palette.primary.main,
                icon: "success",
                allowOutsideClick: false
              });
              setValue("invoice_doc_status_id", 2);
              setValue("cancellation_date", response1.data.cancellation_date);
              setValue("cancelled_sales_invoice_XML", response1.data.cancelled_sales_invoice_XML);
              setValue("cancellation_reason", response1.data.cancellation_reason);
              setValue("cancellation_response_code", response1.data.cancellation_response_code);
              navigate("/salesinvoices");
            }
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setIsLoading(false);
          })
      }
    }
  }

  const onDelete = () => {
    GenericGetResource(`/salesinvoices/${salesInvoiceLocal?.sales_invoice_id}`)
      .then((response) => {
        let mySalesInvoice = {
          sales_invoice_name: response.data.sales_invoice_name,
          sales_invoice_date: response.data.sales_invoice_date,
          purchase_order: response.data.purchase_order,
          customer_id: response.data.customer_id,
          customer_business_name: response.data.customer_business_name,
          customer_comercial_name: response.data.customer_comercial_name,
          customer_rfc: response.data.customer_rfc,
          customer_street_name: response.data.customer_street_name,
          customer_location_id: response.data.customer_location_id,
          customer_postal_code: response.data.customer_postal_code,
          customer_city_id: response.data.customer_city_id,
          customer_city_name: response.data.customer_city_name,
          customer_state_id: response.data.customer_state_id,
          customer_state_name: response.data.customer_state_name,
          customer_state_abbr: response.data.customer_state_abbr,
          customer_country_id: response.data.customer_country_id,
          customer_country_name: response.data.customer_country_name,
          customer_country_code: response.data.customer_country_code,
          subtotal_amount: response.data.subtotal_amount,
          total_amount: response.data.total_amount,
          tax_id: response.data.tax_id,
          tax_code: response.data.tax_code,
          tax_name: response.data.tax_name,
          tax_percent: response.data.tax_percent,
          tax_amount: response.data.tax_amount,
          currency_id: response.data.currency_id,
          currency_code: response.data.currency_code,
          currency_description: response.data.currency_description,
          project_id: response.data.project_id,
          invoice_doc_type_id: response.data.invoice_doc_type_id,
          invoice_doc_status_id: 2,
          payment_mode_id: response.data.payment_mode_id,
          payment_mode_code: response.data.payment_mode_code,
          payment_mode_description: response.payment_mode_description,
          payment_method_id: response.data.payment_method_id,
          payment_method_code: response.data.payment_method_code,
          payment_method_name: response.data.payment_method_name,
          cfdi_id: response.data.cfdi_id,
          cfdi_code: response.data.cfdi_code,
          cfdi_description: response.data.cfdi_description,
          discount: response.data.discount,
          discount_amount: response.data.discount_amount,
          original_string_SAT: response.data.original_string_SAT,
          certificate_number_SAT: response.data.certificate_number_SAT,
          certificate_number_CFDI: response.data.certificate_number_CFDI,
          uuid: response.data.uuid,
          seal_SAT: response.data.seal_SAT,
          seal_CFDI: response.data.seal_CFDI,
          signing_date: response.data.signing_date,
          cancellation_date: response.data.cancellation_date,
          cancellation_reason: response.data.cancellation_reason,
          cancellation_reason_description: response.cancellation_reason_description,
          cancellation_response_code: response.data.cancellation_response_code,
          customer_tax_regime_id: response.data.customer_tax_regime_id,
          customer_tax_regime_code: response.data.customer_tax_regime_code,
          customer_tax_regime_description: response.data.customer_tax_regime_description,
          includes_transportation_document: response.data.includes_transportation_document,
          includes_exterior_complement: response.data.includes_exterior_complement,
          id_consecutive: 1,
          notes: response.data.notes,
          retention_amount: response.data.retention_amount,
          customer_language: response.data.customer_language,
          customer_email: response.data.customer_email,
          customer_phone_number: response.data.customer_phone_number,
          sales_invoice_QR: response.data.sales_invoice_QR,
          sales_invoice_XML: response.data.sales_invoice_XML,
          cancelled_sales_invoice_XML: response.data.cancelled_sales_invoice_XML,
          sales_invoice_pdf: response.data.sales_invoice_pdf,
          exchange_rate: response.data.exchange_rate,
          date_mail_send: response.data.date_mail_send,
          user_mail_send: response.data.user_mail_send,
          company_business_name: response.data.company_business_name,
          company_street: response.data.company_street,
          company_city_id: response.data.company_city_id,
          company_city_name: response.data.company_city_name,
          company_city_code: response.data.company_city_code,
          company_state_id: response.data.company_state_id,
          company_state_name: response.data.company_state_name,
          company_state_abbr: response.data.company_state_abbr,
          company_country_id: response.data.company_country_id,
          company_country_name: response.data.company_country_name,
          company_country_code: response.data.company_country_code,
          company_postal_code: response.data.company_postal_code,
          company_rfc: response.data.company_rfc,
          company_tax_regime_id: response.data.company_tax_regime_id,
          company_tax_regime_code: response.data.company_tax_regime_code,
          company_tax_regime_description: response.data.company_tax_regime_description,
          company_email: response.data.company_email,
          company_phone_number: response.data.company_phone_number,
          company_account_alias: response.data.company_account_alias,
          company_primary_account_alias: response.data.company_primary_account_alias,
          company_primary_bank_name: response.data.company_primary_bank_name,
          company_primary_account_number: response.data.company_primary_account_number,
          company_primary_interbank_key: response.data.company_primary_interbank_key,
          company_primary_swift_code: response.data.company_primary_swift_code,
          company_secondary_account_alias: response.data.company_secondary_account_alias,
          company_secondary_bank_name: response.data.company_secondary_bank_name,
          company_secondary_account_number: response.data.company_secondary_account_number,
          company_secondary_interbank_key: response.data.company_secondary_interbank_key,
          company_secondary_swift_code: response.data.company_secondary_swift_code,
        }
        GenericPutResource(`/salesinvoices/${salesInvoiceLocal?.sales_invoice_id}`, mySalesInvoice)
          .then(() => {
            setOpenDialog(false);
            navigate("/salesinvoices", { replace: true });
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setOpenDialog(false);
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  }

  const GetAndSetConsecutive = async (consecutive_id?: number) => {
    if (consecutive_id) {
      const response = await GenericGetResource(`/consecutives/byConsecutiveId/${consecutive_id}`);
      setMyConsecutive((prev) => response.data.data[0].Consecutive);
    }
    else {
      setMyConsecutive((prev) => myLocalConsecutive);
    }
  }

  const getDataAndGeneratePDF = async (salesInvoiceId: number) => {
    try {
      const response = await GenericGetReport(`/salesinvoices/pdf/${salesInvoiceId}`, false);
      if (response.data.dataIssuingCompany.rpt_image) {
        const responseRptImage = await GenericGetResource(`/filessql/` + response.data.dataIssuingCompany.rpt_image);
        if (responseRptImage.data) {
          response.data.dataInvoiceGeneral.logoImage = responseRptImage.data.data_file ?? undefined;
        }
      }

      let qrString = getValues("sales_invoice_QR");
      if (qrString) {
        const responseQR = await GetFileById(qrString);
        const base64Part = responseQR?.dataURL.split(';base64,')[1];
        const newBase64 = `data:image/png;base64,${base64Part}`;
        response.data.dataInvoiceGeneral.fiscalQr = newBase64 ?? undefined;
      }

      let pdfDoc = await generatePDF(response);
      return pdfDoc;
    } catch (error) {
      throw error;
    }
  };

  const savePDFToDatabase = async (salesInvoiceId: number, pdfDoc: any) => {
    try {
      const startIndex = pdfDoc.indexOf(',') + 1;
      const pdfInBase64 = pdfDoc.substring(startIndex);
      const myFilePDF = {
        entity: "SalesInvoices",
        recordId: salesInvoiceId,
        fileName: salesInvoiceLocal?.sales_invoice_name,
        typeOfFile: "application/pdf",
        schema_name: localStorage.getItem("schemaName"),
        Content: pdfInBase64
      };
      const responsePdf = await GenericPost(`/files`, myFilePDF);
      return responsePdf.file_id;
    } catch (error) {
      throw error;
    }
  };

  const onGeneratePDF = async () => {

    try {
      if (!salesInvoiceLocal?.sales_invoice_pdf) {
        const pdfDoc = await getDataAndGeneratePDF(getValues("sales_invoice_id") ?? 0);
        if (getValues("uuid")) {
          const fileId = await savePDFToDatabase(getValues("sales_invoice_id") ?? 0, pdfDoc);
          setValue("sales_invoice_pdf", fileId);
          onPut(getValues());
          showSnackBar(t("salesinvoices.reports.save-PDF"), "success");
          return;
        }
        showSnackBar(t("salesinvoices.reports.generated-PDF"), 'success');
      } else {

        const pdfFile = await GetFileIfExists(salesInvoiceLocal.sales_invoice_pdf);
        const downloadLink = document.createElement('a');
        const fileName = `${salesInvoiceLocal.sales_invoice_name}.pdf`;
        downloadLink.href = pdfFile?.dataURL ?? '';
        downloadLink.download = fileName;
        downloadLink.click();
        URL.revokeObjectURL(pdfFile);
        showSnackBar(t("salesinvoices.reports.downloaded-PDF"), "success");
      }
    } catch (error: any) {
      showSnackBar(t("salesinvoices.info.error-gnereate-pdf"), "error");
      setOpenDialog(false);
      setValue("sales_invoice_pdf", undefined);
      onPut(getValues());
      await getDataAndGeneratePDF(getValues("sales_invoice_id") ?? 0);
    } finally {
      setOpenDialog(false);
    }
  };


  const onSendEmail = () => {
    if (salesInvoiceLocal) {
      let salesInvoicesData = []
      salesInvoicesData.push({
        data: salesInvoiceLocal,
        status: (salesInvoiceLocal.customer_email) ? "Waiting" : "Error",
        status_description: (salesInvoiceLocal.customer_email) ? "generic.send_email.waiting" :
          "generic.send_email.no_email"
      })
      setDataSalesInvoiceSelected(salesInvoicesData);
      setOpenEmail(true);
    }
  };

  const tryGetFilesWithFiscal = async (myResponse: any) => {
    try {
      const response = await GenericPatchResource(`/salesinvoices/fiscalsignature/${myResponse.sales_invoice_id}`);

      const myFileQR = {
        entity: "SalesInvoices",
        recordId: response.data.sales_invoice_id,
        fileName: response.data.sales_invoice_name,
        typeOfFile: "image/jpeg",
        schema_name: localStorage.getItem("schemaName"),
        Content: response.data.QR,
      };

      const myFileXML = {
        entity: "SalesInvoices",
        recordId: response.data.sales_invoice_id,
        fileName: response.data.sales_invoice_name,
        typeOfFile: "text/xml",
        schema_name: localStorage.getItem("schemaName"),
        Content: window.btoa(response.data.XML),
      };

      const [fileQRResponse, fileXMLResponse] = await Promise.all([
        GenericPost(`/files`, myFileQR),
        GenericPost(`/files`, myFileXML),
      ]);

      const salesInvoiceQrFileId = fileQRResponse.file_id;
      const salesInvoiceXMLFileId = fileXMLResponse.file_id;

      const files = {
        sales_invoice_QR: salesInvoiceQrFileId,
        sales_invoice_XML: salesInvoiceXMLFileId,
      };

      await GenericPutResource(`/salesinvoices/${myResponse.sales_invoice_id}`, files);
      await setValue("sales_invoice_QR", salesInvoiceQrFileId);
      await setValue("sales_invoice_XML", salesInvoiceXMLFileId);

      setIsEdit(true);
    } catch (error) {
      showSnackBar(t("generic.snackbar.try_to_save_files"), "error");
    }
  }

  useEffect(() => {
    let salesInvoiceId;
    let myCustomerId: any;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      salesInvoiceId = myParams.get("sales_invoice_id");
      myCustomerId = myParams.get("customer_id") ?? '';
      setCustomerId((prev) => parseInt(myCustomerId ?? 0));
    }
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("salesinvoices.title-view")}`;
    });
    if (location.state === null && (!salesInvoiceId || !myCustomerId)) return navigate("/salesinvoices", { state: {}, replace: true });
    let myPromises = [
      GenericGetResource("/currencies"),
      GenericGetResource("/paymentmethods"),
      GenericGetResource("/paymentmodes"),
      GenericGetResource("/cfdi"),
      GenericGet("/invoicedocumenttypes"),
      GenericGetResource(`/customerlocations/bycustomerid/${(location.state !== null) ? location.state.row.customer_id : myCustomerId}`),
      GenericGetResource(`/salesinvoices/${(location.state !== null) ? location.state.row.sales_invoice_id : salesInvoiceId}`),
      GenericGetResource("/cancellationreasons"),
      GenericGetResource("/retentions"),
      GetFiles((location.state !== null) ? location.state.row.sales_invoice_id : salesInvoiceId, "SalesInvoices"),
      GenericGetResourceGeneric("/companies", "/gcompanies"),
      GenericGetResource("/paymentconditions"),
      GenericGetResource("/projects"),
    ];
    Promise.all(myPromises)
      .then(async (responses) => {
        setComboCurrencies(responses[0].data.data);
        setComboPaymentMethods(responses[1].data.data);
        setComboPaymentModes(responses[2].data.data);
        setComboCFDIs(responses[3].data.data);
        setComboInvoiceDocumentTypes(responses[4].data.data.filter((element: InvoiceDocumentType) => element.is_type_for_sale == true));
        setComboPaymentConditions(responses[11].data.data);
        setComboCustomerLocations(responses[5].data.data);
        setComboCancellationReasons(responses[7].data.data);
        setDefaultDate(new Date(responses[6].data.sales_invoice_date));
        setCompany((prev) => responses[10].data.data[0]);
        setComboProjects(responses[12].data.data);
        let myProjects: Project[] = responses[12].data.data;
        setDefaultProject((prev) => myProjects.find(item => item.project_id === responses[6].data.project_id));

        await reset({
          ...responses[6].data,
          payment_method_id: (responses[6].data.payment_method_code) ? 0 : null,
          payment_mode_id: (responses[6].data.payment_mode_code) ? 0 : null,
          cfdi_id: (responses[6].data.cfdi_code) ? 0 : null,
          tax_id: (responses[6].data.tax_name) ? 0 : null
        });

        setSalesInvoiceLocal(responses[6].data);

        if (responses[6].data.is_accounted) {
          setIsAccounted((prev) => true);
        }
        if (responses[6].data.invoice_doc_status_id === 2) {
          setIsDeletedInvoice((prev) => true);
        }

        //permissions for actions
        let myActionsPermissions = [
          GetResourceByUrl("/salesinvoices/fiscalsignature"),
          GetResourceByUrl("/salesinvoices/cancel/fiscalsignature"),
          GetResourceByUrl("/salesinvoices"),
          GetResourceByUrl("/salesinvoices/copy"),
          GetResourceByUrl("/salesinvoices/isaccounted"),
          GetResourceByUrl("/salesinvoices/sendemail"),
        ];

        Promise.all(myActionsPermissions)
          .then((responses1) => {
            responses1[0] && setResourceFiscalSignature(responses1[0]);
            responses1[1] && setResourceCancelFiscalSignature(responses1[1]);
            responses1[2] && setResourceScreen(responses1[2]);
            responses1[3] && setResourceCopySalesInvoice(responses1[3]);
            responses1[4] && setResourceAccounted(responses1[4]);
            responses1[5] && setResourceSendEmail(responses1[5]);

            // Si tiene acceso a la factura
            setIsEdit((prev) => (responses[6].data.invoice_doc_status_id === 2) ? true : !responses1[2].update);
          });

        setMyConsecutive((prev) => responses[6].data.sales_invoice_name);

        if (getValues("currency_code")) {
          let myCurrency: Currency = {
            currency_id: 0,
            currency_code: getValues("currency_code") ?? '',
            currency_description: getValues("currency_description") ?? '',
          }
          await reset({ currency_id: 0 }, { keepValues: true });
          setDefaultCurrency((prev) => myCurrency);
        }
        if (getValues("payment_method_code")) {
          let myPaymentMethod: PaymentMethod = {
            payment_method_id: 0,
            code: getValues("payment_method_code"),
            name: getValues("payment_method_name")
          }
          await reset({ payment_method_id: 0 }, { keepValues: true });
          setDefaultPaymentMethod((prev) => myPaymentMethod);
        }
        if (getValues("payment_mode_code")) {
          let myPaymentMode: PaymentMode = {
            payment_mode_id: 0,
            code: getValues("payment_mode_code"),
            description: getValues("payment_mode_description")
          }
          await reset({ payment_mode_id: 0 }, { keepValues: true });
          setDefaultPaymentMode((prev) => myPaymentMode);
        }
        if (getValues("customer_street_name")) {
          let myCustomerLocation: CustomerLocation = {
            customer_location_id: 0,
            business_name: getValues("customer_business_name"),
            street: getValues("customer_street_name"),
            city_name: getValues("customer_city_name"),
            state_name: getValues("customer_state_name"),
            state_abbr: getValues("customer_state_abbr"),
            country_name: getValues("customer_country_name"),
            country_code: getValues("customer_country_code")
          }
          setDefaultCustomerLocation((prev) => myCustomerLocation);
        }
        if (getValues("cfdi_code")) {
          let myCFDI: CFDI = {
            cfdi_id: 0,
            code: getValues("cfdi_code") ?? '',
            description: getValues("cfdi_description") ?? '',
          }
          await reset({ cfdi_id: 0 }, { keepValues: true });
          setDefaultCFDI((prev) => myCFDI);
        }
        if (getValues("payment_condition_days")) {
          let myPaymentCondition: PaymentCondition = {
            payment_condition_id: 0,
            payment_condition_days: getValues("payment_condition_days") ?? 0,
            payment_condition_description: getValues("payment_condition_description") ?? '',
            payment_condition_name: getValues("payment_condition_name") ?? '',
          }
          await reset({ payment_condition_id: 0 }, { keepValues: true });
          setDefaultPaymentConditions((prev) => myPaymentCondition);
        }

        setDefaultInvoiceDocumentType((prev) => responses[4].data.data.find((item: InvoiceDocumentType) => item.invoice_doc_type_id === responses[6].data.invoice_doc_type_id));

        if (responses[6].data.includes_transportation_document) {
          setIncludesTransportationDocument((prev) => false);
        }
        if (responses[6].data.includes_exterior_complement) {
          setIncludesExteriorComplement((prev) => false);
        }
        //traer files
        if (responses[9] && responses[9].length > 0) {
          for (let i = 0; i < responses[9].length; i++) {
            const element: File = responses[9][i];
            if (element.file_id === responses[6].data.sales_invoice_QR) {
              setFiscalQr((prev) => element);
            }
            if (element.file_id === responses[6].data.sales_invoice_XML) {
              setFiscalXML((prev) => element);
            }
          }
        }

        if (responses[6].data.uuid && (!responses[6].data.sales_invoice_QR || !responses[6].data.sales_invoice_XML)) {
          setIsLoadingFiscal(true);
          await tryGetFilesWithFiscal(responses[6].data)
          setIsLoadingFiscal(false);
        }

        setMyLocalConsecutive((prev) => responses[6].data.sales_invoice_name ?? "");
        setMyLocalInvoiceDocType((prev) => responses[6].data.invoice_doc_type_id ?? 0);
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        // navigate("/salesinvoices");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { }, [includesTransportationDocument, includesExteriorComplement]);

  useEffect(() => { }, [includesExteriorComplement]);

  useEffect(() => { }, [isEdit]);

  useEffect(() => { }, [myConsecutive]);

  useEffect(() => { }, [isAccounted]);

  useEffect(() => { }, [isBackdrop]);

  useEffect(() => { }, [onPatchFiscalSignature]);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          {isBackdrop &&
            <Backdrop
              sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
              open={isBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
          <Header
            title={t("salesinvoices.title-view")}
            child={
              <div className='d-flex flex-row-reverse'>
                <div className='pt-1'>
                  <Button aria-describedby={id1} onClick={handleClick1} disabled={loadingPut}>
                    <MoreHorizIcon />
                  </Button>
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 200 }}
                  >
                    <ListItemButton onClick={() => { onCheckFiscalSignature(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 200 }} disabled={(!resourceFiscalSignature?.create) ? true : (!isAccounted)}>
                      {t("salesinvoices.actions.fiscalsignature")}<NotificationsActiveIcon />
                    </ListItemButton>
                    <ListItemButton onClick={() => { onCheckCancelFiscalSignature(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={!resourceCancelFiscalSignature?.create}>
                      {t("salesinvoices.actions.cancel-fiscalsignature")}<CancelPresentationIcon />
                    </ListItemButton>
                    <ListItemButton onClick={() => { setOpenDialogCopySalesInvoice(true); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={!resourceCopySalesInvoice?.create}>
                      {t("salesinvoices.actions.copy")}<ContentCopyIcon />
                    </ListItemButton>
                    <ListItemButton onClick={() => { onSendEmail(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={!resourceSendEmail?.create || getValues("uuid") === null}>
                      {t("salesinvoices.actions.sendemail")}<EmailIcon />
                    </ListItemButton>
                    <ListItemButton sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={true} onClick={() => { setAnchorEl1(() => null) }}>
                      {t("salesinvoices.actions.pay")}<RequestQuoteIcon />
                    </ListItemButton>
                    <ListItemButton onClick={async () => { handleClose1(); await setIsBackdrop((prev) => true); await onAccounted(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={!resourceAccounted?.update || isAccounted}>
                      {t("salesinvoices.actions.accounted")}<AccountBalanceWalletIcon />
                    </ListItemButton>
                    <ListItemButton onClick={async () => { handleClose1(); await setIsBackdrop((prev) => true); await onDeaccounted(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={!resourceAccounted?.update || !isAccounted}>
                      {t("salesinvoices.actions.deaccount")}<AccountBalanceWalletIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='px-1 pt-1'>
                  <Button aria-describedby={id2} onClick={handleClick2}>
                    <PrintIcon />
                  </Button>
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    disableScrollLock={false}
                    sx={{ minWidth: 150 }}
                  >
                    <ListItemButton onClick={() => { onGeneratePDF(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 150 }} disabled={!resourceFiscalSignature?.create}>
                      {t("salesinvoices.reports.pdf")}<PictureAsPdfIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='ps-2'>
                  {resourceScreen?.delete &&
                    <ButtonStyled disabled={isDeletedInvoice || loadingPut || (isAccounted) ? true : isEdit} variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                  }
                </div>
                <div className='pt-1'>
                  <Button disabled={loadingPut} variant="text" onClick={() => { navigate("/salesinvoices", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
                </div>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Arriba de la card */}
                <div className='d-flex w-100 m-2 flex-row-reverse'>
                  <div className="w-75   d-flex flex-row-reverse mx-2 ">
                    <div className='w-25'>
                      {!isNotExchange &&
                        <Controller
                          name="sales_invoice_date"
                          control={control}
                          rules={{}}
                          render={({ field }) =>
                            <DatePicker
                              open={openDatePicker}
                              onClose={() => setOpenDatePicker(false)}
                              ref={field.ref}
                              label={`${t("salesinvoices.fields.sales_invoice_date")}`}
                              onChange={field.onChange}
                              slotProps={{
                                popper: {
                                  sx: myStylePickers
                                },
                                textField: {
                                  variant: "filled",
                                  size: "small",
                                  fullWidth: true,
                                  onClick: () => setOpenDatePicker(true),
                                  onBeforeInput: disableKeyboardEntry,
                                  sx: { paddingRight: 2 },
                                  error: !!errors.sales_invoice_date,
                                  helperText: GetError(errors.sales_invoice_date?.type),
                                }
                              }}
                              defaultValue={dayjs(TimeConverter(defaultDate.toString())) ?? dayjs()}
                              format="DD/MM/YYYY"
                              disableFuture
                              disabled={(isAccounted) ? true : isEdit}
                            />
                          }
                        />
                      }
                    </div>
                    <div className='w-25'>
                      <Controller
                        name="invoice_doc_type_id"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboInvoiceDocumentTypes}
                            getOptionLabel={(option) => option.name}
                            defaultValue={defaultInvoiceDocumentType}
                            renderOption={(props, option: InvoiceDocumentType) => (
                              <div key={option.invoice_doc_type_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.invoice_doc_type_id === value.invoice_doc_type_id}
                            onChange={async (_, values) => {
                              if (values?.invoice_doc_type_id !== myLocalInvoiceDocType) {
                                await setMyLocalInvoiceDocType(values?.invoice_doc_type_id || 0);
                                await GetAndSetConsecutive(values?.consecutive_id);
                                if (values?.invoice_doc_type_id != 1) {
                                  setValue("includes_transportation_document", false);
                                }
                              }
                              else {
                                await GetAndSetConsecutive(undefined);
                              }
                              field.onChange(values?.invoice_doc_type_id || null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("invoicedocumenttypes.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.invoice_doc_type_id}
                                helperText={GetError(errors.invoice_doc_type_id?.type)}
                              />
                            )}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className='w-25'>
                      <TextField
                        variant="filled"
                        label={`${t("salesinvoices.fields.sales_invoice_name")}`}
                        value={myConsecutive}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        InputProps={{ readOnly: true }}
                      />
                    </div>
                    <div className='w-25'>
                      <Controller
                        name="purchase_order"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("salesinvoices.fields.purchase_order")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.purchase_order}
                            helperText={GetError(errors.purchase_order?.type)}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className="w-25">
                      <Controller
                        name="project_id"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboProjects}
                            getOptionLabel={(option) => `${option.project_name}`}
                            defaultValue={defaultProject}
                            renderOption={(props, option: Project) => (
                              <div key={option.project_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.project_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.project_id === value.project_id}
                            onChange={(_, values) => {
                              field.onChange(values?.project_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("projects.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.project_id}
                                helperText={GetError(errors.project_id?.type)}
                              />
                            )}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='custom-card-view'>
                  <div>
                    <UpdateInvoiceHeader
                      loading={loadingPut}
                      control={control}
                      errors={errors}
                      SalesInvoiceLocal={salesInvoiceLocal}
                      defaultPaymentMethod={defaultPaymentMethod}
                      defaultPaymentMode={defaultPaymentMode}
                      defaultCurrency={defaultCurrency}
                      defaultCFDI={defaultCFDI}
                      defaultCustomerLocation={defaultCustomerLocation}
                      defaultPaymentConditions={defaultPaymentConditions}
                      comboCustomerLocations={comboCustomerLocations}
                      comboCurrencies={comboCurrencies}
                      comboPaymentMethods={comboPaymentMethods}
                      comboPaymentModes={comboPaymentModes}
                      comboCFDIs={comboCFDIs}
                      comboPaymentConditions={comboPaymentConditions}
                      getValues={getValues}
                      isEdit={(isAccounted) ? true : isEdit}
                      locationEdit={locationEdit}
                      setLocationEdit={setLocationEdit}
                      isNotExchange={isNotExchange}
                      setIsNotExchange={setIsNotExchange}
                      myLocalInvoiceDocType={myLocalInvoiceDocType}
                      setIncludesTransportationDocument={setIncludesTransportationDocument}
                      setIncludesExteriorComplement={setIncludesExteriorComplement}
                    />
                  </div>
                </div>
              </form>
              <div>
                <InvoiceDetail
                  Update={
                    <UpdateSalesInvoices
                      rows={rows}
                      setRows={setRows}
                      myLocalInvoiceDocType={myLocalInvoiceDocType}
                      control={control}
                      reset={reset}
                      salesInvoicePayload={salesInvoiceLocal}
                      isEdit={(isAccounted) ? true : isEdit}
                      onPut={onPut}
                      setIsChangedTotalValue={setIsChangedTotalValue}
                      setValue={setValue}
                      trigger={trigger}
                      getValues={getValues}
                      dataLoaded={dataloaded}
                      includesForeignTradeComplement={includesExteriorComplement}
                      loadingPut={loadingPut}
                    />
                  }
                  Invoicing={
                    <Invoicing
                      idSalesInvoice={salesInvoiceLocal?.sales_invoice_id}
                      getValues={getValues}
                      isSignature={isAccounted}
                      fiscalXML={fiscalXML}
                      fiscalQr={fiscalQr}
                    />
                  }
                  TransportationDocument={
                    <IndexTransportationDocument
                      isEdit={(isAccounted) ? true : isEdit}
                      customer_id={(salesInvoiceLocal?.customer_id ?? customerId) ?? 0}
                      salesInvoicePayload={salesInvoiceLocal}
                      company_rfc={salesInvoiceLocal?.company_rfc}
                      ref={refTransportationDocument}
                      loadingPut={loadingPut}
                      setLoadingPut={setLoadingPut}
                    />
                  }
                  includesTransportationDocument={includesTransportationDocument}
                  includesExteriorComplement={includesExteriorComplement}
                  Annotations={
                    <AnnotationsSalesInvoice
                      control={control}
                      errors={errors}
                      isEdit={(isAccounted) ? true : isEdit}
                    />
                  }
                  ForeignTrade={
                    <ForeignTradetab
                      isEdit={(isAccounted) ? true : isEdit}
                      salesInvoicePayload={salesInvoiceLocal}
                      ref={refForeignTrade}
                      loadingPut={loadingPut}
                      setLoadingPut={setLoadingPut}
                    />
                  }
                  ShippingTracking={
                    <ShippingTrackingTab
                      salesInvoiceId={salesInvoiceLocal?.sales_invoice_id}
                      isEdit={(isAccounted) ? true : isEdit}
                    />
                  }
                  RelatedInvoices={
                    <RelatedSalesInvoicesIndex
                      salesInvoiceId={salesInvoiceLocal?.sales_invoice_id}
                      isEdit={(isAccounted) ? true : isEdit}
                    />
                  }
                />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("salesinvoices.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <DialogEntity
        open={openDialogCopySalesInvoice}
        setOpen={setOpenDialogCopySalesInvoice}
        title={<Header title={t("salesinvoices.actions.copy")} size='sm' />}
        content={
          <>
            <CopySalesInvoice
              setOpenDialogCopySalesInvoice={setOpenDialogCopySalesInvoice}
              comboInvoiceDocumentTypes={comboInvoiceDocumentTypes}
              defaultInvoiceDocumentType={comboInvoiceDocumentTypes.find((item) => item.invoice_doc_type_id === (getValues("invoice_doc_type_id") ?? 1))}
              salesInvoiceLocal={salesInvoiceLocal}
            />
          </>
        }
      />
      <DialogCancelFiscalSignature
        control={control}
        errors={errors}
        open={openDialogCancelFiscalSignature}
        setOpen={setOpenDialogCancelFiscalSignature}
        setIsLoading={setIsLoading}
        onAcept={onCancelFiscalSignature}
        isLoading={isLoading}
        comboCancellationReasons={comboCancellationReasons}
        getValues={getValues}
        setValue={setValue}
        hasCancelUuid={hasCancelUuid}
        setHasCancelUuid={setHasCancelUuid}
        trigger={trigger}
      />
      <DialogFiscalSignature
        open={openDialogFiscalSignature}
        setOpen={setOpenDialogFiscalSignature}
        setIsLoading={setIsLoadingFiscal}
        onAcept={onPatchFiscalSignature}
        isLoading={isLoadingFiscal}
      />
      <DialogSendEmailSingular
        open={openEmail}
        setOpen={setOpenEmail}
        data={dataSalesInvoiceSelected}
        getValues={getValues}
        setValue={setValue}
        onPut={onPut}
      />
      <SnackbarComponent />
    </>
  )
}
