import { useContext, useEffect, useState } from "react"
import { Box, Button } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import GenericPromises from "../../../../../api/GenericPromises";
import { PrimaryButton } from "../../../../../theme/buttons";
import { useTranslation } from "react-i18next";
import { CustomerLocation } from '../../../../../interfaces/Sales/Catalogs/Customers/customers';
import { Country } from "../../../../../interfaces/Commons/Locations/countries";
import { State } from "../../../../../interfaces/Commons/Locations/states";
import { City } from "../../../../../interfaces/Commons/Locations/cities";
import CustomerLocationsDetail from "./customerLocationsDetail";
import { UpdateGeneral } from "./updateGeneral";
import { AuthContext } from "../../../../../context/AuthContext";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { SnackbarComponent } from "../../../../../components/Commons/SnackBar";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { TaxRegime } from "../../../../../interfaces/Sales/Catalogs/taxRegimes";

export const UpdateCustomerLocations = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { user } = useContext(AuthContext);
  const { GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboCountries, setComboCountries] = useState<Country[]>([]);
  const [defaultCountry, setDefaultCountry] = useState<Country>();
  const [comboStates, setComboStates] = useState<State[]>([]);
  const [defaultState, setDefaultState] = useState<State>();
  const [comboCities, setComboCities] = useState<City[]>([]);
  const [defaultCity, setDefaultCity] = useState<City>();
  const [comboTaxRegimes, setComboTaxRegimes] = useState<TaxRegime[]>([]);
  const [defaultTaxRegime, setDefaultTaxRegime] = useState<TaxRegime>();
  const [openDialog, setOpenDialog] = useState(false);
  const { customerLocationPayload, permissions, customerLocationsData } = props;
  const isEdit = !permissions.update;
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    getValues,
    formState: { errors },
    setValue
  } = useForm<CustomerLocation>({
    defaultValues: {
      country_id: undefined,
      state_id: undefined,
      city_id: undefined,
      postal_code: undefined,
      street: undefined,
      contact_name: undefined,
      business_name: undefined,
      customer_rfc: undefined,
      customer_email: undefined,
      customer_phone_number: undefined,
      customer_web: undefined,
      fiscal_address: false,
      delivery_address: false,
      default_delivery_address: false,
      customer_id: 0,
      tax_regime_id: 0,
    }
  });
  const onSubmit: SubmitHandler<CustomerLocation> = (data) => onPut(data);

  const onPut = (data: CustomerLocation) => {
    if (!props.add) {
      GenericGetResource(`/customerlocations/bycustomerid/${customerLocationPayload.customer_id}`).then((response) => {
        if (data.fiscal_address === true) {
          for (let length = 0; length < response.data.data.length; length++) {
            if (customerLocationPayload.customer_location_id !== (response.data.data[length]).customer_location_id) {
              let customers = {
                "fiscal_address": false
              };
              GenericPutResource(`/customerlocations/${(response.data.data[length]).customer_location_id}`, customers);
              let updateCustomers = {
                ...response.data.data[length],
                customer_location_id: (response.data.data[length]).customer_location_id,
                "fiscal_address": false
              };
              props.UpdateRow(updateCustomers);
            }
          }
          if (props.setValue) {
            let customerUpdate = {
              business_name: data.business_name?.trim(),
              customer_rfc: data.customer_rfc?.trim(),
              customer_email: data.customer_email ?? null,
              customer_phone_number: data.customer_phone_number ?? null,
              customer_web: data.customer_web ?? null,
              tax_regime_id: data.tax_regime_id ?? null,
            }
            GenericPutResource(`/customers/${customerLocationPayload.customer_id}`, customerUpdate).then(() => {
              let myTaxRegime = comboTaxRegimes.find((item) => item.tax_regime_id === data.tax_regime_id);
              props.setValue("business_name", data.business_name?.trim());
              props.setValue("customer_rfc", data.customer_rfc?.trim());
              props.setValue("customer_email", data.customer_email);
              props.setValue("customer_phone_number", data.customer_phone_number);
              props.setValue("customer_web", data.customer_web);
              props.setValue("tax_regime_id", data.tax_regime_id);
              props.setValue("tax_regime", `${myTaxRegime?.tax_regime_code} - ${myTaxRegime?.description}`);
            })
          }
        }
        else if (customerLocationPayload.fiscal_address === true && data.fiscal_address === false) {
          showSnackBar(t("customers.dialog.no_fiscal"), "error");
          return;
        }
        let myData = {
          country_id: data.country_id,
          state_id: data.state_id,
          city_id: data.city_id,
          postal_code: data.postal_code ?? null,
          street: data.street ?? null,
          contact_name: data.contact_name ?? null,
          business_name: data.business_name?.trim(),
          customer_rfc: data.customer_rfc?.trim(),
          customer_email: data.customer_email ?? null,
          customer_phone_number: data.customer_phone_number ?? null,
          customer_web: data.customer_web ?? null,
          fiscal_address: data.fiscal_address ?? false,
          delivery_address: data.delivery_address ?? false,
          default_delivery_address: data.default_delivery_address ?? false,
          customer_id: data.customer_id,
          tax_regime_id: data.tax_regime_id ?? null,
        }
        setLoadingPost(true);
        GenericPutResource(`/customerlocations/${customerLocationPayload.customer_location_id}`, myData)
          .then((response) => {
            let myUpdateData: CustomerLocation = response.data;
            myUpdateData.country_name = comboCountries.find((country: Country) => country.country_id === response.data.country_id)?.country_name;
            myUpdateData.state_name = comboStates.find((state: State) => state.state_id === response.data.state_id)?.state_name;
            myUpdateData.city_name = comboCities.find((city: City) => city.city_id === response.data.city_id)?.city_name;
            props.UpdateRow(myUpdateData);
            showSnackBar(t("generic.snackbar.update"), "success");
            setLoadingPost(false);
            props.setOpenDialog(false);
          })
          .catch((error) => {
            setLoadingPost(false);
            showSnackBar(error.message, "error");
          });
      }).catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
    } else {
      if (data.fiscal_address === true) {
        for (let i = 0; i < props.customerLocationsData.length; i++) {
          if (props.customerLocationsData[i].fiscal_address === true) {
            props.customerLocationsData[i].fiscal_address = false;
            props.UpdateRow(props.customerLocationsData[i]);
          }
        }
        if (props.setValue) {
          let myTaxRegime = comboTaxRegimes.find((item) => item.tax_regime_id === data.tax_regime_id);
          props.setValue("business_name", data.business_name?.trim());
          props.setValue("customer_rfc", data.customer_rfc?.trim());
          props.setValue("customer_email", data.customer_email);
          props.setValue("customer_phone_number", data.customer_phone_number);
          props.setValue("customer_web", data.customer_web);
          props.setValue("tax_regime_id", data.tax_regime_id);
          props.setValue("tax_regime", `${myTaxRegime?.tax_regime_code} - ${myTaxRegime?.description}`);
        }
      }
      let country = comboCountries.find((country: Country) => country.country_id === data.country_id)
      let city = comboCities.find((city: City) => city.city_id === data.city_id)
      let state = comboStates.find((state: State) => state.state_id === data.state_id)
      let myData: CustomerLocation = {
        customer_location_id: customerLocationPayload.customer_location_id,
        country_id: data.country_id,
        country_name: country?.country_name ?? "",
        country_code: country?.country_code ?? "",
        state_id: data.state_id,
        state_name: state?.state_name ?? "",
        state_code: state?.state_code ?? "",
        city_id: data.city_id,
        city_name: city?.city_name ?? "",
        city_code: city?.city_code ?? "",
        postal_code: data.postal_code ?? undefined,
        street: data.street ?? undefined,
        contact_name: data.contact_name ?? undefined,
        business_name: data.business_name?.trim(),
        customer_rfc: data.customer_rfc?.trim(),
        customer_email: data.customer_email ?? undefined,
        customer_phone_number: data.customer_phone_number ?? undefined,
        customer_web: data.customer_web ?? undefined,
        fiscal_address: data.fiscal_address ?? false,
        delivery_address: data.delivery_address ?? false,
        default_delivery_address: data.default_delivery_address ?? false,
        customer_id: undefined,
        creation_date: new Date(),
        last_update_user: user?.user_email,
        last_update_date: new Date(),
        tax_regime_id: data.tax_regime_id ?? undefined,
      };
      props.UpdateRow(myData);
      showSnackBar(t("generic.snackbar.update"), "success");
      setLoadingPost(false);
      props.setOpenDialog(false);
    }
  }

  const onDelete = () => {
    if (customerLocationsData.length > 1) {
      if (!(customerLocationPayload.fiscal_address)) {
        if (!props.add) {
          GenericDeleteResource(`/customerlocations/${customerLocationPayload?.customer_location_id}`)
            .then(() => {
              setOpenDialog(false);
              props.DeleteRow(customerLocationPayload?.customer_location_id);
              props.setOpenDialog(false);
            })
            .catch((error) => {
              showSnackBar(error.message, "error");
              setOpenDialog(false);
            })
        } else {
          setOpenDialog(false);
          props.DeleteRow(customerLocationPayload?.customer_location_id);
          props.setOpenDialog(false);
        }
      }
      else {
        showSnackBar(t("customers.dialog.no_fiscal"), "error");
      }
    } else {
      showSnackBar(t("customers.dialog.last_location"), "error");
    }
  }

  const getCities = async (stateId: number) => {
    await GenericGetResource(`/cities/bystateid/${stateId}`)
      .then((response) => {
        setComboCities(response.data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/countries"),
      GenericGetResource("/states"),
      GenericGetResource("/cities"),
      GenericGetResource("/taxregimes"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboCountries(responses[0].data.data);
        setComboStates(responses[1].data.data);
        setComboCities(responses[2].data.data);
        setComboTaxRegimes(responses[3].data.data);

        reset({
          country_id: customerLocationPayload.country_id,
          state_id: customerLocationPayload.state_id,
          city_id: customerLocationPayload.city_id,
          postal_code: customerLocationPayload.postal_code ?? undefined,
          street: customerLocationPayload.street ?? undefined,
          contact_name: customerLocationPayload.contact_name ?? undefined,
          business_name: customerLocationPayload.business_name ?? undefined,
          customer_rfc: customerLocationPayload.customer_rfc ?? undefined,
          customer_email: customerLocationPayload.customer_email ?? undefined,
          customer_phone_number: customerLocationPayload.customer_phone_number ?? undefined,
          customer_web: customerLocationPayload.customer_web ?? undefined,
          fiscal_address: customerLocationPayload.fiscal_address ?? false,
          delivery_address: customerLocationPayload.delivery_address ?? false,
          default_delivery_address: customerLocationPayload.default_delivery_address ?? false,
          customer_id: customerLocationPayload.customer_id,
          tax_regime_id: customerLocationPayload.tax_regime_id,
        });


        setDefaultCountry(responses[0].data.data.find((country: Country) => country.country_id === customerLocationPayload.country_id));
        setDefaultState(responses[1].data.data.find((state: State) => state.state_id === customerLocationPayload.state_id));
        setDefaultCity(responses[2].data.data.find((city: City) => city.city_id === customerLocationPayload.city_id));
        setDefaultTaxRegime(responses[3].data.data.find((taxregime: TaxRegime) => taxregime.tax_regime_id === customerLocationPayload.tax_regime_id));
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

            <CustomerLocationsDetail
              General={
                <UpdateGeneral
                  control={control}
                  errors={errors}
                  comboCountries={comboCountries}
                  defaultCountry={defaultCountry}
                  comboStates={comboStates}
                  defaultState={defaultState}
                  comboCities={comboCities}
                  defaultCity={defaultCity}
                  customerLocationPayload={customerLocationPayload}
                  isEdit={isEdit}
                  getCities={getCities}
                  setValue={setValue}
                  comboTaxRegimes={comboTaxRegimes}
                  defaultTaxRegime={defaultTaxRegime}
                />
              }
            />

            <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
              <div>
                <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                {permissions.update && <PrimaryButton onClick={() => {
                  trigger().then((response) => response && onSubmit(getValues()));
                }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
              </div>
              {permissions.delete && <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton>}
            </div>

          </form>

        </Box>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("customerlocations.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
