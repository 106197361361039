import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { RelatedSalesInvoice } from "../../../../interfaces/Sales/Catalogs/RelatedSalesInvoices/relatedSalesInvoices";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";
import { SalesInvoiceRelationshipType } from "../../../../interfaces/Sales/Catalogs/salesInvoiceRelationshipTypes";
import { getUUIDFormat } from ".";
import { uuidRegex } from "../../../../constants/Regex";

export const AddRelatedSalesInvoices = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const comboSalesInvoiceRelationshipTypes: SalesInvoiceRelationshipType[] = props.comboSalesInvoiceRelationshipTypes;

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<RelatedSalesInvoice>({
    defaultValues: {

    }
  });
  const onSubmit: SubmitHandler<RelatedSalesInvoice> = (data) => onPost(data);

  const onPost = async (data: RelatedSalesInvoice) => {

    // getsalesinvoicebyuuid
    let responseRelated = await GenericGetResource(`/salesinvoices/byuuid/${data.related_sales_invoice_uuid}`);

    let myData = {
      sales_invoice_id: props.salesInvoiceId ?? null,
      related_sales_invoice: (responseRelated.data.data[0] && responseRelated.data.data[0].sales_invoice_id) ?? null,
      related_sales_invoice_uuid: data.related_sales_invoice_uuid ?? null,
      related_sales_invoice_name: (responseRelated.data.data[0] && responseRelated.data.data[0].sales_invoice_name) ?? null,
      sales_invoice_relationship_type_id: data.sales_invoice_relationship_type_id ?? null,
    }
    setLoadingPost(true);
    GenericPostResource("/relatedsalesinvoices", myData)
      .then((response) => {
        let myNewData: RelatedSalesInvoice = response.data;
        let mySalesInvoiceRelationShipType = comboSalesInvoiceRelationshipTypes.find((item) => item.sales_invoice_relationship_type_id === data.sales_invoice_relationship_type_id);
        myNewData.sales_invoice_relationship_type_code = mySalesInvoiceRelationShipType?.sales_invoice_relationship_type_code ?? '';
        myNewData.sales_invoice_relationship_type_description = mySalesInvoiceRelationShipType?.sales_invoice_relationship_type_description ?? '';
        props.AddNewRow(myNewData);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  return (
    <>
      <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>
        <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

          <div className='d-flex mt-1'>
            <div className='w-50'>
              <Controller
                name="sales_invoice_relationship_type_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    options={comboSalesInvoiceRelationshipTypes}
                    getOptionLabel={(option) => `${option.sales_invoice_relationship_type_code} - ${option.sales_invoice_relationship_type_description}`}
                    renderOption={(props, option: SalesInvoiceRelationshipType) => (
                      <div key={option.sales_invoice_relationship_type_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.sales_invoice_relationship_type_code} - {option.sales_invoice_relationship_type_description}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.sales_invoice_relationship_type_id === value.sales_invoice_relationship_type_id}
                    onChange={(_, values) => {
                      field.onChange(values?.sales_invoice_relationship_type_id || null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("salesinvoicerelationshiptypes.title-view")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.sales_invoice_relationship_type_id}
                        helperText={GetError(errors.sales_invoice_relationship_type_id?.type)}
                      />
                    )}
                    disabled={props.isEdit}
                  />
                )}
              />
            </div>
            <div className='w-50'>
              <Controller
                name="related_sales_invoice_uuid"
                control={control}
                rules={{ required: true, minLength: 36, maxLength: 36, pattern: uuidRegex }}
                render={({ field }) =>
                  <TextField
                    variant="filled"
                    label={`${t("relatedsalesinvoices.fields.related_sales_invoice_uuid")}`}
                    ref={field.ref}
                    value={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    onKeyDown={(event) => {
                      getUUIDFormat(getValues("related_sales_invoice_uuid") ?? "", event.key, setValue);
                    }}
                    error={!!errors.related_sales_invoice_uuid}
                    helperText={GetError(errors.related_sales_invoice_uuid?.type)}
                    size="small"
                    style={{ width: "100%" }}
                  />
                }
              />
            </div>

          </div>

          <div className='d-flex flex-row-reverse mt-3'>
            <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
            <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
          </div>

        </form>
      </Box>
      <SnackbarComponent />
    </>
  )
}
