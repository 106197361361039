import { useEffect, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import GenericPromises from "../../../../../api/GenericPromises";
import { ReminderType } from "../../../../../interfaces/Projects/Catalogs/reminderTypes";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { AddRecurrencePicker } from "./addRecurrencePicker";
import { myStylePickers, PrimaryButton } from "../../../../../theme/buttons";
import { useTranslation } from "react-i18next";
import { ProjectTaskReminderTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskReminderTemplate";
import { ProjectTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskTemplate";

type AddProjectTaskRemindersTemplateProps = {
  handleAddReminder: (newRow: ProjectTaskReminderTemplate) => void;
  projectTaskTemplatePayload: ProjectTaskTemplate;
};

export const AddProjectTaskRemindersTemplate = ({
  handleAddReminder,
  projectTaskTemplatePayload,
}: AddProjectTaskRemindersTemplateProps) => {
  const [t] = useTranslation("global");
  const { showSnackBar, SnackbarComponent } = useSnackBar("left", "bottom");
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GetError } = FormsErrors();
  const [reminderTypesCombo, setReminderTypesCombo] = useState<ReminderType[]>(
    []
  );
  const [
    openCustomDialogAddCustomReminder,
    setOpenCustomDialogAddCustomReminder,
  ] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [openStartedAtPicker, setOpenStartedAtPicker] = useState(false);
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const [newReminderType, setNewReminderType] = useState<ReminderType | null>();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    
  } = useForm<ProjectTaskReminderTemplate>({
    defaultValues: {
      project_task_template_id: undefined,
      reminder_type_id: undefined,
      project_task_reminder_template_name: undefined,
      project_task_reminder_template_description: undefined,
      project_task_reminder_template_started_at: undefined,
      project_task_reminder_template_due_date: undefined,
      project_task_reminder_template_next_execution: undefined,
      project_task_reminder_template_last_execution: undefined,
      project_task_reminder_template_last_time: undefined,
    },
  });

  const onSubmit: SubmitHandler<ProjectTaskReminderTemplate> = (data) =>
    onPost(data);

  const onPost = (data: any) => {
    setLoadingPost(true);
    let myData = {
      project_task_template_id:
        projectTaskTemplatePayload.project_task_template_id,
      reminder_type_id: data.reminder_type_id,
      project_task_reminder_template_name: null,
      project_task_reminder_template_description: null,
      project_task_reminder_template_started_at: null,
      project_task_reminder_template_due_date: null,
      project_task_reminder_template_next_execution: null,
      project_task_reminder_template_last_execution: null,
      project_task_reminder_template_last_time: null,
    };
    GenericPostResource("/projecttaskreminderstemplate", myData)
      .then((response) => {
        handleAddReminder(response.data);
        setNewReminderType(null);
        setValue("reminder_type_id", null);
        showSnackBar(t("generic.snackbar.add"), "success");
        const customReminderType = {
          reminder_type_name: t("remindertypes.combo.custom-option"),
          reminder_type_id: -1,
        };

        setReminderTypesCombo((prevTypes) => {
          return prevTypes.map((type) => {
            if (type.reminder_type_id === newReminderType?.reminder_type_id) {
              return customReminderType;
            }
            return type;
          });
        });
        setNewReminderType(null)
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  const handleNewReminderTypeId = (newReminderType: ReminderType) => {
    if (!newReminderType.reminder_type_id) return;

    setValue("reminder_type_id", newReminderType.reminder_type_id, {
      shouldValidate: true,
    });

    setReminderTypesCombo((prevTypes) => {
      return prevTypes.map((type) =>
        type.reminder_type_id === -1 ? newReminderType : type
      );
    });
    setNewReminderType(newReminderType);
  };

  useEffect(() => {
    let myPromises = [GenericGetResource("/remindertypes")];
    Promise.all(myPromises)
      .then((responses) => {
        const firstFourRecords = responses[0].data.data.slice(0, 4);
        const customReminderType = {
          reminder_type_name: t("remindertypes.combo.custom-option"),
          reminder_type_id: -1,
        };
        setReminderTypesCombo([...firstFourRecords, customReminderType]);
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);
  

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-100 d-flex flex-column gap-2 mt-3"
        >
          <Controller
            name="project_task_reminder_template_started_at"
            control={control}
            rules={{}}
            render={({ field }) => (
              <DatePicker
                disabled
                open={openStartedAtPicker}
                onClose={() => setOpenStartedAtPicker(false)}
                ref={field.ref}
                label={t("projecttaskreminderstemplate.fields.remind-me")}
                value={field.value ? dayjs(field.value) : null}
                onChange={(values: any) => {
                  field.onChange(values?.toDate() || null);
                }}
                slotProps={{
                  popper: {
                    sx: myStylePickers,
                  },
                  textField: {
                    size: "small",
                    variant: "filled",
                    sx: { width: "100%" },
                    onClick: () => setOpenStartedAtPicker(true),
                    onBeforeInput: disableKeyboardEntry,
                    error: !!errors.project_task_reminder_template_started_at,
                    helperText: GetError(
                      errors.project_task_reminder_template_started_at?.type
                    ),
                  },
                }}
                format="D [de] MMMM [de] YYYY"
              />
            )}
          />
          <TextField
            variant="filled"
            label={t(
              "projecttaskstemplate.fields.project_task_template_due_date"
            )}
            value={
              dayjs(
                projectTaskTemplatePayload?.project_task_template_due_date
              ).isValid()
                ? dayjs(
                    projectTaskTemplatePayload.project_task_template_due_date
                  ).format("DD-MM-YYYY")
                : `${
                    projectTaskTemplatePayload?.project_task_template_due_date ||
                    ""
                  }`
            }
            size="small"
            style={{ width: "100%" }}
            disabled
          />
          <Controller
            name="reminder_type_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  sx={{ width: "100%" }}
                  options={reminderTypesCombo}
                  getOptionLabel={(option) => `${option.reminder_type_name}`}
                  renderOption={(props, option: ReminderType) => (
                    <div key={option.reminder_type_id}>
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.reminder_type_name}
                      </Box>
                    </div>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.reminder_type_id === value.reminder_type_id
                  }
                  value={newReminderType ? newReminderType : null}
                  onChange={async (_, values) => {
                    field.onChange(values?.reminder_type_id || null);
                    if (values) {
                      setNewReminderType(values);
                      if (values?.reminder_type_id == -1) {
                        field.onChange(null);
                        setOpenCustomDialogAddCustomReminder(true);
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("remindertypes.fields.repeat")}
                      variant="filled"
                      value={field.value}
                      error={!!errors.reminder_type_id}
                      helperText={GetError(errors.reminder_type_id?.type)}
                    />
                  )}
                />
              </>
            )}
          />
          <div className="d-flex flex-row-reverse">
            <PrimaryButton
              type="submit"
              variant="outlined"
              disabled={loadingPost}
              className="m-1"
            >
              {t("generic.buttons.accept")}
            </PrimaryButton>
          </div>
        </form>
      )}
      <AddRecurrencePicker
        openCustomDialogAddCustomReminder={openCustomDialogAddCustomReminder}
        setOpenCustomDialogAddCustomReminder={
          setOpenCustomDialogAddCustomReminder
        }
        handleNewReminderTypeId={handleNewReminderTypeId}
        reminderTypesCombo={reminderTypesCombo.filter(
          (option) =>
            option.reminder_type_name !== "Personalizado" 
        )}
      />
      <SnackbarComponent />
    </>
  );
};
