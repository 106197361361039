import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Menuitem } from '../../../../interfaces/Security/menu';
import { Payment } from '../../../../interfaces/Purchases/Payments/payments';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ButtonLoading } from '../../../../theme/buttons';

type ConfirmDialogProps = {
  resourceFiscalSignature: Menuitem | undefined,
  isEdit: boolean,
  loadingPost: boolean,
  setDialogConfirmApplyPayment: Dispatch<SetStateAction<boolean>>,
  isLoadingCurrenciesValues: boolean,
  onPost: (data: Payment) => void,
  getValues: UseFormGetValues<Payment>,
  hasPositiveDifference: boolean,
}

export const ConfrimDialogPayment = ({
  resourceFiscalSignature,
  isEdit,
  loadingPost,
  setDialogConfirmApplyPayment,
  isLoadingCurrenciesValues,
  onPost,
  getValues,
  hasPositiveDifference,
}: ConfirmDialogProps) => {
  const [t] = useTranslation("global");
  const [quantityField, setQuantityField] = useState<string | number>();
  const [isEqualQuantity, setEqualQuantity] = useState(false);

  const handleChange = (e: any) => {
    const value = parseFloat(e.target.value);
    setQuantityField(value.toString());
    if (getValues("total_amount")?.toString() === value.toString()) {
      setEqualQuantity((prev) => true);
    } else {
      setEqualQuantity(false);
    }
  }

  useEffect(() => {
    if (!hasPositiveDifference) {
      setEqualQuantity(true);
    }
  }, []);

  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"start"} width={"100%"}>
      {!hasPositiveDifference &&
        <>
          <Typography margin={1}>{t("payments.info.confirm-payment")}</Typography>
        </>
      }
      {hasPositiveDifference &&
        <Box width={"100%"} marginBottom={4}>
          <Typography margin={1}><InfoIcon /> {t("payments.info.no-difference-positive")}</Typography>
        </Box>
      }
      <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"}>
        <ButtonLoading disabled={!resourceFiscalSignature?.create || isEdit || !isEqualQuantity} isLoading={loadingPost} onClick={() => !isLoadingCurrenciesValues ? onPost(getValues()) : {}} className="d-flex align-self-end" variant="outlined">{t("paymentsdetails.buttons.apply-payment")}</ButtonLoading>
        <Button variant='outlined' onClick={() => { setDialogConfirmApplyPayment(false) }} disabled={loadingPost}>{t("generic.buttons.goback")}</Button>
      </Box>
    </Box>
  )
}
