import { Route, Routes } from 'react-router-dom'
import { TableResources } from './table'
import { AddResources } from './add'
import { ViewResources } from './view'

export const BrowserResources = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableResources />} />
      <Route path={"/add"} element={<AddResources />} />
      <Route path={"/view"} element={<ViewResources />} />
    </Routes>
  )
}