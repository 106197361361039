import { DatePicker } from "@mui/x-date-pickers"
import { ProjectExtraField } from "../../../../../interfaces/Projects/Catalogs/projectExtraFields"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { AlertColor, TextField } from "@mui/material"
import GenericPromises from "../../../../../api/GenericPromises"
import { HookPutExtraFields } from "../helpers/hookPutExtraFields.tsx/hookPutExtraFields"
import dayjs from "dayjs"
import { Menuitem } from "../../../../../interfaces/Security/menu"
import { useFormulaEvaluator } from "../../../../../hooks/useFormulaEvaluator"
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner"
import { Parameter } from "../../../../../interfaces/Commons/parameters"
import { useTranslation } from "react-i18next"
import { myStylePickers } from "../../../../../theme/buttons"
import { useValidateCentinelValue } from "../helpers/hookValidateCentinelValue/hookValidateCentinelValue"

type ProjectExtraFieldsProps = {
  value: ProjectExtraField,
  projectExtraFieldsData?: ProjectExtraField[],
  setProjectExtraFieldsData: Dispatch<SetStateAction<ProjectExtraField[]>>,
  resourceScreen: Menuitem | undefined,
  isClosedProject: boolean,
  setIsUpdateProjectExtraFields: Dispatch<SetStateAction<boolean>>,
  showSnackBar: (message: string, severity: AlertColor) => void,
  onFieldUpdate?: () => void,
  fieldsUpdated?: boolean,
}

export const ProjectExtraFieldDate = ({
  value,
  setProjectExtraFieldsData,
  resourceScreen,
  isClosedProject,
  setIsUpdateProjectExtraFields,
  showSnackBar,
  onFieldUpdate,
}: ProjectExtraFieldsProps) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const { GenericPutResource } = GenericPromises();
  const { handleUpdateOnChangeField, onPutDate } = HookPutExtraFields();

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  return (
    <>
      <DatePicker
        open={isClosedProject ? !isClosedProject : openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        label={value.project_extra_field_name}
        onChange={(values: any) => {
          const dateValue = values ? dayjs(values).toISOString() : '';
          handleUpdateOnChangeField(setProjectExtraFieldsData, value.project_extra_field_id ?? 0, dateValue);
          onPutDate(value, values ? values?.toDate() : undefined, setProjectExtraFieldsData, showSnackBar, GenericPutResource, setIsUpdateProjectExtraFields);
          if (onFieldUpdate) onFieldUpdate();
        }}
        slotProps={{
          popper: {
            sx: myStylePickers
          },
          textField: {
            variant: "filled",
            size: "small",
            fullWidth: true,
            onClick: () => setOpenDatePicker(true),
            onBeforeInput: disableKeyboardEntry,
          }
        }}
        value={value.project_extra_field_value ? dayjs(value.project_extra_field_value) : null}
        format="DD/MM/YYYY"
        disabled={!resourceScreen?.update}
        readOnly={isClosedProject}
      />
    </>
  )
}

export const ProjectExtraFieldString = ({
  value,
  setProjectExtraFieldsData,
  resourceScreen,
  isClosedProject,
  setIsUpdateProjectExtraFields,
  showSnackBar,
  onFieldUpdate,
}: ProjectExtraFieldsProps) => {
  const { GenericPutResource } = GenericPromises();
  const { onPut, handleUpdateOnChangeField } = HookPutExtraFields();
  const [isUpdating, setIsUpdating] = useState(false);
  const isPastedRef = useRef(false);

  return (
    <>
      <TextField
        variant="filled"
        label={value.project_extra_field_name}
        onChange={(e) => {
          if (isPastedRef.current) {
            isPastedRef.current = false;
            return;
          }
          else {
            handleUpdateOnChangeField(setProjectExtraFieldsData, value.project_extra_field_id ?? 0, e.target.value);
            if (!isUpdating) {
              setIsUpdating((prev) => true);
            }
            if (isUpdating) {
              setTimeout(async () => {
                await onPut(value, e, setProjectExtraFieldsData, showSnackBar, GenericPutResource, setIsUpdateProjectExtraFields);
                setIsUpdating((prev) => false);
                if (onFieldUpdate) onFieldUpdate();
              }, 3000);
            }
          }
        }}
        onPaste={async (e) => {
          isPastedRef.current = true;
          const pastedText = e.clipboardData.getData('text');

          handleUpdateOnChangeField(setProjectExtraFieldsData, value.project_extra_field_id ?? 0, pastedText);
          await onPut(
            value,
            { target: { value: pastedText } },
            setProjectExtraFieldsData,
            showSnackBar,
            GenericPutResource,
            setIsUpdateProjectExtraFields
          );

          setIsUpdating(false);
        }}
        value={value.project_extra_field_value}
        size="small"
        style={{ width: "100%" }}
        disabled={!resourceScreen?.update}
        InputProps={{ readOnly: isClosedProject }}
      />
    </>
  )
}

export const ProjectExtraFieldNumber = ({
  value,
  setProjectExtraFieldsData,
  resourceScreen,
  isClosedProject,
  setIsUpdateProjectExtraFields,
  showSnackBar,
  onFieldUpdate,
}: ProjectExtraFieldsProps) => {
  const { GenericPutResource } = GenericPromises();
  const { onPut, handleUpdateOnChangeField } = HookPutExtraFields();
  const [isUpdating, setIsUpdating] = useState(false);
  const isPastedRef = useRef(false);

  return (
    <>
      <TextField
        variant="filled"
        sx={{
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
          "& input[type=number]": { MozAppearance: "textfield", }
        }}
        type="number"
        onChange={(e) => {
          if (isPastedRef.current) {
            isPastedRef.current = false;
            return;
          }
          else {
            handleUpdateOnChangeField(setProjectExtraFieldsData, value.project_extra_field_id ?? 0, e.target.value);
            if (!isUpdating) {
              setIsUpdating((prev) => true);
              setTimeout(async () => {
                await onPut(value, e, setProjectExtraFieldsData, showSnackBar, GenericPutResource, setIsUpdateProjectExtraFields);
                if (onFieldUpdate) onFieldUpdate();
                setIsUpdating((prev) => false);
              }, 3000);
            }
          }
        }}
        onPaste={async (e) => {
          const pastedText = e.clipboardData.getData('text');
          isPastedRef.current = true;

          handleUpdateOnChangeField(setProjectExtraFieldsData, value.project_extra_field_id ?? 0, pastedText);
          await onPut(
            value,
            { target: { value: pastedText } },
            setProjectExtraFieldsData,
            showSnackBar,
            GenericPutResource,
            setIsUpdateProjectExtraFields
          );

          setIsUpdating(false);
        }}
        value={value.project_extra_field_value}
        label={value.project_extra_field_name}
        size="small"
        style={{ width: "100%" }}
        disabled={!resourceScreen?.update}
        InputProps={{ readOnly: isClosedProject }}
      />
    </>
  )
}

export const ProjectExtraFieldFormula = ({
  value,
  projectExtraFieldsData,
  resourceScreen,
  isClosedProject,
  fieldsUpdated,
}: ProjectExtraFieldsProps) => {
  const { EvaluateFormula, fetchParameters } = useFormulaEvaluator();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [myValue, setMyValue] = useState<any>();
  const [t] = useTranslation("global");
  const { HasCentinelValue, GetNameParamToReplace, HasValidateResponse } = useValidateCentinelValue();

  useEffect(() => {
    let parameters: Parameter[] = [];
    setDataLoaded((prev) => false);
    new Promise(async () => {
      parameters = await fetchParameters();
      EvaluateFormula(value.project_extra_field_value ?? '', projectExtraFieldsData ?? [], parameters)
        .then(async (response: any) => {
          if (!HasValidateResponse(response) && HasCentinelValue(value.project_extra_field_name ?? '') && !isClosedProject) {
            // calcula el valor con hoy
            const now = new Date();
            const isoString = now.toISOString();
            await EvaluateFormula(`${value?.project_extra_field_value.replace(`{${GetNameParamToReplace(value.project_extra_field_name ?? '')}}`, `new Date("${isoString}")`)}`, projectExtraFieldsData ?? [], parameters)
              .then(async (responseExtraField) => {
                if (responseExtraField > 0) {
                  setMyValue(responseExtraField);
                }
                else {
                  setMyValue(response);
                }
              });
          }
          else {
            setMyValue(response);
          }
          setDataLoaded((prev) => true);
        }).catch((error: any) => {
          setMyValue(`${t("projectextrafields.info.no-calculated")}  ${error.message}`);
          setDataLoaded((prev) => true);
        });
    })
  }, [fieldsUpdated]);

  return (
    <>
      {!dataLoaded &&
        <Spinner isBox={false} />
      }
      {dataLoaded &&
        <TextField
          variant="filled"
          label={value.project_extra_field_name}
          size="small"
          value={myValue}
          style={{ width: "100%" }}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          disabled={!resourceScreen?.update}
        />
      }

    </>
  )
}

