export const useFiltersColumns = () => {

    // Función para evaluar una expresión condicional.
    const evaluateCondition = (condition: string, elementToFilter: any): string => {
        // Dividir en dos partes usando '?'
        const [expression, resultPart] = condition.split('?');
        // Si no hay 'resultPart', retornar una cadena vacía
        if (!resultPart) return '';

        // Dividir el resultado en verdadero y falso usando ':'
        const [trueResult, falseResult] = resultPart.split(':');

        let result = false;
        let currentOp: string | null = null;

        // Divide la expresión en partes usando '&' o '|' como delimitadores.
        const parts = expression.split(/([&|])+/);

        for (const part of parts) {
            const trimmedPart = part.trim();
            if (trimmedPart === '&' || trimmedPart === '|') {
                currentOp = trimmedPart;
            } else if (trimmedPart) {
                const [key, value] = trimmedPart.split('=').map(s => s.trim());
                if (key && value) {
                    const conditionValue = elementToFilter[key].toString();
                    const isConditionTrue = conditionValue == value; // Usar '==' para comparación
                    if (currentOp === '&') {
                        result = result && isConditionTrue;
                    } else if (currentOp === '|') {
                        result = result || isConditionTrue;
                    } else {
                        result = isConditionTrue;
                    }
                }
            }
        }

        // Retornar el resultado basado en la evaluación de la expresión.
        return result ? trueResult : falseResult || '';
    };

    const conditionalFilterForTotals = (filterString: string, elementToFilter: any): string => {
        // Si el filtro no comienza con '=', devolver el string como está.
        if (!filterString.startsWith('=')) {
            return filterString;
        }

        // Eliminar el '=' al inicio.
        filterString = filterString.substring(1);

        // Evaluar la expresión y asegurar que siempre se retorna un string.
        return evaluateCondition(filterString, elementToFilter);
    };

    return { conditionalFilterForTotals };
};