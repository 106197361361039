import { DataGrid, GridColDef, GridEventListener, GridPagination, GridRowModel, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer, GridValidRowModel, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, TablePaginationProps, TextField } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import AddIcon from '@mui/icons-material/Add';
import { MerchandiseDetail } from '../../../interfaces/Sales/Catalogs/TransportationDocuments/transportationDocument';
import GenericPromises from '../../../api/GenericPromises';
import { CustomerItem } from '../../../interfaces/Sales/Catalogs/Customers/customerItems';
import { Item } from '../../../interfaces/Commons/items';
import { PrimaryButton } from '../../../theme/buttons';
import { SnackbarComponent } from '../../Commons/SnackBar';
import { useLocation } from 'react-router-dom';
import { Unit } from '../../../interfaces/Commons/units';

type EditableTableProps = {
  rows: readonly GridValidRowModel[],
  transportationDocument: number,
  columns: GridColDef[],
  rowModesModel: GridRowModesModel,
  handleRowModesModelChange: (newRowModesModel: GridRowModesModel) => void,
  handleRowEditStop: GridEventListener<"rowEditStop">,
  processRowUpdate?: (newRow: GridRowModel, oldRow: GridRowModel) => {},
  setRows: React.Dispatch<React.SetStateAction<MerchandiseDetail[]>>,
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
  isEdit?: boolean,
  ChangeTotalMerchandise: () => void,
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
  changeTotalValue: (addValue: number, minusValue: number, prevValue: number) => void,
  transportationDocument: number,
  isEdit: boolean,
  ChangeTotalMerchandise: () => void,
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, transportationDocument } = props;
  const [t] = useTranslation("global");
  const location = useLocation();
  const { GenericGetResource } = GenericPromises();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageSnack, setMessageSnack] = React.useState("");
  const [severitySnack, setSeveritySnack] = React.useState("warning");
  const [comboItems, setComboItems] = React.useState<CustomerItem[]>([]);
  const [comboUnits, setComboUnits] = React.useState<Unit[]>([]);
  const [myItem, setMyitem] = React.useState<CustomerItem | null>(null);
  const [myQuantity, setMyQuantity] = React.useState<number>();
  const [myWeight, setMyWeight] = React.useState<number>();
  const [myUnit, setMyUnit] = React.useState<Unit | null>();
  const myQuantityRef = React.useRef<any>(null);
  const { GenericPostResource } = GenericPromises();

  const handleChange = (e: any, value: any) => {
    if (value === null) {
      setMyQuantity((prev) => 0);
      setMyWeight((prev) => 0);
    }
    else {
      myItem && myQuantityRef.current && myQuantityRef.current.focus()
      setMyUnit(comboUnits.find((item: Unit) => item.unit_code?.toLowerCase() === ("kgm")) ?? null);
    }
    setMyitem((prev) => value);
  };

  const handleChangeTextfields = (e: any) => {
    let { name, value } = e.target;
    if (name === 'weight') {
      setMyWeight((prev) => value)
    }
    else if (name === 'quantity') {
      setMyQuantity((prev) => value);
    }
  }

  const handleChangeUnit = (e: any, value: any) => {
    setMyUnit((prev) => value);
  }

  const AddRecord = () => {
    if (
      (myQuantity === undefined || myQuantity <= 0) ||
      (myWeight === undefined || myWeight <= 0)
      || myUnit === undefined || myUnit === null) {
      setMessageSnack(t("merchandisedetails.snackbar.warning"));
      setSeveritySnack("warning");
      setOpenSnack(true);
    }
    else {
      let myMerchandiseDetail = {
        transportation_document_id: transportationDocument,
        description: myItem?.item_description,
        product_service_key_id: myItem?.product_service_key_id,
        product_service_key: myItem?.product_service_key,
        unit_id: myItem?.unit_id,
        unit_code: myItem?.unit_code,
        quantity: myQuantity,
        weight_in_kg: myWeight,
        hazardous_material_key_id:null,
        hazardous_material_key: null,
        hazardous_material_description: null,
        packaging_type_id:  null,
        packaging_type_code:  null,
        packaging_type_description:  null,

      }
      GenericPostResource("/merchandisedetails", myMerchandiseDetail)
        .then((response) => {
          setRows((oldRows) => {
            let myRow = oldRows.find((item) => item.product_service_key === myMerchandiseDetail.product_service_key);
            if (myRow) {
              setRowModesModel((oldModel) => ({
                ...oldModel,
                [response.data.merchandise_detail_id]: { mode: GridRowModes.View },
              }));
              return oldRows.map((row) => (row.merchandise_detail_id === response.data.merchandise_detail_id ? response.data : row));;
            }
            else {
              setRowModesModel((oldModel) => ({
                ...oldModel,
                [response.data.merchandise_detail_id]: { mode: GridRowModes.View },
              }));
              return ([...oldRows, {
                merchandise_detail_id: response.data.merchandise_detail_id,
                description: myItem?.item_description,
                product_service_key: myItem?.product_service_key,
                unit_code: myUnit?.unit_code,
                quantity: myQuantity,
                weight_in_kg: myWeight
              }]);
            }
          });
          props.ChangeTotalMerchandise();
          setMyitem((prev) => null);
          setMyQuantity((prev) => undefined);
          setMyWeight((prev) => undefined);
          setMyUnit((prev) => undefined);
        }).catch((error) => console.log(error));
    }
  }

  return (
    <GridToolbarContainer className='d-flex p-1 w-100'>
      {props.isEdit &&
        <>
          <Autocomplete
            value={myItem || null}
            size="small"
            sx={{ flex: 8 }}
            options={comboItems}
            getOptionLabel={(option) => `${option.product_service_key} - ${option.product_service_key_description} - ${option.item_description}`}
            renderOption={(props, option: CustomerItem) => (
              <div key={option.customer_items_id}>
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.product_service_key} - {option.product_service_key_description} - {option.item_description}
                </Box>
              </div>
            )}
            isOptionEqualToValue={(option, value) => option.customer_items_id === value.customer_items_id}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t("merchandisedetails.title-view")}`}
                variant="filled"
              />
            )}
          />
          {myItem &&
            <>
              <TextField
                inputRef={myQuantityRef}
                variant="filled"
                name="quantity"
                label={`${t("merchandisedetails.fields.quantity")}`}
                value={myQuantity || undefined}
                onChange={handleChangeTextfields}
                size="small"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, flex: 3
                }}
                type="number"
              />
              <Autocomplete
                value={myUnit || null}
                size="small"
                sx={{ flex: 3 }}
                options={comboUnits}
                getOptionLabel={(option) => `${option.unit_description} - ${option.unit_code}`}
                renderOption={(props, option: Unit) => (
                  <div key={option.unit_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.unit_description} - {option.unit_code}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.unit_id === value.unit_id}
                onChange={handleChangeUnit}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("merchandisedetails.fields.unit_code")}`}
                    variant="filled"
                  />
                )}
              />
              <TextField
                variant="filled"
                name="weight"
                label={`${t("merchandisedetails.fields.weight_in_kg")}`}
                value={myWeight || undefined}
                onChange={handleChangeTextfields}
                size="small"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, flex: 3
                }}
                type="number"
              />
              <PrimaryButton variant='outlined' sx={{ flex: 1 }} onClick={AddRecord}><AddIcon /></PrimaryButton>
            </>
          }
        </>
      }
      <SnackbarComponent
        message={messageSnack}
        openSnack={openSnack}
        setOpenSnack={setOpenSnack}
        severity={severitySnack}
      />
    </GridToolbarContainer>
  );
}

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      variant='outlined'
      sx={{
        '& .MuiPaginationItem-root:hover': {
          color: 'secondary',
          backgroundColor: 'primary',
          border: 'none',
        }
      }}
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props: any) {
  const [t] = useTranslation("global");
  return <div className='d-flex'>
    <GridPagination labelRowsPerPage={t("generic.grid-table.rows-per-page")} ActionsComponent={Pagination} {...props} />
  </div>
}

export default function TableCancelledMerchandiseDetail({
  rows,
  columns,
  rowModesModel,
  handleRowModesModelChange,
  handleRowEditStop,
  processRowUpdate,
}: EditableTableProps) {
  const [t] = useTranslation("global");

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            color: 'ActiveCaption',
            outline: 'none',
            border: 'none'
          },
          '& .MuiDataGrid-columnHeader--showColumnBorder': {
            outline: 'none',
            bordere: 'none'
          },
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
            marginTop: ".5em",
            marginBottom: ".5em",
          }
        }}
        localeText={{
          toolbarExport: t("generic.grid-table.toolbar-export"),
          toolbarExportCSV: t("generic.grid-table.toolbar-export-csv"),
          columnMenuUnsort: t("generic.grid-table.column-menu-unsort"),
          columnMenuSortAsc: t("generic.grid-table.column-menu-sort-asc"),
          columnMenuSortDesc: t("generic.grid-table.column-menu-sort-desc"),
          columnMenuFilter: t("generic.grid-table.column-menu-filter"),
          columnMenuHideColumn: t("generic.grid-table.column-menu-hide-column"),
          columnMenuShowColumns: t("generic.grid-table.column-menu-show-columns"),
          columnMenuManageColumns: t("generic.grid-table.column-menu-manage-columns"),
          filterOperatorContains: t("generic.grid-table.filter-operator-contains"),
          filterOperatorEquals: t("generic.grid-table.filter-operator-equals"),
          filterOperatorStartsWith: t("generic.grid-table.filter-operator-starts-with"),
          filterOperatorEndsWith: t("generic.grid-table.filter-operator-ends-with"),
          filterOperatorIsEmpty: t("generic.grid-table.filter-operator-is-empty"),
          filterOperatorIsNotEmpty: t("generic.grid-table.filter-operator-is-not-empty"),
          filterOperatorIsAnyOf: t("generic.grid-table.filter-operator-is-any-of"),
        }}
        getRowId={(row) => (row["merchandise_detail_id"] || row["cancelled_merchandise_detail_id"])}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        slots={{
          toolbar: EditToolbar,
          pagination: CustomPagination,
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
      />
    </Box>
  )
}