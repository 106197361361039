import { StyleSheet } from "@react-pdf/renderer"


export const deliverAndPaymentInfoStyles = () => {

    const billingDataTo = StyleSheet.create({
        container: {
            height:110,
            width: '90%',
            alignSelf: 'center',
            borderColor: 'black',
            flexDirection:'column',
            borderLeftWidth:1,
            borderRightWidth:1

        },
        titleContainer: {
            width: '100%',
            height: '25%',
            justifyContent: 'center',
            paddingLeft: 10,
            borderBottomWidth: 1,
            borderColor: 'black',
        },
        title: {
            fontSize: 7,
            fontWeight: 700,
        },

        firstSubContainer: {
            width: '50%',
            height: '100%',
            flexDirection: 'row',
            borderRightWidth: .5,
            borderColor: 'black',
        },

        firstContainer: {
            width: '45%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 5,

        },
        secondContainer: {
            width: '50%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 5,
            gap: 5
        },
        field: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap:'wrap'
        },
        concept: {
            fontSize: 6,
            fontWeight: 700
        },
        data: {
            fontSize: 6,
        }

    })

    const deliverAndPaymentInfo = StyleSheet.create({
        secondSubContainer: {
            width: '50%',
            height: '100%',
            flexDirection: 'row',
            borderLeftWidth: .5,
            borderColor: 'black',
        },
        titleContainer: {
            width: '100%',
            height: '25%',
            justifyContent: 'center',
            paddingLeft: 10,
            borderBottomWidth: 1,
            borderColor: 'black',
        },
        title: {
            fontSize: 7,
            fontWeight: 700
        },

        dataSubContainerConcepts:{
            width: '40%',
            flexDirection: 'column',
            justifyContent:'space-around'
        },
        dataSubContainerData:{
            width: '60%',
            flexDirection: 'column',
            justifyContent:'space-around'
        },
        field:{
            justifyContent:'space-between',
            flexDirection:'row',
            borderBottom:1,
            paddingLeft:2
        },
        fieldWithoutBorder:{
            justifyContent:'space-between',
            flexDirection:'row',
            paddingLeft:2
        }

    })


    return {
        billingDataTo,
        deliverAndPaymentInfo
    }
}