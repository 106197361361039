import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, IconButton, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

interface DashBoardFilterProps {
    onFilterChange: (searchTerm: string) => void,
    onClearFilter: () => void,
    filterValue: string,
}

export const DashBoardFilter = ({
    onFilterChange,
    onClearFilter,
    filterValue,
}: DashBoardFilterProps) => {
    const [t] = useTranslation("global");
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClickOpenPopOver = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        onFilterChange(value);
    };

    return (
        <Box>
            <Typography
                sx={{
                    color: "black",
                    display: "flex",
                    cursor: "pointer",
                    padding: 1,
                    borderRadius: 1,
                    ":hover": {
                        backgroundColor: '#e0e0e0',
                    }
                }}
                onClick={handleClickOpenPopOver}
            >
                <Typography>{t("dashboard.filter")}</Typography>
                <FilterAltIcon />
            </Typography>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ width: 500 }} padding={1}>
                    <TextField
                        value={filterValue}
                        placeholder={t("generic.search")}
                        variant='outlined'
                        size='small'
                        fullWidth
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                filterValue.length > 0 && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={onClearFilter}>
                                            <FilterAltOffIcon sx={{ color: "black", }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            ),
                        }}
                    />
                </Box>
            </Popover>
        </Box>
    )
}