
import { Header } from '../../../components/Header/index';
import { Box, Switch, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { ButtonStyled, PrimaryButton } from '../../../theme/buttons';
import { useTranslation } from 'react-i18next';
import { ItemType } from '../../../interfaces/Commons/items';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';

export const AddItemTypes = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ItemType>({
    defaultValues: {
      item_type_name: "",
      item_type_code: "",
      item_type_description: undefined,
      sale: true,
      purchase: true,
      stock_management: true,
    }
  });
  const onSubmit: SubmitHandler<ItemType> = (data) => onPost(data);

  const onPost = (data: ItemType) => {
    let myData = {
      item_type_name: data.item_type_name,
      item_type_description: data.item_type_description ?? null,
      item_type_code: data.item_type_code,
      sale: data.sale ?? false,
      purchase: data.purchase ?? false,
      stock_management: data.stock_management ?? false,
    }
    setLoadingPost(true);
    GenericPostResource("/itemtypes", myData)
      .then((response) => {
        navigate("/itemtypes", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/itemtypes", { state: {} });
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("item-types.title")}`;
    })
  }, [])

  return (
    <>
      <Header title={t("item-types.title-view")} />

      <div className='d-flex justify-content-center'>

        <div className='custom-card-view w-50'>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

            <div className="custom-card-row">
              <Controller
                name="item_type_name"
                control={control}
                rules={{ required: true, maxLength: 150 }}
                render={({ field }) =>
                  <TextField
                    variant="filled"
                    ref={field.ref}
                    value={field.value}
                    label={t("item-types.fields.item_type_name")}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.item_type_name}
                    helperText={GetError(errors.item_type_name?.type)}
                    size="small"
                    style={{ width: "100%" }}
                  />
                }
              />
            </div>

            <div className="custom-card-row">
              <Controller
                name="item_type_code"
                control={control}
                rules={{ required: true, maxLength: 10 }}
                render={({ field }) =>
                  <TextField
                    variant="filled"
                    ref={field.ref}
                    value={field.value}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    label={t("item-types.fields.item_type_code")}
                    error={!!errors.item_type_code}
                    helperText={GetError(errors.item_type_code?.type)}
                    size="small"
                    style={{ width: "100%" }}
                  />
                }
              />
            </div>

            <div className="custom-card-row">
              <Controller
                name="item_type_description"
                control={control}
                rules={{ maxLength: 300 }}
                render={({ field }) =>
                  <TextField
                    variant="filled"
                    ref={field.ref}
                    value={field.value}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    label={t("item-types.fields.item_type_description")}
                    error={!!errors.item_type_description}
                    helperText={GetError(errors.item_type_description?.type)}
                    size="small"
                    style={{ width: "100%" }}
                  />
                }
              />
            </div>

            <div className="d-flex flex-column mt-3">
              <Controller
                name="sale"
                control={control}
                render={({ field }) =>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                    <Typography sx={{ ml: 2 }}>{t("item-types.fields.sale")}</Typography>
                    <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked />
                  </Box>
                }
              />
              <Controller
                name="purchase"
                control={control}
                render={({ field }) =>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                    <Typography sx={{ ml: 2 }}>{t("item-types.fields.purchase")}</Typography>
                    <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked />
                  </Box>
                }
              />
              <Controller
                name="stock_management"
                control={control}
                render={({ field }) =>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                    <Typography sx={{ ml: 2 }}>{t("item-types.fields.stock_management")}</Typography>
                    <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked />
                  </Box>
                }
              />
            </div>

            <div className='d-flex flex-row-reverse mt-4'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </div>
      </div>
      <SnackbarComponent />
    </>
  )
}
