import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../../api/GenericPromises';
import { DatePicker } from "@mui/x-date-pickers";
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Header } from '../../../../../components/Header';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { ButtonStyled, myStylePickers, PrimaryButton } from '../../../../../theme/buttons';
import { Transportation } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/transportations';
import { VehicleConfiguration } from '../../../../../interfaces/Sales/Catalogs/TransportationDocuments/vehicleConfigurations';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';

export const AddTransportations = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [comboVehicleConfigurations, setcomboVehicleConfigurations] = useState<VehicleConfiguration[]>([]);
  const [loadingPost, setLoadingPost] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Transportation>({
    defaultValues: {
      vehicle_configuration_id: undefined,
      transportation_code: '',
      plate_number: '',
      vin: '',
      model_name: '',
      model_year: '',
      motor_number: '',
      permission_sct: '',
      permission_number_sct: '',
      transportation_weight: undefined,
      insurance_company: '',
      insurance_policy: '',
      insurance_expiration_date: undefined,
    }
  });
  const onSubmit: SubmitHandler<Transportation> = (data) => onPost(data);

  const onPost = (data: Transportation) => {
    let myData = {
      vehicle_configuration_id: data.vehicle_configuration_id,
      transportation_code: data.transportation_code,
      plate_number: data.plate_number ?? null,
      vin: data.vin ?? null,
      model_name: data.model_name ?? null,
      model_year: data.model_year ?? null,
      motor_number: data.motor_number ?? null,
      permission_sct: data.permission_sct ?? null,
      permission_number_sct: data.permission_number_sct ?? null,
      transportation_weight: data.transportation_weight ?? null,
      insurance_company: data.insurance_company ?? null,
      insurance_policy: data.insurance_policy ?? null,
      insurance_expiration_date: data.insurance_expiration_date ?? new Date(),
    }
    setLoadingPost(true);
    GenericPostResource("/transportations", myData)
      .then((response) => {
        navigate("/transportations", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/transportations", { state: {} });
  }

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/vehicleconfigurations"),
    ]
    Promise.all(myPromises)
      .then((responses) => {
        setcomboVehicleConfigurations(responses[0].data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }, [])
  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("transportations.title-view")} />
          <div className='d-flex justify-content-center'>

            <div className='custom-card-view w-75'>

              <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                <div className="custom-card-row d-flex justify-content-between">
                  <div className="w-100">
                    <div className='d-flex w-100'>
                      <div className='w-50'>
                        <Controller
                          name="vehicle_configuration_id"
                          control={control}
                          rules={{ required: true, maxLength: 3 }}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%", paddingRight: 2 }}
                              options={comboVehicleConfigurations}
                              getOptionLabel={(option) => `${option.vehicle_config_code} - ${option.vehicle_config_description}`}
                              renderOption={(props, option: VehicleConfiguration) => (
                                <div key={option.vehicle_config_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.vehicle_config_code} - {option.vehicle_config_description}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.vehicle_config_id === value.vehicle_config_id}
                              onChange={(_, values) => {
                                field.onChange(values?.vehicle_config_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("transportations.fields.vehicle_configuration_id")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.vehicle_configuration_id}
                                  helperText={GetError(errors.vehicle_configuration_id?.type)}
                                />
                              )}
                            />
                          )}
                        />
                      </div>

                      <div className="w-50">
                        <Controller
                          name="transportation_code"
                          control={control}
                          rules={{ required: true, maxLength: 10 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.transportation_code")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.transportation_code}
                              helperText={GetError(errors.transportation_code?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex w-100 pt-3'>
                      <div className='w-50'>
                        <Controller
                          name="plate_number"
                          control={control}
                          rules={{ required: true, maxLength: 10 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.plate_number")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.plate_number}
                              helperText={GetError(errors.plate_number?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{ paddingRight: 2 }}
                            />
                          }
                        />
                      </div>

                      <div className="w-50">
                        <Controller
                          name="vin"
                          control={control}
                          rules={{ required: true, maxLength: 25 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.vin")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.vin}
                              helperText={GetError(errors.vin?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex w-100 pt-3'>
                      <div className='w-50'>
                        <Controller
                          name="model_name"
                          control={control}
                          rules={{ required: true, maxLength: 100 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.model_name")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.model_name}
                              helperText={GetError(errors.model_name?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{ paddingRight: 2 }}
                            />
                          }
                        />
                      </div>

                      <div className="w-50">
                        <Controller
                          name="model_year"
                          control={control}
                          rules={{ required: true, maxLength: 4 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.model_year")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              type='number'
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", },
                              }}
                              error={!!errors.model_year}
                              helperText={GetError(errors.model_year?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex w-100 pt-3'>
                      <div className='w-50'>
                        <Controller
                          name="motor_number"
                          control={control}
                          rules={{ required: true, maxLength: 20 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.motor_number")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.motor_number}
                              helperText={GetError(errors.motor_number?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{ paddingRight: 2 }}
                            />
                          }
                        />
                      </div>

                      <div className="w-50">
                        <Controller
                          name="permission_sct"
                          control={control}
                          rules={{ required: true, maxLength: 10 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.permission_sct")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.permission_sct}
                              helperText={GetError(errors.permission_sct?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex w-100 pt-3'>
                      <div className="w-50">
                        <Controller
                          name="permission_number_sct"
                          control={control}
                          rules={{ required: true, maxLength: 30 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.permission_number_sct")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.permission_number_sct}
                              helperText={GetError(errors.permission_number_sct?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{ paddingRight: 2 }}
                            />
                          }
                        />
                      </div>
                      <div className='w-50'>
                        <Controller
                          name="transportation_weight"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.transportation_weight")}`}
                              ref={field.ref}
                              value={field.value}
                              type='number'
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", },
                              }}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.transportation_weight}
                              helperText={GetError(errors.transportation_weight?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex w-100 pt-3'>
                      <div className="w-50">
                        <Controller
                          name="insurance_company"
                          control={control}
                          rules={{ required: true, maxLength: 200 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.insurance_company")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.insurance_company}
                              helperText={GetError(errors.insurance_company?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{ paddingRight: 2 }}
                            />
                          }
                        />
                      </div>
                      <div className='w-50'>
                        <Controller
                          name="insurance_policy"
                          control={control}
                          rules={{ required: true, maxLength: 50 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("transportations.fields.insurance_policy")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.insurance_policy}
                              helperText={GetError(errors.insurance_policy?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex w-100 pt-3'>
                      <div className="w-50">
                        <Controller
                          name="insurance_expiration_date"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) =>
                            <DatePicker
                              open={openDatePicker}
                              onClose={() => setOpenDatePicker(false)}
                              ref={field.ref}
                              label={`${t("transportations.fields.insurance_expiration_date")}`}
                              onChange={field.onChange}
                              slotProps={{
                                popper: {
                                  sx: myStylePickers
                                },
                                textField: {
                                  variant: "filled",
                                  size: "small",
                                  fullWidth: true,
                                  onClick: () => setOpenDatePicker(true),
                                  onBeforeInput: disableKeyboardEntry,
                                  error: !!errors.insurance_expiration_date,
                                  helperText: GetError(errors.insurance_expiration_date?.type),
                                },
                              }}
                              format="DD/MM/YYYY"
                              sx={{ paddingRight: 2 }}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>

                </div>

                <div className='d-flex flex-row-reverse mt-1'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                  <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                </div>

              </form>

            </div>

          </div>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
