import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { AuthContext } from '../../../context/AuthContext';
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../interfaces/Security/menu';
import { Currency } from '../../../interfaces/Commons/currencies';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import DataTable from '../../../components/Tables/GridTableMaterialUI/DataTable';
import { PrimaryButton } from '../../../theme/buttons';
import { Header } from '../../../components/Header';
import { UpdateCurrency } from './update';
import { AddCurrency } from './add';
import { usePermissions } from '../../../hooks/usePermissions';
import { DialogEntity } from '../../../components/Dialogs/DialogEntity';
import { useDof } from './helpers/useDof';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { useDates } from '../../../hooks/useDates';
import { useCurrencies } from '../../../hooks/useCurrencies';

export const TableCurrencies = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { GenericGetResource, GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetRateBySerie } = useDof();
  const { TimeConverter } = useDates();
  const { user } = useContext(AuthContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [currenciesData, setCurrenciesData] = useState<GridRowsProp>([]);
  const [currencyPayload, setCurrencyPayload] = useState({});
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const { GetExchangeRateFromCurrencyCode } = useCurrencies();
  const [columnsCurrencies, setColumnsCurrencies] = useState<GridColDef<Currency>[]>([
    { field: 'currency_code', headerName: t("currencies.fields.currency_code"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'currency_description', headerName: t("currencies.fields.currency_description"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'currency_symbol', headerName: t("currencies.fields.currency_symbol"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'rate', headerName: t("exchangeratehistory.fields.rate"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        if (params.value && params.value.rate && params.value.rate > 0) {
          return params.value.rate;
        }
        return params.value;
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-currencies");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-currencies-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<Currency, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if ((element.field === 'rate')) {
            element.valueGetter = function (params: GridValueGetterParams<Currency, any, GridTreeNodeWithRender>) {
              if (params.value && params.value.rate && params.value.rate > 0) {
                return params.value.rate;
              }
              return params.value;
            }
          }
          else if (element.field !== 'last_update_user') {
            element.headerName = t(`currencies.fields.${element.field}`);
          }
          else {
            element.headerName = t(`currencies.fields.${element.field}`);
          }
        }
      }
      setColumnsCurrencies(myJson);
    }
  }

  const handleAddRow = (newRow: Currency) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setCurrenciesData((prevState) => [...currenciesData, myRow]);
  };

  const handleUpdateRow = (updateRow: Currency) => {
    if (currenciesData.length === 0) {
      return;
    }
    setCurrenciesData((prevRows) => {
      const rowToUpdateIndex = currenciesData.findIndex(e => e.currency_id === updateRow.currency_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    if (currenciesData.length === 0) {
      return;
    }
    setCurrenciesData((prevRows) => {
      const rowToDeleteIndex = currenciesData.findIndex(e => e.currency_id === deleteRow);
      return [
        ...currenciesData.slice(0, rowToDeleteIndex),
        ...currenciesData.slice(rowToDeleteIndex + 1),
      ];
    });
  };

  const handleUpdateValueRate = (newRate: number) => {
    let updateRow: any = currencyPayload;
    setCurrenciesData((prevRows) => {
      const rowToUpdateIndex = currenciesData.findIndex(e => e.currency_id === updateRow.currency_id);
      updateRow.rate = newRate;

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  }

  const loadExchangeRateHistory = (currenciesData: Currency[]) => {

    // obtener ID por codigo (USD)
    let myCurrency = currenciesData.find((currency) => currency.currency_code === "USD")
    if (myCurrency !== undefined) {
      // obtener valores del dolar en db con la ultima fecha capturada
      GenericGetResource(`/exchangeratehistory/bycurrencyid/${myCurrency.currency_id}`)
        .then(async (responseRates) => {
          let myRates = responseRates.data.data;
          // obtener cual registro tiene la ultima fecha capturada 
          const orderedRanges = myRates.slice().sort((a: any, b: any) => {
            let first: any = new Date(a.rate_date);
            let second: any = new Date(b.rate_date);
            return second - first
          });
          let myDate = orderedRanges.length > 0 ? new Date(orderedRanges[0].rate_date) : new Date();
          // myDate.setDate(myDate.getDate() + 1);
          // si la ultima fecha no es hoy entonces postea hasta la actual
          if (orderedRanges.length > 0 && new Date().toISOString().split('T')[0] > myDate.toISOString().split('T')[0]) {
            // verificar valor de dolares desde la ultima fecha capurada
            let myResponseDates = await GetRateBySerie("158", orderedRanges[0].rate_date.split('T')[0], new Date().toISOString().split('T')[0]);
            let myNewRows = await myResponseDates.data.ListaIndicadores;
            // si la fecha no es la misma que el primer valor del arreglo entonces postea 
            let d = (myNewRows.at(-1).fecha).split("/");
            if (new Date(d[2] + '/' + d[1] + '/' + d[0]) !== new Date(orderedRanges[0].rate_date)) {
              // Post valores en DB
              for (let i = 1; i < myNewRows.length; i++) {
                const element = myNewRows[i];
                let myDate = element.fecha.split("-");
                let myExchangeRateHistory = {
                  rate_date: new Date(myDate[2] + '/' + myDate[1] + '/' + myDate[0]),
                  rate: element.valor,
                  currency_id: myCurrency?.currency_id,
                }
                GenericPostResource(`/exchangeratehistory`, myExchangeRateHistory)
                  .then(async () => {
                    if (myExchangeRateHistory.currency_id && myExchangeRateHistory.rate_date) {

                      if (myExchangeRateHistory.rate_date.getDay() === 1) {
                        myExchangeRateHistory.rate = await GetExchangeRateFromCurrencyCode(myExchangeRateHistory.currency_id, myExchangeRateHistory.rate_date);
                      }
                      else if (myExchangeRateHistory.rate_date.getDay() === 0) {
                        myExchangeRateHistory.rate = await GetExchangeRateFromCurrencyCode(myExchangeRateHistory.currency_id, myExchangeRateHistory.rate_date);
                      }
                    }
                    if (myCurrency) {
                      let updateRow: any = myCurrency;
                      setCurrenciesData((prevRows) => {
                        const rowToUpdateIndex = currenciesData.findIndex(e => e.currency_id === updateRow.currency_id);
                        updateRow.rate = myExchangeRateHistory.rate;

                        return prevRows.map((row, index) =>
                          index === rowToUpdateIndex ? updateRow : row,
                        );
                      });
                    }
                    setDataLoaded(true);
                  });
              }
            }
          }
          // si esta vacio
          else if (orderedRanges.length === 0) {
            // traer valores desde la ultima fecha hacia atrás
            let myResponseDates;
            let myDate = new Date().toISOString().split('T')[0].split('-');
            let myYear = myDate[0];
            let myMonth = myDate[1];
            let myDay = myDate[2];
            do {
              if (myDay.length === 1) { myDay = `0${myDay}` }
              myResponseDates = await GetRateBySerie("158", `${myYear}-${myMonth}-${myDay}`, `${myYear}-${myMonth}-${myDay}`);
              if (parseInt(myDay) === 1) { myDay = '31' }
              else { myDay = (parseInt(myDay) - 1).toString(); }
            }
            while (myResponseDates?.data.ListaIndicadores.lenght === 0)

            let myNewRows = await myResponseDates.data.ListaIndicadores;
            for (let i = 0; i < myNewRows.length; i++) {
              const element = myNewRows[i];
              let myDate = element.fecha.split("-");
              let myExchangeRateHistory = {
                rate_date: new Date(myDate[2] + '/' + myDate[1] + '/' + myDate[0]),
                rate: element.valor,
                currency_id: myCurrency?.currency_id,
              }
              await GenericPostResource(`/exchangeratehistory`, myExchangeRateHistory)
                .then(async () => {
                  if (myExchangeRateHistory.currency_id && myExchangeRateHistory.rate_date) {

                    if (myExchangeRateHistory.rate_date.getDay() === 1) {
                      myExchangeRateHistory.rate = await GetExchangeRateFromCurrencyCode(myExchangeRateHistory.currency_id, myExchangeRateHistory.rate_date);
                    }
                    else if (myExchangeRateHistory.rate_date.getDay() === 0) {
                      myExchangeRateHistory.rate = await GetExchangeRateFromCurrencyCode(myExchangeRateHistory.currency_id, myExchangeRateHistory.rate_date);
                    }
                  }
                  if (myCurrency) {
                    let updateRow: any = myCurrency;
                    setCurrenciesData((prevRows) => {
                      const rowToUpdateIndex = currenciesData.findIndex(e => e.currency_id === updateRow.currency_id);
                      updateRow.rate = myExchangeRateHistory.rate;

                      return prevRows.map((row, index) =>
                        index === rowToUpdateIndex ? updateRow : row,
                      );
                    });
                  }
                  setDataLoaded(true);
                });
            }
          }
        });
    }
  };



  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("currencies.title")}`;
    })

    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource("/currencies")
      .then(async (response) => {
        setCurrenciesData(response.data.data);
        await loadExchangeRateHistory(response.data.data);
        GetResourceByUrl(`/currencies`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            loadColumnsOrder();
            loadPreferences();
            setDataLoaded(true);
          })
          .catch((error) => {
            setMessageSnack(error.message);
            showSnackBar(error.message, 'error');
            setUnauthorized(false);
          });
      }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, 'error');
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read &&
        <>
          <div className='screen-container'>
            <Header
              title={t("currencies.title")}
              child={
                <div className='d-flex flex-row-reverse my-1'>
                  {resourceScreen?.create === true &&
                    <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
                  }
                </div>
              }
            />

            <div className="d-flex justify-content-center">
              <DataTable
                columns={columnsCurrencies}
                setColumns={setColumnsCurrencies}
                data={currenciesData}
                entityId={"currency_id"}
                entity={`Currencies`}
                preferences={myPreferences}
                namePreferences={"grid-currencies"}
                nameOrderColumns={"grid-currencies-columns"}
                isChildren={true}
                setOpenDialog={setOpenDialogUpdate}
                setDataRow={setCurrencyPayload}
              />
            </div>
          </div>
          <DialogEntity
            content={<UpdateCurrency currencyPayload={currencyPayload} setOpenDialog={setOpenDialogUpdate} permissions={resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} handleUpdateValueRate={handleUpdateValueRate} />}
            open={openDialogUpdate}
            setOpen={setOpenDialogUpdate}
            title={<Header title={t("currencies.title-view")} size='md' />}
          />
          <DialogEntity
            content={<AddCurrency setOpenDialog={setOpenDialogAdd} AddNewRow={handleAddRow} />}
            open={openDialogAdd}
            setOpen={setOpenDialogAdd}
            title={<Header title={t("currencies.title-view")} size='md' />}
          />
          <SnackbarComponent />
        </>
      }
    </>
  )
}
