import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import GenericPromises from "../../../../api/GenericPromises";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Header } from "../../../../components/Header";
import { TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";
import { PaymentCondition } from "../../../../interfaces/Sales/Catalogs/paymentConditions";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";

export const AddPaymentConditions = () => {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const { GenericPostResource } = GenericPromises();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const {showSnackBar,SnackbarComponent}= useSnackBar();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<PaymentCondition>({
        defaultValues: {
            payment_condition_days: undefined,
            payment_condition_name: '',
            payment_condition_description: '',
        }
    });
    const onSubmit: SubmitHandler<PaymentCondition> = (data) => onPost(data);

    const onPost = (data: PaymentCondition) => {
        let myData = {
            payment_condition_days: data.payment_condition_days,
            payment_condition_name: data.payment_condition_name,
            payment_condition_description: data.payment_condition_description ?? null,
        }
        setLoadingPost(true);
        GenericPostResource("/paymentconditions", myData)
            .then((response) => {
                navigate("/paymentconditions", { state: { method: "add" } });
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message,"error");
            });        
    }
    
    const onCancel = () => {
        // validacion si quiere cancelar sus cambios 
        navigate("/paymentconditions", { state: {} });
    }

    return (
        <>
            <Header title={t("paymentconditions.title-view")} />
            <div className='d-flex justify-content-center'>

                <div className='custom-card-view w-75'>

                    <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                        <div className='d-flex w-100'>
                            <div className='w-50'>
                                <Controller
                                    name="payment_condition_days"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("paymentconditions.fields.payment_condition_days")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.payment_condition_days}
                                            helperText={GetError(errors.payment_condition_days?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                                            }}
                                            type="number"
                                        />
                                    }
                                />
                            </div>

                            <div className='w-50'>
                                <Controller
                                    name="payment_condition_name"
                                    control={control}
                                    rules={{ required: true, maxLength: 30 }}
                                    render={({ field }) =>
                                        <TextField
                                            variant="filled"
                                            label={`${t("paymentconditions.fields.payment_condition_name")}`}
                                            ref={field.ref}
                                            value={field.value}
                                            onChange={(event) => { field.onChange(event.target.value) }}
                                            error={!!errors.payment_condition_name}
                                            helperText={GetError(errors.payment_condition_name?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                            sx={{ paddingRight: 2 }}
                                        />
                                    }
                                />
                            </div>

                            <div className="w-50">
                                <Controller
                                    name="payment_condition_description"
                                    control={control}
                                    rules={{  maxLength: 100 }}
                                    render={({ field }) =>
                                        <TextField
                                            ref={field.ref}
                                            variant="filled"
                                            value={field.value}
                                            onChange={field.onChange}
                                            label={t("paymentconditions.fields.payment_condition_description")}
                                            error={!!errors.payment_condition_description}
                                            helperText={GetError(errors.payment_condition_description?.type)}
                                            size="small"
                                            style={{ width: "100%" }}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <div className='d-flex flex-row-reverse mt-1'>
                            <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                            <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                        </div>

                    </form>

                </div>

            </div>
            <SnackbarComponent/>
        </>
    )
}