import { Route, Routes } from 'react-router-dom'
import { TablePermission } from './table'
import { AddPermission } from './add'
import { ViewPermission } from './view'

export const BrowserPermission = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TablePermission />} />
      <Route path={"/add"} element={<AddPermission />} />
      <Route path={"/view"} element={<ViewPermission />} />
    </Routes>
  )
}