import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Item } from "../../interfaces/Commons/items"
import { useEffect, useState } from "react";
import { Spinner } from "../Commons/Spinner/Spinner";
import { AlertColor, Autocomplete, Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../hooks/Forms/FormsErrors";
import { Unit } from "../../interfaces/Commons/units";
import { ProductServiceKey } from "../../interfaces/Sales/Catalogs/productServiceKeys";
import { TaxObject } from "../../interfaces/Sales/Catalogs/taxObjects";
import { ButtonStyled, PrimaryButton } from "../../theme/buttons";
import { Retention } from "../../interfaces/Sales/Catalogs/retentions";
import { Tax } from "../../interfaces/Sales/Catalogs/taxes";
import { TariffFraction } from "../../interfaces/Sales/Catalogs/tariffFractions";
import GenericPromises from "../../api/GenericPromises";
import { ItemsStatuses } from "../../interfaces/Commons/itemstatuses";

type HotAddItemProps = {
  label: string,
  setLabel: React.Dispatch<React.SetStateAction<string>>,
  onAddNewItem: (item: Item) => void,
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>,
  showSnackBar: (message: string, severity: AlertColor) => void,
}

export const HotAddItem = ({
  label,
  setLabel,
  onAddNewItem,
  setOpenDialog,
  showSnackBar,
}: HotAddItemProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const { GenericGetResource, GenericPostResource, GenericGet, GenericGetResourceGeneric } = GenericPromises();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [comboItemsStatuses, setComboItemsStatuses] = useState<ItemsStatuses[]>([]);
  const [comboUnits, setComboUnits] = useState<Unit[]>([]);
  const [comboKeys, setComboKeys] = useState<ProductServiceKey[]>([]);
  const [comboTaxObjects, setComboTaxObjects] = useState<TaxObject[]>([]);
  const [comboRetentions, setComboRetentions] = useState<Retention[]>([]);
  const [comboTaxes, setComboTaxes] = useState<Tax[]>([]);
  const [comboTariffFractions, setComboTariffFractions] = useState<TariffFraction[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Item>({
    defaultValues: {
      item_description: label,
    }
  });
  const onSubmit: SubmitHandler<Item> = (data) => onPost(data);

  const onPost = (data: Item) => {
    setLoadingPost(true);
    let myData = {
      item_code: data.item_code,
      item_description: data.item_description,
      default_item_price: data.default_item_price ?? null,
      weight: data.weight ?? null,
      item_type_id: data.item_type_id ?? null,
      item_family_id: data.item_family_id ?? null,
      item_status_id: comboItemsStatuses[0].item_status_id,
      unit_id: data.unit_id,
      product_service_key_id: data.product_service_key_id,
      tax_object_id: data.tax_object_id,
      retention_id: data.retention_id ?? null,
      tax_id: data.tax_id ?? null,
      tariff_fraction_id: data.tariff_fraction_id ?? null,
    }
    GenericPostResource("/items", myData)
      .then(async (response) => {
        showSnackBar(t("generic.snackbar.add"), "success");
        await onAddNewItem(response.data);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    setLabel("");
    setOpenDialog(false);
  }

  useEffect(() => {
    let myPromises = [
      GenericGet("/itemsstatuses"),
      GenericGetResource("/units"),
      GenericGetResource("/taxobjects"),
      GenericGetResource("/productservicekeys"),
      GenericGetResource("/retentions"),
      GenericGetResource("/taxes"),
      GenericGetResource("/tarifffractions")
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboItemsStatuses(responses[0].data.data);
        setComboUnits(responses[1].data.data);
        setComboTaxObjects(responses[2].data.data);
        setComboKeys(responses[3].data.data);
        setComboRetentions(responses[4].data.data);
        setComboTaxes(responses[5].data.data);
        setComboTariffFractions(responses[6].data.data);

        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <div className='d-flex justify-content-center my-2'>

            <div className='w-75'>

              <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                <div className="d-flex justify-content-between">

                  <div className='w-100'>

                    <div className='d-flex'>
                      <div className="w-50"><Controller
                        name="item_description"
                        control={control}
                        rules={{ required: true, maxLength: 300 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            ref={field.ref}
                            value={field.value}
                            label={`${t("items.fields.item_description")}`}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.item_description}
                            helperText={GetError(errors.item_description?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                          />
                        }
                      /></div>
                      <div className="w-50">
                        <Controller
                          name="item_code"
                          control={control}
                          rules={{ required: true, maxLength: 50 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("items.fields.item_code")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.item_code}
                              helperText={GetError(errors.item_code?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className='d-flex my-2 pt-1'>

                      <div className='w-50'>
                        <Controller
                          name="default_item_price"
                          control={control}
                          rules={{}}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("items.fields.default_item_price")}`}
                              type='number'
                              ref={field.ref}
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                              }}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.default_item_price}
                              helperText={GetError(errors.default_item_price?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>

                      <div className='w-50'>
                        <Controller
                          name="unit_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%" }}
                              options={comboUnits}
                              getOptionLabel={(option) => `${option.unit_code} - ${option.unit_description}`}
                              renderOption={(props, option: Unit) => (
                                <div key={option.unit_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {`${option.unit_code} - ${option.unit_description}`}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.unit_id === value.unit_id}
                              onChange={(_, values) => {
                                field.onChange(values?.unit_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("items.fields.unit_id")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.unit_id || field.value === null}
                                  helperText={(errors.unit_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className='d-flex pt-2'>

                      <div className='w-50'>
                        <Controller
                          name="product_service_key_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%", paddingRight: 2 }}
                              options={comboKeys}
                              getOptionLabel={(option) => `${option.product_service_key} - ${option.description}`}
                              renderOption={(props, option: ProductServiceKey) => (
                                <div key={option.product_service_key_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {`${option.product_service_key} - ${option.description}`}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.product_service_key_id === value.product_service_key_id}
                              onChange={(_, values) => {
                                field.onChange(values?.product_service_key_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("productservicekeys.fields.product_service_key")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.product_service_key_id || field.value === null}
                                  helperText={(errors.product_service_key_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                      <div className='w-50'>
                        <Controller
                          name="tax_object_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%" }}
                              options={comboTaxObjects}
                              getOptionLabel={(option) => `${option.tax_object} - ${option.description}`}
                              renderOption={(props, option: TaxObject) => (
                                <div key={option.tax_object_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {`${option.tax_object} - ${option.description}`}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.tax_object_id === value.tax_object_id}
                              onChange={(_, values) => {
                                field.onChange(values?.tax_object_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("items.fields.tax_object")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.tax_object_id || field.value === null}
                                  helperText={(errors.tax_object_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className='d-flex pt-3'>

                      <div className='w-50'>
                        <Controller
                          name="retention_id"
                          control={control}
                          rules={{}}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%", paddingRight: 2 }}
                              options={comboRetentions}
                              getOptionLabel={(option) => `${option.retention_name} - ${option.retention_percent}%`}
                              renderOption={(props, option: Retention) => (
                                <div key={option.retention_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {`${option.retention_name} - ${option.retention_percent}%`}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.retention_id === value.retention_id}
                              onChange={(_, values) => {
                                field.onChange(values?.retention_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("items.fields.retention")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.retention_id || field.value === null}
                                  helperText={(errors.retention_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                      <div className='w-50'>
                        <Controller
                          name="tax_id"
                          control={control}
                          rules={{}}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%" }}
                              options={comboTaxes}
                              getOptionLabel={(option) => `${option.tax_name} - ${option.tax_percent}%`}
                              renderOption={(props, option: Tax) => (
                                <div key={option.tax_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {`${option.tax_name} - ${option.tax_percent}%`}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.tax_id === value.tax_id}
                              onChange={(_, values) => {
                                field.onChange(values?.tax_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("items.fields.tax")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.tax_id || field.value === null}
                                  helperText={(errors.tax_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                />
                              )}
                            />

                          )}
                        />
                      </div>
                    </div>

                    <div className='d-flex pt-3'>
                      <div className='w-50'>
                        <Controller
                          name="tariff_fraction_id"
                          control={control}
                          rules={{}}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%" }}
                              options={comboTariffFractions}
                              getOptionLabel={(option) => `${option.tariff_fraction_code} - ${option.tariff_fraction_specific_description}`}
                              renderOption={(props, option: TariffFraction) => (
                                <div key={option.tariff_fraction_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {`${option.tariff_fraction_code} - ${option.tariff_fraction_specific_description}`}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.tariff_fraction_id === value.tariff_fraction_id}
                              onChange={(_, values) => {
                                field.onChange(values?.tariff_fraction_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("tarifffractions.title-view")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.item_type_id}
                                  sx={{ paddingRight: 2 }}
                                />
                              )}
                            />

                          )}
                        />
                      </div>
                    </div>



                  </div>

                </div>

                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                  <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                </div>

              </form>

            </div>

          </div>
        </>
      }
    </>
  )
}
