import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { ProjectType } from "../../../../../interfaces/Projects/Catalogs/projectTypes";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../../components/Header";
import { Autocomplete, Box, TextField } from "@mui/material";
import { ButtonStyled, PrimaryButton } from "../../../../../theme/buttons";
import { ProjectTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectsTemplate";

export const AddProjectsTemplate = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResource, GenericGetResourceGeneric } =
    GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [comboProjectTypes, setComboProjectTypes] = useState<ProjectType[]>([]);
  const [projectNameDisabled, setProjectNameDisabled] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useForm<ProjectTemplate>({
    defaultValues: {
      project_type_id: undefined,
      project_template_name: undefined,
      project_template_comments: undefined,
    },
  });

  const onSubmit: SubmitHandler<ProjectTemplate> = (data) => onPost(data);

  const onPost = (data: ProjectTemplate) => {
    trigger().then(async (responseTrigger) => {
      if (responseTrigger && data.project_type_id) {
        let myData = {
          project_type_id: data.project_type_id,
          project_template_name: data.project_template_name,
          project_template_comments: data.project_template_comments ?? null,
        };
        setLoadingPost(true);
        GenericPostResource("/projectstemplate", myData)
          .then(async (response) => {
            await navigate("/projectstemplate/view", {
              state: { row: response.data },
            });
          })
          .catch((error) => {
            setLoadingPost(false);
            showSnackBar(error.message, "error");
          });
      } else {
        showSnackBar(t("salesinvoices.info.no-data"), "warning");
      }
    });
  };

  const onCancel = () => {
    navigate("/projectstemplate", { state: {} });
  };

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t(
        "projectstemplate.title"
      )}`;
    });

    let myPromises = [
      GenericGetResource("/projecttypes"),
      GenericGetResource("/projectstemplate"),
    ];

    Promise.all(myPromises)
      .then((responses) => {

        const ids = responses[1].data.data.map(
          (item: ProjectTemplate) => item.project_type_id
        );
        if (ids) {
          const filteredProjectTypes = responses[0].data.data.filter(
            (projectType: ProjectType) =>
              !ids.includes(projectType.project_type_id)
          );
          setComboProjectTypes(filteredProjectTypes);
        } else {
          setComboProjectTypes(responses[0].data.data);
        }

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);



  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded && (
        <>
          <Header title={t("projectstemplate.title-view")} />
          <div className="d-flex justify-content-center">
            <div className="custom-card-view w-100 mt-4">
              <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                <div className="custom-card-row d-flex w-100">
                  <div className="w-100">
                    <div className="d-flex w-100">
                      <div className="w-50">
                        <Controller
                          name="project_type_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%", paddingRight: 2 }}
                              options={comboProjectTypes}
                              getOptionLabel={(option) =>
                                `${option.project_type_name} - ${option.project_type_description}`
                              }
                              renderOption={(props, option: ProjectType) => (
                                <div key={option.project_type_id}>
                                  <Box
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    {option.project_type_name} -{" "}
                                    {option.project_type_description}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option.project_type_id === value.project_type_id
                              }
                              onChange={(_, values) => {
                                field.onChange(values?.project_type_id || null);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t(
                                    "projectstemplate.fields.project_type"
                                  )}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.project_type_id}
                                  helperText={GetError(
                                    errors.project_type_id?.type
                                  )}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                      <div className="w-50">
                        <Controller
                          name="project_template_name"
                          control={control}
                          rules={{ required: true, maxLength: 50 }}
                          render={({ field }) => (
                            <TextField
                              variant="filled"
                              label={`${t(
                                "projectstemplate.fields.project_template_name"
                              )}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => {
                                field.onChange(event.target.value);
                              }}
                              error={!!errors.project_template_name}
                              helperText={GetError(
                                errors.project_template_name?.type
                              )}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="d-flex w-100 pt-3">
                      <div className="w-100">
                        <Controller
                          name="project_template_comments"
                          control={control}
                          rules={{ maxLength: 1500 }}
                          render={({ field }) => (
                            <TextField
                              variant="filled"
                              label={`${t(
                                "projectstemplate.fields.project_template_comments"
                              )}`}
                              ref={field.ref}
                              value={field.value}
                              multiline
                              minRows={5}
                              onChange={(event) => {
                                field.onChange(event.target.value);
                              }}
                              error={!!errors.project_template_comments}
                              helperText={GetError(
                                errors.project_template_comments?.type
                              )}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row-reverse mt-1">
                  <PrimaryButton
                    type="submit"
                    variant="outlined"
                    className="m-1"
                    disabled={loadingPost}
                  >
                    {t("generic.buttons.accept")}
                  </PrimaryButton>
                  <ButtonStyled
                    variant="contained"
                    className="m-1"
                    onClick={onCancel}
                  >
                    {t("generic.buttons.cancel")}
                  </ButtonStyled>
                </div>
              </form>
            </div>
          </div>
          <SnackbarComponent />
        </>
      )}
    </>
  );
};
