import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import GenericPromises from '../../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { useTranslation } from 'react-i18next';
import { Company } from '../../../../interfaces/Security/companies';
import { UserCompanies } from '../../../../interfaces/Security/userCompanies';
import { Schema } from '../../../../interfaces/Security/schemas';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';


export const AddUserCompanies = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboSchemas, setComboSchemas] = useState<Schema[]>([]);
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserCompanies>({
    defaultValues: {
      schema_id: undefined
    }
  });
  const onSubmit: SubmitHandler<UserCompanies> = (data) => onPost(data);

  const onPost = (data: UserCompanies) => {
    setLoadingPost(true);
    let myData = {
      user_id: props.user.user_id,
      schema_id: data.schema_id,
    }
    GenericPostResource("/usercompanies/byschema", myData)
      .then((response) => {
        let schema = comboSchemas.find((element) => element.schema_id === response.data.schema_id);
        let myNewData: UserCompanies = {
          user_company_id: response.data.user_company_id,
          user_id: response.data.user_id,
          company_id: response.data.company_id,
          business_name: schema?.schema_description,
          schema_id: response.data.schema_id,
          is_default_schema: false,
          schema_name: schema?.schema_name,
        };
        props.AddNewRow(myNewData);
        props.setOpenDialog(false);
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        if (error.message === 'Network Error') showSnackBar(t("userCompanies.fields.duplicate"), "error");
        else showSnackBar(error.message, "error");
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/schemas")
    ];

    Promise.all(myPromises)
      .then((responses) => {
        var mySchemasFiltered = responses[0].data.data.filter((responseItem : Schema) => {
          // Verificamos si el schema_name actual no está en usersCompaniesData
          return !props.usersCompaniesData.some((companyItem : UserCompanies) => companyItem.schema_name === responseItem.schema_name);
        });
        setComboSchemas(mySchemasFiltered);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 500 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='d-flex'>
              <div className='w-100'>
                <Controller
                  name="schema_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      disabled = {comboSchemas.length <= 0}
                      options={comboSchemas}
                      getOptionLabel={(option) => `${option.schema_description} - ${option.schema_name}`}
                      isOptionEqualToValue={(option, value) => option.schema_id === value.schema_id}
                      onChange={(_, values) => {
                        field.onChange(values?.schema_id || null)
                      }}
                      renderOption={(props, option: Schema) => (
                        <div key={option.schema_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.schema_description} - {option.schema_name}
                          </Box>
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("userCompanies.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.schema_id}
                          helperText={GetError(errors.schema_id?.type)}
                        />
                      )}
                    />
                  }
                />
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel} disabled={loadingPost}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <SnackbarComponent />
    </>
  )
}
