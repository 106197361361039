import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../../../components/Header";
import { User } from '../../../../interfaces/Security/users';
import GenericPromises from "../../../../api/GenericPromises";
import { Button } from '@mui/material';
import { Menuitem } from "../../../../interfaces/Security/menu";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { UpdateUser } from "./update";
import { UserCompaniesTable } from "../UserCompanies/table";
import UserDetail from "./userDetail";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../../hooks/usePermissions";
import { MenuPermissions } from "../../../../components/Security/AccordionPermissions";
import { GenericDialog } from "../../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../../theme/buttons";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";

export const ViewUser = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [userLocal, setUserLocal] = useState<User>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GetResourceByUrl } = usePermissions();
  const {showSnackBar,SnackbarComponent}=useSnackBar();



  const onDelete = () => {
    // delete companies
    GenericGetResource(`/usercompanies/byuserid/${userLocal?.user_id}`)
      .then((response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];
          GenericDeleteResource(`/usercompanies/${element.user_company_id}`)
            .catch((error) => {
              showSnackBar(error.message,"error");
              setOpenDialog(false);
            })
        }

        GenericDeleteResource(`/users/${userLocal?.user_id}`)
          .then(() => {
            setOpenDialog(false);
            navigate("/users", { state: { method: "delete" }, replace: true });
          })
          .catch((error) => {
            showSnackBar(error.message,"error");
            setOpenDialog(false);
          })
      })
      .catch((error) => {
        showSnackBar(error.message,"error");
        setOpenDialog(false);
      });
  }

  useEffect(() => {
    if (location.state === null) return navigate("/users", { state: {}, replace: true });
    GenericGetResource(`/users/${location.state.row.user_id}`)
      .then(
        (response) => {
          setUserLocal(response.data);

          GetResourceByUrl(`/users`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message,"error");
            });
        }
      ).catch((error) => {
        showSnackBar(error.message,"error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header title={t("users.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="outlined" onClick={() => { navigate("/users", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>}
          />
          {resourceScreen?.read &&
            <>
              <div className="mt-4">
                <UserDetail
                  view={
                    <div>
                      {resourceScreen?.update === true &&
                        <UpdateUser userPayload={userLocal} isEdit={resourceScreen?.update} />
                      }
                    </div>
                  }
                  companies={
                    <UserCompaniesTable user={userLocal} />
                  }
                  permissions={
                    <MenuPermissions groupId={userLocal?.group_id} />
                  }
                />
              </div>
            </>
          }

          <GenericDialog
            title={t("generic.dialog.delete")}
            content={t("users.dialog.delete")}
            open={openDialog}
            setOpen={setOpenDialog}
            onAcept={onDelete}
          />
          <SnackbarComponent/>
        </>
      }
    </>
  )
}
