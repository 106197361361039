import React, { useEffect, useState } from 'react'
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowEditStopReasons, GridRowModesModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { SnackbarComponent } from '../../../../components/Commons/SnackBar';
import { CancelledMerchandiseDetail, CancelledTransportationDocument } from '../../../../interfaces/Sales/Invoices/Cancellations/cancelledTransportationDocuments';
import { useLocation } from 'react-router-dom';
import TableCancelledMerchandiseDetail from '../../../../components/Tables/TableCancelledMerchandiseDetail';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

type MerchandiseProps = {
  transportationDocumentPayload?: CancelledTransportationDocument | undefined;
}

export const TableMerchandise = ({
  transportationDocumentPayload,
}: MerchandiseProps) => {
  const [rows, setRows] = React.useState<CancelledMerchandiseDetail[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [t] = useTranslation("global");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isDangerousMaterial, setIsDangerousMaterial] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const location = useLocation();
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  let columns: GridColDef[] = (!isDangerousMaterial) ?
    [
      {
        field: 'product_service_key',
        headerName: t("productservicekeys.title"),
        flex: 1,
      },
      {
        field: 'description',
        headerName: t("merchandisedetails.fields.concept"),
        flex: 1,
      },
      {
        field: 'quantity',
        headerName: t("merchandisedetails.fields.quantity"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
      {
        field: 'unit_code',
        headerName: t("merchandisedetails.fields.unit_code"),
        flex: 1
      },
      {
        field: 'weight_in_kg',
        headerName: t("merchandisedetails.fields.weight_in_kg"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
    ]
    :
    [
      {
        field: 'product_service_key',
        headerName: t("productservicekeys.title"),
        flex: 1,
      },
      {
        field: 'description',
        headerName: t("merchandisedetails.fields.concept"),
        flex: 1,
      },
      {
        field: 'quantity',
        headerName: t("merchandisedetails.fields.quantity"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
      {
        field: 'unit_code',
        headerName: t("merchandisedetails.fields.unit_code"),
        flex: 1
      },
      {
        field: 'weight_in_kg',
        headerName: t("merchandisedetails.fields.weight_in_kg"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
      {
        field: 'packaging_type_code',
        headerName: t("merchandisedetails.fields.packaging_type_code"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
      {
        field: 'hazardous_material_key',
        headerName: t("merchandisedetails.fields.hazardous_material_key"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
    ];

  const changeTotalValue = () => {

  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  useEffect(() => {
    if (transportationDocumentPayload !== undefined || location.state.row.cancelled_transportation_document_id) {
      GenericGetResource(`/cancelledmerchandisedetails/bycancelledtransportationdocumentid/${transportationDocumentPayload?.cancelled_transportation_document_id ?? location.state.row.cancelled_transportation_document_id}`)
        .then((response) => {
          setRows(response.data.data);
          if (response.data.data.find((cancelledmerchandisedetails: CancelledMerchandiseDetail) => cancelledmerchandisedetails.is_dangerous_material || cancelledmerchandisedetails.could_be_hazardous_material)) {
            setIsDangerousMaterial(true);
          }
          setDataLoaded(true);
        })
        .catch((error) => {
          showSnackBar(error.message,"error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {dataLoaded &&
        <TableCancelledMerchandiseDetail
          rows={rows}
          transportationDocument={transportationDocumentPayload?.cancelled_transportation_document_id ?? 0}
          columns={columns}
          rowModesModel={rowModesModel}
          handleRowModesModelChange={handleRowModesModelChange}
          handleRowEditStop={handleRowEditStop}
          setRows={setRows}
          setRowModesModel={setRowModesModel}
          ChangeTotalMerchandise={changeTotalValue}
        />
      }
      <SnackbarComponent/>
    </React.Fragment>
  );
}
