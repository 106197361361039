import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import GenericPromises from '../../../../api/GenericPromises';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { OptionMenu } from '../../../../interfaces/Security/optionsMenu';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, Switch, TextField, Typography } from '@mui/material';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Resource } from '../../../../interfaces/Security/resources';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';

export const AddOptionMenuTree = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPostResource, GenericGetResource, } = GenericPromises();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { showSnackBar, SnackbarComponent } = useSnackBar();

    const {
        handleSubmit,
        control,
        formState: { errors },
        getValues,
        setValue,
        reset,
        trigger,
    } = useForm<OptionMenu>({
        defaultValues: {
            parent_option_id: undefined,
            resource_id: undefined,
            option_name: undefined,
            description: undefined,
            menu_order: undefined,
            is_visible: true,
        }
    });
    const onSubmit: SubmitHandler<OptionMenu> = (data) => onPost(data);

    const onPost = (data: OptionMenu) => {
        setLoadingPost(true);
        trigger().then(async (triggerData) => {
            if (triggerData) {
                let myOptionMenu = {
                    parent_option_id: null,
                    resource_id: null,
                    option_name: data.option_name,
                    description: data.description ?? null,
                    is_visible: true,
                }
                GenericPostResource("/optionmenu", myOptionMenu)
                    .then((response) => {
                        let myEnum = {
                            enum_id: response.data.option_menu_id,
                            option_name: response.data.description ?? response.data.option_name,
                            route: `/menu/parentid/${response.data.option_menu_id}`,
                        };
                        let myComboEnum = props.comboEnum
                        myComboEnum.push(myEnum)
                        props.setComboEnum(myComboEnum)
                        props.setOpenDialog(false);
                    })
                    .catch((error) => {
                        setLoadingPost(false);
                        showSnackBar(error.message, 'error');
                    });
            }
        })
        setLoadingPost(false);
    }

    const onCancel = () => {
        props.setOpenDialog(false);
    }

    useEffect(() => {
        setDataLoaded(true);
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
                <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>
                    <form onSubmit={handleSubmit(onSubmit)} className='w-100'>
                        <div className='w-100'>
                            <div className='w-100 d-flex'>
                                <div className="w-50">
                                    <Controller
                                        name="option_name"
                                        control={control}
                                        rules={{ required: true, maxLength: 200 }}
                                        render={({ field }) =>
                                            <TextField
                                                variant="filled"
                                                label={`${t("optionsmenu.fields.option_name")}`}
                                                ref={field.ref}
                                                value={field.value}
                                                onChange={(event) => { field.onChange(event.target.value) }}
                                                error={!!errors.option_name}
                                                helperText={GetError(errors.option_name?.type)}
                                                size="small"
                                                style={{ width: "100%" }}
                                                sx={{ paddingRight: 2 }}
                                            />
                                        }
                                    />
                                </div>
                                <div className="w-50">
                                    <Controller
                                        name="description"
                                        control={control}
                                        rules={{ maxLength: 200 }}
                                        render={({ field }) =>
                                            <TextField
                                                variant="filled"
                                                label={`${t("optionsmenu.fields.description")}`}
                                                ref={field.ref}
                                                value={field.value}
                                                onChange={(event) => { field.onChange(event.target.value) }}
                                                error={!!errors.description}
                                                helperText={GetError(errors.description?.type)}
                                                size="small"
                                                style={{ width: "100%" }}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row-reverse mt-1'>
                            <PrimaryButton onClick={() => { onPost(getValues()) }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                            <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                        </div>
                    </form>
                </Box >
            }
            <SnackbarComponent />
        </>
    )
}
