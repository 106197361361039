import GenericPromises from "../../api/GenericPromises"
import { CompanyConfiguration } from "../../interfaces/Security/companies"

export const useSendEmails = () => {
  const { GenericGetResourceGeneric } = GenericPromises();

  const GetCompanyConfiguration = async () => {
    try {
      let myResponse = await GenericGetResourceGeneric("/companyconfigurations/bycompanyid/1", "/gcompanyconfigurations");
      return myResponse;
    }
    catch(error) {
      throw error;
    }
  }

  const ReplaceMessages = (message: string, data: any) => {
    return message.replace(/{([^}]+)}/g, (_, key) => {
      return key in data ? data[key] : `{${key}}`;
    });
  }

  return {
    GetCompanyConfiguration,
    ReplaceMessages,
  }
}
