import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../../../../components/Header";
import GenericPromises from "../../../../../api/GenericPromises";
import { Button } from '@mui/material';
import { Menuitem } from "../../../../../interfaces/Security/menu";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { UpdateCustomer } from "./update";
import { useTranslation } from "react-i18next";
import { Customer, CustomerBankAccount, CustomerLocation } from '../../../../../interfaces/Sales/Catalogs/Customers/customers';
import CustomerDetail from "./customerDetail";
import { TableCustomerLocations } from "../CustomerLocations/table";
import { TableCustomerItems } from "../CustomerItems/table";
import { FinancialDataTab } from "./financialData";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { SubmitHandler, useForm } from "react-hook-form";
import { File } from "../../../../../interfaces/Commons/files";
import { useFiles } from "../../../../../hooks/useFiles";
import { CFDI } from "../../../../../interfaces/Sales/Catalogs/CFDIs";
import { Currency } from "../../../../../interfaces/Commons/currencies";
import { PaymentMethod } from "../../../../../interfaces/Sales/Catalogs/paymentMethods";
import { PaymentMode } from "../../../../../interfaces/Sales/Catalogs/paymentModes";
import { Tax } from "../../../../../interfaces/Sales/Catalogs/taxes";
import { TaxRegime } from "../../../../../interfaces/Sales/Catalogs/taxRegimes";
import { Retention } from "../../../../../interfaces/Sales/Catalogs/retentions";
import { INCOTERM } from "../../../../../interfaces/Sales/Catalogs/TransportationDocuments/INCOTERMs";
import { CustomerItem } from '../../../../../interfaces/Sales/Catalogs/Customers/customerItems';
import { PaymentCondition } from "../../../../../interfaces/Sales/Catalogs/paymentConditions";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../../../theme/buttons";
import { CustomKeyCode } from "../../../../../interfaces/Sales/Catalogs/customsKeyCodes";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { BrowserCustomerBankAccounts } from "../CustomerBankAccounts";
import { Language } from "../../../../../interfaces/Commons/languages";
import { useParamsFilter } from "../../../../../hooks/useParamsFilter";

export const ViewCustomer = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const { GetParamsFromBase64 } = useParamsFilter();
  const [customerLocal, setCustomerLocal] = useState<Customer>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [isNotMexican, setIsNotMexican] = useState(false);
  const { GenericGetResource, GenericPutResource, GenericDeleteResource, GenericGet } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const { GetContentURLImage, PostFiles } = useFiles();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [imageFile, setImageFile] = useState<File>({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
  const [comboTaxRegimes, setComboTaxRegimes] = useState<TaxRegime[]>([]);
  const [defaultTaxRegime, setDefaultTaxRegime] = useState<TaxRegime>();
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [comboTaxes, setComboTaxes] = useState<Tax[]>([]);
  const [defaultTax, setDefaultTax] = useState<Tax>();
  const [comboPaymentModes, setComboPaymentModes] = useState<PaymentMode[]>([]);
  const [defaultPaymentMode, setDefaultPaymentMode] = useState<PaymentMode>();
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [comboCFDIs, setComboCFDIs] = useState<CFDI[]>([]);
  const [defaultCFDI, setDefaultCFDI] = useState<CFDI>();
  const [comboRetentions, setComboRetentions] = useState<Retention[]>([]);
  const [defaultRetention, setDefaultRetention] = useState<Retention>();
  const [comboINCOTERMs, setComboINCOTERMs] = useState<INCOTERM[]>([]);
  const [defaultINCOTERM, setDefaultINCOTERM] = useState<INCOTERM>();
  const [comboPaymentConditions, setComboPaymentConditions] = useState<PaymentCondition[]>([]);
  const [defaultPaymentConditions, setDefaultPaymentConditions] = useState<PaymentCondition>();
  const [comboCustomsKeyCodes, setComboCustomsKeyCodes] = useState<CustomKeyCode[]>([]);
  const [defaultCustomsKeyCode, setDefaultCustomsKeyCode] = useState<CustomKeyCode>();
  const [comboLanguages, setComboLanguages] = useState<Language[]>([]);
  const [defaultLanguage, setDefaultLanguage] = useState<Language>();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [defaultPrimaryBankAccount, setDefaultPrimaryBankAccount] = useState<CustomerBankAccount>();
  const [comboCustomerBankAccount, setComboCustomerBankAccount] = useState<CustomerBankAccount[]>([]);
  const [originalCustomerIdentifier, setOriginalCustomerIdentifier] = useState<string | undefined>();

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Customer>({
    defaultValues: {
      business_name: "",
      comercial_name: "",
      customer_rfc: "",
      customer_email: "",
      customer_phone_number: "",
      customer_web: "",
      customer_identifier: "",
      tax_regime_id: undefined,
      default_currency_id: undefined,
      default_tax_id: undefined,
      default_payment_mode_id: undefined,
      default_payment_method_id: undefined,
      default_cfdi_id: undefined,
      default_retention_id: undefined,
      group_by: undefined,
      group_salesorders_by: undefined,
      group_backorders_by: undefined,
      group_shippingdocuments_by: undefined,
      group_invoices_by: undefined,
      default_payment_condition_id: undefined,
      default_customs_key_code_id: undefined,
      language_id: undefined
    }
  });
  const onSubmit: SubmitHandler<Customer> = (data) => onPut(data);

  const onPut = (data: Customer) => {
    setLoadingPost(true);
    let myData = {
      business_name: data.business_name.trim(),
      comercial_name: data.comercial_name ?? null,
      customer_rfc: data.customer_rfc.trim(),
      customer_email: data.customer_email ?? null,
      customer_phone_number: data.customer_phone_number ?? null,
      customer_web: data.customer_web ?? null,
      customer_identifier: data.customer_identifier ?? null,
      tax_regime_id: data.tax_regime_id ?? null,
      language_id: data.language_id,
      default_currency_id: defaultCurrency?.currency_id ?? data.default_currency_id ?? null,
      default_tax_id: data.default_tax_id ?? defaultTax?.tax_id ?? null,
      default_payment_mode_id: data.default_payment_mode_id ?? defaultPaymentMode?.payment_mode_id ?? null,
      default_payment_method_id: data.default_payment_method_id ?? defaultPaymentMethod?.payment_method_id ?? null,
      default_cfdi_id: data.default_cfdi_id ?? defaultCFDI?.cfdi_id ?? null,
      default_retention_id: data.default_retention_id ?? defaultRetention?.retention_id ?? null,
      default_incoterm_id: data.default_incoterm_id ?? defaultINCOTERM?.incoterm_id ?? null,
      default_payment_condition_id: data.default_payment_condition_id ?? defaultPaymentConditions?.payment_condition_id ?? null,
      default_customs_key_code_id: data.default_customs_key_code_id ?? defaultCustomsKeyCode?.customs_key_code_id ?? null,
      group_by: data.group_by ?? 0,
      group_salesorders_by: data.group_salesorders_by ?? null,
      group_backorders_by: data.group_backorders_by ?? null,
      group_shippingdocuments_by: data.group_shippingdocuments_by ?? null,
      group_invoices_by: data.group_invoices_by ?? null,
      primary_bank_account: data.primary_bank_account ?? null,
    }
    GenericPutResource(`/customers/${customerLocal?.customer_id}`, myData)
      .then((response) => {
        setOriginalCustomerIdentifier(data.customer_identifier)
        showSnackBar(t("generic.snackbar.update"), "success")
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(t(error.response.data), "error");
        setValue('customer_identifier', originalCustomerIdentifier);
      });
  }

  const onPostImage = (image: any) => {
    let myFile = {
      entity: "Customers",
      recordId: customerLocal?.customer_id,
      fileName: image.file_name,
      typeOfFile: image.type_of_file,
      schema_name: localStorage.getItem("schemaName"),
      Content: GetContentURLImage(image.dataURL),
    }
    PostFiles(myFile)
      .then(() => {
        setImageFile((prev) => image);
        setImageLoaded(true);
        showSnackBar(t("generic.snackbar.update_photo"), "success")
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  const onDelete = () => {
    let myPromises = [
      GenericGetResource(`/customeritems/bycustomerid/${customerLocal?.customer_id}`),
      GenericGetResource(`/customerlocations/bycustomerid/${customerLocal?.customer_id}`),
    ];
    Promise.all(myPromises)
      .then(async (responses) => {
        let myChildPromises: Promise<any>[] = [];
        await responses[0].data.data.forEach((element: CustomerItem) => {
          myChildPromises.push(GenericDeleteResource(`/customeritems/${element.customer_items_id}`));
        });
        await responses[1].data.data.forEach((element: CustomerLocation) => {
          myChildPromises.push(GenericDeleteResource(`/customerlocations/${element.customer_location_id}`));
        });
        Promise.all(myChildPromises)
          .then(() => {
            GenericDeleteResource(`/customers/${customerLocal?.customer_id}`)
              .then(() => {
                setOpenDialog(false);
                navigate("/customers", { state: { method: "delete" }, replace: true });
              })
              .catch((error) => {
                showSnackBar(error.message, "error");
                setOpenDialog(false);
              });
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setOpenDialog(false);
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  }

  useEffect(() => {
    let customerId: any;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      customerId = myParams.get("customer_id") ?? '';
    }
    if (location.state === null && (!customerId)) return navigate("/customers", { state: {}, replace: true });

    GenericGetResource(`/customers/${(location.state !== null) ? location.state.row.customer_id: customerId}`).then((response) => {
      setCustomerLocal((prev) => response.data);
      GetResourceByUrl("/customers")
        .then((response1) => {
          setResourceScreen((prev) => response1);
          let myPromises = [
            GenericGetResource("/customers"),
            GenericGet(`/files/recordId/${response.data.customer_id}/entity/Customers/schema/${localStorage.getItem("schemaName")}`),
            GenericGetResource("/currencies"),
            GenericGetResource("/taxes"),
            GenericGetResource("/paymentmodes"),
            GenericGetResource("/paymentmethods"),
            GenericGetResource("/cfdi"),
            GenericGetResource("/taxregimes"),
            GenericGetResource("/retentions"),
            GenericGetResource("/incoterms"),
            GenericGetResource("/paymentconditions"),
            GenericGetResource("/customskeycodes"),
            GenericGetResource("/languages"),
            GenericGetResource(`/customerbankaccounts/bycustomerid/${response.data.customer_id}`),
          ];

          Promise.all(myPromises)
            .then((responses) => {
              setComboCustomers(responses[0].data.data);

              reset({
                business_name: response.data.business_name,
                comercial_name: response.data.comercial_name ?? undefined,
                customer_rfc: response.data.customer_rfc,
                customer_email: response.data.customer_email ?? undefined,
                customer_phone_number: response.data.customer_phone_number ?? undefined,
                customer_web: response.data.customer_web ?? undefined,
                customer_identifier: response.data.customer_identifier ?? undefined,
                tax_regime_id: response.data.tax_regime_id ?? undefined,
                default_currency_id: response.data.default_currency_id ?? undefined,
                default_tax_id: response.data.default_tax_id ?? undefined,
                default_payment_mode_id: response.data.default_payment_mode_id ?? undefined,
                default_payment_method_id: response.data.default_payment_method_id ?? undefined,
                default_cfdi_id: response.data.default_cfdi_id ?? undefined,
                default_retention_id: response.data.default_retention_id ?? undefined,
                group_by: response.data.group_by ?? 0,
                group_salesorders_by: response.data.group_salesorders_by ?? undefined,
                group_backorders_by: response.data.group_backorders_by ?? undefined,
                group_shippingdocuments_by: response.data.group_shippingdocuments_by ?? undefined,
                group_invoices_by: response.data.group_invoices_by ?? undefined,
                default_incoterm_id: response.data.default_incoterm_id ?? undefined,
                default_payment_condition_id: response.data.default_payment_condition_id ?? undefined,
                default_customs_key_code_id: response.data.default_customs_key_code_id ?? undefined,
                language_id: response.data.language_id,
              });

              // setDefaultGroupBy(responses[0].data.data.find((customer: Customer) => customer.customer_id === customerLocal?.group_by));
              // setDefaultGroupSalesOrdersBy(responses[0].data.data.find((customer: Customer) => customer.customer_id === customerLocal?.group_salesorders_by));
              // setDefaultGroupBackOrdersBy(responses[0].data.data.find((customer: Customer) => customer.customer_id === customerLocal?.group_backorders_by));
              // setDefaultGroupShippingDocumentsBy(responses[0].data.data.find((customer: Customer) => customer.customer_id === customerLocal?.group_shippingdocuments_by));
              // setDefaultGroupInvoicesBy(responses[0].data.data.find((customer: Customer) => customer.customer_id === customerLocal?.group_invoices_by));

              if (responses[1].data.data && responses[1].data.data.length > 0) {
                GenericGet(`/files/${responses[1].data.data[0].object_id}`)
                  .then((response2) => {
                    let myDataUrl = `data:${responses[1].data.data[0].type_of_file};base64,${response2.data.content}`
                    setImageFile((prev) => ({ ...prev, dataURL: myDataUrl, file_id: responses[1].data.data[0].file_id }));
                    setImageLoaded(true);
                  });
              }
              setComboCustomerBankAccount(responses[13].data.data);
              let myDefaultBankAccount = responses[13].data.data.find((item: CustomerBankAccount) => item.customer_bank_account_id === response.data.primary_bank_account);
              if (myDefaultBankAccount) {
                setDefaultPrimaryBankAccount(myDefaultBankAccount);
                let myDefaultCurrency: Currency = {
                  currency_id: myDefaultBankAccount.currency_id,
                  currency_code: myDefaultBankAccount.currency_code,
                  currency_description: myDefaultBankAccount.currency_description,
                  currency_symbol: myDefaultBankAccount.currency_symbol,
                }
                setDefaultCurrency(myDefaultCurrency)
                setValue("primary_bank_account", response.data.primary_bank_account)
              }

              setComboCurrencies((prev) => responses[2].data.data);
              setComboTaxes((prev) => responses[3].data.data);
              setComboPaymentModes((prev) => responses[4].data.data);
              setComboPaymentMethods((prev) => responses[5].data.data);
              setComboCFDIs((prev) => responses[6].data.data);
              setComboTaxRegimes((prev) => responses[7].data.data);
              setComboRetentions((prev) => responses[8].data.data);
              setComboINCOTERMs((prev) => responses[9].data.data);
              setComboPaymentConditions((prev) => responses[10].data.data);
              setComboCustomsKeyCodes((prev) => responses[11].data.data);
              setComboLanguages((prev) => responses[12].data.data);

              let myDefaultTaxRegime: TaxRegime = responses[7].data.data.find((item: TaxRegime) => item.tax_regime_id === response.data.tax_regime_id);
              if(myDefaultTaxRegime) { setValue("tax_regime", `${myDefaultTaxRegime.tax_regime_code} - ${myDefaultTaxRegime.description}`); }

              setDefaultTax(responses[3].data.data.find((tax: Tax) => tax.tax_id === response.data.default_tax_id));
              setDefaultPaymentMode(responses[4].data.data.find((paymentMode: PaymentMode) => paymentMode.payment_mode_id === response.data.default_payment_mode_id));
              setDefaultPaymentMethod(responses[5].data.data.find((paymentMethod: PaymentMethod) => paymentMethod.payment_method_id === response.data.default_payment_method_id));
              setDefaultCFDI(responses[6].data.data.find((cfdi: CFDI) => cfdi.cfdi_id === response.data.default_cfdi_id));
              setDefaultTaxRegime((prev) => myDefaultTaxRegime);
              setDefaultRetention((prev) => responses[8].data.data.find((item: Retention) => item.retention_id === response.data.default_retention_id));
              setDefaultINCOTERM((prev) => responses[9].data.data.find((item: INCOTERM) => item.incoterm_id === response.data.default_incoterm_id))
              setDefaultPaymentConditions((prev) => responses[10].data.data.find((item: PaymentCondition) => item.payment_condition_id === response.data.default_payment_condition_id))
              setDefaultCustomsKeyCode((prev) => responses[11].data.data.find((item: CustomKeyCode) => item.customs_key_code_id === response.data.default_customs_key_code_id))
              setDefaultLanguage((prev) => responses[12].data.data.find((item: Language) => item.language_id === response.data.language_id));

              setOriginalCustomerIdentifier(response.data.customer_identifier);
              setDataLoaded(true);
            }).catch((error) => {
              showSnackBar(error.message, "error");
            });
        }).catch((error) => {
          showSnackBar(error.message, "error");
          navigate("/customers");
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("customers.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="outlined" onClick={() => { navigate("/customers", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <UpdateCustomer
                    customerPayload={customerLocal}
                    control={control}
                    errors={errors}
                    isEdit={resourceScreen?.update}
                    onPostImage={onPostImage}
                    loadingPost={loadingPost}
                    imageFile={imageFile}
                    imageLoaded={imageLoaded}
                    comboCustomers={comboCustomers}
                    comboTaxRegimes={comboTaxRegimes}
                    defaultTaxRegime={defaultTaxRegime}
                    comboLanguages={comboLanguages}
                    defaultLanguage={defaultLanguage}
                    customer={customerLocal}
                    isNotMexican={isNotMexican}
                    comboCustomerBankAccount={comboCustomerBankAccount}
                    defaultPrimaryBankAccount={defaultPrimaryBankAccount}
                    setComboCustomerBankAccount={setComboCustomerBankAccount}
                    setDefaultPrimaryBankAccount={setDefaultPrimaryBankAccount}
                    setDefaultCurrency={setDefaultCurrency}
                  />
                </div>

                <div className="mt-4">
                  <CustomerDetail
                    Locations={<TableCustomerLocations customer_id={customerLocal?.customer_id} getValues={getValues} setValue={setValue} reset={reset} setIsNotMexican={setIsNotMexican} isNotMexican={isNotMexican} />}
                    Items={<TableCustomerItems customer_id={customerLocal?.customer_id} business_name={customerLocal?.business_name} />}
                    // Taxes={<TableCustomerTaxes customer_id={customerLocal?.customer_id} />}
                    Taxes={
                      <FinancialDataTab
                        control={control}
                        errors={errors}
                        comboCurrencies={comboCurrencies}
                        defaultCurrency={defaultCurrency}
                        setDefaultCurrency={setDefaultCurrency}
                        comboTaxes={comboTaxes}
                        defaultTax={defaultTax}
                        setDefaultTax={setDefaultTax}
                        comboPaymentModes={comboPaymentModes}
                        defaultPaymentMode={defaultPaymentMode}
                        setDefaultPaymentMode={setDefaultPaymentMode}
                        comboPaymentMethods={comboPaymentMethods}
                        defaultPaymentMethod={defaultPaymentMethod}
                        setDefaultPaymentMethod={setDefaultPaymentMethod}
                        comboCFDIs={comboCFDIs}
                        defaultCFDI={defaultCFDI}
                        setDefaultCFDI={setDefaultCFDI}
                        comboRetention={comboRetentions}
                        defaultRetention={defaultRetention}
                        setDefaultRetention={setDefaultRetention}
                        comboINCOTERMs={comboINCOTERMs}
                        defaultINCOTERM={defaultINCOTERM}
                        setDefaultINCOTERM={setDefaultINCOTERM}
                        comboPaymentConditions={comboPaymentConditions}
                        defaultPaymentCondition={defaultPaymentConditions}
                        setDefaultPaymentCondition={setDefaultPaymentConditions}
                        setValue={setValue}
                        comboCustomsKeyCodes={comboCustomsKeyCodes}
                        defaultCustomsKeyCode={defaultCustomsKeyCode}
                        setDefaultCustomsKeyCode={setDefaultCustomsKeyCode}
                      />
                    }
                    CustomerBankAccounts={
                      <BrowserCustomerBankAccounts customer_id={customerLocal?.customer_id} />
                    }
                  />
                </div>
              </form>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("customers.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
