import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PaymentMode } from '../../../../interfaces/Sales/Catalogs/paymentModes';
import { Header } from '../../../../components/Header';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { TextField } from '@mui/material';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const AddPaymentModes = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResourceGeneric } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const {showSnackBar,SnackbarComponent}= useSnackBar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PaymentMode>({
    defaultValues: {

    }
  });
  const onSubmit: SubmitHandler<PaymentMode> = (data) => onPost(data);

  const onPost = (data: PaymentMode) => {
    let myData = {
      code: data.code ?? null,
      description: data.description ?? null,
    }
    setLoadingPost(true);
    GenericPostResource("/paymentmodes", myData)
      .then((response) => {
        navigate("/paymentmodes", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message,'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/paymentmodes", { state: {} });
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("paymentmodes.title")}`;
    })
  }, [])

  return (
    <>
      <Header title={t("paymentmodes.title-view")} 
      
      />
      <div className='d-flex justify-content-center'>

        <div className='custom-card-view w-75'>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

            <div className='d-flex w-100'>
              <div className='w-50'>
                <Controller
                  name="code"
                  control={control}
                  rules={{ required: true, maxLength: 3 }}
                  render={({ field }) =>
                    <TextField
                      variant="filled"
                      label={`${t("paymentmodes.fields.code")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => { field.onChange(event.target.value) }}
                      error={!!errors.code}
                      helperText={GetError(errors.code?.type)}
                      size="small"
                      style={{ width: "100%" }}
                      sx={{ paddingRight: 2 }}
                    />
                  }
                />
              </div>

              <div className="w-50">
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) =>
                    <TextField
                      ref={field.ref}
                      variant="filled"
                      value={field.value}
                      onChange={field.onChange}
                      label={t("paymentmodes.fields.description")}
                      error={!!errors.description}
                      helperText={GetError(errors.description?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </div>

      </div>
      <SnackbarComponent/>
    </>
  )
}
